<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt30 pb140 bgcolor-1">
      <ur-box-container alignV="start" componentid="" direction="column" class="profile_guide--box ty-consulting">
        <!-- 슬라이딩 배너 영역 -->
        <div class="ns-swiper-main profile_guide">
          <mo-carousel :number-of-page="mockData.length" height="auto" :no-rotate="true" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" class="guide_roll">
            <template v-for="(mock, idx) in mockData" v-slot:[`${idx+1}`]>
              <div class="guide_group box-shadow-type-3" :class="mock.color" :key="idx">
                <div class="txt-block fexTy3-1">
                  <span class="fs18rem fwm">{{mock.title}}</span>
                  <span class="fs12rem crTy-bk1">
                    <span class="fill-box">현재보장금액</span>
                    <!-- <span class="line-box mt4">표준모델</span> -->
                    <span class="line-box mt4">권장금액</span>
                  </span>
                </div>
                <div class="guide-img w100 mt10">
                  <div class="rChartMSPCT111D" style="position: relative; width: 100%; height: 250px; margin: auto; background: #FFFFFF;" :id="'chartView' + idx"></div>
                </div>
                <mo-button class="ns-btn-round h40 white rounded fs16rem" @click="fn_moveDetail(idx)">상세보기</mo-button>
              </div>
            </template>
          </mo-carousel>
        </div>
        <!-- 슬라이딩 배너 영역 //-->
      </ur-box-container>

      <div class="mt30 text-white-box box-shadow-type-3 bgcolor-2 fexTy3" @click="fn_openMSPCT114P">
        <div class="fexTy5">
          <mo-icon icon="msp-icon-body" class="blue-box-icon fs28 mr10" />
          <span class="fs18rem fwm">신체부위별 보장현황</span>
        </div>
        <div class="link-arrow"></div>
      </div>

        
    </ur-box-container>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  require('@/assets/lib/rMateChartH5/Assets/Css/rMateChartH5.css')
  // require('@/assets/lib/rMateChartH5/JS/rMateChartH5.js') // RadarChart 차트 사용시 해당 js 주석처리 해야함
  // require('@/assets/lib/rMateChartH5/JS/rMateRadarChartH5.js')
  require('@/assets/lib/rMateChartH5/LicenseKey/rMateChartH5License.js')
  require('@/assets/lib/rMateChartH5/JS/rMateChartVar.js')
  require('@/assets/lib/rMateChartH5/JS/rMateIntegrationH5.js')

  import MSPCT114P from '@/ui/ct/MSPCT114P'
  import CtConstants from '@/config/constants/ctConstants'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT111D",
    screenId: "MSPCT111D",
    components: {
    },
    props: {
      pCustBaseInfo: {
        type: Object
      }
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        currYm: new Date().getFullYear() + '' + (new Date().getMonth() + 1),
        swiperOption: {
          // centerSlides: true,
          slidesPerView: 1,
          spaceBetween: 20,
          autoplay: {
            delay: 50000000,
            disableOnInteraction: false
          }
        },
        contCovrList: {},
        stdCovr: {},
        mockData: [
          {
            title: '사망·후유장해 보장',
            color: 'blue',
            data: [
              {
                type: '일반사망',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              },
              {
                type: '질병사망',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              },
              {
                type: '암사망',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              },
              {
                type: '일반재해사망',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              },
              {
                type: '질병후유장해80%',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              }
            ]
          },
          {
            title: '진단 보장',
            color: 'green',
            data: [
              {
                type: '일반암',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              },
              {
                type: '뇌출혈',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              },
              {
                type: '뇌경색',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              },
              {
                type: '급성심근경색',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              },
              {
                type: '장기요양(중증)',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              }
            ]
          },
          {
            title: '수술·입원 보장',
            color: 'purple',
            data: [
              {
                type: '신수술',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              },
              {
                type: '질병종수술',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              },
              {
                type: '암수술',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              },
              {
                type: '암입원',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              },
              {
                type: '질병입원',
                amount: '0',
                requireAmount: '0',
                overAmount: '0'
              }
            ]
          }
        ]
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
    },
    beforeDestroy () {
      console.log('beforeDestroy ::: MSPCT111D')
      // 인스턴트가 remove 되기전 rMateChartH5.create 로 생성하였던 차트 의 ROOT 까지 모두 삭제 (메모리 누수 방지)
      let lv_objArr = document.getElementsByClassName('rChartMSPCT111D')
      for( let objArriDX = 0; objArriDX < lv_objArr.length; objArriDX++ ) {
        let lv_id = lv_objArr[objArriDX].firstChild !== null? lv_objArr[objArriDX].firstChild.id : null
        let lv_chartObj = document.getElementById(lv_id)
        if ( lv_chartObj !== null ) {
          lv_chartObj.destroy()
        }
      }
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_openMSPCT114P() {
        let lp_Vm = this  
        this.popupCT114P = this.$bottomModal.open(MSPCT114P, {
          properties: {
            pPage: 'MSPCT111D'
          },
          listeners: {
            onPopupSearch: () => {
              lp_Vm.$bottomModal.close(lp_Vm.popupCT114P)
            },
            onPopupClose: () => {
              lp_Vm.$bottomModal.close(lp_Vm.popupCT114P)
            }
          }
        },
        {
          properties: {
            noHeader: true
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_selTSSCT005M
      * 설명       : 보장분석요약 조회(세대중심)
      ******************************************************************************/
      fn_selTSSCT005M () {
        let tcompAcont = [] // 당사기계약
        let otcomAcont = [] // 타사기계약
        let contCovrList = [] // 보장급부 list (보장자산)
        let contFprdDthTrtyExpYmList = [] // 정기사망특약만기일자 list (contCovrList와 index가 동일하면 같은 상품임)

        let covrStnrdMdl = [] // 보장표준모델
        let stdCovr = [] // 표준모델

        let covanCT005Temp = []

        covrStnrdMdl = this.getStore('ctStore').getters.getCovanData.data.gtplCllgHoshVO.covrStnrdMdlVO // 보장표준모델VO
        covanCT005Temp = this.getStore('ctStore').getters.getCovanData.data.gtplCllgHoshVO.gtplCllgCustVO // 보장플랜종합고객VO

        let custBaseInfo = {}
        let sxdsCd = 0 // 성별

        this.TSSCT005MParam = this.$props.pCustBaseInfo
        let tcompKcisSc = this.getStore('ctStore').getters.getTcompKcisSc // 당사,신정원 정보출처구분
        console.log('covanCT005Temp >>> ', covanCT005Temp, this.tcompKcisSc)
        for (let i in covanCT005Temp) {
          if (this.TSSCT005MParam.data.chnlCustId === covanCT005Temp[i].chnlCustId) {
          // 선택한 고객으로 필터링된 보장플랜종합고객VO의 성별을 고객정보 vuex 의 성별 셋팅
          // (고객파트에서 파라미터로 주는 성별이 유효하지 않는 경우가 있어 보장분석 기준 성별로 다시 셋팅함)
            sxdsCd = covanCT005Temp[0].sxdsCd

            custBaseInfo = {
              'age': this.TSSCT005MParam.data.age,
              'gender': sxdsCd,
              'custNm': this.TSSCT005MParam.data.custNm,
              'chnlCustId': this.TSSCT005MParam.data.chnlCustId
            }
            this.getStore('ctStore').dispatch('CUSTREG', {'data': custBaseInfo})

            // 당사기계약
            for (var indexTComAcont001 in covanCT005Temp[i].tcompAcontVO01) {
              if ( covanCT005Temp[i].tcompAcontVO01[indexTComAcont001].mnisdNm === this.TSSCT005MParam.data.custNm ||
              covanCT005Temp[i].tcompAcontVO01[indexTComAcont001].aisdNm === this.TSSCT005MParam.data.custNm ) {
              // 보장급부 정보가 유효하면
                if ( covanCT005Temp[i].tcompAcontVO01[indexTComAcont001].tcompAcontCovrVO.length > 0 ) {
                  tcompAcont.push(covanCT005Temp[i].tcompAcontVO01[indexTComAcont001])
                }
              }
            }
            for (var indexTComAcont002 in covanCT005Temp[i].tcompAcontVO02) {
              if ( covanCT005Temp[i].tcompAcontVO02[indexTComAcont002].mnisdNm === this.TSSCT005MParam.data.custNm ||
              covanCT005Temp[i].tcompAcontVO02[indexTComAcont002].aisdNm === this.TSSCT005MParam.data.custNm ) {
              // 보장급부 정보가 유효하면
                if ( covanCT005Temp[i].tcompAcontVO02[indexTComAcont002].tcompAcontCovrVO.length > 0 ) {
                  tcompAcont.push(covanCT005Temp[i].tcompAcontVO02[indexTComAcont002])
                }
              }
            }
            
            // 타사기계약
            for (var indexOTComAcont001 in covanCT005Temp[i].otcomAcontVO01) {
              console.log('indexOTComAcont001 >> ',i, '번째 mnisdNm >> ', covanCT005Temp[i].otcomAcontVO01[indexOTComAcont001].mnisdNm)
              if ( covanCT005Temp[i].otcomAcontVO01[indexOTComAcont001].mnisdNm === this.TSSCT005MParam.data.custNm ||
              covanCT005Temp[i].otcomAcontVO01[indexOTComAcont001].aisdNm === this.TSSCT005MParam.data.custNm ) {
              // 보장급부 정보가 유효하면
                if ( covanCT005Temp[i].otcomAcontVO01[indexOTComAcont001].otcomAcontCovrVO.length > 0 ) {
                  otcomAcont.push(covanCT005Temp[i].otcomAcontVO01[indexOTComAcont001])
                }
              }
            }
            for (var indexOTComAcont002 in covanCT005Temp[i].otcomAcontVO02) {
              console.log('indexOTComAcont002 >> ',i, '번째 mnisdNm >> ', covanCT005Temp[i].otcomAcontVO02[indexOTComAcont002].mnisdNm)
              if ( covanCT005Temp[i].otcomAcontVO02[indexOTComAcont002].mnisdNm === this.TSSCT005MParam.data.custNm ||
              covanCT005Temp[i].otcomAcontVO02[indexOTComAcont002].aisdNm === this.TSSCT005MParam.data.custNm ) {
              // 보장급부 정보가 유효하면
                if ( covanCT005Temp[i].otcomAcontVO02[indexOTComAcont002].otcomAcontCovrVO.length > 0 ) {
                  otcomAcont.push(covanCT005Temp[i].otcomAcontVO02[indexOTComAcont002])
                }
              }
            }

            // 고객별 표준모델 값을 가져오되, 해당값이 빈값이면 보장표준모델 vo에서 보장정보르 가져온다
            // console.log('covanCT005Temp[i].stnrdMdlClbyCustVO.chnlCustId ::: ' + covanCT005Temp[i].stnrdMdlClbyCustVO.chnlCustId)
            // console.log('covanCT005Temp[i].stnrdMdlClbyCustVO.chnlCustId isEmpty ::: ' + this.$bizUtil.isEmpty( covanCT005Temp[i].stnrdMdlClbyCustVO.chnlCustId))

            if ( this.$bizUtil.isEmpty( covanCT005Temp[i].stnrdMdlClbyCustVO.chnlCustId ) ) {
              for (let indexFilterItem in covrStnrdMdl) {
                if ( covrStnrdMdl[indexFilterItem].gtplCovrMdlScCd === '01' ) { // '01' 표준모델 '02' 최저모델
                  if ( stdCovr.length === 0 ) {
                    stdCovr.push(covrStnrdMdl[indexFilterItem].gtplCovrVO)
                    // 보장플랜보장 모델 구분 코드 , 보장플랜보장 급부 정보 저장
                    this.getStore('ctStore').dispatch('CUSTCOVRST', {'gtplCovrMdlScCd': covrStnrdMdl[indexFilterItem].gtplCovrMdlScCd, 'data': covrStnrdMdl[indexFilterItem].gtplCovrVO})
                  }
                }
              }
            } else {
              if ( covanCT005Temp[i].stnrdMdlClbyCustVO.chnlCustId === this.TSSCT005MParam.data.chnlCustId ) {
                if ( stdCovr.length === 0 ) {
                  stdCovr.push(covanCT005Temp[i].stnrdMdlClbyCustVO.gtplCovrVO)
                  // 보장플랜보장 모델 구분 코드 , 보장플랜보장 급부 정보 저장
                  this.getStore('ctStore').dispatch('CUSTCOVRST', {'gtplCovrMdlScCd': covanCT005Temp[i].stnrdMdlClbyCustVO.gtplCovrMdlScCd, 'data': covanCT005Temp[i].stnrdMdlClbyCustVO.gtplCovrVO})
                }
              }
            }
          }
        }
        console.log('stdCovr ::: ' ,stdCovr)
        // 계약번호로 중복데이터 제거(당사)
        tcompAcont = tcompAcont.filter((tcompAcont, index, self) => index === self.findIndex((t) => (t.contNo === tcompAcont.contNo)))
        // 계약번호로 중복데이터 제거(타사)
        otcomAcont = otcomAcont.filter((otcomAcont, index, self) => index === self.findIndex((o) => (o.otcomContSno === otcomAcont.otcomContSno)))

        let tcompAcontF = []
        let otcomAcontF = []
        // 정보출처 당사
        if(tcompKcisSc === '1'){
          // 당사정보는 그대로 사용
          tcompAcontF = this.$bizUtil.cloneDeep(tcompAcont)
          // 타사정보는 당사정보 제외
          for(let otcomAcontIdx in otcomAcont){
            // 신정원데이터이고 회사명에 삼성생명이 포함되면 제외
            if(otcomAcont[otcomAcontIdx].insrCoNm.indexOf('삼성생명') > -1 && otcomAcont[otcomAcontIdx].gtplOtcomPrdtInptScCd === '4'){
              continue
            }
            otcomAcontF.push(otcomAcont[otcomAcontIdx])
          }
        }
        // 정보출처 신정원
        else if(tcompKcisSc === '2'){
          for(let otcomAcontIdx in otcomAcont){
            // 신정원데이터이고 회사명에 삼성생명이 포함되면
            if(otcomAcont[otcomAcontIdx].insrCoNm.indexOf('삼성생명') > -1 && otcomAcont[otcomAcontIdx].gtplOtcomPrdtInptScCd === '4'){
              for(let tcompAcontIdx in tcompAcont){
                // 당사정보에 계약번호가 존재하면 추가
                if(tcompAcont[tcompAcontIdx].contNo.trim().indexOf(otcomAcont[otcomAcontIdx].insrSecusNo.trim()) > -1){
                  otcomAcontF.push(otcomAcont[otcomAcontIdx])
                }
              }
            }else{
              otcomAcontF.push(otcomAcont[otcomAcontIdx])
            }
          }
        }

        tcompAcont = this.$bizUtil.cloneDeep(tcompAcontF)
        otcomAcont = this.$bizUtil.cloneDeep(otcomAcontF)
    console.log('111D tcompAcont, otcomAcont >> ', tcompAcont, otcomAcont)

        // 본인(검색 고객)이 피보험자(주피/종피) 인 계약건만 대상 (::: 혹은 고객 ID 를 파라미터 고객ID로 필터)
        // tcompAcont = tcompAcont.filter( x => { return ( x.mnisdNm === this.TSSCT005MParam.data.custNm || x.aisdNm === this.TSSCT005MParam.data.custNm ) })
        // otcomAcont = otcomAcont.filter( x => { return ( x.mnisdNm === this.TSSCT005MParam.data.custNm || x.aisdNm === this.TSSCT005MParam.data.custNm ) })

        console.log('tcompAcont ::: ' ,tcompAcont)
        console.log('otcomAcont ::: ' ,otcomAcont)
        for (let indexFilterItem in tcompAcont) {
          for (let i in tcompAcont[indexFilterItem].tcompAcontCovrVO) {
            // this.$bizUtil.getFirstDayOfNowMonth 함수는 크롬 70대 버전에서 지원하지 않는 구문이 있어서 주석처리하고 다른 구문으로 대체
            if ( tcompAcont[indexFilterItem].tcompAcontCovrVO[i].gtplCovrYr === new Date().getFullYear().toString() ) { // this.$bizUtil.getFirstDayOfNowMonth('String').substr(0, 4)
              contCovrList.push(tcompAcont[indexFilterItem].tcompAcontCovrVO[i].gtplCovrVO)
              contFprdDthTrtyExpYmList.push('') // 정기사망특약만기일자 (미등록타사에만 존재하지만 contCovrList와 index 맞추기 위해 dummyData 넣음)
            }
          }
        }
        // console.log('당사 contCovrList length ::: ' + contCovrList.length)

        for (let indexOtcomFilterItem in otcomAcont) {
          for (let j in otcomAcont[indexOtcomFilterItem].otcomAcontCovrVO) {
            // this.$bizUtil.getFirstDayOfNowMonth 함수는 크롬 70대 버전에서 지원하지 않는 구문이 있어서 주석처리하고 다른 구문으로 대체
            if ( otcomAcont[indexOtcomFilterItem].otcomAcontCovrVO[j].gtplCovrYr === new Date().getFullYear().toString() ) { // this.$bizUtil.getFirstDayOfNowMonth('String').substr(0, 4)
              contCovrList.push(otcomAcont[indexOtcomFilterItem].otcomAcontCovrVO[j].gtplCovrVO)
              contFprdDthTrtyExpYmList.push(otcomAcont[indexOtcomFilterItem].fprdDthTrtyExpYm) // 정기사망특약만기일자
            }
          }
        }

        console.log('contCovrList ::: ' ,contCovrList)

        let tmpctTableLoadMap = this.$bizUtil.cloneDeep(CtConstants.ctTableLoadMap)
        let tmpregOtcomInsrVO = tmpctTableLoadMap.reduce((acc,cur)=> { return acc.concat(cur.columns) }, []) // CtConstants.regOtcomInsrVO
        for (let index = 0; index < tmpregOtcomInsrVO.length; index++) {
          let tmpData = tmpregOtcomInsrVO[index].data
          for (let idx = 0; idx < tmpData.length; idx++) {
            if (tmpData[idx].hasOwnProperty('type')) {
              let tmp = {}
              tmp['label'] = tmpData[idx].type
              tmp['attr'] = tmpData[idx].attr
              tmp['value'] = '0'
              delete tmpData[idx]
              tmpData[idx] = tmp
            }
          }
        }
        tmpregOtcomInsrVO.category = tmpregOtcomInsrVO.title

        // let tmpctTableEngNmLoadMap = []
        // for (let index = 0; index < tmpregOtcomInsrVO.length; index++) {
        //   tmpctTableEngNmLoadMap.push(tmpregOtcomInsrVO[index].data.reduce((acc,cur)=> { return acc.concat(cur.attr) }, []))
        // }

        
        let covrNmList = (tmpregOtcomInsrVO.reduce((acc,cur)=> { return acc.concat(cur.data) },[])).reduce((acc,cur)=> { return acc.concat(cur.attr) },[])
        /* var covrNmList = [
          // 가족보장
          'genDthCovrAmt', 'diseDthCovrAmt', 'cncrDthCovrAmt', 'genDstrDthCovrAmt',
          // 생활보장
          'dstrDsabRatOhrCovrAmt', 'dstrDsabRatSvtyCovrAmt', 'dstrDsabRatThCovrAmt',

          // 뇌혈관질환(cevarDsCovrAmt), 일과성 뇌허혈발작(tiscaDignCovrAmt), 심방세동/조동(afaflDignCovrAmt) 추가 - 보장분석시스템 고도화PJT
          'genCncrDignCovrAmt', 'lgatCncrDignCovrAmt', 'crbhmDignCovrAmt', 'cbifDignCovrAmt', 'cevarDsCovrAmt',
          'tiscaDignCovrAmt', 'actmiDignCovrAmt', 'spfIshHetDsDignAmt', 'ishHetDsDignAmt', 'afaflDignCovrAmt',
          'ltcDignCovrAmt', 'ltcMsptDignCovrAmt', 'ssptDmntDignCovrAmt', 'msptDmntDignCovrAmt', 'diabtDignCovrAmt', // >>> 진단

          // 의료보장
          // 'diseHspzRldmCovrAmt', 'diseMdhspRldmCovrAmt', 'dstrHspzRldmCovrAmt', 'dstrMdhspRldmCovrAmt', 'mtherRldmCovrAmt',
          // 'injcRldmCovrAmt', 'mriRldmCovrAmt', // >>> 의료실손
          'sroSurgCovrAmt', 'nwSurgCovrAmt', 'feclsCovrAmt', 'cncrSurgCovrAmt', 'spfDiseSurgCovrAmt', // >>> 수술
          'cncrHspzCovrAmt', 'genDiseHspzCovrAmt', 'dstrHspzCovrAmt', 'ondayGenHspzCovrAmt', 'hspzCarerUseDignAmt', // >>> 입원
          'cncrMdhspRmdyCovrAmt', 'cncrRarMdhspCovrAmt', 'dstrFrctDignCovrAmt',
          'cncrMdhspRmdyCovrAmt', 'cncrRarMdhspCovrAmt', 'lgatAntirDignCovrAmt', 'immueAntirDignCovrAmt', 'dstrFrctDignCovrAmt', 'pstsRmdyCovrAmt', 'psvtRmdyCovrAmt' // >>> 통원치료
        ] */
        
        // console.log(covrNmList)
        for (let indexCovrNm=0; indexCovrNm<covrNmList.length; indexCovrNm++) {
          this.contCovrList[covrNmList[indexCovrNm]] = 0
          this.stdCovr[covrNmList[indexCovrNm]] = 0

          for (let jdx=0; jdx<contCovrList.length; jdx++) {
            if ((covrNmList[indexCovrNm] === 'genDthCovrAmt' || covrNmList[indexCovrNm] === 'diseDthCovrAmt' || covrNmList[indexCovrNm] === 'cncrDthCovrAmt' || covrNmList[indexCovrNm] === 'genDstrDthCovrAmt' || covrNmList[indexCovrNm] === 'dstrDsabRatOhrCovrAmt') &&
            // if ((covrNmList[indexCovrNm] === 'genDthCovrAmt' || covrNmList[indexCovrNm] === 'diseDthCovrAmt' || covrNmList[indexCovrNm] === 'cncrDthCovrAmt' || covrNmList[indexCovrNm] === 'genDstrDthCovrAmt') || covrNmList[indexCovrNm] === 'dstrDsabRatEyCovrAmt') &&
                contFprdDthTrtyExpYmList[jdx] >= this.currYm) {
              this.contCovrList[covrNmList[indexCovrNm]] += (this.$bizUtil.isEmpty(contCovrList[jdx][covrNmList[indexCovrNm]]) ? 0 : contCovrList[jdx][covrNmList[indexCovrNm]].longAmount) + (this.$bizUtil.isEmpty(contCovrList[jdx]['fprdDthTrtyCovrAmt']) ? 0 : contCovrList[jdx]['fprdDthTrtyCovrAmt'].longAmount)
            } else {
              this.contCovrList[covrNmList[indexCovrNm]] += this.$bizUtil.isEmpty(contCovrList[jdx][covrNmList[indexCovrNm]]) ? 0 : contCovrList[jdx][covrNmList[indexCovrNm]].longAmount
            }
          }

          for (let k=0; k<stdCovr.length; k++) {
            // console.log('stdCovr length::: ' + stdCovr.length)
            this.stdCovr[covrNmList[indexCovrNm]] += this.$bizUtil.isEmpty(stdCovr[k][covrNmList[indexCovrNm]]) ? 0 : stdCovr[k][covrNmList[indexCovrNm]].longAmount
          }
        }

        // 가족, 생활, 의료 보장 급부 전체 계산후 문구 표시
        // console.log('contCovrList ::: ' + JSON.stringify(contCovrList))
        // console.log('stdCovrt ::: ' + JSON.stringify(stdCovr))

        const fIndex = 0 // 가족보장 index
        let fChartDataList = [] // 가족보장 차트 데이터

        // 가족보장 급부 항목 list (일반사망, 질병사망, 암사망,일반재해사망, 재해장해100%)
        let fChartCovrAmtList = [ {covrAmt: 'genDthCovrAmt', covrName: '일반사망', covrLabelName: '일반사망'},
          {covrAmt: 'diseDthCovrAmt', covrName: '질병사망', covrLabelName: '질병<br><br>사망'},
          {covrAmt: 'cncrDthCovrAmt', covrName: '암사망', covrLabelName: '암사망'},
          {covrAmt: 'genDstrDthCovrAmt', covrName: '일반재해사망', covrLabelName: '일반재해사망'},
          // {covrAmt: 'dstrDsabRatOhrCovrAmt', covrName: '재해후유장해80%', covrLabelName: '재해후유장해<br><br>100%'} ]
          {covrAmt: 'dstrDsabRatEyCovrAmt', covrName: '재해후유장해80%', covrLabelName: '재해후유장해<br><br>80%'} ]

        for ( let i=0 ; i<fChartCovrAmtList.length; i++ ) {
          // 가족보장 보장분석 요약 데이터
          this.mockData[fIndex].data[i].amount = this.$commonUtil.numberWithCommas( Math.floor( this.contCovrList[fChartCovrAmtList[i].covrAmt] / 10000 ) ) // 보장금액
          this.mockData[fIndex].data[i].requireAmount = this.$commonUtil.numberWithCommas( Math.floor( this.stdCovr[fChartCovrAmtList[i].covrAmt] / 10000 ) ) // 표준금액
          this.mockData[fIndex].data[i].overAmount = Math.floor( this.contCovrList[fChartCovrAmtList[i].covrAmt] / 10000 ) - Math.floor( this.stdCovr[fChartCovrAmtList[i].covrAmt] / 10000 ) // 과부족금액
          this.mockData[fIndex].data[i].overAmount = this.$commonUtil.numberWithCommas( this.mockData[fIndex].data[i].overAmount > 0 ? '+' + this.mockData[fIndex].data[i].overAmount : this.mockData[fIndex].data[i].overAmount )

          // 가족보장 차트 데이터
          let fChart = {}
          fChart.covrName = fChartCovrAmtList[i].covrName // 보장급부명
          fChart.covrLabelName = fChartCovrAmtList[i].covrLabelName // 보장급부명 (라벨표시용)
          fChart.covrAmt = Math.floor( this.contCovrList[fChartCovrAmtList[i].covrAmt] / 10000 ) // 보장금액
          fChart.requireAmt = Math.floor( this.stdCovr[fChartCovrAmtList[i].covrAmt] / 10000 ) // 표준금액

          console.log(i , ' covrAmt >>> ' , fChartCovrAmtList[i].covrAmt , ' >>> ', this.contCovrList[fChartCovrAmtList[i].covrAmt])
          console.log(i , ' requireAmt >>> ' , fChartCovrAmtList[i].covrAmt , ' >>> ', this.stdCovr[fChartCovrAmtList[i].covrAmt])

          // 보장금액 대비 표준비율 (보장금액 / 표준금액) * 100
          let covrRate = Math.floor((fChart.covrAmt / fChart.requireAmt) * 100)
          fChart.covrRate = covrRate >= 100 ? 100 : covrRate
          fChart.maxRate = 100

          fChart.overAmt = Math.floor(this.contCovrList[fChartCovrAmtList[i].covrAmt] - this.stdCovr[fChartCovrAmtList[i].covrAmt]) // 과부족금액
          fChart.covrInfo = fChart.covrLabelName + '|' + fChart.overAmt // 보장급부명|과부족금액 (차트데이터 컨트롤 용)
          // fChart.covrInfo = {covrLabelName : fChart.covrLabelName, overAmt : fChart.overAmt}  // 보장급부명|과부족금액 (차트데이터 컨트롤 용)
          fChartDataList.push(fChart)
        }

        const lIndex = 1 // 생활보장 index
        let lChartDataList = [] // 생활보장 차트 데이터

        // 생활보장 급부 항목 list (일반암, 뇌출혈, 뇌경색, 급성심근경색, LTC(중증))
        let lChartCovrAmtList = [ {covrAmt: 'genCncrDignCovrAmt', covrName: '일반암', covrLabelName: '일반암'},
          {covrAmt: 'crbhmDignCovrAmt', covrName: '뇌출혈', covrLabelName: '뇌출혈'},
          {covrAmt: 'cbifDignCovrAmt', covrName: '뇌경색', covrLabelName: '뇌경색'},
          {covrAmt: 'actmiDignCovrAmt', covrName: '급성심근경색', covrLabelName: '급성심근경색'},
          {covrAmt: 'ltcDignCovrAmt', covrName: '장기요양(중증)', covrLabelName: '장기요양<br><br>(중증)'} ]

        for ( let j=0; j<lChartCovrAmtList.length; j++ ) {
          // 생활보장 보장분석 요약 데이터
          this.mockData[lIndex].data[j].amount = this.$commonUtil.numberWithCommas( Math.floor( this.contCovrList[lChartCovrAmtList[j].covrAmt] / 10000 ) ) // 보장금액
          this.mockData[lIndex].data[j].requireAmount = this.$commonUtil.numberWithCommas(Math.floor( this.stdCovr[lChartCovrAmtList[j].covrAmt] / 10000 ) ) // 표준금액
          this.mockData[lIndex].data[j].overAmount = Math.floor( this.contCovrList[lChartCovrAmtList[j].covrAmt] / 10000 ) - Math.floor( this.stdCovr[lChartCovrAmtList[j].covrAmt] / 10000 ) // 과부족금액
          this.mockData[lIndex].data[j].overAmount = this.$commonUtil.numberWithCommas( this.mockData[lIndex].data[j].overAmount > 0 ? '+' + this.mockData[lIndex].data[j].overAmount : this.mockData[lIndex].data[j].overAmount )

          // 생활보장 차트 데이터
          let lChart = {}
          lChart.covrName = lChartCovrAmtList[j].covrName // 보장급부명
          lChart.covrLabelName = lChartCovrAmtList[j].covrLabelName // 보장급부명 (라벨표시용)
          lChart.covrAmt = Math.floor( this.contCovrList[lChartCovrAmtList[j].covrAmt] / 10000 ) // 보장금액
          lChart.requireAmt = Math.floor( this.stdCovr[lChartCovrAmtList[j].covrAmt] / 10000 ) // 표준금액

          // 보장금액 대비 표준비율 (보장금액 / 표준금액)*100
          let covrRate = Math.floor((lChart.covrAmt / lChart.requireAmt) * 100)
          lChart.covrRate = covrRate >= 100 ? 100 : covrRate
          lChart.maxRate = 100

          lChart.overAmt = Math.floor(this.contCovrList[lChartCovrAmtList[j].covrAmt] - this.stdCovr[lChartCovrAmtList[j].covrAmt]) // 과부족금액
          lChart.covrInfo = lChart.covrLabelName + '|' + lChart.overAmt // 보장급부명|과부족금액 (차트데이터 컨트롤 용)
          // lChart.covrInfo = {covrLabelName : lChart.covrLabelName, overAmt : lChart.overAmt}  // 보장급부명|과부족금액 (차트데이터 컨트롤 용)
          lChartDataList.push(lChart)
        }

        const hIndex = 2 // 의료보장 index
        let hChartDataList = [] // 의료보장 차트 데이터

        // 의료보장 급부 항목 list (신수술, 질병종수술, 암수술, 암입원, 질병입원)
        let hChartCovrAmtList = [ {covrAmt: 'nwSurgCovrAmt', covrName: '신수술', covrLabelName: '신수술'},
          {covrAmt: 'feclsCovrAmt', covrName: '질병종수술', covrLabelName: '질병종수술'},
          {covrAmt: 'cncrSurgCovrAmt', covrName: '암수술', covrLabelName: '암수술'},
          {covrAmt: 'cncrHspzCovrAmt', covrName: '암입원', covrLabelName: '암입원'},
          {covrAmt: 'genDiseHspzCovrAmt', covrName: '질병입원', covrLabelName: '질병<br><br>입원'} ]

        for ( let k=0; k<hChartCovrAmtList.length; k++ ) {
          // 의료보장 보장분석 요약 데이터
          this.mockData[hIndex].data[k].amount = this.$commonUtil.numberWithCommas( Math.floor( this.contCovrList[hChartCovrAmtList[k].covrAmt] / 10000 ) ) // 보장금액
          this.mockData[hIndex].data[k].requireAmount = this.$commonUtil.numberWithCommas(Math.floor( this.stdCovr[hChartCovrAmtList[k].covrAmt] / 10000 ) ) // 표준금액
          this.mockData[hIndex].data[k].overAmount = Math.floor( this.contCovrList[hChartCovrAmtList[k].covrAmt] / 10000 ) - Math.floor( this.stdCovr[hChartCovrAmtList[k].covrAmt] / 10000 ) // 과부족금액
          this.mockData[hIndex].data[k].overAmount = this.$commonUtil.numberWithCommas( this.mockData[hIndex].data[k].overAmount > 0 ? '+' + this.mockData[hIndex].data[k].overAmount : this.mockData[hIndex].data[k].overAmount )

          // 의료보장 차트 데이터
          let hChart = {}
          hChart.covrName = hChartCovrAmtList[k].covrName // 보장급부명
          hChart.covrLabelName = hChartCovrAmtList[k].covrLabelName // 보장급부명 (라벨표시용)
          hChart.covrAmt = Math.floor( this.contCovrList[hChartCovrAmtList[k].covrAmt] / 10000 ) // 보장금액
          hChart.requireAmt = Math.floor( this.stdCovr[hChartCovrAmtList[k].covrAmt] / 10000 ) // 표준금액

          // 보장금액 대비 표준비율 (보장금액 / 표준금액)*100
          let covrRate = Math.floor((hChart.covrAmt / hChart.requireAmt) * 100)
          hChart.covrRate = covrRate >= 100 ? 100 : covrRate
          hChart.maxRate = 100

          hChart.overAmt = Math.floor(this.contCovrList[hChartCovrAmtList[k].covrAmt] - this.stdCovr[hChartCovrAmtList[k].covrAmt]) // 과부족금액
          hChart.covrInfo = hChart.covrLabelName + '|' + hChart.overAmt // 보장급부명|과부족금액 (차트데이터 컨트롤 용)
          // hChart.covrInfo = {covrLabelName : hChart.covrLabelName, overAmt : hChart.overAmt}  // 보장급부명|과부족금액 (차트데이터 컨트롤 용)
          hChartDataList.push(hChart)
        }

        console.log('fChartDataList >> ', fChartDataList)
        // 차트 데이터 생성
        this.fn_makeChartData([fChartDataList, lChartDataList, hChartDataList])
      },
      /* eslint-disable */
      /******************************************************************************
       * Function명 : fn_makeChartData
       * 설명       : 보장분석요약화면 차트 표시
       *            : eslint-disable 설정을 위해 함수위치를 스크립트 하단으로 이동 - 보장분석시스템 고도화 PJT
      ******************************************************************************/
      fn_makeChartData (pChartData) {
        console.log('======================= fn_makeChartData ============================', pChartData)
        for(let i=0; i<pChartData.length; i++){
          chartData = undefined
          layoutStr = undefined

          let lv_rgba_line = '' // 방사형 차트 점/선 색깔
          let lv_rgba_area = '' // 방사형 차트 면 색깔
          let lv_rgba_line_max = '' // 방사형 차트 점/선 색깔
          let lv_rgba_area_max = '<SolidColor color="#ffffff" alpha="0"/>' // 방사형 차트 면 색깔
          if ( i === 0 ) { // 사망·재해장해 보장
            lv_rgba_line = '<Stroke color="#2793ff" weight="0.1"/>'
            lv_rgba_area = '<SolidColor color="#2793ff" alpha="0.2"/>'
            lv_rgba_line_max = '<Stroke color="#519ef6" weight="2"/>'
          } else if ( i === 1 ) { // 진단 보장
            lv_rgba_line = '<Stroke color="#28dcde" weight="0.1"/>'
            lv_rgba_area = '<SolidColor color="#28dcde" alpha="0.2"/>'
            lv_rgba_line_max = '<Stroke color="#00a4a6" weight="2"/>'
          } else if ( i === 2 ) { // 수술·입원 보장
            lv_rgba_line = '<Stroke color="#9d2cff" weight="0.1"/>'
            lv_rgba_area = '<SolidColor color="#9d2cff" alpha="0.2"/>'
            lv_rgba_line_max = '<Stroke color="#7960ff" weight="2"/>'
          }

          chartData = pChartData[i]
          layoutStr = `<rMateChart backgroundColor="#FFFFFF"  borderStyle="none">
                        <Options>
                          <Caption fontFamily="Noto Sans KR, Arial, sans-serif"/>
                        </Options>
                        <RadarChart id="ct0005m`+i+`" isSeriesOnAxis="true" type="polygon" paddingTop="25" paddingBottom="25" paddingLeft="60" paddingRight="60" startAngle="270" mouseSensitivity="17" letter-spacing="0.5px" line-height="7px">
                          <backgroundElements>
                            <RadarGridLines>
                                <radarAlternateFill>
                                  <SolidColor color="#ffffff"/>
                                </radarAlternateFill>
                            </RadarGridLines>
                          </backgroundElements>
                          <radialAxis>
                            <LinearAxis id="rAxis" maximum="100"/>
                          </radialAxis>
                          <angularAxis>
                            <CategoryAxis id="aAxis" categoryField="covrInfo" displayName="" labelJsFunction="fn_TSSCT005M_LabelFunc"/>
                          </angularAxis>
                          <radialAxisRenderers>
                            <Axis2DRenderer axis="{rAxis}" horizontal="false" visible="false" tickPlacement="outside" tickLength="4">
                              <axisStroke>
                                <Stroke color="#555555" weight="1"/>
                              </axisStroke>
                            </Axis2DRenderer>
                          </radialAxisRenderers>
                          <radialAxisRenderers>
                            <Axis2DRenderer axis="{rAxis}" horizontal="false" visible="false" tickPlacement="outside" tickLength="4">
                              <axisStroke>
                                <Stroke color="#555555" weight="1"/>
                              </axisStroke>
                            </Axis2DRenderer>
                          </radialAxisRenderers>
                          <angularAxisRenderers>
                            <AngularAxisRenderer axis="{aAxis}" labelGap="12"/>
                          </angularAxisRenderers>
                          <series>
                            <RadarSeries radius="0" field="covrRate" selectionRadius="0">
                              <showDataEffect>
                                <SeriesInterpolate/>
                              </showDataEffect>
                              <lineStroke>` + lv_rgba_line + `</lineStroke>
                              <areaFill>` + lv_rgba_area   + `</areaFill>
                            </RadarSeries>
                            <RadarSeries radius="0" field="maxRate" selectionRadius="0">
                              <showDataEffect>
                                <SeriesInterpolate/>
                              </showDataEffect>
                              <lineStroke>` + lv_rgba_line_max + `</lineStroke>
                              <areaFill>` + lv_rgba_area_max   + `</areaFill>
                            </RadarSeries>
                          </series>
                        </RadarChart>
                      </rMateChart>`

          rMateChartH5.create('ct0005m'+i, 'chartView'+i, 'rMateOnLoadCallFunction=chartReadyHandler', '100%', '100%')
          rMateChartH5.registerTheme(rMateChartH5.themes)
        }
      },
      fn_moveDetail(idx) {
        console.log('moveDetail >> ', idx)
        this.$emit('moveDetail', idx)
      }
    }
  };
</script>
<style scoped>
</style>