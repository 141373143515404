 /*
 * 업무구분: 타사증권 입력
 * 화 면 명: MSPCT144D
 * 화면설명: 타사증권 입력 1.상품선택 단계 (미등록상품)
 * 접근권한:
 * 작 성 일: 2023.03
 * 작 성 자: 정지윤
 */
 <template>
  <!-- Content영역 -->
  <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30 mb200">
    <ur-box-container alignV="start" componentid="" direction="column" class=""> <!-- class="info-box mt20" -->
      <div class="info-title-wrap mb0">
        <span class="fs14rem crTy-bk7 must">가입회사</span>
      </div>
      <ur-box-container alignV="start" componentid="" direction="column" class="">
        <mo-text-field  class="form-input-name full" underline clearable placeholder="입력" v-model="contractorCompany" @input="fn_replaceNm" :rules="validateRuleRequire1" />
      </ur-box-container>
    </ur-box-container>  

    <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20"> 
      <div class="info-title-wrap mb0">
        <span class="fs14rem crTy-bk7 must">상품</span>
      </div>
      <ur-box-container alignV="start" componentid="" direction="column" class="">
        <mo-text-field  class="form-input-name full" underline clearable placeholder="입력" v-model="productName" @input="fn_replaceNm" :rules="validateRuleRequire2" />
      </ur-box-container>
    </ur-box-container> 

    <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20"> 
      <div class="info-title-wrap mb0">
        <span class="fs14rem crTy-bk7">유니버설 상품</span>
      </div>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
        <mo-segment-wrapper solid primary v-model="universal" class="chip-type-wrap">
          <mo-segment-button value="1">예</mo-segment-button>
          <mo-segment-button value="2">아니오</mo-segment-button>
        </mo-segment-wrapper>
      </ur-box-container> 
    </ur-box-container>     
  </ur-box-container>
  <!-- Content 영역 end -->
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT144D",
    screenId: "MSPCT144D",
    components: { },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
            // 상단 검색, 카테고리 버튼 생성
      this.actionSlot = {
        actionComponent: {
          template:'<span><mo-button shape="border" class="ns-btn-round btn-title-style-1">보장설계</mo-button> <mo-icon icon-size="30px" type="picto" >more</mo-icon></span>',
          methods: {

          }
        }
      }
    },
    mounted () {
      
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        universal: '1', // 유니버설 ('1':예, '2':아니오)
        gtplInsrScCd: '03', // 보장플랜보험구분코드 '':일반, '03':유니버설
        contractorCompany: '', // 가입회사
        validateRuleRequire1: [
          v => !!v || '회사명을 입력해 주세요'
        ],
        productName: '', // 상품명
        validateRuleRequire2: [
          v => !!v || '상품명을 입력해 주세요'
        ],
        isComPro: false, //

        // 수정 및 삭제
        entryType: '',
        getSelOtcomInsr: {},
        contData: {},
        sno: '',
        chnlCustId: '',
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    },

    watch: {
      // 상품 구분(일반/유니버설)
      universal() {
        if (this.universal === '1') { // 예(유니버설)
          this.gtplInsrScCd = '03'
        } else {                  // 아니오(일반)
          this.gtplInsrScCd = ''
        }
      },

      contractorCompany() {
        //if(this.contractorCompany.length > 0 && this.productName.length > 0) {
        if(this.contractorCompany !== '' && this.productName !== '') {
          this.isComPro = true
          this.$emit('isComPro', !this.isComPro) // MSPCT140P의 다음 버튼 활성화 (회사명, 상품명 입력 시)
        } else {
          this.isComPro = false
          this.$emit('isComPro', !this.isComPro)
        }
      },
      
      productName() {
        if(this.contractorCompany !== '' && this.productName !== '') {
          this.isComPro = true
          this.$emit('isComPro', !this.isComPro) // MSPCT140P의 다음 버튼 활성화 (회사명, 상품명 입력 시)
        } else {
          this.isComPro = false
          this.$emit('isComPro', !this.isComPro)
        }
      },

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_selUnRegOtcomInsr
      * 설명       : 미등록타사보험조회
      ******************************************************************************/
      fn_selUnRegOtcomInsr () {
        let lv_Vm = this
        let pParams = {
          otcomContSno: lv_Vm.sno, //계약번호
          chnlCustId: lv_Vm.chnlCustId
        }
        this.$bizUtil.ctUtils.fn_selUnRegOtcomInsr(lv_Vm, pParams).then(function (response) {
          if (response.msgComm.msgCd !== 'EFWS003') {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            lv_Vm.showPopup = false // 에러시 팝업 닫힘
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      
      /******************************************************************************
      * Function명 : fn_replaceNm
      * 설명       : " 제거
      * 출력물 문자열 전송시 "를 포함하고 있을 경우 에러가 발생하여 " 제거
      ******************************************************************************/
      fn_replaceNm () {
        this.contractorCompany = this.contractorCompany.replace(/\"/g, '')
        this.productName = this.productName.replace(/\"/g, '')
        // this.contractor = this.contractor.replace(/\"/g, '')
      },


      // 수정 및 삭제 위한 함수 영역
      /******************************************************************************
      * Function명 : fn_getOtInfo
      * 설명       : 등록된 미등록타사 정보 가져오기
      ******************************************************************************/
      fn_getOtInfo(Data, Info) {
        this.entryType = 'unReg'
        this.getSelOtcomInsr = Data
        this.contData = Info
        this.contractorCompany = this.getSelOtcomInsr.body.insrCoNm // 보험회사명
        this.productName = this.getSelOtcomInsr.body.otcomPrdtNm // 타사상품명
        this.universal = this.getSelOtcomInsr.body.gtplInsrScCd == '03' ? '1' : '2' // 유니버설상품 ('1':예, '2':아니오)
        this.sno = this.contData.otcomContSno, // 계약번호
        this.chnlCustId = this.contData.chnlCustId

        // this.fn_selUnRegOtcomInsr () // 미등록타사보험조회
      },

      /******************************************************************************
      * Function명 : fn_getOtInfoKcis
      * 설명       : 등록된 신용정보원 정보 가져오기
      ******************************************************************************/
      fn_getOtInfoKcis(Data, Info) {
        this.entryType = 'kcis'
        this.getSelOtcomInsr = Data.body
        this.contData = Info
        console.log('신용정보원 데이터 >>>>> ', this.getSelOtcomInsr)
        this.contractorCompany = this.getSelOtcomInsr.insrCoNm // 보험회사명
        this.productName = this.getSelOtcomInsr.otcomPrdtNm // 타사상품명
        console.log('신용정보원 타사상품명 >>>>> ', this.productName)
        this.universal = this.getSelOtcomInsr.gtplInsrScCd == '03' ? '1' : '2' // 유니버설상품 ('1':예, '2':아니오)
        // this.sno = this.getSelOtcomInsr.otcomContSno, // 계약번호
        this.sno = this.contData.otcomContSno, // 계약번호
        this.chnlCustId = this.contData.chnlCustId
      },

      
    },
  };
</script>
<style scoped>
</style>