<template>
  <ur-page-container class="msp" :show-title="true" title="신체부위별 보장현황" type="subpage" :topButton="true" @on-header-left-click="fn_popupClose">
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap">
      
      <ur-box-container direction="row" alignV="start" class="con-area pt30 pb122 fexTy3">
        <template v-for="(item, idx) in dataList">
          <div class="txt-center min20p mb40 pl13 pr13 " :key="idx" @click="open(idx+1)">
            <div class="blue-cicle-icon" :class="['icon_body'+$bizUtil.lpad(String(idx+1), 2, '0'), {disabled : item.value == '없음'}]"></div>
            <span class="dsD fs16rem mt10">{{item.type}}</span>
            <span class="dsD fs20rem mt4"><b>{{item.value}}</b>{{item.value == '없음' ? '' : '만원'}}</span>
          </div>
        </template>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_popupClose">확인</mo-button>
        </div>
      </ur-box-container>

    </ur-box-container>

    <!-- 일반사망 (MSPCT114P_B01) -->
    <mo-bottom-sheet ref="MSPCT001P_B01" class="ns-bottom-sheet msp-bottomSt-alert"  :close-btn="true">
      <div class="content-area mt30">
        <span class="dsD fs19rem fwb">3인 가족 3년 평균 소득은</span>
        <span class="dsD fs24rem"><b class="crTy-blue3 mr4">2억 4,130만원</b>입니다</span>

        <div class="text-gray-box mt20">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>출처 : 통계청(가계금융복지조사, 2022)</li>
          </ul>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="close">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 일반암 (MSPCT114P_B02) -->
    <mo-bottom-sheet ref="MSPCT001P_B02" class="ns-bottom-sheet msp-bottomSt-alert"  :close-btn="true">
      <div class="content-area mt30">
        <span class="dsD fs19rem fwb">주요 암 종류별 수술비는 다음과 같습니다</span>
        <span class="dsD fs14rem crTy-bk7">(절제술 기준)</span>

        <div class="mt20 bd-b-Ty1 bd-T-Ty1 w100 pal10 fexTy3">
          <div class="left ">
            <span class="fexTy3">
              <span class="fs14rem w60px crTy-bk7">위암</span>
              <span class="fs16rem">1,135만원</span>
            </span>
            <span class="fexTy3 mt4">
              <span class="fs14rem w60px crTy-bk7">뇌암</span>
              <span class="fs16rem">2,072만원</span>
            </span>
            <span class="fexTy3 mt4">
              <span class="fs14rem w60px crTy-bk7">식도암</span>
              <span class="fs16rem">2,042만원</span>
            </span>
            <span class="fexTy3 mt4">
              <span class="fs14rem w60px crTy-bk7">직장암</span>
              <span class="fs16rem">2,213만원</span>
            </span>
          </div>
          <div class="right ">
            <span class="fexTy3">
              <span class="fs14rem w60px crTy-bk7">간암</span>
              <span class="fs16rem">1,357만원</span>
            </span>
            <span class="fexTy3 mt4">
              <span class="fs14rem w60px crTy-bk7">담도암</span>
              <span class="fs16rem">2,575만원</span>
            </span>
            <span class="fexTy3 mt4">
              <span class="fs14rem w60px crTy-bk7">췌장암</span>
              <span class="fs16rem">2,430만원</span>
            </span>
            <span class="fexTy3 mt4">
              <span class="fs14rem w60px crTy-bk7">폐암</span>
              <span class="fs16rem">2,180만원</span>
            </span>
          </div>
        </div>
        <span class="dsD fs19rem mt30 fwb">기타 암 종류별 수술비는 다음과 같습니다</span>
        <span class="dsD fs14rem crTy-bk7">(절제술 기준)</span>

        <div class="mt20 bd-b-Ty1 bd-T-Ty1 w100 pal10 fexTy3">
          <div class="left ">
            <span class="fexTy3">
              <span class="fs14rem w60px crTy-bk7">갑상선암</span>
              <span class="fs16rem">359만원</span>
            </span>
            <span class="fexTy3 mt4">
              <span class="fs14rem w60px crTy-bk7">자궁암</span>
              <span class="fs16rem">685만원</span>
            </span>
          </div>
          <div class="right ">
            <span class="fexTy3">
              <span class="fs14rem w60px crTy-bk7">유방암</span>
              <span class="fs16rem">545만원</span>
            </span>
            <span class="fexTy3 mt4">
              <span class="fs14rem w60px crTy-bk7">전립선</span>
              <span class="fs16rem">787만원</span>
            </span>
          </div>
        </div>

        <div class="text-gray-box mt20">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>출처 : 국민건강보험공단<br><span class="ml50">(건강보험주요수술통계, 2022)</span></li>
          </ul>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="close">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 뇌출혈 (MSPCT114P_B03) -->
    <mo-bottom-sheet ref="MSPCT001P_B03" class="ns-bottom-sheet msp-bottomSt-alert"  :close-btn="true">
      <div class="content-area mt30">
        <span class="dsD fs19rem fwb">뇌내출혈로 인한 평균 수술비는</span>
        <span class="dsD fs24rem"><b class="crTy-blue3 mr4">2,307만원</b>입니다</span>
        <span class="dsD fs14rem crTy-bk7 mt4">(뇌종양 수술 기준)</span>

        <div class="text-gray-box mt20">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>출처 : 국민건강보험공단<br><span class="ml50">(건강보험주요수술통계, 2021)</span></li>
          </ul>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="close">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 뇌출혈 (MSPCT114P_B03) -->
    <mo-bottom-sheet ref="MSPCT001P_B04" class="ns-bottom-sheet msp-bottomSt-alert"  :close-btn="true">
      <div class="content-area mt30">
        <span class="dsD fs19rem fwb">뇌경색증으로 인한 평균 수술비는</span>
        <span class="dsD fs24rem"><b class="crTy-blue3 mr4">1,771만원</b>입니다</span>
        <span class="dsD fs19rem fwb mt20">뇌경색증으로 인한 평균 요양비는</span>
        <span class="dsD fs24rem"><b class="crTy-blue3 mr4">1,348만원</b>입니다</span>

        <div class="text-gray-box mt20">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>출처 : 국민건강보험공단<br><span class="ml50">(건강보험주요수술통계, 2022)</span></li>
            <li>출처 : 건강보험심사평가원<br><span class="ml50">(진료비통계자료, 2021)</span></li>
          </ul>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="close">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 급성심근경색 (MSPCT114P_B05) -->
    <mo-bottom-sheet ref="MSPCT001P_B05" class="ns-bottom-sheet msp-bottomSt-alert"  :close-btn="true">
      <div class="content-area mt30">
        <span class="dsD fs19rem fwb">급성심근경색증으로 인한 평균 수술비는</span>
        <span class="dsD fs24rem"><b class="crTy-blue3 mr4">3,689만원</b>입니다</span>
        <span class="dsD fs14rem crTy-bk7 mt4">(관상동맥우회수술비 기준)</span>

        <div class="text-gray-box mt20">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>출처 : 국민건강보험공단<br><span class="ml50">(건강보험주요수술통계, 2021)</span></li>
          </ul>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="close">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 허혈심장질환 (MSPCT114P_B06) -->
    <mo-bottom-sheet ref="MSPCT001P_B06" class="ns-bottom-sheet msp-bottomSt-alert"  :close-btn="true">
      <div class="content-area mt30">
        <span class="dsD fs19rem fwb">만성허혈심장병으로 인한 평균 수술비는</span>
        <span class="dsD fs24rem"><b class="crTy-blue3 mr4">3,088만원</b>입니다</span>
        <span class="dsD fs14rem crTy-bk7 mt4">(관상동맥우회수술비 기준)</span>

        <div class="text-gray-box mt20">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>출처 : 국민건강보험공단<br><span class="ml50">(건강보험주요수술통계, 2021)</span></li>
          </ul>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="close">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- LTC(중증) (MSPCT114P_B07) -->
    <mo-bottom-sheet ref="MSPCT001P_B07" class="ns-bottom-sheet msp-bottomSt-alert"  :close-btn="true">
      <div class="content-area mt30">
        <span class="dsD fs19rem fwb">알츠하이머로 인한 평균 요양 입원비는</span>
        <span class="dsD fs24rem"><b class="crTy-blue3 mr4">1,551만원</b>입니다</span>

        <div class="text-gray-box mt20">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>출처 : 국민건강보험공단<br><span class="ml50">(건강보험주요수술통계, 2021)</span></li>
          </ul>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="close">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 신수술 (MSPCT114P_B08) -->
    <mo-bottom-sheet ref="MSPCT001P_B08" class="ns-bottom-sheet msp-bottomSt-alert"  :close-btn="true">
      <div class="content-area mt30">
        <span class="dsD fs19rem fwb">수술 평균 진료비는</span>
        <span class="dsD fs24rem"><b class="crTy-blue3 mr4">371만원</b>입니다</span>
        <span class="dsD fs19rem fwb mt20">상급 종합병원 수술 평균 진료비는</span>
        <span class="dsD fs24rem"><b class="crTy-blue3 mr4">736만원</b>입니다</span>

        <div class="text-gray-box mt20">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>출처 : 국민건강보험공단<br><span class="ml50">(건강보험주요수술통계, 2021)</span></li>
          </ul>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="close">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 질병입원 (MSPCT114P_B09) -->
    <mo-bottom-sheet ref="MSPCT001P_B09" class="ns-bottom-sheet msp-bottomSt-alert"  :close-btn="true">
      <div class="content-area mt30">
        <span class="dsD fs19rem fwb">입원 일당 평균 진료비는</span>
        <span class="dsD fs24rem"><b class="crTy-blue3 mr4">25만원</b>입니다</span>
        <span class="dsD fs19rem fwb mt20">급여 1일당 평균 본인 부담금은</span>
        <span class="dsD fs24rem"><b class="crTy-blue3 mr4">3만원</b>입니다</span>

        <div class="text-gray-box mt20">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>출처 : 보건의료빅데이터개방시스템<br><span class="ml50">(건강보험진료통계, 2021)</span></li>
            <li>출처 : 국민건강보험공단<br><span class="ml50">(건강보험주요수술통계, 2021)</span></li>
          </ul>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="close">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 치아 보철치료(MSPCT114P_B10) -->
    <mo-bottom-sheet ref="MSPCT001P_B10" class="ns-bottom-sheet msp-bottomSt-alert"  :close-btn="true">
      <div class="content-area mt30">
        <span class="dsD fs19rem fwb">1치당 치과 임플란트 평균 비용은</span>
        <span class="dsD fs24rem"><b class="crTy-blue3 mr4">199만원</b>입니다</span>

        <div class="text-gray-box mt20">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>출처 : 건강보험심사평가원<br><span class="ml50">(비급여 진료비용 및 제증명수수료통계, 2022)</span></li>
          </ul>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="close">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 치아 보존치료 (MSPCT114P_B11) -->
    <mo-bottom-sheet ref="MSPCT001P_B11" class="ns-bottom-sheet msp-bottomSt-alert"  :close-btn="true">
      <div class="content-area mt30">
        <span class="dsD fs19rem fwb">1치당 치과 크라운 평균 비용은</span>
        <span class="dsD fs24rem"><b class="crTy-blue3 mr4">59만원</b>입니다</span>

        <div class="text-gray-box mt20">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>출처 : 건강보험심사평가원<br><span class="ml50">(비급여 진료비용 및 제증명수수료통계, 2022)</span></li>
          </ul>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="close">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT114P",
    screenId: "MSPCT114P",
    components: { },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_popupClose)
    },
    beforeDestroy() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_popupClose)
    },
    mounted () {
      this.$bizUtil.insSrnLog(this.$options.screenId)

      this.fn_init114P()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
          arrList: ["genDthCovrAmt","genCncrDignCovrAmt","crbhmDignCovrAmt","cevarDsCovrAmt","actmiDignCovrAmt","ishHetDsDignAmt","ltcDignCovrAmt","nwSurgCovrAmt","genDiseHspzCovrAmt","pstsRmdyCovrAmt","psvtRmdyCovrAmt"],
          dataList: [],
          openBsSheet: ''
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      tableHeaderMock: function () {
        return this.$bizUtil.ctUtils.fn_GetTableTitles()
      },
      lv_CovrStnrd: function () {
        // 표준모델
        let returnValue = this.getStore('ctStore').getters.getCustCovrStnrd
        return returnValue
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      open(idx){
        this.openBsSheet = 'MSPCT001P_B' +this.$bizUtil.lpad(String(idx), 2, '0')
        this.$refs[this.openBsSheet].open()
      },
      close() {
        this.$refs[this.openBsSheet].close()
      },
      fn_popupClose() {
        this.$emit('onPopupClose')
      },
      fn_init114P() {
        let lv_TargetSelf = this
        Promise.all([this.$bizUtil.ctUtils.fn_PrmsRgstdt(lv_TargetSelf, true)])
          .then(function (response) {
            lv_TargetSelf.$bizUtil.ctUtils.fn_DrwRgstdt(lv_TargetSelf)
            lv_TargetSelf.productList = []
            lv_TargetSelf.custInfo = []
            let dataBucket = []
            lv_TargetSelf.custInfo = lv_TargetSelf.$bizUtil.cloneDeep(lv_TargetSelf.getStore('ctStore').getters.getContData.data)
            lv_TargetSelf.custInfo.filter( x => { return x.mnisdNm === lv_TargetSelf.contractor || x.aisdNm === lv_TargetSelf.contractor })

            for (let custItem in lv_TargetSelf.custInfo) {
              lv_TargetSelf.productList.push(lv_TargetSelf.$bizUtil.ctUtils.fn_SettingListItem(lv_TargetSelf.contractor, lv_TargetSelf.custInfo[custItem], true, false, false))
            }

            if (lv_TargetSelf.productList.length !== 0) {
              lv_TargetSelf.isEmpty = true
            }

            let lv_TempArray = []
            lv_TempArray = lv_TempArray.concat(lv_TargetSelf.productList.slice())

            // 표준 모델을 노출시킨다.
            lv_TargetSelf.$bizUtil.ctUtils.fn_showStndModel(lv_TargetSelf.tableHeaderMock, lv_TargetSelf.lv_CovrStnrd)

            // 총 보장자산 노출
            dataBucket = lv_TargetSelf.$bizUtil.ctUtils.fn_CalcAmount(true, false, lv_TempArray) // 총보장자산 계산
            lv_TargetSelf.$bizUtil.ctUtils.fn_DoSettingAmount(true, dataBucket, lv_TargetSelf) // 총보장자산 노출

            // 신체부위별 보장현황 설정
            lv_TargetSelf.fn_setDataList()

          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      fn_setDataList() {
        this.dataList = []

        this.arrList.forEach((attr) => {
          this.tableHeaderMock.forEach((item) => {
            // console.log(item)
            item.columns.forEach((columns) => {
              columns.data.forEach((data) => {
                // console.log(attr, ', ', data.attr, ', ', attr == data.attr)
                if(attr == data.attr){
                  let obj = {}
                  obj.type = data.type
                  if(data.custAmount > 0){
                    obj.value = this.$bizUtil.numberWithCommasByNumber(data.custAmount)
                  }else{
                    obj.value = '없음'
                  }
                  // console.log('obj >>> ', obj)
                  this.dataList.push(obj)
                }
              })
            })
          })
        })

        // console.log(this.dataList)
      }


    }
  }
</script>
<style scoped>
</style>