<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">    
    <!-- 컨텐츠 영역 --> 
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap bgcolor-1 pb82">
      <ur-box-container alignV="start" componentid="" direction="column" class="con-area msp-chart-slide pt30 bgcolor-1">
        <ur-box-container alignV="start" componentid="" direction="column" class="profile_guide--box ty-consulting">
          <!-- 슬라이딩 배너 영역 -->
          <div class="ns-swiper-main profile_guide" v-show="showCarousel">
            <mo-carousel :number-of-page="mockData.length" ref="refSwiper" height="auto" :no-rotate="true" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" class="guide_roll">
              <template v-for="(mock, idx) in mockData" v-slot:[`${idx+1}`]>
                <div class="guide_group box-shadow-type-3" :class="mock.color" :key="'BAchart'+idx">
                  <div class="txt-block fexTy3-1">
                    <span class="fs18rem fwm">{{mock.title}}</span>
                    <span class="fs12rem crTy-bk1">
                      <span class="fill-box">설계 전</span>
                      <span class="fill-box2">설계 후</span>
                      <span class="line-box mt4">권장금액</span>
                    </span>
                  </div>
                  <div class="guide-img w100 mt10">
                    <div class="rChartMSPCT123D" style="position: relative; width: 100%; height: 250px; margin: auto; background: #FFFFFF;" :id="'chartViewBeforAfter' + idx"></div>
                  </div>
                  <mo-button class="ns-btn-round h40 white rounded fs16rem" @click="fn_moveDetail(idx)">상세보기</mo-button>
                </div>
              </template>
            </mo-carousel>
          </div>
        </ur-box-container>    
      </ur-box-container>
    </ur-box-container>
    <!-- 컨텐츠 영역 //--> 
  </ur-box-container>
</template>

<script>
import UrBoxContainer from '@/_uidev/containers/box/urBoxContainer.vue'
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
require('@/assets/lib/rMateChartH5/Assets/Css/rMateChartH5.css')
// require('@/assets/lib/rMateChartH5/JS/rMateChartH5.js') // RadarChart 차트 사용시 해당 js 주석처리 해야함
// require('@/assets/lib/rMateChartH5/JS/rMateRadarChartH5.js')
require('@/assets/lib/rMateChartH5/LicenseKey/rMateChartH5License.js')
require('@/assets/lib/rMateChartH5/JS/rMateChartVar.js')
require('@/assets/lib/rMateChartH5/JS/rMateIntegrationH5.js')

export default {
  
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCT122D",
  screenId: "MSPCT122D",
  components: {
   UrBoxContainer
  },//componets,
  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    slctTabIdx : {type:Number, default:1} // 부모 선택한 현재 탭 인덱스
  },//props
  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      showCarousel: false,
      swiperOption: {
        // centerSlides: true,
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: {
          delay: 50000000,
          disableOnInteraction: false
        }
      },
      mockData: [
        {
          title: '사망·후유장해 보장',
          color: 'blue'          
        },
        {
          title: '진단 보장',
          color: 'green'  
        },
        {
          title: '수술·입원 보장',
          color: 'purple'          
        }
      ]
    }
  },//data

  created () {
  },//created
  mounted () {
  },
  beforeDestroy () {
    console.log('beforeDestroy ::: MSPCT123D')
    // 인스턴트가 remove 되기전 rMateChartH5.create 로 생성하였던 차트 의 ROOT 까지 모두 삭제 (메모리 누수 방지)
    let lv_objArr = document.getElementsByClassName('rChartMSPCT123D')
    for( let objArriDX = 0; objArriDX < lv_objArr.length; objArriDX++ ) {
      let lv_id = lv_objArr[objArriDX].firstChild !== null? lv_objArr[objArriDX].firstChild.id : null
      let lv_chartObj = document.getElementById(lv_id)
      if ( lv_chartObj !== null ) {
        lv_chartObj.destroy()
      }
    }
  },
  watch: {
    slctTabIdx(){
      console.log(this.$options.screenId + " : this.slctTabIdx = "+ this.slctTabIdx)
    },
  },//watch
  computed: {
    lv_BaseData: function () {
      // 고객 기본정보
      let returnValue = this.getStore('ctStore').getters.getCustBaseInfo
      return returnValue
    }
  },

  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    fn_loadTSCT009MDataSet () {
      let lv_TargetSelf = this
      
      //데이터 초기화 : 시작 // 표준모델 재갱신
      lv_TargetSelf.planAfCovan = {}
      lv_TargetSelf.bfCovanSmtotPrm = 0
      lv_TargetSelf.afCoVanSmtotPrm = 0
      lv_TargetSelf.bfContCovrList = {}
      lv_TargetSelf.afContCovrList = {}
      lv_TargetSelf.bfContFprdDthTrtyExpYmList = [] // 설계전 정기사망특약만기년월 리스트 this.bfContCovrList 와 index에 따른 상품이 동일함
      lv_TargetSelf.afContFprdDthTrtyExpYmList = [] // 설계후 정기사망특약만기년월 리스트 this.afContCovrList 와 index에 따른 상품이 동일함
      // productList = Array.prototype.slice.call(productList),
      lv_TargetSelf.excludeProductList = []
      lv_TargetSelf.includeProductList = []      
      lv_TargetSelf.stdCovr = []
      lv_TargetSelf.currYm = new Date().getFullYear() +''+ (new Date().getMonth()+1)
      //데이터 초기화 : 끝 // 표준모델 재갱신

      lv_TargetSelf.TSSCT005MParam = lv_TargetSelf.lv_BaseData
      console.log('lv_TargetSelf::: ' + JSON.stringify(lv_TargetSelf.TSSCT005MParam))
      let lv_PostPrm = {
        chnlCustId: lv_TargetSelf.lv_BaseData.data.chnlCustId,
        custNm: lv_TargetSelf.lv_BaseData.data.custNm,
        cnsltNo: lv_TargetSelf.lv_BaseData.data.cnsltNo,
        conctScCd: '3' // 접속구분 코드 추가 2 : 모바일
      }
      Promise.all([this.$bizUtil.ctUtils.fn_rePrmsRgstdt(lv_TargetSelf, lv_PostPrm, false), lv_TargetSelf.$bizUtil.ctUtils.fn_PrpslCovrAnly(lv_TargetSelf, false)])
        .then(function (response) {
              lv_TargetSelf.$bizUtil.ctUtils.fn_DrwRgstdt(lv_TargetSelf)
              lv_TargetSelf.$bizUtil.ctUtils.fn_planAfCovan(lv_TargetSelf)
              lv_TargetSelf.planAfCovan = lv_TargetSelf.getStore('ctStore').getters.getPlanAfCovan.data
              // console.log('lv_TargetSelf.planAfCovan ::: ' + JSON.stringify(lv_TargetSelf.planAfCovan))
              
              lv_TargetSelf.bfCovanSmtotPrm = lv_TargetSelf.planAfCovan.bfCovanSmtotPrm
              lv_TargetSelf.afCoVanSmtotPrm = lv_TargetSelf.planAfCovan.afCoVanSmtotPrm
              // console.log('lv_TargetSelf.planAfCovan bfCovanSmtotPrm ::: ' + lv_TargetSelf.bfCovanSmtotPrm)
              // console.log('lv_TargetSelf.planAfCovan afCoVanSmtotPrm ::: ' + lv_TargetSelf.afCoVanSmtotPrm)

              lv_TargetSelf.bfContCovrList = lv_TargetSelf.planAfCovan.bfContCovrList
              lv_TargetSelf.afContCovrList = lv_TargetSelf.planAfCovan.afContCovrList
              lv_TargetSelf.bfContFprdDthTrtyExpYmList = lv_TargetSelf.planAfCovan.bfContFprdDthTrtyExpYmList
              lv_TargetSelf.afContFprdDthTrtyExpYmList = lv_TargetSelf.planAfCovan.afContFprdDthTrtyExpYmList
              lv_TargetSelf.stdCovr = lv_TargetSelf.planAfCovan.stdCovr
              
              // 제외한 상품
              lv_TargetSelf.excludeProductList = [] // lv_TargetSelf.planAfCovan.excluPrdtInfo 
              // debugger
              for (let i in lv_TargetSelf.planAfCovan.excluPrdtInfo) {
                let lv_excludeProduct = {}
                lv_excludeProduct = lv_TargetSelf.planAfCovan.excluPrdtInfo[i]
                let lv_RstItem = lv_TargetSelf.$bizUtil.ctUtils.fn_SettingListItem(lv_TargetSelf.lv_BaseData.data.custNm, lv_excludeProduct, true, false, false)
                lv_TargetSelf.excludeProductList.push(lv_RstItem)
              }

              // 추가한 상품
              lv_TargetSelf.includeProductList =[]  // .concat(lv_TargetSelf.planAfCovan.addmPrdtInfo) // 추가한 상품

              for (let i in lv_TargetSelf.planAfCovan.addmPrdtInfo) {
                let lv_includeProduct = {}
                lv_includeProduct = lv_TargetSelf.planAfCovan.addmPrdtInfo[i]
                let lv_RstItem = lv_TargetSelf.$bizUtil.ctUtils.fn_SettingListItem(lv_TargetSelf.lv_BaseData.data.custNm, lv_includeProduct, false, true, false)
                lv_TargetSelf.includeProductList.push(lv_RstItem)
              }

              // console.log('lv_TargetSelf.excludeProductList ::: ' + JSON.stringify(lv_TargetSelf.excludeProductList))
              // console.log('lv_TargetSelf.includeProductList ::: ' + JSON.stringify(lv_TargetSelf.includeProductList))
              // console.log('lv_TargetSelf.excludeProductList.length ::: ' + lv_TargetSelf.excludeProductList.length)
              // console.log('lv_TargetSelf.includeProductList.length ::: ' + lv_TargetSelf.includeProductList.length)

              // console.log('returnCustcovrAnly ::: ' + JSON.stringify(lv_TargetSelf.getStore('ctStore').getters.getCustcovrAnly.data))
              let prpsPldtList = []
              prpsPldtList = lv_TargetSelf.getStore('ctStore').getters.getCustcovrAnly.data.entplVO
              // 월보험료가 1억미만 상품만 추린다. -> 보장분석에서는 가설목록 보여줌 (연금계산기에서만 배재) 2019.12.17
              // prpsPldtList = prpsPldtList.filter( x => { return x.smtotPrm.longAmount < 100000000 })

              // 추가한 상품 목록을, 가입설계 목록에 있는 상품(설계번호)으로 체크해서 해당 설계번호가 없으면 추가 상품목록에서 삭제한다.
              let inChkCnt = 0
              let lv_incLen  = lv_TargetSelf.includeProductList.length-1
              for ( let i= lv_incLen ; i>=0; i-- ) {
                inChkCnt = 0
                for ( let j in prpsPldtList ) {
                  if ( lv_TargetSelf.includeProductList[i].inEntplVO.planId === prpsPldtList[j].planId ) {
                    inChkCnt++
                    break
                  }
                }
                if( inChkCnt === 0 ) {
                  lv_TargetSelf.includeProductList.splice(i,1)
                }
              }
              // 차트 데이터 생성
              lv_TargetSelf.fn_chartDataSet()

              // 보험료,상품수정갯수 설정
              lv_TargetSelf.$emit('setPrmMdfcCnt')

        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    fn_chartDataSet () {
      // console.log('fn_chartDataSet ::: ')

      let famtChartDataList = [] // 사망·재해장해 차트 데이터
      let lvngChartDataList = [] // 진단 차트 데이터
      let mdcrChartDataList = [] // 수술·입원 차트 데이터

      // 가족보장 급부 항목 list (일반사망, 질병사망, 암사망,일반재해사망, 교통재해)
      let famtCovrAmtList = [ { covrAmt : 'genDthCovrAmt', covrName : '일반사망', covrLabelName : '일반사망'},
                           { covrAmt : 'diseDthCovrAmt', covrName : '질병사망', covrLabelName : '질병<br><br>사망'},
                           { covrAmt : 'cncrDthCovrAmt', covrName : '암사망', covrLabelName : '암사망'},                           
                           { covrAmt : 'genDstrDthCovrAmt', covrName : '일반재해사망', covrLabelName : '일반재해사망'},
                          //  { covrAmt : 'dstrDsabRatOhrCovrAmt', covrName : '재해후유장해 100%', covrLabelName : '재해후유장해<br><br>100%'} ]
                           { covrAmt : 'dstrDsabRatEyCovrAmt', covrName : '재해후유장해 80%', covrLabelName : '재해후유장해<br><br>80%'} ]

      // 가족보장 차트 데이터
      for ( let i in famtCovrAmtList ) {
        let famChart = {}
        famChart.covrName = famtCovrAmtList[i].covrName
        famChart.covrLabelName = famtCovrAmtList[i].covrLabelName
        famChart.covrBfAmt = 0
        famChart.covrAfAmt = 0

        for ( let j in this.bfContCovrList ) {
          let bfContCovrItem = this.bfContCovrList[j]
          let bfContFprdDthTrtyExpYm = this.bfContFprdDthTrtyExpYmList[j] // bfContCovrList와 bfContFprdDthTrtyExpYmList의 index가 같은 값은 동일 상품임
          if ((famtCovrAmtList[i].covrAmt === 'genDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'diseDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'cncrDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'genDstrDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'dstrDsabRatOhrCovrAmt') &&
          // if ((famtCovrAmtList[i].covrAmt === 'genDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'diseDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'cncrDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'genDstrDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'dstrDsabRatEyCovrAmt') &&
              bfContFprdDthTrtyExpYm >= this.currYm) {
            // 타사 미등록 상품중 일반사망, 질병사망, 암사망 금액은 '현재 날짜가 정기사망특약 기간에 속할경우' 값을 더한다.
            famChart.covrBfAmt += bfContCovrItem[famtCovrAmtList[i].covrAmt].longAmount + bfContCovrItem['fprdDthTrtyCovrAmt'].longAmount
          } else {
            famChart.covrBfAmt += bfContCovrItem[famtCovrAmtList[i].covrAmt].longAmount
          }
        }

        for ( let k in this.afContCovrList ) {
          let afContCovrItem = this.afContCovrList[k]
          let afContFprdDthTrtyExpYm = this.afContFprdDthTrtyExpYmList[k] // afContCovrList와 afContFprdDthTrtyExpYmList의 index가 같은 값은 동일 상품임
          if ((famtCovrAmtList[i].covrAmt === 'genDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'diseDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'cncrDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'genDstrDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'dstrDsabRatOhrCovrAmt') &&
          // if ((famtCovrAmtList[i].covrAmt === 'genDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'diseDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'cncrDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'genDstrDthCovrAmt' || famtCovrAmtList[i].covrAmt === 'dstrDsabRatEyCovrAmt') &&
              afContFprdDthTrtyExpYm >= this.currYm) {
            // 타사 미등록 상품중 일반사망, 질병사망, 암사망 금액은 '현재 날짜가 정기사망특약 기간에 속할경우' 값을 더한다.
            famChart.covrAfAmt += afContCovrItem[famtCovrAmtList[i].covrAmt].longAmount + afContCovrItem['fprdDthTrtyCovrAmt'].longAmount
          } else {
            famChart.covrAfAmt += afContCovrItem[famtCovrAmtList[i].covrAmt].longAmount
          }
        }

        // 가족 보장급부별 합산후 절삭
        famChart.covrBfAmt = Math.floor( famChart.covrBfAmt / 10000 )
        famChart.covrAfAmt = Math.floor( famChart.covrAfAmt / 10000 )

        for ( let l in this.stdCovr ) {
          famChart.requireAmt = Math.floor(this.stdCovr[l][famtCovrAmtList[i].covrAmt].longAmount / 10000 ) // 표준금액
        }

        // 보장금액 대비 표준비율 (보장금액 / 표준금액) * 100
        let covrBfRate = Math.round((famChart.covrBfAmt / famChart.requireAmt)*100)
        famChart.covrBfRate = covrBfRate >= 100 ? 100 : covrBfRate

        let covrAfRate = Math.round((famChart.covrAfAmt / famChart.requireAmt)*100)
        famChart.covrAfRate = covrAfRate >= 100 ? 100 : covrAfRate

        famChart.maxRate = 100

        famChart.overAmt = famChart.covrAfAmt - famChart.requireAmt // 과부족금액
        famChart.covrInfo = famChart.covrLabelName + '|' + famChart.overAmt // 보장급부명|과부족금액 (차트데이터 컨트롤 용)
        // famChart.covrInfo = {covrLabelName : famChart.covrLabelName, overAmt : famChart.overAmt} // 보장급부명|과부족금액 (차트데이터 컨트롤 용)

        famtChartDataList.push(famChart)
      }
      // console.log('famtChartDataList ::: ' + JSON.stringify(famtChartDataList))

      // 생활보장 급부 항목 list (일반암, 뇌출혈, 뇌경색, 급성심근경색, LTC)
      let lvngCCovrAmtList = [ { covrAmt : 'genCncrDignCovrAmt', covrName : '일반암', covrLabelName : '일반암'},
                           { covrAmt : 'crbhmDignCovrAmt', covrName : '뇌출혈', covrLabelName : '뇌출혈'},
                           { covrAmt : 'cbifDignCovrAmt', covrName : '뇌경색', covrLabelName : '뇌경색'},
                           { covrAmt : 'actmiDignCovrAmt', covrName : '급성심근경색', covrLabelName : '급성심근경색'},
                           { covrAmt : 'ltcDignCovrAmt', covrName : '장기요양(중증)', covrLabelName : '장기요양<br><br>(중증)'} ]

      // 생활보장 차트 데이터
      for ( let i in lvngCCovrAmtList ) {
        let lvngChart = {}
        lvngChart.covrName = lvngCCovrAmtList[i].covrName
        lvngChart.covrLabelName = lvngCCovrAmtList[i].covrLabelName
        lvngChart.covrBfAmt = 0
        lvngChart.covrAfAmt = 0
        lvngChart.requireAmt = 0

        for ( let j in this.bfContCovrList ) {
          // console.log('this.bfContCovrList[j][lvngCCovrAmtList[i].longAmount] ::: ' + i + ':::' + JSON.stringify(this.bfContCovrList[j][lvngCCovrAmtList[i].covrAmt].longAmount))
          // lvngChart.covrBfAmt += Math.floor( this.bfContCovrList[j][lvngCCovrAmtList[i].covrAmt].longAmount / 10000 )
          lvngChart.covrBfAmt += this.bfContCovrList[j][lvngCCovrAmtList[i].covrAmt].longAmount
        }

        for ( let k in this.afContCovrList ) {
          // lvngChart.covrAfAmt += Math.floor( this.afContCovrList[k][lvngCCovrAmtList[i].covrAmt].longAmount / 10000 )
          lvngChart.covrAfAmt += this.afContCovrList[k][lvngCCovrAmtList[i].covrAmt].longAmount
        }

        for ( let l in this.stdCovr ) {
          lvngChart.requireAmt = Math.floor(this.stdCovr[l][lvngCCovrAmtList[i].covrAmt].longAmount / 10000 ) // 표준금액
        }

        // 생활 보장급부별 합산후 절삭
        lvngChart.covrBfAmt = Math.floor( lvngChart.covrBfAmt / 10000 )
        lvngChart.covrAfAmt = Math.floor( lvngChart.covrAfAmt / 10000 )

        // 보장금액 대비 표준비율 (보장금액 / 표준금액) * 100
        let covrBfRate = Math.floor((lvngChart.covrBfAmt / lvngChart.requireAmt)*100)
        lvngChart.covrBfRate = covrBfRate >= 100 ? 100 : covrBfRate

        let covrAfRate = Math.floor((lvngChart.covrAfAmt / lvngChart.requireAmt)*100)
        lvngChart.covrAfRate = covrAfRate >= 100 ? 100 : covrAfRate

        lvngChart.maxRate = 100

        lvngChart.overAmt = lvngChart.covrAfAmt - lvngChart.requireAmt // 과부족금액
        lvngChart.covrInfo = lvngChart.covrLabelName + '|' + lvngChart.overAmt // 보장급부명|과부족금액 (차트데이터 컨트롤 용)
        // lvngChart.covrInfo = {covrLabelName : lvngChart.covrLabelName, overAmt : lvngChart.overAmt} // 보장급부명|과부족금액 (차트데이터 컨트롤 용)

        lvngChartDataList.push(lvngChart)
      }
      // console.log('lvngChartDataList ::: ' + JSON.stringify(lvngChartDataList))

      // 의료보장 급부 항목 list (신수술, 종수술, 암수술, 암입원, 질병입원)
      let mdcrCovrAmtList = [ { covrAmt : 'nwSurgCovrAmt', covrName : '신수술', covrLabelName : '신수술'},
                           { covrAmt : 'feclsCovrAmt', covrName : '질병종수술', covrLabelName : '질병종수술'},
                           { covrAmt : 'cncrSurgCovrAmt', covrName : '암수술', covrLabelName : '암수술'},
                           { covrAmt : 'cncrHspzCovrAmt', covrName : '암입원', covrLabelName : '암입원'},
                           { covrAmt : 'genDiseHspzCovrAmt', covrName : '질병입원', covrLabelName : '질병<br><br>입원'} ]

      // 의료보장 차트 데이터
      for ( let i in mdcrCovrAmtList ) {
        let mdcrChart = {}
        mdcrChart.covrName = mdcrCovrAmtList[i].covrName
        mdcrChart.covrLabelName = mdcrCovrAmtList[i].covrLabelName
        mdcrChart.covrBfAmt = 0
        mdcrChart.covrAfAmt = 0
        mdcrChart.requireAmt = 0

        for ( let j in this.bfContCovrList ) {
          // debugger
          // console.log('this.bfContCovrList[j][mdcrCovrAmtList[i].longAmount] ::: ' + i + ':::' + JSON.stringify(this.bfContCovrList[j][mdcrCovrAmtList[i].covrAmt].longAmount))
          // mdcrChart.covrBfAmt += Math.floor( this.bfContCovrList[j][mdcrCovrAmtList[i].covrAmt].longAmount / 10000 )
          mdcrChart.covrBfAmt += this.bfContCovrList[j][mdcrCovrAmtList[i].covrAmt].longAmount
        }

        for ( let k in this.afContCovrList ) {
          // mdcrChart.covrAfAmt += Math.floor( this.afContCovrList[k][mdcrCovrAmtList[i].covrAmt].longAmount / 10000 )
          mdcrChart.covrAfAmt += this.afContCovrList[k][mdcrCovrAmtList[i].covrAmt].longAmount
        }

        for ( let l in this.stdCovr ) {
          mdcrChart.requireAmt = Math.floor(this.stdCovr[l][mdcrCovrAmtList[i].covrAmt].longAmount / 10000 ) // 표준금액
        }

        // 의료 보장급부별 합산후 절삭
        mdcrChart.covrBfAmt = Math.floor( mdcrChart.covrBfAmt / 10000 )
        mdcrChart.covrAfAmt = Math.floor( mdcrChart.covrAfAmt / 10000 )

        // 보장금액 대비 표준비율 (보장금액 / 표준금액) * 100
        let covrBfRate = Math.round((mdcrChart.covrBfAmt / mdcrChart.requireAmt)*100)
        mdcrChart.covrBfRate = covrBfRate >= 100 ? 100 : covrBfRate

        let covrAfRate = Math.round((mdcrChart.covrAfAmt / mdcrChart.requireAmt)*100)
        mdcrChart.covrAfRate = covrAfRate >= 100 ? 100 : covrAfRate

        mdcrChart.maxRate = 100

        mdcrChart.overAmt = mdcrChart.covrAfAmt - mdcrChart.requireAmt // 과부족금액
        mdcrChart.covrInfo = mdcrChart.covrLabelName + '|' + mdcrChart.overAmt // 보장급부명|과부족금액 (차트데이터 컨트롤 용)
        // mdcrChart.covrInfo = {covrLabelName : mdcrChart.covrLabelName, overAmt : mdcrChart.overAmt}  // 보장급부명|과부족금액 (차트데이터 컨트롤 용)

        mdcrChartDataList.push(mdcrChart)
      }
      // console.log('mdcrChartDataList ::: ' + JSON.stringify(mdcrChartDataList))

      this.showCarousel = true
      this.fn_makeChartData([famtChartDataList, lvngChartDataList, mdcrChartDataList])
    },
    fn_makeChartData (pChartData) {
      // console.log('pChartData :::', pChartData)

      this.$nextTick(() => {
        for(let i=0; i<pChartData.length; i++){
          chartData = undefined
          layoutStr = undefined

          let lv_rgba_line = '' // 방사형 차트 면 색깔
          let lv_rgba_area = '' // 방사형 차트 점/선 색깔
          let lv_af_rgba_line = '' // (설계후) 방사형 차트 면 색깔
          let lv_af_rgba_area = '' // (설계후) 방사형 차트 점/선 색깔
          let lv_rgba_line_max = '' // 방사형 차트 점/선 색깔
          let lv_rgba_area_max = '<SolidColor color="#ffffff" alpha="0"/>' // 방사형 차트 면 색깔

          if ( i === 0 ) { // 사망·재해장해 보장
            lv_rgba_line = '<Stroke color="#2793ff" weight="0.1"/>'
            lv_rgba_area = '<SolidColor color="#2793ff" alpha="0.2"/>'
            lv_af_rgba_line = '<Stroke color="#0042dc" weight="0.1"/>'
            lv_af_rgba_area = '<SolidColor color="#0042dc" alpha="0.5"/>'
            lv_rgba_line_max = '<Stroke color="#519ef6" weight="2"/>'
          } else if ( i === 1 ) { // 진단 보장
            lv_rgba_line = '<Stroke color="#28dcde" weight="0.1"/>'
            lv_rgba_area = '<SolidColor color="#28dcde" alpha="0.2"/>'
            lv_af_rgba_line = '<Stroke color="#00a4a6" weight="0.1"/>'
            lv_af_rgba_area = '<SolidColor color="#00a4a6" alpha="0.5"/>'
            lv_rgba_line_max = '<Stroke color="#00a4a6" weight="2"/>'
          } else if ( i === 2 ) { // 수술·입원 보장
            lv_rgba_line = '<Stroke color="#9d2cff" weight="0.1"/>'
            lv_rgba_area = '<SolidColor color="#9d2cff" alpha="0.2"/>'
            lv_af_rgba_line = '<Stroke color="#3505bb" weight="0.1"/>'
            lv_af_rgba_area = '<SolidColor color="#3505bb" alpha="0.5"/>'
            lv_rgba_line_max = '<Stroke color="#7960ff" weight="2"/>'
          }

          chartData = pChartData[i]
          // console.log(chartData)

          layoutStr = `<rMateChart backgroundColor="#FFFFFF"  borderStyle="none">
                      <Options>
                          <Caption fontFamily="Noto Sans KR, Arial, sans-serif"/>
                        </Options>   
                      <RadarChart id="ct0009m`+i+`" isSeriesOnAxis="true" type="polygon" paddingTop="25" paddingBottom="25" paddingLeft="60" paddingRight="60" startAngle="270" mouseSensitivity="17" letter-spacing="0.5px" line-height="7px">
                        <backgroundElements>
                              <RadarGridLines>
                                  <radarAlternateFill>
                                      <SolidColor color="#ffffff"/>
                                  </radarAlternateFill>
                              </RadarGridLines>
                          </backgroundElements>
                          <radialAxis>
                              <LinearAxis id="rAxis" maximum="100"/>
                          </radialAxis>
                          <angularAxis>
                              <CategoryAxis id="aAxis" categoryField="covrInfo" displayName="" labelJsFunction="fn_TSSCT009M_LabelFunc"/>
                          </angularAxis>
                          <radialAxisRenderers>
                              <Axis2DRenderer axis="{rAxis}" horizontal="false" visible="false" tickPlacement="outside" tickLength="4">
                                  <axisStroke>
                                      <Stroke color="#555555" weight="1"/>
                                  </axisStroke>
                              </Axis2DRenderer>
                          </radialAxisRenderers>
                        <angularAxisRenderers>
                            <AngularAxisRenderer axis="{aAxis}" labelGap="7"/>
                          </angularAxisRenderers>
                          <series>
                        
                        <RadarSeries radius="0" field="covrBfRate" selectionRadius="0">                     
                            <showDataEffect>
                                <SeriesInterpolate/>
                          </showDataEffect>
                          <lineStroke>` + lv_rgba_line + `</lineStroke>
                          <areaFill>` + lv_rgba_area   + `</areaFill>
                        </RadarSeries>                    
                        <RadarSeries radius="0" field="covrAfRate" selectionRadius="0">  
                          <showDataEffect>
                                <SeriesInterpolate/>
                          </showDataEffect>
                          <lineStroke>` + lv_af_rgba_line + `</lineStroke>
                          <areaFill>` + lv_af_rgba_area   + `</areaFill>
                        </RadarSeries> 
                        <RadarSeries radius="0" field="maxRate" selectionRadius="0">
                          <showDataEffect>
                            <SeriesInterpolate/>
                          </showDataEffect>
                          <lineStroke>` + lv_rgba_line_max + `</lineStroke>
                          <areaFill>` + lv_rgba_area_max   + `</areaFill>
                        </RadarSeries> 
                      </series>
                    </RadarChart>
          </rMateChart>`
          
          // console.log(layoutStr)
          rMateChartH5.create('ct0009m'+i, 'chartViewBeforAfter'+i, 'rMateOnLoadCallFunction=chartReadyHandler', '100%', '100%')
          rMateChartH5.registerTheme(rMateChartH5.themes)
        }
      })

    },
    fn_moveDetail(idx) {
      console.log('moveDetail >> ', idx)
      this.$emit('moveDetail', idx)
    }
    
 }// methods

}//export default


</script>
<style scoped>
</style>