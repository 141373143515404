 /*
 * 업무구분: 타사증권 입력
 * 화 면 명: MSPCT141D
 * 화면설명: 타사증권 입력 1.상품선택 단계 (등록상품)
 * 접근권한:
 * 작 성 일: 2023.03
 * 작 성 자: 정지윤
 */
 <template>
  <!-- Content영역 -->
  <mo-validate-watcher ref="vWatcher">
  <ur-box-container direction="column" alignV="start">
    <ur-box-container alignV="start" componentid="" class="ns-input-box mt30">
      <ur-box-container alignV="start" componentid="" direction="column" class=""> <!-- class="info-box mt20" -->
        <div class="info-title-wrap mb0">
          <span class="fs14rem crTy-bk7">가입회사</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
          <mo-segment-wrapper solid primary v-model="firstItem2" @click="segmentBoxClicked" class="chip-type-wrap">
            <mo-segment-button value="Y">생명보험</mo-segment-button>
            <mo-segment-button value="N">손해보험</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="mt10 ns-certify-sed pl0 pr0" :class="isErrorChk ? 'error' : ''">
          <msp-bottom-select ref="slctBox" class="ns-dropdown-sheet" :items="selected" :itemValue="'key'" :itemText="'label'"  underline bottom-title="보험사 선택" closeBtn scrolling placeholder="보험사 선택"
           v-model="select1.key" @input="fn_select1(select1)" :rules="validateRuleRequire1"/>
          <!-- <div class="ns-certify-sed" :class="isErrorChk ? 'error' : ''">
            <msp-bottom-select ref="slctBox" class="ns-dropdown-sheet" :items="selected" :itemValue="'key'" :itemText="'label'"  underline bottom-title="보험사 선택" closeBtn scrolling placeholder="보험사 선택"
           v-model="select1.key" @input="fn_select1(select1)" :rules="validateRuleRequire1"/>
          <div class="ns-certify-sed-txt">
            <div class="txt-error">보험사를 선택해 주세요</div>
          </div>
          </div> -->
          <div class="ns-certify-sed-txt">
            <div class="txt-error">보험사를 선택해 주세요</div>
          </div>
        </ur-box-container>
      </ur-box-container>  

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20"> 
        <div ref="test" tabindex="-1" class="info-title-wrap mb0">
          <span class="fs14rem crTy-bk7">상품</span>
        </div> 
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
          <mo-segment-wrapper solid primary v-model="firstItem3" class="chip-type-wrap">
            <mo-segment-button value="1">일반</mo-segment-button>
            <mo-segment-button value="2">유니버설</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container> 
        <ur-box-container alignV="start" componentid="" direction="column" class="mt10">
          <mo-text-field ref="searchKeyword" class="form-input-name full" searchable underline placeholder="상품명 입력" v-model="searchKeyword" @keyup.enter="fn_runSearch()" @click-icon="fn_runSearch()" :rules="validateRuleRequire2" />
        </ur-box-container>
      </ur-box-container>              
    </ur-box-container>

    <!-- 조회 후 -->
    <ur-box-container v-show="isSearch && select1.key!=='0'" alignV="start" componentid="" direction="column" class="ns-list-area mt30">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총 <strong class="crTy-blue3">{{getItems.length}}</strong> 건</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-radio-list2" >
        <mo-list :list-data="getItems">
          <template #list-item="{item}">
            <mo-list-item>
              <mo-radio v-model="slctData" :value="item.comyProfrOprcd" @input="fn_CheckSelectedItem(item)">
                <div class="list-item__contents">
                  <div class="list-item__contents__title mb0">
                    <span class="fwn name txtSkip" v-html="fn_getFilterKeyword(item.name)">{{item.name}}</span>
                  </div>
                </div>
              </mo-radio>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>

      <!-- no-data --> 
      <ur-box-container v-show="getItems=='' && select1.key!=='0'" alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
        <mo-list-item ref="expItem">
          <div class="list-item__contents">
            <div class="list-item__contents__info small-space">
              <span class="ns-ftcr-gray">
                <p class="mt20">조회된 상품정보가 없어요.</p>
                <mo-button class="ns-btn-round white sm" @click="fn_ResearchBtn()">다시 검색</mo-button>
              </span>                      
            </div>
          </div>
        </mo-list-item>
      </ur-box-container> 
    </ur-box-container>  
    <!-- Content 영역 end --> 
  </ur-box-container>
  </mo-validate-watcher>
</template>
<script>

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT141D",
    screenId: "MSPCT141D",
    components: { },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {

    },
    mounted () {
      this.fn_selListInsrOtcom(this.firstItem2)

      // 스크롤 애니메이션 instance 선언
      //this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      //document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        firstItem2: 'Y', // 가입회사('Y': 생명보험 / 'N' : 손해보험)
        firstItem3: '1', // 상품('1': 일반 / '2': 유니버셜)
        ProductGubun: '', // 상품구분(일반/유니버설)

        validateRuleRequire1: [
          v => !!v || '보험사를 선택해 주세요'
        ],
        validateRuleRequire2: [
          v => !!v || '상품명을 입력해 주세요'
        ],
        
        selected: [], // 보험사 리스트
        select1: {key: '0', label: '보험사명'},
        isErrorChk: false, // 보험사를 선택 여부
        getItems: [], // 상품명 검색 리스트
        searchKeyword : '', // 검색하는 상품명
        isSearch: false,
        selectedItem: {}, // 선택상품
        isSelectedItem: false, // 상품 선택 여부
        
        CustInfo: {},
        contractor: this.getStore('ctStore').getters.getCustBaseInfo.data.custNm, // 계약자. 세션값 가져오기 전까지 임시로 등록
        pdBtnIdx : '1', // 1: 등록상품, 2: 미등록상품
        scrapAplcYn: '', // 스크래핑 적용여부 (스크래핑)
        entryType: '', // 진입구분값 reg:신규등록 upd:수정 unReg:미등록 수정
        select2: {key: '', label: '', srcCustId: ''}, // 주종피보험자명. 비동의 일 경우는 나오면 안된다.
        custContRltnCd: '', // 고객계약관계코드
        date1: '', // 계약년월
        date2: '', // 만기년월
        date3: '', // 납입완료년월
        padCyl: {key: '01', label: '월납'},  // 납입주기 
        insurance: '0', // 보험료
        interestRate: '4.0', // 예정이율
        selGtplHoshInfoInputData: {
          mnCustId: this.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId,
          cnsltNo: this.getStore('ctStore').getters.getCustBaseInfo.data.cnsltNo
        },
        contractorDisabled: false, // 계약자 disabled 여부
        select2Disabled: false, // 보험대상자 disabled 여부
        slctData: '',

        // ■■■ 아래 변수들은 스크래핑에서만 사용됨 ■■■ // 신정원에서받아오는값?
        scrapAplcYn: '', // 스크래핑 적용여부 (스크래핑)
        insrSecusNo: '', // 증권번호 (스크래핑)
        prpslStatCd: '', // 제안상태코드 (스크래핑)
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      getCustInfo () {
        return this.getStore('ctStore').getters.getCustBaseInfo.data.custNm
      }
    },

    watch: {    
      // 가입회사(생명보험/손해보험)
      firstItem2() {
        this.isSelectedItem = false
        this.$emit('isSelectedItem', !this.isSelectedItem) // MSPCT140P의 다음 버튼 비활성화
      },

      // 상품 구분(일반/유니버설)
      firstItem3() {
        if (this.firstItem3 === '1') { // 일반
          this.ProductGubun = ''
        } else {                  // 유니버설
          this.ProductGubun = '03'
        }
        this.fn_beforeMoveMSPCT142D() // 정보저장
      },

      

      selectedItem() {
        this.sno = ''
        this.getStore('ctStore').dispatch('OTCOMPCONTDEL')
        this.date1 = '2000-' + moment(new Date().toISOString()).format('MM')
        this.date2 = moment(new Date().toISOString()).format('YYYY-MM-DD')
        this.date3 = this.fn_paidEndYearMonth()
        this.fn_paidEndYearMonth()
        this.fn_selListInsrOtcom(this.firstItem2) // 타사 상품 조회
        this.fn_selGtplHoshInfo() // 세대원 정보 조회

        if(Object.keys(this.selectedItem).length > 0) {
          this.isSelectedItem = true
        }
        this.$emit('isSelectedItem', !this.isSelectedItem) // MSPCT140P의 다음 버튼 활성화

        //this.fn_beforeMoveMSPCT142D() // 정보저장
      }, 

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : segmentBoxClicked
      * 설명       : 가입회사선택(생명보험/손해보함)
      ******************************************************************************/
      segmentBoxClicked () {
        this.$nextTick(() => {
          this.select1.key = '0' // 선택된 보험사 초기화
          this.fn_selListInsrOtcom(this.firstItem2)
        })
        this.getItems = [] // 상품 검색 초기화
        this.searchKeyword = '' // 상품 검색 키워드 초기화
      },

      /******************************************************************************
      * Function명  : fn_selListInsrOtcom
      * 설명        : 타보험회사조회
      ******************************************************************************/
      fn_selListInsrOtcom (yn) {
        this.isLoading = true
        let lv_Vm = this
        let pParams = {
          linsSlctYn: yn
        }
        const trnstId = 'txTSSCT14S1'
        const auth = 'S'
        // this.select1 = {key: '-1', label: '보험사명'}
        console.log('pParams >>>', pParams)
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            let otcomCdInfoVO = response.body.otcomCdInfoVO
            lv_Vm.isLoading = false
            lv_Vm.fn_OtcomInsrCoSVO(otcomCdInfoVO)
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },

      /******************************************************************************
      * Function명 : fn_OtcomInsrCoSVO
      * 설명       : 가입회사 상품 리스트
      ******************************************************************************/
      fn_OtcomInsrCoSVO (getdata) {
        let data = getdata
        let sVO = {}
        let sList = []
        for (let i in data) {
          sVO = {}
          sVO.key = data[i].outOtcomStndCd
          sVO.label = data[i].outOtcomStndValNm
          sList.push(sVO)
        }
        this.selected = sList
      },

      /******************************************************************************
      * Function명 : fn_ResearchBtn
      * 설명       : 다시 검색 버튼
      ******************************************************************************/
      fn_ResearchBtn () {
        // 화면 상단으로 다시 스크롤링
        this.$emit('scrollTop')
        // 상품명 검색영역 focus
        this.$refs.searchKeyword.focus()
      },

      /******************************************************************************
      * Function명  : fn_runSearch
      * 설명        : 상품 검색건에 대한 출력
      ******************************************************************************/
      fn_runSearch () {
        this.isSearch = true
        // 상품검색 유효성 체크
        if (this.select1.key === '0') {
          //this.$refs.slctBox.validate()
          // this.getStore('confirm').dispatch('ADD', '가입회사를 선택해 주세요.')
          this.isErrorChk = true // 보험사를 선택해 주세요.
          // this.$refs.vWatcher.validate()
          this.isSearch = false
          return false
        } else if (this.searchKeyword === '') {
          this.isSelectedItem = false
          this.$emit('isSelectedItem', !this.isSelectedItem) // MSPCT140P의 다음 버튼 비활성화
          this.searchKeyword = ''
          this.$refs.searchKeyword.focus()
          // this.getStore('confirm').dispatch('ADD', '상품명을 입력해 주세요.')
          // this.$refs.vWatcher.validate()
          this.isSearch = false
          return false
        }
        this.isErrorChk = false
        this.slctData = '' // 선택상품 라디오버튼 초기화
        this.selectedItem = {} // 선택상품 초기화
        if(this.slctData === ''){
          this.isSelectedItem = false
          this.$emit('isSelectedItem', !this.isSelectedItem) // MSPCT140P의 다음 버튼 비활성화
        }

        this.isLoading = true
        let lv_Vm = this
        let pParams = {
          otcomPrdtNm: lv_Vm.searchKeyword,
          othInsrCoCd: lv_Vm.select1.key
        }
        const trnstId = 'txTSSCT17S1'
        const auth = 'S'

        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            let otcomPrdtInfoVO = response.body.otcomPrdtInfoVO
            lv_Vm.isLoading = false
            lv_Vm.fn_otcomPrdtInfoVO(otcomPrdtInfoVO)
            // lv_Vm.getItems = lv_Vm.fn_getFilterKeyword(lv_Vm.searchKeyword)
          })
          .catch(function (error) {
            window.vue.error(error)
          })

        //test
        // lv_Vm.$refs.test.scrollIntoView({ behavior: "smooth"})
        
      },

      /******************************************************************************
      * Function명 : fn_otcomPrdtInfoVO
      * 설명       : (검색 상품 가져오기)
      ******************************************************************************/
      fn_otcomPrdtInfoVO (keyword) {
        let data = keyword
        let sVO = {}
        let sList = []
        for (let i in data) {
          sVO = {}
          sVO.name = data[i].otcomPrdtNm
          sVO.otcomPrdtScCd = data[i].otcomPrdtScCd
          sVO.comyProfrOprcd = data[i].comyProfrOprcd
          sList.push(sVO)
        }
        console.log('sList>>>', sList)
        this.getItems = sList

        // console.log('this.$refs.detail', this.$refs.test)
        // this.$refs.test.focus()
      },

      /******************************************************************************
      * Function명  : fn_getFilterKeyword
      * 설명        : 상품명 내 검색어 하이라이트
      ******************************************************************************/
      fn_getFilterKeyword (val) {
        if(!this.searchKeyword){
          return val
        }
        const regex = new RegExp(`(${this.searchKeyword})`, 'gi')
        return val.replace(regex, '<span class="crTy-blue3">$1</span>')
      },

      /******************************************************************************
       * Function명 : fn_CheckSelectedItem
       * 설명       : 선택 상품 저장
       ******************************************************************************/
      fn_CheckSelectedItem (item) {
        this.selectedItem = item
        console.log('@@@this.selectedItem >> ', this.selectedItem)
        //this.fn_beforeMoveMSPCT142D()
      },

      /******************************************************************************
       * Function명 : fn_InitCheckSelectedItem
       * 설명       : 이전 선택 버튼 초기화
       ******************************************************************************/
      fn_InitCheckSelectedItem (item) {
        //this.selectedItem.checked = false
        this.fn_CheckSelectedItem(item)
      },

      /******************************************************************************
      * Function명 : fn_beforeMoveMSPCT142D
      * 설명       : 입력한 기본정보 Vuex에 담아놓음
      ******************************************************************************/
      fn_beforeMoveMSPCT142D () {
        console.log('fn_beforeMoveMSPCT142D selectedItem>>>>', this.selectedItem)
        console.log('this.select2>>>', this.select2)
          let gtplOtcomPrdtInptScCd = this.pdBtnIdx // 등록상품('1')
          let comyProfrOprcd = ''
          let outComyPrdtTrtyVO01 = ''
          let InsuredGubun = '1' // 1: 주피 / 2: 종피

          // if (gtplOtcomPrdtInptScCd === '1') {
          //   if (this.scrapAplcYn === 'Y') { // 스크래핑 적용여부
          //     this.ItemGubun = '3' // 스크래핑일 경우
          //   } else {
          //     this.ItemGubun = '1'
          //   }
          // } else {
          //   this.ItemGubun = '2'
          // }

          // 등록상품 수정 (수정하기)
          if (this.entryType === 'upd') { 
            comyProfrOprcd = this.getItems[0].comyProFrOprcd
            outComyPrdtTrtyVO01 = this.outComyPrdtTrtyVO01
          }
          // 등록상품 신규 등록 (or 미등록수정)
          else {
            comyProfrOprcd = this.selectedItem.comyProfrOprcd // 선택 상품명
            outComyPrdtTrtyVO01 = ''
          }

          if (InsuredGubun === '1' || InsuredGubun === '01') {
            this.InsuredGubun = '01' // 주피
          } else {
            this.InsuredGubun = '02' // 종피
          }

          this.fn_custContRltnCd() // 고객계약관계코드

          let setVO = {
            chnlCustId: this.select2.srcCustId, // 채널고객ID
            srcCustId: this.entryType === 'upd' ? this.srcCustId : this.select2.srcCustId, // 등록시에는 채널고객ID와 동일, 수정 시 보험대상자를 변경하는 경우에 변경 전 보험대상자ID
            insrdObjScCd: this.InsuredGubun, // 피보험자대상구분코드 01-주피, 02-종피
            otcomAcontVO: { // 타사기계약VO
              chnlCustId: this.select2.srcCustId, // 채널고객ID
              contrNm: this.contractor, // 계약자명
              gtplInsrScCd: this.ProductGubun, // 보장플랜보험구분코드 '':일반, '03':유니버설
              custContRltnCd: this.custContRltnCd, // 고객계약관계코드 '12-계약자+주피, 13-계약자+종피, 21-주피보험자, 23-종피보험자
              insrCoNm: this.select1.label, // 보험회사명
              insrdObjScCd: this.InsuredGubun, // 피보험자대상구분코드 01-주피, 02-종피
              mnisdNm: this.InsuredGubun === '01' ? this.select2.label : '', // 주피보험자명
              aisdNm: this.InsuredGubun === '02' ? this.select2.label : '', // 종피보험자명
              otcomPrdtNm: this.selectedItem.name, // 타사상품명
              comyProfrOprcd: comyProfrOprcd, // 업체제공외부상품코드
              contYm: this.date1.substr(0, 7), // 계약년월
              expYm: this.date2.substr(0, 7), // 만기년월
              padCylCd: this.padCyl.key, // 납입주기코드
              padCmpltYm: this.date3.substr(0, 7), // 납입완료년월
              prm: this.insurance, // 보험료
              gtplOtcomPrdtInptScCd: this.pdBtnIdx, // 보장플랜타사상품입력구분코드 1 : 등록 타사 상품 2 : 미등록 타사상품 3:스크래핑
              othInsrCoCd: this.select1.key, // 타보험회사코드
              parngIrt: this.interestRate, // 예정이율
              //cnsltNo: this.selGtplHoshInfoInputData.cnsltNo, // 컨설턴트번호
              // ■■■ 아래는 스크래핑 타사를 위한 값임 ■■■ //
              scrapAplcYn: this.scrapAplcYn, // 스크래핑 적용여부
              insrSecusNo: this.insrSecusNo, // 증권번호
              prpslStatCd: this.prpslStatCd // 제안상태코드

            },
            outComyPrdtTrtyVO01: outComyPrdtTrtyVO01,
            otcomContSno: this.sno
          }
          this.CustInfo = this.getStore('ctStore').dispatch('OTCOMPCONTREG', {'data': setVO}) // vuex 타사 팝업관련 값 저장 방법
          console.log('setVO>>>', setVO)
        // }
      },

      /******************************************************************************
      * Function명 : fn_custContRltnCd
      * 설명       : 고객계약관계코드 추출
      ******************************************************************************/
      fn_custContRltnCd () {
        if (this.InsuredGubun === '01' && this.select2.label === this.getCustInfo) {
          this.custContRltnCd = '12'
        } else if (this.InsuredGubun === '02' && this.select2.label === this.getCustInfo) {
          this.custContRltnCd = '13'
        } else if (this.InsuredGubun === '01' && this.select2.label !== this.getCustInfo) {
          this.custContRltnCd = '21'
        } else if (this.InsuredGubun === '02' && this.select2.label !== this.getCustInfo) {
          this.custContRltnCd = '23'
        }
      },  

      /******************************************************************************
      * Function명 : fn_select1
      * 설명       : 선택한 보험사
      ******************************************************************************/
      fn_select1 (selectData) {
        this.isErrorChk = false // 보험사 선택 문구 (보험사를 선택해 주세요.)
        this.getItems = [] // 검색 상품 리스트 초기화
        this.searchKeyword = '' // 상품 검색 키워드 초기화
        let selData = this.selected.filter(item => {
          return item.key === selectData.key
        })[0]
        this.select1 = {key: selData.key, label: selData.label}
        // this.selected.filter(item => {
        //    if(item.key === selectData.key) {
        //      this.select1 = {key: item.key, label: item.label}
        //    }
        // })
      },  

      /******************************************************************************
      * Function명  : fn_selRegOtcomInsr
      * 설명        : 등록된 타사보험 계약사항 조회
      * Params      :
      ******************************************************************************/
      fn_selRegOtcomInsr () {
        let lv_Vm = this
        let chnlMnCustId = this.getStore('ctStore').getters.getCovanData.data.gtplCllgHoshVO.chnlMnCustId
        let pParams = {
          otcomContSno: lv_Vm.sno,
          chnlCustId: lv_Vm.chnlCustId,
          chnlMnCustId: chnlMnCustId
        }

        this.$bizUtil.ctUtils.fn_selRegOtcomInsr(lv_Vm, pParams).then(function (response) {
          if (response.msgComm.msgCd !== 'EFWS003') {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            // lv_Vm.showPopup = false // 에러시 팝업 닫힘
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      }, 

      /******************************************************************************
      * Function명  : fn_paidEndYearMonth
      * 설명        : 납입완료 월 계산
      * Params      :
      ******************************************************************************/
      fn_paidEndYearMonth () {
        let getYYYY = moment(new Date().toISOString()).format('YYYY-')
        let paidEndMonthChoice = moment(new Date().toISOString()).format('MM')
        let now = Number(moment(new Date().toISOString()).format('MM'))
        if (now === 1) {
          paidEndMonthChoice = '12'
        } else {
          paidEndMonthChoice = String(Number(paidEndMonthChoice) - 1)
          if (Number(paidEndMonthChoice) <= 9) {
            paidEndMonthChoice = '0' + paidEndMonthChoice
          } else {
            paidEndMonthChoice = String(paidEndMonthChoice)
          }
        }
        return getYYYY + paidEndMonthChoice
      },

      /******************************************************************************
      * Function명  : fn_selGtplHoshInfo
      * 설명        : 세대원정보 조회
      ******************************************************************************/
      fn_selGtplHoshInfo () {
        let lv_Vm = this
        let ipData = lv_Vm.selGtplHoshInfoInputData
        this.$bizUtil.ctUtils.fn_SelHems(lv_Vm, ipData).then(function (response) {
          let rtnArray = response
          lv_Vm.fn_gtplCllgCustVO(rtnArray)
          if (lv_Vm.entryType !== 'upd') {
            lv_Vm.select2 = {key: lv_Vm.getCustNm[0].key, label: lv_Vm.getCustNm[0].label, srcCustId: lv_Vm.getCustNm[0].srcCustId}
            console.log('무조건 탐!!!!', lv_Vm.select2)
            lv_Vm.fn_beforeMoveMSPCT142D() // 정보저장
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },

      /******************************************************************************
      * Function명 : fn_gtplCllgCustVO
      * 설명       : 세대원 정보(고객명, 주고객관계코드 추출)
      ******************************************************************************/
      fn_gtplCllgCustVO (getCust) {
        // 비동의 유무 체크
        let data = getCust
        let sVO = {}
        let sList = []
        for (let i in data) {
          if (data[i].gtplCllgCustInfoPrcusCnsntVO.gtplInfoPrcusCnsntYmd !== ' ') {
            sVO = {}
            sVO.key = i
            sVO.label = data[i].custNm
            sVO.srcCustId = data[i].chnlCustId
            sList.push(sVO)
          }
        }
        this.getCustNm = sList
      },

    },
  };
</script>
<style scoped>
</style>