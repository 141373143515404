<template>
   <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">    
    <!-- 컨텐츠 영역 --> 
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap bgcolor-1 pb100">
      <mo-tab-box :defaultIdx="0" ref="refPlanClsf" @tab-change="fn_planClsfTabChange" class="ns-move-tab-box w100 gray-tab-bar msp-tab-ty">
        <template v-for="(list, idx) in tableHeaderMock">
          <mo-tab-label :idx="idx" :key="idx"><span>{{list.title}}</span></mo-tab-label>
        </template>
      </mo-tab-box>
      <!--보장설계: 변경된 보장만보기 -->
      <ur-box-container direction="row" alignV="start" class="fexTy3-1 bgcolor-1 pal1524 pb5">
        <span class="fs16rem fwn fexTy5">
          변경된 보장만 보기 <mo-switch v-model="changeValue" small class="ns-switch ml10"/>
        </span>
        <span class="fs14rem crTy-bk7">(단위: 만원)</span>
      </ur-box-container>
      <!--//보장설계:  변경된 보장만보기 -->
      <!-- S: 보장목록 리스트 -->
      <ur-box-container direction="column" alignV="start" class="ns-info-list pl24" v-if="scrnDataList.length > 0">
        <template v-for="(item, idx) in scrnDataList">
          <ur-box-container :key="idx" direction="column" alignV="start" class="round-box round-ty">
            <div class="w100">
              <div class="title fexTy3 pb10">
                <strong class="fexTy5 fs18rem fwm">
                  <span class="mr6">{{item.type}}</span> 
                  <mo-icon type="picto">{{'emoticon'+ (Number(item.status) + 1)}}</mo-icon>
                </strong>
                <!-- S: 설계전후 증감표시 -->
                <!-- 증가표시  -->
                <span class="in-decrease-express fexTy5" v-if="item.amount-item.custAmount > 0">
                  <b class="num fs18rem crTy-blue3">{{$bizUtil.numberWithCommasByNumber(item.amount-item.custAmount, item)}}</b>
                  <mo-icon icon="msp-arrow-increase"/>
                </span>
                <!-- 감소표시 -->
                <span class="in-decrease-express fexTy5" v-if="item.amount-item.custAmount < 0">
                  <b class="num fs18rem crTy-orange2">{{$bizUtil.replaceAll($bizUtil.numberWithCommasByNumber(item.amount-item.custAmount, item), '-', '')}}</b>
                  <mo-icon icon="msp-arrow-decrease"/>
                </span>
                <!-- 동일표시 -->
                <span class="in-decrease-express fexTy5" v-if="item.amount-item.custAmount == 0">
                  <mo-icon icon="msp-arrow-same"/>
                </span>
                <!-- E: 설계전후 증감표시 //--> 
              </div>
              <span class="stand-express crTy-bk7 fexTy5">
                <span class="pr10">권장 <b>{{$bizUtil.numberWithCommasByNumber(item.requireAmount)}}</b></span>
                <mo-button icon="write2" class="ic-button pal0" borderless small @click="fn_openMSPCT150P(item.attr)"></mo-button>
              </span>
              <!-- S: 설계전후 권장모델대비 보장자산 금액 bar 차트 -->
              <!-- 설계전 -->
              <div class="msp-chart-area mt20">
                <div class="txt-box fexTy3-1">
                  <span class="tit crTy-bk7">설계 전</span>
                  <b class="mount">{{$bizUtil.numberWithCommasByNumber(item.custAmount)}}</b>
                </div>
                <div class="bar-chart">
                  <div class="fill-bar" :style="{width:fn_chartBarBf(item)}"></div> <!--:style="width:"-->
                </div>
              </div>
              <!-- 설계전 //-->

              <!-- 설계후 -->
              <div class="msp-chart-area mt20">
                <div class="txt-box fexTy3-1">
                  <span class="tit crTy-bk1 fwb">설계 후</span>
                  <b class="mount">{{$bizUtil.numberWithCommasByNumber(item.amount)}}</b>
                </div>
                <div class="bar-chart">
                  <div class="fill-bar" :class="item.amount-item.custAmount >= 0 ? 'after-up': 'after-down'" :style="{width:fn_chartBarAf(item)}"></div> <!--:style="width:"-->
                </div>
              </div>
              <!-- 설계후 //-->
              <!-- E: 설계전후 권장모델대비 보장자산 금액 bar 차트 //-->
              <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="w100 mt20">
                <mo-button color="primary" shape="border" size="medium" class="ns-btn-round btn-title-style-1 w100 h40 fs16rem" @click="fn_openDetail(item)">상품별 보장금액</mo-button>
              </ur-box-container>

            </div>
          </ur-box-container>
        </template>
      </ur-box-container>
      <!-- E: 보장목록 리스트 -->
      <!-- no-data  -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="no-data" v-else>
        <mo-list-item ref="expItem" class="bgcolor-1">
          <div class="list-item__contents bgcolor-1">
            <div class="list-item__contents__info small-space">
              <span class="ns-ftcr-gray">
                <p class="mt20">선택한 상태의 보장이 없어요.</p>
              </span>                      
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>  

       <!-- 바텀시트 : 일반사망 바텀시트 표출 -->
        <mo-bottom-sheet ref="bottomSheet1"  class="ns-bottom-sheet msp-bottomSt-ty7 closebtn">
          <template v-slot:title>{{pdtDetailItem.type}}</template>
        <div class="ns-btn-close" @click="fn_closeDetail"></div>  
          <div class="content-area">
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area">
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-list lst-bdBN bd-b-Ty1">  
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info pr0">
                      <!-- 증가표시 //-->
                      <template v-if="pdtDetailItem.amount-pdtDetailItem.custAmount > 0">
                        <span class="fs26rem fwb crTy-blue3"><b class="fs30rem">{{$bizUtil.numberWithCommasByNumber(pdtDetailItem.amount-pdtDetailItem.custAmount, pdtDetailItem)}}</b>만원</span>
                        <mo-icon icon="msp-arrow-increase2" icon-size="28px" class="ml10"/> 
                      </template>
                      <!-- 증가표시 //-->
                      <!-- 감소표시  -->
                      <template v-if="pdtDetailItem.amount-pdtDetailItem.custAmount < 0">
                        <span class="fs26rem fwb crTy-orange2"><b class="fs30rem">{{$bizUtil.replaceAll($bizUtil.numberWithCommasByNumber(pdtDetailItem.amount-pdtDetailItem.custAmount, pdtDetailItem), '-', '')}}</b>만원</span>
                        <mo-icon icon="msp-arrow-decrease2" icon-size="28px" class="ml10"/>  
                      </template>
                       <!-- 감소표시 // -->
                      <!-- 동일표시  -->
                      <template v-if="pdtDetailItem.amount-pdtDetailItem.custAmount == 0">
                        <span class="fs26rem fwb"><b class="fs30rem">{{$bizUtil.numberWithCommasByNumber(pdtDetailItem.amount-pdtDetailItem.custAmount, pdtDetailItem)}}</b>만원</span>
                        <mo-icon icon="msp-arrow-same2" icon-size="22px" class="ml10 mt3"/>  
                      </template>
                       <!-- 동일표시 // -->
                    </div>
                    <div class="list-item__contents__info pr0 pt10 fexTy5">
                      <span class="fs14rem crTy-bk7 mr10">설계 전</span><span>{{$bizUtil.numberWithCommasByNumber(pdtDetailItem.custAmount)}}만원</span><em>|</em>
                      <span class="fs14rem crTy-bk7 mr10">설계 후</span><span>{{$bizUtil.numberWithCommasByNumber(pdtDetailItem.amount)}}만원</span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container>
              <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-check-list">
                <mo-list :list-data="pdtDetailItem.data">
                  <template #list-item="{item}">
                    <mo-list-item> 
                      <div class="list-item__contents">
                        <div class="list-item__contents__title">
                          <span class="name fs17rem fwn">{{item.prdtNm}}</span>
                          <mo-badge class="badge-sample-badge sm mt3" :class="item.isRecommendProduct ? 'lightred2' : !item.isOtherProduct ? 'lightblue' : item.gtplOtcomPrdtInptScCd === '4' ? 'purple' : 'lightgray2'" shape="status">
                            {{item.isRecommendProduct ? '추천' : !item.isOtherProduct ? '당사' : item.gtplOtcomPrdtInptScCd === '4' ? '신정원' : '타사'}}
                          </mo-badge>
                        </div>
                        <div class="list-item__contents__info fexTy3 pal0">
                          <span class="crTy-bk1 fs14rem">{{item.insrCoNm}}</span>
                          <strong class="amount crTy-bk1 fs17rem">{{$bizUtil.numberWithCommasByNumber(item.value)}}만원</strong>
                        </div>
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list>
              </ur-box-container>
            </ur-box-container>
          </div>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
              <div class="relative-div">
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_closeDetail" name="확인">확인</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
        <!-- 바텀시트 : 일반사망 바텀시트 표출 //-->    
    </ur-box-container>
    <!-- 컨텐츠 영역 //--> 
  </ur-box-container>
</template>

<script>
/***********************************************************************************
 * INCLUDE 영역
***********************************************************************************/
import UrBoxContainer from '@/_uidev/containers/box/urBoxContainer.vue'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCT121D",
  screenId: "MSPCT121D",
  components: {
   UrBoxContainer
  },//componets,
  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    pSlctIdx: {
      type: Number,
      default: 1
    }, // 부모 선택한 현재 탭 인덱스
    pChangeValue: {
      type: Boolean,
      default: false
    },
    pProductList: {
      type: Array,
      default: []
    }
  },//props
  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      dataList: [],
      scrnDataList: [],
      pdtDetailItem: { 
        type: '',
        custAmount: 0,
        amount: 0,
        data: [] 
      },
      slctIdx: 0,
      changeValue: false
    }
  },//data
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
  },
  mounted () {
    this.dataList = this.tableHeaderMock[this.slctIdx].columns[0].data
    this.fn_setScrnDataList(this.changeValue)
  },
  computed: {
    tableHeaderMock: function () {
      return this.$bizUtil.ctUtils.fn_GetTableTitles()
    }
  },
  watch: {
    slctIdx(v){
      this.dataList = this.tableHeaderMock[v].columns[0].data
      this.fn_setScrnDataList(this.changeValue)
    },
    changeValue(v) {
      this.fn_setScrnDataList(v)
    },
    // pSlctIdx(){
    //   // console.log(this.$options.screenId + " : this.pSlctIdx = "+ this.pSlctIdx)
    //   this.dataList = this.tableHeaderMock[this.$props.pSlctIdx].columns[0].data
    //   this.fn_setScrnDataList(this.$props.pChangeValue)
    // },
    // pChangeValue(newVal){
    //   this.fn_setScrnDataList(newVal)
    // }
  },

  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    fn_planClsfTabChange(idx) {
      this.slctIdx = idx
    },
    fn_openMSPCT150P(attr){
      this.$emit('openMSPCT150P', attr)
    },
    fn_setScrnDataList(newVal){
      if(newVal){
        this.scrnDataList = this.dataList.filter((item) => {return item.amount != item.custAmount})
      }else{
        this.scrnDataList = this.dataList
      }
    },
    fn_chartBarBf(item) {
      if(item.custAmount == 0) return '0%'
      let per = item.custAmount/item.requireAmount*100
      if(per > 100){
        return '100%'
      }else{
        return per + '%'
      }
    },
    fn_chartBarAf(item) {
      if(item.amount == 0) return '0%'
      let per = item.amount/item.requireAmount*100
      if(per > 100){
        return '100%'
      }else{
        return per + '%'
      }
    },
    fn_openDetail(item) {
      this.pdtDetailItem = item
      this.pdtDetailItem.data = []
      this.$props.pProductList.forEach((info) => {
        let data = {}
        data.prdtNm = info.name // 상품명
        data.insrCoNm = info.insrCoNm // 회사명
        data.gtplOtcomPrdtInptScCd = info.gtplOtcomPrdtInptScCd // 신정원코드
        data.isOtherProduct = info.isOtherProduct // 타사여부
        data.isRecommendProduct = info.isRecommendProduct // 추천
        for(let dKey in info.details){
          let valueList = info.details[dKey]
          for(let vKey in valueList){
            if(valueList[vKey].attr == this.pdtDetailItem.attr){
              // console.log(this.pdtDetailItem.type, '매핑됨 >>> ', this.pdtDetailItem.attr, ' >>> ', valueList[vKey].value, this.pdtDetailItem.data.length)
              data.value = valueList[vKey].value  // 보장금액

              this.pdtDetailItem.data.push(data)
              return false
            }
          }
        }
      })
      console.log(this.pdtDetailItem)
      this.$refs.bottomSheet1.open()
    },
    fn_closeDetail() {
      this.pdtDetailItem = { 
        type: '',
        custAmount: 0,
        amount: 0,
        data: [] 
      }
      this.$refs.bottomSheet1.close()
    }

  
  }

}//export default

</script>
<style scoped>
</style>