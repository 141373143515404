<template>
  <ur-page-container ref="page" class="msp" :show-title="false" title="실손가입 조회" :topButton="true">
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_popupClose">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '실손가입 조회' : '실손가입 조회'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <ur-box-container direction="column" alignV="start" class="pal2430"> 
              <div class="full fexTy5">
                <b class="txtSkip fs30rem" @click="fn_PopupCustNmUiCard">{{ selRldmEntData.selRldmEntPnstaCtlVO.custNm }}</b> 
              </div>
              <div class="full fexTy5 mt10">
                <span class="crTy-bk7">정보동의</span>
                <span v-if="selRldmEntData.selRldmEntPnstaCtlVO.rldmInfoPrcusCnsntYmd" class="ml10 fs16rem">{{ selRldmEntData.selRldmEntPnstaCtlVO.rldmInfoPrcusCnsntYmd }}</span>
              </div>
              <div class="full fexTy5 mt4">
                <span class="crTy-bk7">정보수령</span>
                <span v-if="selRldmEntData.selRldmEntPnstaCtlVO.infoInqrReciDtm" class="ml10 fs16rem">{{ selRldmEntData.selRldmEntPnstaCtlVO.infoInqrReciDtm }}</span>
              </div>
            </ur-box-container> 
            <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3"></ur-box-container>  
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <ur-box-container alignV="start" componentid="" direction="column" class="pal2430 pb15">
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment msp-segment-ty1">
                <mo-segment-wrapper solid primary v-model="tab_index">
                  <mo-segment-button value="1">가입정보</mo-segment-button>
                  <mo-segment-button value="2">가입현황</mo-segment-button>
                  <mo-segment-button value="3">가능여부</mo-segment-button>
                </mo-segment-wrapper>
              </ur-box-container>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum" v-if="tab_index === '1'">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="sum">총 <strong class="crTy-blue3">{{ selRldmEntData.pIRldmDupInsrVO.length }}</strong> 건</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container> 
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- Content영역 -->
            <!--가입정보 탭-->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area" v-if="tab_index === '1'">
              <!-- no-data --> 
              <ur-box-container v-if="selRldmEntData.pIRldmDupInsrVO.length === 0" alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
                <mo-list-item ref="expItem">
                  <div class="list-item__contents">
                    <div class="list-item__contents__info">
                      <span class="ns-ftcr-gray"><p class="mt20">조회된 가입정보가 없어요.</p></span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container>

              <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list list-pa2024">
                <mo-list :list-data="selRldmEntData.pIRldmDupInsrVO">
                  <template #list-item="{item}">
                    <mo-list-item>
                      <div class="list-item__contents">
                        <div class="list-item__contents__title fexTy3 mb1">
                          <span class="name txtSkip">{{item.coNm}}</span>
                          <mo-badge class="badge-sample-badge sm lightblue5" text="" shape="status">{{ item.contStatCdNm }}</mo-badge>
                        </div>
                        <div class="list-item__contents__info">
                          <span class="fs14rem crTy-bk1">{{item.covrCdNm}}</span>
                        </div>
                        <div class="list-item__contents__info pt7">
                          <span><span class="fs14rem">보장유형</span><span class="ml10 crTy-bk1">{{item.covrTypNm}}</span></span><em>|</em><span><span class="fs14rem">담보특성</span><span class="ml10 crTy-bk1">{{item.rldmColtrTypCdNm}}</span></span>
                        </div>
                        <div class="list-item__contents__info pt1">
                          <span class="fs14rem">가입금액</span>
                          <span class="ml10 crTy-bk1">{{ $commonUtil.numberWithCommas(item.entAmt) }}원</span>
                        </div>
                        <div class="list-item__contents__info pt1">
                          <span class="fs14rem">담보별보장기간</span>
                          <span class="ml10 crTy-bk1">{{item.secusYmd}}</span>
                        </div>
                        <div class="list-item__contents__info pt1 pb0">
                          <span class="fs14rem">보험종목명</span>
                          <span class="ml10 crTy-bk1">{{item.rldmInsrLineNm}}</span>
                        </div>
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list>        
              </ur-box-container>
            </ur-box-container>

            <!--가입현황 탭-->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area" v-if="tab_index === '2'">
              <ur-box-container direction="column" alignV="start" class="con-area mt15"> 
                <div class="full">
                  <span class="fs18rem">실손 가입현황(당사 및 타사포함)</span> 
                </div>
              </ur-box-container> 
              <ur-box-container direction="column" align-v="start" class="ns-add-area pt10">
                <div class="table-area">
                  <table class="table col-type th-txt-left">
                    <colgroup>
                      <col width="50%" />
                      <col width="" />
                    </colgroup>
                    <tbody>
                      <tr v-for="data in selRldmEntData.otcomRldmEntPnsta2VO" :key="data.key">
                        <th class="bdRN">{{ data.key }}</th>
                        <td>{{ data.value === 'Y' ? 'O' : 'X' }}</td>
                      </tr>

                      <tr v-for="data in selRldmEntData.otcomRldmEntPnstaVO" :key="data.key">
                        <th class="bdRN">{{ data.key !== 'NRI/NRA' ? data.key : 'MRI/MRA' }}</th>
                        <td>{{ data.value === 'Y' ? 'O' : 'X' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ur-box-container>    
            </ur-box-container>  

            <!--가능여부 탭-->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area" v-if="tab_index === '3'">
              <ur-box-container direction="column" alignV="start" class="con-area mt15"> 
                <div class="full">
                  <span class="fs18rem">당사 실손 가입가능 여부</span> 
                </div>
                <div class="text-gray-box mt10">
                  <ul class="terms-list-area crTy-bk7 fs14rem">
                    <li>{{ selRldmEntData.selRldmEntPnstaCtlVO.rldmEntPsbYnMsgCntnt }}</li>
                  </ul>
                </div>
              </ur-box-container> 
              <ur-box-container direction="column" align-v="start" class="ns-add-area pt10">
                <div class="table-area">
                  <table class="table col-type th-txt-left">
                    <colgroup>
                      <col width="50%" />
                      <col width="" />
                    </colgroup>
                    <tbody>
                      <tr v-for="data in selRldmEntData.tcompRldmEntPsbPnsta2VO" :key="data.key">
                        <th class="bdRN">{{ data.key}}</th>
                        <td>{{ data.value === 'Y' ? 'O' : 'X' }}</td>
                      </tr>

                      <tr v-for="data in selRldmEntData.tcompRldmEntPsbPnstaVO" :key="data.key">
                        <th class="bdRN">{{ data.key !== 'NRI/NRA' ? data.key : 'MRI/MRA' }}</th>
                        <td>{{ data.value === 'Y' ? 'O' : 'X' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ur-box-container>    
            </ur-box-container>              
            <!-- Content 영역 end -->  

            <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_popupClose">취소</mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_reLoad">다시 불러오기</mo-button>
              </div>
            </ur-box-container>
          </template>

        </mo-collapsing-header-view>
      </div> 
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import CustInfoBottomSheet from '@/ui/cm/CustInfoBottomSheet' // 고객카드
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT130P",
    screenId: "MSPCT130P",
    components: { },
    props: {
      pSelRldmEntData: {
        type: Object,
        default: {}
      }
    },
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.selRldmEntData = this.pSelRldmEntData
    },
    mounted () {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog(this.$options.screenId)

      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_HeaderviewScrollCompID: '',
        selGtplHoshInfoInputData: {
          mnCustId: this.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId, // 만자신
          cnsltNo: this.getStore('ctStore').getters.getCustBaseInfo.data.cnsltNo
        },
        selRldmEntData: {},
        tab_index: '1',
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      setopen () {
        this.event01=!this.event01;
      },
      open() { this.$refs.nsbottomsheet.open();  },
      close() { this.$refs.nsbottomsheet.close(); },
      open1() { this.$refs.nsbottomsheet1.open();  },
      close1() { this.$refs.nsbottomsheet1.close(); },
      open2() { this.$refs.nsbottomsheet2.open();  },
      close2() { this.$refs.nsbottomsheet2.close(); },

      /******************************************************************************
      * Function명  : fn_reLoad
      * 설명        : 다시 불러오기
      ******************************************************************************/
      fn_reLoad() {
        let lv_Vm = this
        // 실손가입예약신청 I/F
        this.$bizUtil.ctUtils.fn_reqRldmEntInqr(lv_Vm, false).then( function(result) {
          lv_Vm.selRldmEntData = result
          console.log('다시불러오기 결과값 확인>>>', lv_Vm.selRldmEntData)
        }).catch(function (error) {
          window.vue.error(error)
        })
      },

      fn_popupClose() {
        this.$emit('onPopupClose')
      },

      /******************************************************************************
      * Function명  : fn_PopupCustNmUiCard
      * 설명        : 고객카드 팝업 오픈
      ******************************************************************************/
      fn_PopupCustNmUiCard () {
      //to-do 고객네임카드 연계 화면 처리
      console.log('aaaa', this.getStore('userInfo').getters.getUserInfo.userId)
      //if(pChnlCustId !== '-'){
        this.lv_CustInfoBottomSheet = this.$bottomModal.open(CustInfoBottomSheet, {
          properties: {
            chnlCustId: this.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId,
            cnstlNo: this.getStore('userInfo').getters.getUserInfo.userId,
            //contNo : contNo
            parentId: 'MSPCT130P'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_CustInfoBottomSheet)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_CustInfoBottomSheet)
            }
          }
        })
      //}
    },
    }
  }
</script>
<style scoped>
</style>