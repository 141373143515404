 /*
 * 업무구분: 타사증권 입력
 * 화 면 명: MSPCT143D
 * 화면설명: 타사증권 입력 3.특약정보 단계 (등록상품)
 * 접근권한:
 * 작 성 일: 2023.03
 * 작 성 자: 정지윤
 */
 <template>
  <ur-page-container class="msp" :show-title="false" title="타사증권 입력" :topButton="true" :action-type="actionSlot">
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- stickey 영역 -->
          <template #sticky>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- Content영역 -->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30">
              <b class="fs22rem">특약정보</b>
            </ur-box-container>  
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum mt15">
              <mo-list-item>
                <mo-checkbox v-model="isSelectAll" @input="selectAllItemsFunc" size="small" />
                <div class="list-item__contents">
                  <div v-show="checkCount===0" class="list-item__contents__title">
                    <span class="sum">총 <strong class="crTy-blue3">{{otCompPrdtTrtyData.length}}</strong> 건</span>
                  </div>
                  <div v-show="checkCount > 0" class="list-item__contents__title">
                    <span class="sum"><strong class="crTy-blue3">{{checkCount}}</strong> 건 선택</span>
                  </div>
                </div>					
              </mo-list-item>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area">
              
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024">
                <mo-list :list-data="otCompPrdtTrtyData">
                  <template #list-item="{item, index}">
                    <mo-list-item :class="{'on' : item.checked === true}">
                      <mo-checkbox v-model="item.checked"/>
                      <div class="list-item__contents overflow-visible">
                        <div class="list-item__contents__title">
                          <span class="name" v-if="item.otcomTrtyShpAddmExpln === ''">
                            {{item.otcomTrtyInsrNm + '-' + item.otcomTrtyShpCdNm + '-' + item.otcomTrtyEntCustScCdNm}}
                          </span>
                          <span class="ns-tool-tip" v-else>    
                            <span :id="`location${index}`" class="name underline">
                              {{item.otcomTrtyInsrNm + '-' + item.otcomTrtyShpCdNm + '-' + item.otcomTrtyEntCustScCdNm}}
                            </span>
                            <mo-tooltip :target-id="`location${index}`" class="tool-tip-box">{{item.otcomTrtyShpAddmExpln}}</mo-tooltip>                  
                          </span>
                        </div>
                        <div class="list-item__contents__info pt5">
                          <span class="crTy-bk1">{{item.otcomTrtyShpCdNm}}</span><em>|</em><span class="crTy-bk1">{{item.otcomTrtyEntCustScCdNm}}</span>
                        </div>
                        <div class="list-item__contents__info pt1">
                          <span class="fs14rem">가입금액</span><span class="ml10 crTy-bk1" :maxlength="6" :v-model="item.insuredAmount">{{$commonUtil.numberWithCommas(item.insuredAmount)}}만원</span>
                          <em>|</em><span class="fs14rem">보장만기</span><span class="ml10 crTy-bk1">{{item.trtyExpYm}}</span>
                        </div>
                        <div class="mlm45 mrm5 mt17">
                          <ur-box-container alignV="start" componentid="" direction="row" class="insert-area pl0 pal0">
                            <mo-button class="ns-btn-round bd-radius-40" @click="fn_update(item, index)">수정</mo-button>
                            <mo-button class="ns-btn-round bd-radius-40" @click="fn_CopyOrDelete(item, index)">{{item.copyType === 'copy' ? '복사' : '삭제'}}</mo-button>
                          </ur-box-container>
                        </div>
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list>   
              </ur-box-container> 
            </ur-box-container>  
            <!-- Content 영역 end --> 
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container> 

    <!-- Popup 납입완료가 만기년월보다 클 경우 Alert -->
    <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
      <!-- <div class="customer-info-txt txt-center">
        <p class="ment">납입완료 년월은<br/>만기년월 보다 늦을수 없습니다.</p>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" name="확인">확인</mo-button>
          </div>
        </ur-box-container>
      </template> -->
    </mo-bottom-sheet>

    <!-- Popup : 특약정보 수정 -->
    <mo-bottom-sheet ref="refMSPCT143D_B01" class="ns-bottom-sheet closebtn">  <!-- :close-btn="false" -->
      <template v-slot:title>
        특약정보 수정
      </template>
      <div class="ns-btn-close" @click="fn_closeUpdateBS"></div>
      
      <div class="content-area">
        <ur-box-container class="customer-info-txt pal0">
          <b class="fs22rem">교통상해소득보삼금-기본-주피</b>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt30">
          <div class="info-title-wrap mb0">
              <span class="fs14rem crTy-bk7 must">가입금액</span>
          </div>
          <div class="relative">
            <mo-decimal-field numeric class="form-input-name full" underline placeholder="입력" :maxlength="7" v-model="updateInsuredAmount" :rules="validateRuleRequire" />
            <span class="input-txt">만원</span>
          </div>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt30">
          <div class="info-title-wrap mb0">
              <span class="fs14rem crTy-bk7">보장만기</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column" class="">
            <mo-month-picker class="full" v-model="updateTrtyExpYm" :min-date="minExpYm" :disabled="disabled"/>
          </ur-box-container>
          <div class="ns-check flex mt10">
            <mo-checkbox v-model="dateLife" small>종신</mo-checkbox>
          </div>
        </ur-box-container>
      </div><!-- //content-area -->        

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="fn_closeUpdateBS()" class="ns-btn-round white">취소</mo-button>
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_saveUpdate()" class="ns-btn-round blue">저장</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!--//  Popup : 특약정보 수정 -->

    <!-- Toast -->
      <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

  </ur-page-container>
</template>
<script>
import UrBoxContainer from '@/_uidev/containers/box/urBoxContainer.vue'
import moment from 'moment'

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT143D",
    screenId: "MSPCT143D",
    components: {
      UrBoxContainer
     },
     props: {
       vind142D: {
         type: Object,
         default: {}
       }
     },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
            // 상단 검색, 카테고리 버튼 생성
      this.actionSlot = {
        actionComponent: {
          template:'<span><mo-button shape="border" class="ns-btn-round btn-title-style-1">보장설계</mo-button> <mo-icon icon-size="30px" type="picto" >more</mo-icon></span>',
          methods: {

          }
        }
      }
      let t = []
      t.length >0 

    },
    mounted () {
      
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        vindData: {}, // MSPCT142D 저장 정보
        entryType: '',

        insrCoNm: '', // 보험회사명
        prdtNm: '', // 상품명
        contrNm: '', // 계약자명
        insrdNm: '', // 피보험자명
        insrdObjScNm: '', // '피보험자명(구분)'
        contYm: '', // 계약년월
        padCmpltYm: '', // 납입완료년월
        expYm: '', // 보장년월
        prm: '', // 납부금액
        padCylCd: '', // 납입주기코드
        otCompPrdtTrtyData: [], // 특약목록
        // selectItems: [], // 선택한특약 object (전체선택)
        isSelectAll: false,

        // 수정팝업
        updindex: '',
        idx : '', // key (업체제공외부특약코드)
        updateTrtyExpYm: ['',''], // 보장만기
        minExpYm : `${moment(new Date().toISOString()).format('YYYY')}-01-01`, // 만기년월 최소일자
        dateLife: false, // 종신
        disabled: false,
        updateInsuredAmount: '', // 가입금액
        validateRuleRequire: [
          v => !!v || '가입금액을 입력해 주세요'
        ],
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      items1() {
        let rtn = [];
        rtn.push({value: '01', text: '월납'});
        rtn.push({value: '02', text: '2월납'});
        rtn.push({value: '03', text: '3월납'});
        rtn.push({value: '06', text: '6월납'});
        rtn.push({value: '12', text: '연납'});
        rtn.push({value: '99', text: '일시납'});
        return rtn;
      },

      checkCount () {
        return this.otCompPrdtTrtyData.filter(item => item.checked).length
      },

      selectItem () { // 선택한 특약
        return this.otCompPrdtTrtyData.filter(item => item.checked)
      },
    },

    watch: {
      vind142D() {
        this.vindData = this.vind142D
        if(Object.keys(this.vindData).length > 0){
          if (Number(this.vindData.contYm[1]) < 10){
            this.vindData.contYm = `${this.vindData.contYm[0]}-0${Number(this.vindData.contYm[1])}`
          } else {
            this.vindData.contYm = `${this.vindData.contYm[0]}-${Number(this.vindData.contYm[1])}`
          }
          if (Number(this.vindData.expYm[1]) < 10){
            this.vindData.expYm = `${this.vindData.expYm[0]}-0${Number(this.vindData.expYm[1])}`
          } else {
            this.vindData.expYm = `${this.vindData.expYm[0]}-${Number(this.vindData.expYm[1])}`
          }
          if (Number(this.vindData.padCmpltYm[1]) < 10){
            this.vindData.padCmpltYm = `${this.vindData.padCmpltYm[0]}-0${Number(this.vindData.padCmpltYm[1])}`
          } else {
            this.vindData.padCmpltYm = `${this.vindData.padCmpltYm[0]}-${Number(this.vindData.padCmpltYm[1])}`
          }
        }
        this.entryType = this.vindData.entryType
        console.log('this.vindData >> ',this.vindData)
        this.fn_SelListOtCompPrdtTrty()
        this.fn_InitMSPCT143D()
      },

      dateLife () { // 종신 체크
        if (this.dateLife) {
          this.updateTrtyExpYm = ['9999','12']
          // 캘린더 아이콘 비활성화
          this.disabled = true
        } 
        else {
          this.updateTrtyExpYm = moment(new Date().toISOString()).format('YYYY-MM').split('-')
          // this.updateTrtyExpYm = this.getStore('ctStore').getters.getOtCompCont.data.otcomAcontVO.expYm.split('-')
          this.disabled = false
        }
      },

      checkCount() {
        console.log('checkCount >>> ', this.checkCount)
        this.$emit('isOtCompPrdtTrtyData', this.checkCount) // MSPCT140P의 등록 버튼(특약 미선택 시 "특약을 입력해주세요.")
      },

      selectItem() {
        if (this.checkCount === this.otCompPrdtTrtyData.length) {
          this.isSelectAll = true
        } else {
          this.isSelectAll = false
        }
        console.log('selectItem >>> ', this.selectItem)
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_InitMSPCT143D
      * 설명       : 팝업 내용 초기값 세팅
      ******************************************************************************/
      fn_InitMSPCT143D () {
        let otcompCont = this.getStore('ctStore').getters.getOtCompCont.data !== undefined ? this.getStore('ctStore').getters.getOtCompCont.data.otcomAcontVO : {}
        this.insrCoNm = otcompCont.insrCoNm // 회사명
        this.prdtNm = otcompCont.otcomPrdtNm // 상품명
        this.contrNm = otcompCont.contrNm // 계약자명
        this.insrdObjScNm = otcompCont.insrdObjScCd === '01' ? '주피' : '종피' // 01-주피, 02-종피 // 피보험자명(구분)
        this.insrdNm = otcompCont.insrdObjScCd === '01' ? otcompCont.mnisdNm : otcompCont.aisdNm // 피보험자명
        this.contYm = otcompCont.contYm // 계약년월
        this.padCmpltYm = otcompCont.padCmpltYm // 납입완료년월
        this.expYm = otcompCont.expYm // 보장년월
        this.prm = this.fn_PrmByPadCylCd({'padCylCd': otcompCont.padCylCd, 'prm': otcompCont.prm}) // 납부금액
        this.padCylCd = otcompCont.padCylCd // 납입주기코드
        // this.selectItems = [] // 선택한특약 object
        //
      },

      /******************************************************************************
      * Function명 : fn_PrmByPadCylCd
      * 설명       : 납입주기코드로 납입금액 및 년/월 표기값 추출
        주요 파라미터   : 납입주기코드 / 납입금액
      ******************************************************************************/
      fn_PrmByPadCylCd (targetItem) {
        let prm = ''
        switch (targetItem.padCylCd) {
          case '01' : // 월납
            prm = targetItem.prm === 0 ? targetItem.prm : targetItem.prm
            break
          case '02' : // 2월납
            prm = targetItem.prm === 0 ? targetItem.prm : targetItem.prm / 2
            break
          case '03' : // 3월납
            prm = targetItem.prm === 0 ? targetItem.prm : targetItem.prm / 3
            break
          case '06' : // 6월납
            prm = targetItem.prm === 0 ? targetItem.prm : targetItem.prm / 6
            break
          case '12' : // 연납.
            prm = targetItem.prm === 0 ? targetItem.prm : targetItem.prm
            break
          default : // case '99' 일시납
            prm = targetItem.prm === 0 ? targetItem.prm : targetItem.prm
            break
        }
        return prm
      },

      /******************************************************************************
      * Function명 : fn_SelListOtCompPrdtTrty
      * 설명       : 타사 상품 내용(특약정보)를 가져오는 인터페이스(IF-CT-0018) 호출
      ******************************************************************************/
      fn_SelListOtCompPrdtTrty () {
        // 앞 페이지에서 상품ID 받아서, 현재페이지 열릴때 조회함.
        let lv_Vm = this
        let otCompContBasicInfo = lv_Vm.getStore('ctStore').getters.getOtCompCont.data !== undefined ? lv_Vm.getStore('ctStore').getters.getOtCompCont.data : {}
        if(Object.keys(otCompContBasicInfo).length > 0) {
          let otcomAcontVO = otCompContBasicInfo.otcomAcontVO
          let pParams = {
            'othInsrCoCd': otcomAcontVO.othInsrCoCd, // 타보험회사코드
            'comyProfrOprcd': otcomAcontVO.comyProfrOprcd // 업체제공외부상품코드
          }
          const trnstId = 'txTSSCT18S1'
          const auth = 'S'
          this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
            let outComyPrdtTrtyVO = response.body.outComyPrdtTrtyVO // 기본 특약정보
            lv_Vm.fn_SetListOtCompPrdtTrty(lv_Vm, outComyPrdtTrtyVO) // 화면에 출력
          }).catch(function (error) {
            window.vue.error(error)
          })
        }
        
      },

      /******************************************************************************
      * Function명 : fn_SetListOtCompPrdtTrty
      * 설명       : 특약목록 화면을 만들 데이터 세팅
      * 파라미터 : 해당 상품에 속해있는 모든 특약
      ******************************************************************************/
      fn_SetListOtCompPrdtTrty (lv_Vm, outComyPrdtTrtyVO) {
        console.log('■■■ 해당상품에 속한 모든 특약의 모수 ■■■ :: ', outComyPrdtTrtyVO)
        // 해당상품에 기본 특약 정보 노출
        let otCompContBasicInfo = lv_Vm.getStore('ctStore').getters.getOtCompCont.data
        let outComyPrdtTrtyVO01 = otCompContBasicInfo.outComyPrdtTrtyVO01 // 저장된 특약정보
        let baseOtCompPrdtTrtyData = [] // 로직에서만 사용할 특약 데이터

        console.log('■■■ 저장시 선택한 특약들 ■■■ :: ', outComyPrdtTrtyVO01)

        // uniacCustEntAmt : 화면
        // otcomTrtyEntAmt : 기준값
        for (let i in outComyPrdtTrtyVO) {
          // if (lv_Vm.scrpFlag) { // 스크래핑일 경우에 구좌금액을 특약금액에 노출시킨다.
          //   for (let j in outComyPrdtTrtyVO01) {
          //     if (outComyPrdtTrtyVO[i].otcomTrtyLclsfCd === outComyPrdtTrtyVO01[j].otcomTrtyLclsfCd && outComyPrdtTrtyVO[i].otcomTrtyMclsfCd === outComyPrdtTrtyVO01[j].otcomTrtyMclsfCd) {
          //       outComyPrdtTrtyVO01[j].otcomTrtyEntAmt.amount = outComyPrdtTrtyVO[i].otcomTrtyEntAmt.longAmount
          //       outComyPrdtTrtyVO01[j].uniacCustEntAmt.amount = String(Math.floor(Number(outComyPrdtTrtyVO01[j].uniacCustEntAmt.longAmount) / 10000))
          //       outComyPrdtTrtyVO01[j].otcomTrtyShpCdNm = outComyPrdtTrtyVO[i].otcomTrtyShpCdNm
          //       outComyPrdtTrtyVO01[j].otcomTrtyEntCustScCdNm = outComyPrdtTrtyVO[i].otcomTrtyEntCustScCdNm
          //       outComyPrdtTrtyVO01[j].otcomTrtyShpAddmExpln = outComyPrdtTrtyVO[i].otcomTrtyShpAddmExpln === ' ' ? '' : outComyPrdtTrtyVO[i].otcomTrtyShpAddmExpln
          //     }
          //   }
          //   // outComyPrdtTrtyVO[i].uniacCustEntAmt.amount = String(Math.floor(Number(outComyPrdtTrtyVO[i].otcomTrtyEntAmt.longAmount))) // 스크래핑의 모수는 만원단위로 오니, 10000으로 안나눠도 됨.
          //   // outComyPrdtTrtyVO[i].otcomTrtyEntAmt.amount = outComyPrdtTrtyVO[i].uniacCustEntAmt.amount // 스크래핑은 원단위로 오기때문에 만원으로 나눠야함.
          // }

          let tmpData = {
            'otcomTrtyScCd': outComyPrdtTrtyVO[i].otcomTrtyScCd, // 타사특약구분코드 1-주계약, 4-특약
            'otcomTrtyLclsfCd': outComyPrdtTrtyVO[i].otcomTrtyLclsfCd, // 타사특약대분류코드
            'otcomTrtyMclsfCd': outComyPrdtTrtyVO[i].otcomTrtyMclsfCd, // 타사특약중분류코드
            'comyProfrOtrcd': outComyPrdtTrtyVO[i].comyProfrOtrcd, // 업체제공외부특약코드
            'otcomTrtyEntCustScCd': outComyPrdtTrtyVO[i].otcomTrtyEntCustScCd, // 타사특약가입고객구분코드
            'otcomTrtyEntCustScCdNm': outComyPrdtTrtyVO[i].otcomTrtyEntCustScCdNm, // 타사특약가입고객구분코드명
            'otcomTrtyShpCd': outComyPrdtTrtyVO[i].otcomTrtyShpCd, // 타사특약형태코드
            'otcomTrtyShpCdNm': outComyPrdtTrtyVO[i].otcomTrtyShpCdNm, // 타사특약형태코드명
            'otcomTrtyEntAmtStndCd': outComyPrdtTrtyVO[i].otcomTrtyEntAmtStndCd, // 타사특약가입금액기준코드 0901-금액기준, 0902-구좌수기준
            'otcomTrtyShpAddmExpln': outComyPrdtTrtyVO[i].otcomTrtyShpAddmExpln, // 타사특약형태추가설명
            'otcomTrtyEntAmtUseYn': outComyPrdtTrtyVO[i].otcomTrtyEntAmtUseYn, // 타사특약가입금액사용여부 1-사용, 0-미사용
            'trtyExpYm': lv_Vm.expYm, // 특약만기년월 (2018-10-04T22:49:34.081Z)
            'otcomTrtyInsrNm': outComyPrdtTrtyVO[i].otcomTrtyInsrNm, // 타사특약보험명
            'otcomTrtyEntAmt': {
              'amount': outComyPrdtTrtyVO[i].otcomTrtyEntAmt.amount // 타사특약가입금액
            },
            'uniacCustEntAmt': {
              'amount': outComyPrdtTrtyVO[i].uniacCustEntAmt.amount // 구좌고객가입금액
            },
            'insuredAmount': outComyPrdtTrtyVO[i].otcomTrtyEntAmt.amount,
            'copyType': 'copy', // copy(원본 - 복사버튼), del(복사본 - 삭제버튼)0,
            // 'dateFlag': lv_Vm.expYm === '9999-12',
            'dateFlag': outComyPrdtTrtyVO[i].trtyExpYm === '9999-12',
            'othInsrCoCd': outComyPrdtTrtyVO[i].othInsrCoCd, // 타보험회사코드
            'comyProfrOprcd': outComyPrdtTrtyVO[i].comyProfrOprcd, // 업체제공외부상품코드
            'checked' : false
          }
          baseOtCompPrdtTrtyData.push(tmpData)
        }

        // 기계약 수정일 경우에, 저장된 특약내용 화면에적용 (복사된 값이 있을경우, 하단에 '삭제' 버튼으로 하나 더 만듬.)
        if (lv_Vm.entryType === 'upd') {
          outComyPrdtTrtyVO01.forEach(function (selTrtyVO, selTrtyVOIdx) {
            let copyTrty = false // 특약 복제 판단 flag
            let prevSelTrtyVO = outComyPrdtTrtyVO01[selTrtyVOIdx - 1]
            // 현재 특약이 복제(복사) 된 특약인지 확인
            let condition1 = false
            // if (lv_Vm.scrpFlag) {
            //   condition1 = prevSelTrtyVO && selTrtyVO.otcomTrtyLclsfCd === prevSelTrtyVO.otcomTrtyLclsfCd && selTrtyVO.otcomTrtyMclsfCd === prevSelTrtyVO.otcomTrtyMclsfCd
            // } else {
              condition1 = prevSelTrtyVO && selTrtyVO.comyProfrOtrcd === prevSelTrtyVO.comyProfrOtrcd && selTrtyVO.otcomTrtyEntCustScCd === prevSelTrtyVO.otcomTrtyEntCustScCd
            // }
            if (condition1) {
              copyTrty = true
            }

            baseOtCompPrdtTrtyData.some(function (trtyData, trtyDataIdx) { // forEach문에 break가 없어서 some 함수 사용
              let condition2 = false
              // if (lv_Vm.scrpFlag) {
              //   condition2 = selTrtyVO.otcomTrtyLclsfCd === trtyData.otcomTrtyLclsfCd && selTrtyVO.otcomTrtyMclsfCd === trtyData.otcomTrtyMclsfCd
              //   // selTrtyVO.uniacCustEntAmt.amount = String(Math.floor(Number(selTrtyVO.uniacCustEntAmt.longAmount) / 10000)) // 스크래핑일 경우에 구좌금액을 특약금액에 노출시킨다. 스크래핑은 원단위로 오기때문에 만원단위로 나눠야함.
              //   // selTrtyVO.otcomTrtyEntAmt.amount = selTrtyVO.uniacCustEntAmt.amount // 스크래핑일 경우에 구좌금액을 특약금액에 노출시킨다. 스크래핑은 원단위로 오기때문에 만원단위로 나눠야함.
              //   selTrtyVO.otcomTrtyEntAmtUseYn = trtyData.otcomTrtyEntAmtUseYn // 스크래핑일 경우 otcomTrtyEntAmtUseYn(타사특약가입금액사용여부)를 선택된VO에서 가져오지 않고, 모수VO에서 가져오도록 한다.
              // } else {
                condition2 = selTrtyVO.comyProfrOtrcd === trtyData.comyProfrOtrcd && selTrtyVO.otcomTrtyEntCustScCd === trtyData.otcomTrtyEntCustScCd
              // }

              if (condition2) {
                let tmpData = {
                  'checked': true,
                  'otcomTrtyScCd': selTrtyVO.otcomTrtyScCd, // 타사특약구분코드 1-주계약, 4-특약
                  'otcomTrtyLclsfCd': selTrtyVO.otcomTrtyLclsfCd, // 타사특약대분류코드
                  'otcomTrtyMclsfCd': selTrtyVO.otcomTrtyMclsfCd, // 타사특약중분류코드
                  'comyProfrOtrcd': selTrtyVO.comyProfrOtrcd, // 업체제공외부특약코드
                  'otcomTrtyEntCustScCd': selTrtyVO.otcomTrtyEntCustScCd, // 타사특약가입고객구분코드
                  'otcomTrtyEntCustScCdNm': selTrtyVO.otcomTrtyEntCustScCdNm, // 타사특약가입고객구분코드명
                  'otcomTrtyShpCd': selTrtyVO.otcomTrtyShpCd, // 타사특약형태코드
                  'otcomTrtyShpCdNm': selTrtyVO.otcomTrtyShpCdNm, // 타사특약형태코드명
                  'otcomTrtyEntAmtStndCd': selTrtyVO.otcomTrtyEntAmtStndCd, // 타사특약가입금액기준코드 0901-금액기준, 0902-구좌수기준
                  'otcomTrtyShpAddmExpln': selTrtyVO.otcomTrtyShpAddmExpln, // 타사특약형태추가설명
                  'otcomTrtyEntAmtUseYn': selTrtyVO.otcomTrtyEntAmtUseYn, // 타사특약가입금액사용여부 1-사용, 0-미사용
                  'trtyExpYm': selTrtyVO.trtyExpYm.length === 6 ? selTrtyVO.trtyExpYm.substr(0, 4) + '-' + selTrtyVO.trtyExpYm.substr(4, 2) : selTrtyVO.trtyExpYm, // 특약만기년월 (2018-10-04T22:49:34.081Z)
                  // 'trtyExpYm': lv_Vm.expYm, // 수정시에도 보험기본정보의 만기년월의 일자를 가져온다. // 특약만기년월
                  'otcomTrtyInsrNm': selTrtyVO.otcomTrtyInsrNm, // 타사특약보험명
                  'otcomTrtyEntAmt': {
                    'amount': selTrtyVO.otcomTrtyEntAmt.amount // 타사특약가입금액
                  },
                  'uniacCustEntAmt': {
                    'amount': selTrtyVO.uniacCustEntAmt.amount // 구좌고객가입금액
                  },
                  'insuredAmount': selTrtyVO.uniacCustEntAmt.amount,
                  'copyType': copyTrty ? 'del' : 'copy', // copy(복사버튼), del(삭제버튼)
                  // 'dateFlag': lv_Vm.expYm === '9999-12',
                  'dateFlag': selTrtyVO.trtyExpYm === '999912',
                  'othInsrCoCd': selTrtyVO.othInsrCoCd, // 타보험회사코드
                  'comyProfrOprcd': selTrtyVO.comyProfrOprcd // 업체제공외부상품코드
                }

                if (copyTrty) {
                  // 복제 된 특약일 경우 아래행에 넣음
                  baseOtCompPrdtTrtyData.splice(trtyDataIdx + 1, 0, tmpData)
                } else {
                  // 복제 안된 특약일 경우 값 바꿈
                  baseOtCompPrdtTrtyData[trtyDataIdx] = tmpData
                }
                // lv_Vm.selectItems.push(tmpData)
                lv_Vm.selectItem.push(tmpData)
              }
              return (condition2) // some내에서는 forEach문의 break와 같은 역할
            })
          })
        }
        // 선택한 특약 먼저 lv_Vm.selectItems 에 세팅 후, lv_Vm.otCompPrdtTrtyData 세팅해야 체크박스가 적용됨 (FDP)
        lv_Vm.otCompPrdtTrtyData = baseOtCompPrdtTrtyData

        // if (lv_Vm.selectItems.length !== lv_Vm.otCompPrdtTrtyData.length) {
        //   lv_Vm.isSelectAll = false
        // } else {
        //   lv_Vm.isSelectAll = true
        // }
      },

      /******************************************************************************
      * Function명 : selectAllItemsFunc
      * 설명       : 전체선택
      ******************************************************************************/
      selectAllItemsFunc () {
        if(this.isSelectAll){
          for (let i in this.otCompPrdtTrtyData) {
            this.otCompPrdtTrtyData[i].checked = true
          }
        } else {
          for (let i in this.otCompPrdtTrtyData) {
            this.otCompPrdtTrtyData[i].checked = false
          }
        }
        
      },

      /******************************************************************************
      * Function명 : fn_update
      * 설명       : 수정 팝업 open
      ******************************************************************************/
      fn_update(item, index) {
        this.$refs.refMSPCT143D_B01.open();
        this.updateInsuredAmount = item.insuredAmount
        this.updateTrtyExpYm = item.trtyExpYm
        // this.idx =  item.comyProfrOtrcd // key (업체제공외부특약코드)
        this.updindex =  index // key
        if(this.updateTrtyExpYm == '9999-12') { // 종신버튼 체크되어있으면
          this.dateLife = true
        }
        else {
          this.dateLife = false
        }
        this.updateTrtyExpYm = item.trtyExpYm.split('-')

        console.log('수정할 데이터 >> ', item)
      },

      /******************************************************************************
      * Function명 : fn_closeUpdateBS
      * 설명       : 수정 팝업 close
      ******************************************************************************/
      fn_closeUpdateBS() { 
        this.$refs.refMSPCT143D_B01.close(); 
      },

      /******************************************************************************
      * Function명 : fn_saveUpdate
      * 설명       : 수정 팝업 save
      ******************************************************************************/
      fn_saveUpdate() {
        let idxNum = this.updindex
        
        // for(let i in this.otCompPrdtTrtyData){
        //   if(this.otCompPrdtTrtyData[i].comyProfrOtrcd === this.idx){
        //     idxNum = i
        //     break
        //   }
        // }

        this.otCompPrdtTrtyData[idxNum].insuredAmount = this.updateInsuredAmount // 가입금액
        if(Number(this.updateTrtyExpYm[1]) < 10) {
          this.otCompPrdtTrtyData[idxNum].trtyExpYm = `${this.updateTrtyExpYm[0]}-0${Number(this.updateTrtyExpYm[1])}`
        } else {
          this.otCompPrdtTrtyData[idxNum].trtyExpYm = `${this.updateTrtyExpYm[0]}-${this.updateTrtyExpYm[1]}` // 보장만기  ['2022', '10'] => '2022-10'
        }

        this.$refs.refMSPCT143D_B01.close(); 
        // 수정 완료 toast
        this.$addSnackbar("수정되었습니다.")
      },
      
      /******************************************************************************
      * Function명 : fn_CopyOrDelete
      * 설명       : 복사/삭제 버튼(하단에 동일 목록 복사/삭제, toast 노출)
      ******************************************************************************/
      fn_CopyOrDelete(item, index) {
        let selectItem = this.selectItem
        let otCompPrdtTrtyData = this.otCompPrdtTrtyData
        if (item.copyType === 'copy') {
          let copyRow = {}
          if (otCompPrdtTrtyData[index].comyProfrOtrcd === item.comyProfrOtrcd) {
            copyRow = this.$bizUtil.cloneDeep(item) // object 복제
            otCompPrdtTrtyData.splice(index + 1, 0, copyRow)
            // otCompPrdtTrtyData[Number(index) + 1].copyType = 'del'
            copyRow.copyType = 'del'

            // 복사하려는 행이 선택되어져 있을 경우, 복사된 행도 선택된 배열에 넣음
            selectItem.forEach(function (selItem, index) {
              if (selItem.copyType === 'copy' && selItem.comyProfrOtrcd === copyRow.comyProfrOtrcd) {
                selectItem.push(copyRow)
              }
            })
          }
          this.$addSnackbar('복사되었습니다.')
        } else {
          otCompPrdtTrtyData.splice(index, 1)
          this.$addSnackbar('삭제되었습니다.')
          
        } 

      },

      /******************************************************************************
      * Function명 : fn_BtnI1OnClick
      * 설명       : 등록된타사보험저장(IF-CT-0016) 인터페이스 호출
      ******************************************************************************/
      fn_BtnI1OnClick () {
        let lv_Vm = this
        let otCompContBasicInfo = lv_Vm.getStore('ctStore').getters.getOtCompCont.data
        let outComyPrdtTrtyList = []
        // lv_Vm.selectItems = lv_Vm.selectItem

        for (let i in lv_Vm.selectItem) {
          let item = lv_Vm.selectItem[i]
          let outComyPrdtTrty = {
            'othInsrCoCd': item.othInsrCoCd,
            'comyProfrOprcd': item.comyProfrOprcd,
            'otcomTrtyScCd': item.otcomTrtyScCd,
            'otcomTrtyLclsfCd': item.otcomTrtyLclsfCd,
            'otcomTrtyMclsfCd': item.otcomTrtyMclsfCd,
            'comyProfrOtrcd': item.comyProfrOtrcd,
            'otcomTrtyEntCustScCd': item.otcomTrtyEntCustScCd,
            'otcomTrtyShpCd': item.otcomTrtyShpCd,
            'otcomTrtyEntAmtStndCd': item.otcomTrtyEntAmtStndCd,
            'otcomTrtyEntAmt': Number(item.otcomTrtyEntAmt.amount),
            'otcomTrtyEntAmtUseYn': item.otcomTrtyEntAmtUseYn,
            'uniacCustEntAmt': item.insuredAmount,
            'trtyExpYm': item.trtyExpYm.replace('-', ''),
            // ■■■ 아래는 스크래핑 타사를 위한 값임 ■■■ //
            'otcomTrtyInsrNm': item.otcomTrtyInsrNm
          }
          // ■■■ 스크래핑일 경우에는 원단위로 데이터를 주고 받고, 등록/미등록 타사의 경우에는 만원단위로 데이터를 주고 받음. (유파트너 모듈) ■■■
          // if (lv_Vm.scrpFlag) {
          //   outComyPrdtTrty.otcomTrtyEntAmt *= 10000
          //   outComyPrdtTrty.uniacCustEntAmt *= 10000
          //   otCompContBasicInfo.chnlCustId = otCompContBasicInfo.srcCustId // 스크래핑은 신규등록이 아닌 수정만 되기때문에, 005P에서 세팅하는 srcCustId를 chnlCustId로 보내야한다.
          //   otCompContBasicInfo.otcomAcontVO.chnlCustId = otCompContBasicInfo.srcCustId // 스크래핑은 신규등록이 아닌 수정만 되기때문에, 005P에서 세팅하는 srcCustId를 chnlCustId로 보내야한다.
          // }

          outComyPrdtTrtyList.push(outComyPrdtTrty)
        }

        let pParams = {
          'cnsltNo': '', // 컨설턴트번호
          'chnlCustId': otCompContBasicInfo.chnlCustId, // 채널고객ID // SBJ 원소스
          'srcCustId': otCompContBasicInfo.srcCustId, // SBJ 원소스
          'insrdObjScCd': lv_Vm.vindData.insrdObjScCd, // 피보험자대상구분코드 01-주피, 02-종피
          'otcomContSno': otCompContBasicInfo.otcomContSno ? otCompContBasicInfo.otcomContSno : '', // 계약번호
          'otcomAcontVO': { // 타사기계약VO
            'otcomContSno': otCompContBasicInfo.otcomContSno ? otCompContBasicInfo.otcomContSno : '', // 계약번호
            'chnlCustId': otCompContBasicInfo.otcomAcontVO.chnlCustId, // 채널고객ID
            'contrNm': otCompContBasicInfo.otcomAcontVO.contrNm, // 계약자명 custInfo.custNm
            'custContRltnCd': otCompContBasicInfo.otcomAcontVO.custContRltnCd, // 고객계약관계코드 '12-계약자+주피, 13-계약자+종피, 21-주피보험자, 23-종피보험자
            'insrCoNm': otCompContBasicInfo.otcomAcontVO.insrCoNm, // 보험회사명
            'mnisdNm': otCompContBasicInfo.otcomAcontVO.mnisdNm, // 주피보험자명
            'aisdNm': otCompContBasicInfo.otcomAcontVO.aisdNm, // 종피보험자명
            'otcomPrdtNm': otCompContBasicInfo.otcomAcontVO.otcomPrdtNm, // 타사상품명
            'contYm': lv_Vm.vindData.contYm.replace('-', ''), // 계약년월
            'expYm': lv_Vm.vindData.expYm.replace('-', ''), // 만기년월
            'padCylCd': lv_Vm.vindData.padCylCd, // 납입주기코드
            'padCmpltYm': lv_Vm.vindData.padCmpltYm.replace('-', ''), // 납입완료년월
            'prm': lv_Vm.vindData.insurance, // 보험료
            'gtplOtcomPrdtInptScCd': otCompContBasicInfo.otcomAcontVO.gtplOtcomPrdtInptScCd, // 보장플랜타사상품입력구분코드 1 : 등록 타사 상품 2 : 미등록 타사상품 3: 스크래핑
            'othInsrCoCd': otCompContBasicInfo.otcomAcontVO.othInsrCoCd, // 타보험회사코드
            'parngIrt': lv_Vm.vindData.parngIrt, // 예정이율
            'cnsltNo': '', // 컨설턴트번호
            'gtplInsrScCd': otCompContBasicInfo.otcomAcontVO.gtplInsrScCd, // 03:유니버셜
            // ■■■ 아래는 스크래핑 타사를 위한 값임 ■■■ //
            'scrapAplcYn': otCompContBasicInfo.otcomAcontVO.scrapAplcYn, // 스크래핑 적용여부
            'insrSecusNo': otCompContBasicInfo.otcomAcontVO.insrSecusNo, // 증권번호
            'prpslStatCd': otCompContBasicInfo.otcomAcontVO.prpslStatCd // 제안상태코드
          },
          'outComyPrdtTrtyVO': outComyPrdtTrtyList
        }

        // ■■■스크래핑일 경우에는 otcomAcontVO 밑에 outComyPrdtTrtyVO를 넣어서 보내줘야한다. (유파트너 모듈 요청사항) ■■■
        // if (lv_Vm.scrpFlag) {
        //   pParams.otcomAcontVO.outComyPrdtTrtyVO = outComyPrdtTrtyList
        // }

        console.log('■■■ 수정시 선택한 특약들 ■■■ :: ', outComyPrdtTrtyList)

        if (!lv_Vm.fn_Validate(pParams)) {
          return
        }

        window.vue.getStore('progress').dispatch('UPDATE', true)

        let trnstId = ''
        let auth = 'I'
        // if (lv_Vm.scrpFlag) {
        //   // 스크래핑 수정 (레거시 직접호출)
        //   trnstId = 'txTSSCT85I1'
        //   lv_Vm.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
        //   // lv_Vm.$bizUtil.ctUtils.fn_DoTrz2SvrLegacy(lv_Vm, pParams, trnstId, auth).then(function (response) { // 직접호출불가
        //     console.log('####txTSSCT85I1', response)
        //     lv_Vm.fn_RefreshParent(lv_Vm, response, 'mod')
        //   }).catch(function (error) {
        //     window.vue.error(error)
        //   })
        // } else {
          // 등록된 타사상품 수정 (AP 호출)
          trnstId = 'txTSSCT16I1'
          lv_Vm.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
            console.log('###txTSSCT16I1', response)
            // lv_Vm.fn_RefreshParent(lv_Vm, response, 'mod')
          }).catch(function (error) {
            window.vue.error(error)
          })
        // }
      },

      /******************************************************************************
      * Function명 : fn_Validate
      * 설명       : 유효성 체크
      ******************************************************************************/
      fn_Validate (param) {
        let flag = true
        if (param.outComyPrdtTrtyVO === null || param.outComyPrdtTrtyVO.length <= 0) { // outComyPrdtTrtyList?
          this.getStore('confirm').dispatch('ADD', '특약을 입력해주세요.')
          flag = false
        }
        // SBJ
        // AlertMsg.showInfo(otcomTrtyInsrNm + "의 가입금액은 구좌단위로 입력가능합니다. \n입력하신 값이 구좌 단위가 맞는지 확인하시고 진행하시기 바랍니다.");
        // AlertMsg.showInfo(otcomTrtyInsrNm + " 특약만기년월은 계약년월일보다 늦을 수 없습니다.");
        return flag
      },

      /******************************************************************************
      * Function명 : fn_RefreshParent
      * 설명       : 호출한 부모의 화면을 갱신하는 함수 (등록,수정시)
      * type       : 등록(reg), 수정(upd), 삭제(del)
      ******************************************************************************/
      fn_RefreshParent (lv_Vm, response, type) {
        window.vue.getStore('progress').dispatch('UPDATE', false)
        if (lv_Vm.$commonUtil.isSuccess(response)) {
          let currContentPrg = lv_Vm.$parent.currentContent.component
          let lv_PromiseArray = []
          lv_Vm.getStore('ctStore').dispatch('CUSTREGMDF_F', true)
          lv_PromiseArray.push(lv_Vm.$parent.$refs.TSSCT005MTab.fn_initLoadOtCompData())
          if ( currContentPrg === 'TSSCT003M') { // 호출한 부모화면의 컨텐츠가 TSSCT003M ('보장분석>> 계약현황분석 기계약현황' 화면이면)
            lv_PromiseArray.push(lv_Vm.$parent.$refs.currComp.fn_initTSSCT003MLoadData()) // TSSCT003M 의 fn_initTSSCT003MLoadData(초기 로딩 함수) 실행
          } else if ( currContentPrg === 'TSSCT006M') { // 호출한 부모화면의 컨텐츠가 TSSCT003M ('보장분석>> 보장분석 >> 상품별' 화면이면)
            lv_PromiseArray.push(lv_Vm.$parent.$refs.currComp.fn_initTSSCT006MLoadData()) // TSSCT003M 의 fn_initTSSCT006MLoadData(초기 로딩 함수) 실행
          } else if ( currContentPrg === 'TSSCT008M') { // 호출한 부모화면의 컨텐츠가 TSSCT003M ('보장분석>> 상품컨설팅 >> 상품별' 화면이면)
            lv_PromiseArray.push(lv_Vm.$parent.$refs.currComp.fn_initTSSCT008MLoadData()) // TSSCT003M 의 fn_initTSSCT008MLoadData(초기 로딩 함수) 실행
          }
          // else {
          //   lv_PromiseArray.push(lv_Vm.$parent.$refs.TSSCT005MTab.fn_initLoadOtCompData()) // 그외 TSSCT005M 의 fn_initLoadOtCompData(타사증권 입력후 갱신 함수) 실행
          // }
          console.log('lv_PromiseArray ::: ' + JSON.stringify(lv_PromiseArray))
          Promise.all(lv_PromiseArray)
            .then(function (response) {
              let typeNm = ''
              if (type === 'mod') typeNm = '적용'
              else typeNm = '삭제' // del
              lv_Vm.getStore('ctStore').dispatch('CUSTREGMDF_F', true)
              lv_Vm.getStore('confirm').dispatch('ADD', typeNm + '되었습니다.')
            }).catch(function (error) {
              window.vue.error(error)
            })
        } else {
          // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
          lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
        }
      },

    },
  };
</script>
<style scoped>
</style>