<template>
  <ur-page-container ref="page" class="msp" type="subpage" :show-title="false" title="상세 보장설계" :topButton="true">
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap bg-ty-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="26" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <mo-button class="btn_popup_back" @click="fn_popupClose" />
              <div slot="nav" class="icon-wrapper" @click="fn_popupClose">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '상세 보장설계' : '상세 보장설계'}}</div>
              <div slot="action" class="icon-wrapper">
                <mo-icon icon-size="30px" v-show="vGssAuthYN !== 'Y'" type="picto" @click="fn_openSubMenu">more</mo-icon>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-list lst-bdBN">  
              <mo-list-item>
                <div class="list-item__contents pt10">
                  <div class="list-item__contents__title mb10">
                    <span class="fs14rem fwn">월 보험료</span>
                  </div>
                  <div class="list-item__contents__info pr0 fexTy5">
                    <span class="fs16rem fwb"><b class="fs20rem">{{$commonUtil.numberWithCommas(bfCovanSmtotPrm)}}</b>원</span>
                    <mo-icon icon="msp-arrow-line2" class="ml10 mr10" v-if="mdfcCnt > 0" />
                    <span class="fs26rem fwb crTy-blue3" v-if="mdfcCnt > 0"><b class="fs30rem">{{$commonUtil.numberWithCommas(afCoVanSmtotPrm)}}</b>원</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
          </template>              

          <!-- stickey 영역 -->
          <template #sticky>   
            <mo-tab-box :defaultIdx="defaultIdxs" ref="refTab" @tab-change="fn_TabChangeHandler" class="ns-move-tab-box txt-blue pt15">
              <mo-tab-content :idx="1" label="보장설계">
                <!-- <mo-tab-box :defaultIdx="0" ref="refPlanClsf" @tab-change="fn_planClsfTabChange" class="ns-move-tab-box gray-tab-bar msp-tab-ty">
                  <template v-for="(list, idx) in tableHeaderMock">
                    <mo-tab-label :idx="idx" :key="idx"><span>{{list.title}}</span></mo-tab-label>
                  </template>
                </mo-tab-box> -->
              </mo-tab-content>
              <mo-tab-content :idx="2" label="보장기간">
                <!-- 보장기간: 문구표출  -->
                <!-- <ur-box-container direction="row" alignV="start"> 
                  <div class="text-gray-box bgcolor-2 bd-radius-0 pb10">
                    <ul class="terms-list-area crTy-bk7 fs14rem">
                      <li>신용정보원에서 제공하는 정보는 기간별 분석에서 '주보험' 기간으로만 예시하였고, 실제 보장기간은 다를 수 있습니다.</li>
                    </ul>
                  </div>
                </ur-box-container>                
                <ur-box-container alignV="start" componentid="" direction="column" class="pl24 mb20">
                  <msp-bottom-select class="ns-dropdown-sheet" :items="yearList" v-model="year" itemHeight="374" :itemValue="'key'" :itemText="'label'" @input="fn_loadInqrStndPrd" underline bottom-title="기간선택" scrolling placeholder="기간 선택" closeBtn/>
                </ur-box-container> -->
                <!-- 보장기간: 문구표출  -->
                <!-- <mo-tab-box :defaultIdx="0" ref="refCovrPrd" @tab-change="fn_covrPrdTabChange" class="ns-move-tab-box gray-tab-bar msp-tab-ty expand_topline">
                  <template v-for="(list, idx) in tableHeaderMock">
                    <mo-tab-label :idx="idx" :key="'prd' + idx"><span>{{list.title}}</span></mo-tab-label>
                  </template>
                </mo-tab-box> -->
              </mo-tab-content>
              <mo-tab-content :idx="3" label="설계 전·후"></mo-tab-content>
            </mo-tab-box>
            <!--보장설계: 변경된 보장만보기 -->
            <!-- <ur-box-container direction="row" alignV="start" class="fexTy3-1 bgcolor-1 pal1524 pb5" v-if="slctTabIdx === 1">
              <span class="fs16rem fwn fexTy5">
                변경된 보장만 보기 <mo-switch v-model="changeValue" small class="ns-switch ml10"/>
              </span>
              <span class="fs14rem crTy-bk7">(단위: 만원)</span>
            </ur-box-container> -->
            <!--//보장설계:  변경된 보장만보기 -->
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- <tabMSPCT121D v-show="slctTabIdx === 1" :pSlctIdx="planClsfSlctIdx" :pChangeValue="changeValue" :pProductList="productList" @setPrmMdfcCnt="fn_setPrmMdfcCnt" @openMSPCT150P="fn_openMSPCT150P"/>   -->
            <!-- <tabMSPCT122D v-show="slctTabIdx === 2" ref="refMSPCT122D" :pSlctIdx="covrPrdSlctIdx"  @setPrmMdfcCnt="fn_setPrmMdfcCnt"/> -->
            <tabMSPCT121D v-show="slctTabIdx === 1" ref="refMSPCT121D" :pProductList="productList" @setPrmMdfcCnt="fn_setPrmMdfcCnt" @openMSPCT150P="fn_openMSPCT150P"/>  
            <tabMSPCT122D v-show="slctTabIdx === 2" ref="refMSPCT122D" @setPrmMdfcCnt="fn_setPrmMdfcCnt"/>
            <tabMSPCT123D v-show="slctTabIdx === 3" ref="refMSPCT123D" @setPrmMdfcCnt="fn_setPrmMdfcCnt" @moveDetail="fn_moveDetail"/>
          </template>          
        </mo-collapsing-header-view>
      </div>

      <!-- 하단 버튼영역 -->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <!-- <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_popupClose">보장설계 홈</mo-button> -->
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_openMSPCT124P">크게보기</mo-button>
          <mo-button componentid="mo_button_002" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_openPrdtMdfc">상품수정{{mdfcCnt > 0 ? '('+mdfcCnt+')' : ''}}</mo-button>
        </div>
      </ur-box-container>
      <!-- 하단 버튼영역 //-->  

      <!-- 바텀시트2 : 상품수정 바텀시트 표출 -->
      <mo-bottom-sheet ref="bottomSheet2" class="ns-bottom-sheet msp-bottomSt-ty6 closebtn" preventTouchClose>
        <template v-slot:title>상품수정</template>
        <div class="ns-btn-close" @click="fn_closePrdtMdfc"></div>  
        <!-- 탭메뉴 --> 
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-segment pl24 pr24">
          <mo-segment-wrapper solid primary v-model="tabPrdtMdfcIdx">
            <mo-segment-button value="1">상품 제외</mo-segment-button>
            <mo-segment-button value="2">상품 추가</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>
        <!-- 탭메뉴 -->
        <div class="content-area maxH500">          
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area bd-T-Ty1 mt20">
            <!-- 탭1 내용: 보유 S -->
            <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-check-list" v-if="tabPrdtMdfcIdx === '1'">
              <mo-list :list-data="productList" v-if="productList.length > 0">
                <template #list-item="{item}">
                  <mo-list-item> 
                    <mo-checkbox v-model="item.checked"/>
                    <div class="list-item__contents">
                      <div class="list-item__contents__title">
                        <span class="name fs17rem fwn">{{item.name}}</span>
                        <mo-badge class="badge-sample-badge sm mt3" :class="item.isRecommendProduct ? 'lightred2' : !item.isOtherProduct ? 'lightblue' : item.gtplOtcomPrdtInptScCd === '4' ? 'purple' : 'lightgray2'" text="" shape="status">
                          {{item.isRecommendProduct ? '추천' : !item.isOtherProduct ? '당사' : item.gtplOtcomPrdtInptScCd === '4' ? '신정원' : '타사'}}
                        </mo-badge>
                      </div>
                      <div class="list-item__contents__info">
                        <strong class="amount crTy-blue3 fs17rem">월 {{$bizUtil.numberWithCommasByNumber(item.monthPrice)}} 원</strong>
                      </div>
                    </div>
                  </mo-list-item>
                </template>
              </mo-list>
            </ur-box-container>
            <!-- 탭1 내용: 보유 E //-->

            <!-- 탭2 내용: 최근 가입설계 S -->
            <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-check-list" v-if="tabPrdtMdfcIdx === '2'">
              <mo-list :list-data="productConfigList" v-if="productConfigList.length > 0">
                <template #list-item="{item}">
                  <mo-list-item> 
                    <mo-checkbox v-model="item.checked"/>
                    <div class="list-item__contents">
                      <div class="list-item__contents__title">
                        <span class="name fs17rem fwn">{{item.name}}</span>
                        <mo-badge class="badge-sample-badge sm mt3" :class="item.isRecommendProduct ? 'lightred2' : !item.isOtherProduct ? 'lightblue' : item.gtplOtcomPrdtInptScCd === '4' ? 'purple' : 'lightgray2'" text="" shape="status">
                          {{item.isRecommendProduct ? '추천' : !item.isOtherProduct ? '당사' : item.gtplOtcomPrdtInptScCd === '4' ? '신정원' : '타사'}}
                        </mo-badge>
                      </div>
                      <div class="list-item__contents__info">
                        <strong class="amount crTy-blue3 fs17rem">월 {{$bizUtil.numberWithCommasByNumber(item.monthPrice)}} 원</strong>
                      </div>
                      <div class="list-item__contents__info">
                      </div>
                    </div>
                  </mo-list-item>
                </template>
              </mo-list>
            </ur-box-container>
            <!-- 탭2 내용: 최근 가입설계 E //-->

            <!-- no-data 데이타 없음 --> 
            <ur-box-container alignV="start" componentid="" direction="column" class="no-data" v-if="(tabPrdtMdfcIdx === '1' && productList.length == 0) || (tabPrdtMdfcIdx === '2' && productConfigList.length == 0)">
              <mo-list-item class="pb0">
                <div class="list-item__contents">
                  <div class="list-item__contents__info small-space h200px">
                    <span class="ns-ftcr-gray"><p class="mt10">보유 상품이 없습니다.</p></span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- no-data end -->

          </ur-box-container>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_movePlan">새로설계</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="addDelExcute">{{tabPrdtMdfcIdx === '1' ? '제외': '추가'}}</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 바텀시트2 : 상품수정 바텀시트 표출 //-->

      <!-- 더보기 -->
      <mo-bottom-sheet ref="refMoreList" class="ns-bottom-sheet" :close-btn="true">
        <template v-slot:title>
          더보기
        </template>
        <div class="content-area pal0 pb30">
          <ur-box-container alignV="start" componentid="" direction="column" class="btn-list-type">
            <mo-button @click="fn_btnSubMenu('1')" class="ns-btn-check">실손가입 조회<mo-icon icon="msp-check-bold"/></mo-button>
            <mo-button @click="fn_btnSubMenu('2')" class="ns-btn-check">타사증권 입력<mo-icon icon="msp-check-bold"/></mo-button>
            <mo-button @click="fn_btnSubMenu('3')" class="ns-btn-check">권장금액 선택<mo-icon icon="msp-check-bold"/></mo-button>
            <mo-button @click="fn_btnSubMenu('5')" class="ns-btn-check">당사계약 정보 출처 선택<mo-icon icon="msp-check-bold"/></mo-button>
            <mo-button @click="fn_btnSubMenu('4')" class="ns-btn-check">보장설계 발송<mo-icon icon="msp-check-bold"/></mo-button>
            <!-- <mo-button @click="fn_btnSubMenu('6')" class="ns-btn-check">연금계산기<mo-icon icon="msp-check-bold"/></mo-button> -->
          </ur-box-container>
        </div>

      </mo-bottom-sheet>

    </ur-box-container>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPCT121D from '@/ui/ct/MSPCT121D'
  import MSPCT122D from '@/ui/ct/MSPCT122D'
  import MSPCT123D from '@/ui/ct/MSPCT123D'
  import MSPCT124P from '@/ui/ct/MSPCT124P'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT120P",
    screenId: "MSPCT120P",
    components: {
      'tabMSPCT121D' : MSPCT121D,
      'tabMSPCT122D' : MSPCT122D,
      'tabMSPCT123D' : MSPCT123D,
    },
    props: {
      // pSelRldmEntData: {
      //   type: Object,
      //   default: {}
      // }
    },
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      window.vue.getStore('progress').dispatch('INIT')

      //ga매니저 권한
      this.vGssAuthYN = this.getStore('cmStore').getters.getState.gssAuthYN
    },
    // beforeMount () {
    //   // 마운트되기 직전에 조회기준기간 콤보값 셋팅 (현화면 보장분석 >>> 상품별:TSSCT006M 에서는 disabled, 보장분석 >>> 기간별 화면:TSSCT007M 에서 사용)
    //   let maxInqrStndPrd = Number(this.getStore('ctStore').getters.getCovanData.data.gtplCllgHoshVO.gtplCllgVO.maxInqrStndPrd) // 최대조회기준기간
    //   maxInqrStndPrd = maxInqrStndPrd === undefined ? 0 : maxInqrStndPrd
    //   let inqrStndPrd = Number(this.getStore('ctStore').getters.getCovanData.data.gtplCllgHoshVO.gtplCllgVO.inqrStndPrd) // 조회기준기간
    //   inqrStndPrd = inqrStndPrd === undefined ? 0 : inqrStndPrd

    //   // 최대조회기준기간 을 조회기준년도 콤보값 max 값 , 조회기준기간을 조회기준년도 콤보값의 현재값
    //   this.yearList = []
    //   for ( let inqrPrdIdx = 0; inqrPrdIdx < maxInqrStndPrd; inqrPrdIdx++ ) {
    //     let lv_item = {}
    //     lv_item.key = inqrPrdIdx + 1
    //     lv_item.label = inqrPrdIdx + 1 + '년'
    //     this.yearList.push(lv_item)
    //   }
    //   this.year = inqrStndPrd
    // },
    mounted () {
      this.$bizUtil.insSrnLog(this.$options.screenId)

      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

      this.fn_TabChangeHandler(this.defaultIdxs)

      this.fn_initTSSCT008MLoadData()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        vGssAuthYN: '', //ga매니저 권한
        lv_HeaderviewScrollCompID: '',
        productConfigList: [],
        productList: [],
        bfCovanSmtotPrm: 0,
        afCoVanSmtotPrm: 0,
        defaultIdxs : 1,
        slctTabIdx : 1,
        // planClsfSlctIdx: 0,
        // covrPrdSlctIdx: 0,
        tabPrdtMdfcIdx: '1',
        // changeValue: false,
        mdfcCnt: 0
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      lv_BaseData: function () {
        // 고객 기본정보
        let returnValue = this.getStore('ctStore').getters.getCustBaseInfo
        return returnValue
      },
      lv_CovrStnrd: function () {
        // 권장금액
        let returnValue = this.getStore('ctStore').getters.getCustCovrStnrd
        return returnValue
      },
      lv_ContData: function () {
        // 기계약 데이터
        let returnValue = this.$bizUtil.cloneDeep(this.getStore('ctStore').getters.getContData.data)
        return returnValue
      },
      lv_CovanData: function () {
        // 고객 계약 데이터
        let returnValue = this.getStore('ctStore').getters.getCovanData.data
        return returnValue
      },
      tableHeaderMock: function () {
        return this.$bizUtil.ctUtils.fn_GetTableTitles()
      }
    },
    watch: {
      slctTabIdx(newVal) {
        this.fn_changeSlctTabIdx(newVal)
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_openMSPCT124P(){
        let lp_Vm = this  
        this.MSPCT124P = this.$bottomModal.open(MSPCT124P, {
          properties: {
            pPage: 'MSPCT124P',
            pSlctTabIdx: String(this.slctTabIdx),
            pProductList : this.productList
          },
          listeners: {
            onPopupClose:() => {
              lp_Vm.$bottomModal.close(lp_Vm.MSPCT124P)
            },
          }
        },
        {
          properties: {
            noHeader: true
          }
        }
        )
      },
      fn_openMSPCT150P(attr) {
        this.$emit('openSubMenu', {dvcd: '3', attr: attr})
      },
      fn_openSubMenu() {
        this.$refs.refMoreList.open()
      },
      fn_btnSubMenu(dvcd) {
        this.$refs.refMoreList.close()
        this.$emit('openSubMenu', dvcd)
      },
      fn_popupClose() {
        this.$emit('onPopupClose')
      },
      fn_initTSSCT008MLoadData: function () {
        this.bfCovanSmtotPrm = 0
        this.afCoVanSmtotPrm = 0
        this.TSSCT005MParam = this.lv_BaseData

        let lv_TargetSelf = this
        return new Promise(function (resolve, reject) {
          // 데이터 초기화
          lv_TargetSelf.lv_CheckCode = '01' // 제안 삭제 코드 -> 제안에 삭제 되어 설계중인 목록에 나오므로 제외해야 한다.
          lv_TargetSelf.isEmpty = false
          lv_TargetSelf.isVisible = true
          lv_TargetSelf.tableData = []

          // 가입설계는 최신화가 되는데 제안상품은 업데이트가 안되면 화면이동시 최신의 데이터를 보여줄수가 없다.
          // 따라서 화면을 만듬고 동시에 고객 데이터(CONTREG)를 최신화 시킨다.
          console.log('TSSCT008M ----> start')
          // 기계약 최신화 프로미스 대기
          // 설게상품 최신화를 위한 프로미스대기
          // let lv_PostPrm = {
          //   chnlCustId: lv_TargetSelf.lv_BaseData.data.chnlCustId,
          //   custNm: lv_TargetSelf.lv_BaseData.data.custNm,
          //   cnsltNo: lv_TargetSelf.lv_BaseData.data.cnsltNo,
          //   conctScCd: '3' // 접속구분 코드 추가 2 : 모바일
          // }
          // Promise.all([lv_TargetSelf.$bizUtil.ctUtils.fn_rePrmsRgstdt(lv_TargetSelf, lv_PostPrm, false), lv_TargetSelf.$bizUtil.ctUtils.fn_PrpslCovrAnly(lv_TargetSelf, false)])
          Promise.all([lv_TargetSelf.$bizUtil.ctUtils.fn_PrmsRgstdt(lv_TargetSelf, true, false), lv_TargetSelf.$bizUtil.ctUtils.fn_PrpslCovrAnly(lv_TargetSelf, false)])
            .then(function (response) {
              console.log('CT120P response >>> ', response)
              // console.log('CT120P response >>> ', response[0].body.gtplCllgHoshVO.gtplCllgCustVO[0].entplPrpslPrdtVO)
              lv_TargetSelf.$bizUtil.ctUtils.fn_DrwRgstdt(lv_TargetSelf)
              lv_TargetSelf.$bizUtil.ctUtils.fn_planAfCovan(lv_TargetSelf)
              // lv_TargetSelf.planAfCovan = lv_TargetSelf.getStore('ctStore').getters.getPlanAfCovan.data
              // lv_TargetSelf.bfCovanSmtotPrm = lv_TargetSelf.planAfCovan.bfCovanSmtotPrm
              // lv_TargetSelf.afCoVanSmtotPrm = lv_TargetSelf.planAfCovan.afCoVanSmtotPrm
              
              // 수정된 상품갯수 설정
              lv_TargetSelf.fn_setPrmMdfcCnt()


            })
            .catch(function (error) {
              window.vue.error(error)
            })
        })
      },
      fn_CovrItem () {
        for (let custItem in this.lv_ContData) {
          let lv_InnerItem = this.lv_ContData[custItem]
          // 기계약만 처리하고 있으므로, 보유 true, 추천 false.....
          // 기계약의 경우 추가 삭제시 컨설턴트번호,채널고객아이디,계약번호를 물고다닌다.
          if (lv_InnerItem.prpslStatCd === this.lv_CheckCode) {
            let rtnitem = this.$bizUtil.ctUtils.fn_SettingListItem(this.lv_BaseData.data.custNm, lv_InnerItem, true, false, true)
            rtnitem.checked = false // 상품수정에서 사용
            this.productList.push(rtnitem)
          } else {
            // 설계상품으로 보낸다.
            let rtnitem = this.$bizUtil.ctUtils.fn_SettingListItem(this.lv_BaseData.data.custNm, lv_InnerItem, true, false, true)
            rtnitem.checked = false // 상품수정에서 사용
            this.productConfigList.push(rtnitem)
          }
        }
      },
      // 최초 노출(총보장자산, 설계후)는 1개의 플래그(보유여부)만 사용한다.
      fn_CalcDefaultAmount (hasFlag, targetList) {
        return this.$bizUtil.ctUtils.fn_CalcAmount(hasFlag, false, targetList)
      },
      // hasProduct > 현재 설계중인 상품계산여부, isReconmmend > 추천상품에 대한
      fn_DoCalcModifyAmount: function (onlyHasProduct, onlyRecommned) {
        let tempTotalArray = [] // 설계중인 상품정보와 추천상품정보를 같이 가지고 있을 배열
        let allItemAmount = {}
        let ctItemAmount = {}
        let calcDataBucket = {}

        // 수정을 계산한다.
        tempTotalArray = tempTotalArray.concat(this.productList.slice())
        tempTotalArray = tempTotalArray.concat(this.productConfigList.slice())
        allItemAmount = this.$bizUtil.ctUtils.fn_CalcAmount(onlyHasProduct, onlyRecommned, tempTotalArray)
        if (onlyHasProduct) {
          // 수정은 보유자산의 변동량으로 총자산과 설계중인 상품의 보유자산 차로 계산한다.
          tempTotalArray = this.productList.slice()
        } else {
          // 추가는 비보유자산의 변동량으로 총자산과 추천중인 상품의 보유자산 차로 계산한다.
          tempTotalArray = this.productConfigList.slice()
        }
        ctItemAmount = this.$bizUtil.ctUtils.fn_CalcAmount(onlyHasProduct, onlyRecommned, tempTotalArray)
        if (allItemAmount.length === ctItemAmount.length) {
          for (let checekIndex in allItemAmount) {
            if (onlyHasProduct) {
              // 수정은 보유자산의 변동량으로 총자산과 설계중인 상품의 보유자산 차로 계산한다.
              calcDataBucket[checekIndex] = ctItemAmount[checekIndex] - allItemAmount[checekIndex]
            } else {
              // 추가는 비보유자산의 변동량으로 총자산과 추천중인 상품의 보유자산 차로 계산한다.
              calcDataBucket[checekIndex] = allItemAmount[checekIndex] - ctItemAmount[checekIndex]
            }
          }
        }
        this.doSettingModified(onlyHasProduct, calcDataBucket)
      },
      // initFlag > 최초생성시 --> 보장자산은 보유자산으로만 구성된다.
      doSettingModified: function (modifyFlag, calcDataBucket) {
        for (let blankArrayIndex in this.tableHeaderMock) {
          let lv_BnkOtrItem = this.tableHeaderMock[blankArrayIndex] // ex : title : 가족보장(최상위 아이템)
          for (let lv_BnkInrItemIdx in lv_BnkOtrItem.columns) {
            let lv_BnkInrItem = lv_BnkOtrItem.columns[lv_BnkInrItemIdx] // ex : title: 사망 (중간 아이템)
            for (let lv_BnkChkItemIdx in lv_BnkInrItem.data) { // ex : title : 암사망(최종아이템)
              let lv_BnkChkItem = lv_BnkInrItem.data[lv_BnkChkItemIdx]
              if (modifyFlag) {
              // 수정으로 노출시킴
                // lv_BnkChkItem.modifyAmount = Math.floor(calcDataBucket[lv_BnkChkItem.attr] / 10000) // Math.floor(targetList[lv_BnkChkItem.attr] / 10000)
                if (lv_BnkChkItem.dcmp === 1) {
                  lv_BnkChkItem.modifyAmount = Number((calcDataBucket[lv_BnkChkItem.attr] / 10000).toFixed(lv_BnkChkItem.dcmp))
                } else {
                  lv_BnkChkItem.modifyAmount = Math.floor(calcDataBucket[lv_BnkChkItem.attr] / 10000)
                }
              } else {
              // 추가로 노출시킴
                // lv_BnkChkItem.addAmount = Math.floor(calcDataBucket[lv_BnkChkItem.attr] / 10000)
                if (lv_BnkChkItem.dcmp === 1) {
                  lv_BnkChkItem.addAmount = Number((calcDataBucket[lv_BnkChkItem.attr] / 10000).toFixed(lv_BnkChkItem.dcmp))
                } else {
                  lv_BnkChkItem.addAmount = Math.floor(calcDataBucket[lv_BnkChkItem.attr] / 10000)
                }
              }
            }
          }
        }
      },
      fn_addCommonList (lv_Vm, itemList, idxList, response) {
        console.log('fn_addCommonList itemList, response >> ', itemList, response)
        let dataBucket = []
        itemList.forEach((item) => {
          if(item.hasProduct){
            // 기계약의 경우는 퍼블에 맞게 변수가 변경되어 들어갔기때문에 메타에서 기저장한 원본을 재셋팅해준다.
            let lv_MetaItem = item.inEntplVO
            item.prdtNm = lv_MetaItem.prdtNm
            item.padCylCd = lv_MetaItem.padCylCd
            item.smtotPrm = lv_MetaItem.smtotPrm
            item.compAcontCovr = lv_MetaItem.compAcontCovr
            item.tcomYn = lv_MetaItem.tcomYn
            item.contNo = lv_MetaItem.contNo

            lv_Vm.productList.push(lv_Vm.$bizUtil.ctUtils.fn_SettingListItem(lv_Vm.lv_BaseData.data.custNm, item, true, false, true)) // 기계약 가입설계는 보유이면서 추천일수 없다.
          }
          item.checked = false

        })
        response.body.entplPrpslPrdtSVO.forEach((entplPrpslPrdtSVO) => {
          let lv_InEntp = entplPrpslPrdtSVO.inEntplVO
          let lv_OutEntp = entplPrpslPrdtSVO.outEntplPrpslPrdtVO[0] // 추가에 대한 답변이므로 최상위는 1개
          // input에는 있으나 outPut에는 없는 것들을 재셋팅한다.
          lv_OutEntp.padCylCdNm = lv_InEntp.padCylCdNm
          lv_OutEntp.smtotPrm = lv_OutEntp.prm // output에도 존재하지만 이름을 바꿔서 재셋팅
          lv_OutEntp.prpslStatCd = lv_InEntp.prpslStatCd
          lv_OutEntp.mdfcDtm = lv_InEntp.mdfcDtm
          lv_OutEntp.prcd = lv_InEntp.prcd
          lv_OutEntp.compAcontCovr = lv_Vm.$bizUtil.ctUtils.fn_GetThisYearPrpslPrdt(lv_OutEntp.entplPrpslPrdtCovrVO)

          console.log('lv_OutEntp >>> ', lv_OutEntp, lv_OutEntp.planNm)

          lv_Vm.productList.push(lv_Vm.$bizUtil.ctUtils.fn_SettingListItem(lv_Vm.lv_BaseData.data.custNm, lv_OutEntp, false, true, true)) // 가입설계는 자사 추천상품이다.
        })
            
        for(let i=idxList.length-1; i>-1; i--){
          console.log(i, idxList[i])
          lv_Vm.productConfigList.splice(idxList[i], 1)
        }

        dataBucket = lv_Vm.fn_CalcDefaultAmount(false, lv_Vm.productList) // 설계후 계산
        lv_Vm.$bizUtil.ctUtils.fn_DoSettingAmount(false, dataBucket, lv_Vm) // 설계후 노출
        lv_Vm.fn_DoCalcModifyAmount(true, false) // 수정금액 계산
        lv_Vm.fn_DoCalcModifyAmount(false, true) // 추가금액 계산

        // 아이템정렬
        lv_Vm.$bizUtil.ctUtils.fn_OrdCrdList(lv_Vm, true)
        lv_Vm.getStore('ctStore').dispatch('CUSTREGMDF_F', true)
      },
      addProductList(itemList, idxList) {
        console.log('addProductList 전송전 itemList >>> ', itemList)
        // 상품추가 및 삭제 리스트의 상품 클릭 시 상품목록으로 이동.
        // 인터페이스를 테우는 처리 필요
        // -> 보유 상품여부를 기준으로 보내는 인터페이스를 가른다.
        // -> 기계약건의 경우 인터페이스 요청후 단순 이동처리한다.
        // -> 가입설계 상품 인입의 경우 respose로 데이터를 최신화 해야 한다.
        // --> 가입설계의 경우 설계 내역이 바뀔수 있으므로, 기존데이터가 있는 상황이라도 최신화 한다.
        let lv_Vm = this
        this.$bizUtil.ctUtils.fn_InsAcontEntplPrpslPrdtList(lv_Vm, itemList).then(function (response) {
          console.log('fn_InsAcontEntplPrpslPrdtList 이후 itemList >> ', itemList)
          console.log('fn_InsAcontEntplPrpslPrdtList 이후  response >> ', response)
          lv_Vm.fn_addCommonList(lv_Vm, itemList, idxList, response)
          lv_Vm.$addSnackbar('적용되었습니다.')
          lv_Vm.fn_closePrdtMdfc()
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },
      removeList (itemList, idxList) { // 상품목록에서 삭제한 상품은 상품추가 및 삭제 리스트로 이동.
        let lv_Vm = this
        // 보유상품 추천상품 삭제처리
        this.$bizUtil.ctUtils.fn_DelAcontEntplPrpslPrdtList(lv_Vm, itemList).then(function () {
          lv_Vm.fn_DltCommonList(lv_Vm, itemList, idxList)
          lv_Vm.$addSnackbar('적용되었습니다.')
          lv_Vm.fn_closePrdtMdfc()
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      fn_DltCommonList (lv_Vm, itemList, idxList) {
        let dataBucket = []
        // -> 인터페이스 이후 별도의 처리 없이 화면당 redraw만 한다.
        itemList.forEach((item) => {
          item.checked = false
          lv_Vm.productConfigList.push(item)
        })
        for(let i=idxList.length-1; i>-1; i--){
          console.log(i, idxList[i])
          lv_Vm.productList.splice(idxList[i], 1)
        }
        lv_Vm.isVisible = true
        dataBucket = lv_Vm.fn_CalcDefaultAmount(false, lv_Vm.productList) // 설계후 계산
        lv_Vm.$bizUtil.ctUtils.fn_DoSettingAmount(false, dataBucket, lv_Vm) // 설계후 노출
        lv_Vm.fn_DoCalcModifyAmount(true, false) // 수정금액 계산
        lv_Vm.fn_DoCalcModifyAmount(false, true) // 추가금액 계산
        // 아이템정렬
        lv_Vm.$bizUtil.ctUtils.fn_OrdCrdList(lv_Vm, true)
        lv_Vm.getStore('ctStore').dispatch('CUSTREGMDF_F', true)
      },
      addDelExcute(){
        let itemList = []
        let idxList = []
        if(this.tabPrdtMdfcIdx == '1'){
          this.productList.forEach((item, idx) => {
            if(item.checked){
              // this.remove(item, idx)
              console.log(item.name, idx)
              itemList.push(item)
              idxList.push(idx)
            }
          })
          if(itemList.length > 0){
            this.removeList(itemList, idxList)
          }else{
            this.$bizUtil.ctUtils.fn_alert(this, {content: '제거할 상품을 선택하세요.', title_pos_btn: '확인'})
          }
        }else if(this.tabPrdtMdfcIdx == '2'){
          this.productConfigList.forEach((item, idx) => {
            if(item.checked){
              // this.addProduct(item, idx)
              console.log(item.name, idx)
              itemList.push(item)
              idxList.push(idx)
            }
          })
          if(itemList.length > 0){
            this.addProductList(itemList, idxList)
          }else{
            this.$bizUtil.ctUtils.fn_alert(this, {content: '추가할 상품을 선택하세요.', title_pos_btn: '확인'})
          }
        }
      },
      fn_openPrdtMdfc() {
        this.$refs.bottomSheet2.open()
      },
      fn_closePrdtMdfc() {
        this.$refs.bottomSheet2.close()
        if(this.getStore('ctStore').getters.getCustBaseInfoMdfF){
          this.fn_changeSlctTabIdx(this.slctTabIdx)
        }
      },
      fn_TabChangeHandler(idx){
        this.slctTabIdx = idx
      },
      fn_changeSlctTabIdx(idx) {
        console.log('fn_changeSlctTabIdx >> ', idx)
        if(idx == 1){
          this.fn_initTSSCT008MLoadData()
        }else if(idx == 2){
          // this.fn_initTSSCT008MLoadData()
          this.$refs.refMSPCT122D.fn_init()
        }else if(idx == 3){
          // this.fn_initTSSCT008MLoadData()
          this.$refs.refMSPCT123D.fn_loadTSCT009MDataSet()
        }
      },
      fn_setPrmMdfcCnt() {
        this.productConfigList = []
        this.productList = []

        // 최초 데이터는 퍼블리싱 요소전에 먼저 만들고자 created로 로드한다.
        // 기계약은 제안상품코드 01인(본인계약자, 본인 주/종피계약건) 것들과 가입설계제안VO에 담긴 내역들로 꾸린다.
        // 추가/삭제영역은 기계약중 제안상품코드02(본인계약자, 본인 주/종피계약건)와 가입설계(새로운 인터페이스)에 있는 내역들로 꾸린다.
        // --> 미제안 기계약건은 미리 데이터를 셋팅하지만 가입설계 상품의 경우 데이터를 셋팅할수 없으며, 추가 될때 셋팅된다.
        // 설계중 아이템 가져오기
        this.fn_CovrItem()
        // 가입설계 가져오기
        this.$bizUtil.ctUtils.fn_PrpslCovrAnlyRcg(this)
        let dataBucket = []
        // 권장금액을 노출시킨다.
        this.$bizUtil.ctUtils.fn_showStndModel(this.tableHeaderMock, this.lv_CovrStnrd)

        // 보장자산을 계산한다..
        // 보장자산은 가입설계와 설계제안 모두에서 보유로 계산된다.
        let lv_TempArray = []
        lv_TempArray = lv_TempArray.concat(this.productList.slice()).concat(this.productConfigList.slice())

        // 2018-12-28 자체수정분 : 권장금액 팝업에서 수정후 반영안되는 부분 자체 수정
        if (this.productList.length !== 0) {
          this.isEmpty = true
        }

        dataBucket = this.fn_CalcDefaultAmount(true, lv_TempArray) // 총보장자산 계산
        this.$bizUtil.ctUtils.fn_DoSettingAmount(true, dataBucket, this) // 총보장자산 노출

        dataBucket = this.fn_CalcDefaultAmount(false, this.productList) // 설계후 계산
        this.$bizUtil.ctUtils.fn_DoSettingAmount(false, dataBucket, this) // 설계후 노출

        // 수정을 계산한다.
        this.fn_DoCalcModifyAmount(true, false)
        // 추가를 계산한다.
        this.fn_DoCalcModifyAmount(false, true)
        // 아이템정렬
        this.$bizUtil.ctUtils.fn_OrdCrdList(this, true)


        this.planAfCovan = this.getStore('ctStore').getters.getPlanAfCovan.data
        this.bfCovanSmtotPrm = this.planAfCovan.bfCovanSmtotPrm
        this.afCoVanSmtotPrm = this.planAfCovan.afCoVanSmtotPrm
        this.mdfcCnt = this.planAfCovan.addmPrdtInfo.length + this.planAfCovan.excluPrdtInfo.length
       
        console.log('lv_TargetSelf.tableHeaderMock >> ', JSON.stringify(this.tableHeaderMock))
        console.log('lv_TargetSelf.productList >> ', JSON.stringify(this.productList))
        console.log('lv_TargetSelf.productConfigList >> ', JSON.stringify(this.productConfigList))
      },
      // fn_planClsfTabChange(idx){
      //   this.planClsfSlctIdx = idx
      // },
      // fn_covrPrdTabChange(idx){
      //   this.covrPrdSlctIdx = idx
      // },
      // /******************************************************************************
      //   * Function명 : fn_loadInqrStndPrd
      //   * 설명       : 보장분석 기간별 select box 값 전환시 호출 (조회기준기간 변경)
      // ******************************************************************************/
      // fn_loadInqrStndPrd (year) {
      //   this.$refs.refMSPCT122D.fn_loadInqrStndPrdSet(year)
      // },
      fn_movePlan() {
        this.$emit('onMovePlan')
      },
      fn_moveDetail(idx) {
        this.fn_TabChangeHandler(1)
        this.$refs.refTab.setIdx(1)
        this.$refs.refMSPCT121D.$refs.refPlanClsf.setIdx(idx)
      }
      
    }
  };
</script>
<style scoped>
</style>