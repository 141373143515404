<template>
   <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">    
    <!-- 컨텐츠 영역 --> 
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap bgcolor-1 pb100">
      <!-- 보장기간: 문구표출  -->
      <ur-box-container direction="row" alignV="start"> 
        <div class="text-gray-box bgcolor-2 bd-radius-0 pb10">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>신용정보원에서 제공하는 정보는 기간별 분석에서 '주보험' 기간으로만 예시하였고, 실제 보장기간은 다를 수 있습니다.</li>
          </ul>
        </div>
      </ur-box-container>                
      <ur-box-container alignV="start" componentid="" direction="column" class="pl24 bg-white pb20">
        <msp-bottom-select class="ns-dropdown-sheet" :items="yearList" v-model="year" itemHeight="374" :itemValue="'key'" :itemText="'label'" @input="fn_loadInqrStndPrdSet" underline bottom-title="기간선택" scrolling placeholder="기간 선택" closeBtn/>
      </ur-box-container>
      <!-- 보장기간: 문구표출  -->
      <mo-tab-box :defaultIdx="0" ref="refCovrPrd" @tab-change="fn_covrPrdTabChange" class="ns-move-tab-box w100 gray-tab-bar msp-tab-ty expand_topline">
        <template v-for="(list, idx) in tableHeaderMock">
          <mo-tab-label :idx="idx" :key="'prd' + idx"><span>{{list.title}}</span></mo-tab-label>
        </template>
      </mo-tab-box>
      <!-- S: 보장목록 리스트 -->
      <ur-box-container direction="column" alignV="start" class="ns-info-list pt20 pl24" v-if="scrnDataList.length > 0">
        <template v-for="(item, idx) in scrnDataList">
          <ur-box-container :key="idx" direction="column" alignV="start" class="round-box round-ty">
            <div class="w100">
              <div class="title fexTy3">
                <strong class="fexTy5 fs18rem fwm">
                  <span class="mr6">{{item.type}}</span> 
                </strong>
                <!-- 종신 표시 -->
                <span class="in-decrease-express fexTy5">
                  <b class="num fs18rem crTy-blue3">{{item.afterCovrAge == '113' ? '종신' : item.afterCovrAge + '세'}}</b>
                </span>
              </div>
              <!-- S: 설계전후 표준모델대비 보장자산 금액 bar 차트 -->
              <!-- 설계전 -->
              <div class="msp-chart-area mt20">
                <div class="txt-box fexTy3-1">
                  <span class="tit crTy-bk7">설계 전</span>
                  <b class="age">{{item.beforeCovrAge == '113' ? '종신' : item.beforeCovrAge + '세'}}</b>
                </div>
                <div class="bar-chart">
                  <div class="fill-bar" :style="{width:''+fn_calCovrAge(item.beforeCovrAge)+'%'}"></div> <!--:style="width:"-->
                </div>
                <div class="fexTy3 mt5">
                  <template v-for="(item, idx) in ageList" >
                    <span class="fs12rem crTy-bk7" :key="idx">{{item == '113' ? '종신' : item}}</span>
                  </template>
                </div>
              </div>
              <!-- 설계전 //-->

              <!-- 설계후 -->
              <div class="msp-chart-area mt20">
                <div class="txt-box fexTy3-1">
                  <span class="tit crTy-bk1 fwb">설계 후</span>
                  <b class="age">{{item.afterCovrAge == '113' ? '종신' : item.afterCovrAge + '세'}}</b>
                </div>
                <div class="bar-chart">
                  <!-- 설계후 증가시 : 파란색 bar 표출 -->
                  <div class="fill-bar" :class="item.afterCovrAge - item.beforeCovrAge >= 0 ? 'after-up' : 'after-down'" :style="{width:''+fn_calCovrAge(item.afterCovrAge)+'%'}"></div> <!--:style="width:"-->
                  <!-- 설계후 감소시 : 주황색 bar 표출 
                  <div class="fill-bar after-down" :style="{width:'30%'}"></div> -->
                </div>
                <div class="fexTy3 mt5">
                  <template v-for="(item, idx) in ageList" >
                    <span class="fs12rem crTy-bk7" :key="idx">{{item == '113' ? '종신' : item}}</span>
                  </template>
                </div>
              </div>
              <!-- 설계후 //-->
              <!-- E: 설계전후 표준모델대비 보장자산 금액 bar 차트 //-->
              <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="w100 mt20">
                <mo-button color="primary" shape="border" size="medium" class="ns-btn-round btn-title-style-1 w100 h40 fs16rem" @click="fn_openPrdCovrAmt(item)">기간별 보장금액</mo-button>
              </ur-box-container>
            </div>
          </ur-box-container>
        </template>
      </ur-box-container>
      <!-- E: 보장목록 리스트 -->
      <!-- no-data  -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="no-data" v-else>
        <mo-list-item ref="expItem" class="bgcolor-1">
          <div class="list-item__contents bgcolor-1">
            <div class="list-item__contents__info small-space">
              <span class="ns-ftcr-gray">
                <p class="mt20">선택한 상태의 보장이 없어요.</p>
              </span>                      
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>  

      <!-- 바텀시트1 : 일반암 바텀시트 표출 -->
      <mo-bottom-sheet ref="bottomSheet1"  class="ns-bottom-sheet msp-bottomSt-ty7 closebtn">
        <template v-slot:title>{{prdCovrAmt.type}}</template>
        <div class="ns-btn-close" @click="fn_closePrdCovrAmt"></div>  
        <div class="content-area">
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area pb5">
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-list lst-bdBN">  
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title line-h-Ty2 mb5">
                    <span class="fs30rem fwb crTy-blue3">{{prdCovrAmt.afterCovrAge == '113' ? '종신' : prdCovrAmt.afterCovrAge + '세'}}</span>
                  </div>
                  <div class="list-item__contents__info pr0 fexTy3">
                    <span class="fexTy5">
                      <span class="fs14rem crTy-bk7 mr10">설계 전</span><span>{{prdCovrAmt.beforeCovrAge == '113' ? '종신' : prdCovrAmt.beforeCovrAge + '세'}}</span><em>|</em>
                      <span class="fs14rem crTy-bk7 mr10">설계 후</span><span>{{prdCovrAmt.afterCovrAge == '113' ? '종신' : prdCovrAmt.afterCovrAge + '세'}}</span>
                    </span>
                    <span class="fs14rem crTy-bk7">(단위: 만원)</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
          </ur-box-container>
          <!-- 연령별 보장금액 table -->
          <div class="table-area">
            <table class="table col-type col-type-bg1">
              <colgroup>
                <col width="33%" />
                <col width="33%" />
                <col width="33%" />
              </colgroup>
              <thead>
                <tr>
                  <th>연령</th>
                  <th>설계전</th>
                  <th>설계후</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, idx) in prdCovrAmt.data">
                  <tr :key="idx">
                    <td>{{item.age == '113' ? '종신' : item.age + '세'}}</td>
                    <td>{{$bizUtil.numberWithCommasByNumber(item.price, item)}}</td>
                    <td>{{$bizUtil.numberWithCommasByNumber(item.afterPrice, item)}}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <!-- 연령별 보장금액 table //-->
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_closePrdCovrAmt" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 바텀시트1 : 일반암 바텀시트 표출 //-->   

  
    </ur-box-container>
    <!-- 컨텐츠 영역 //--> 
  </ur-box-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import UrBoxContainer from '@/_uidev/containers/box/urBoxContainer.vue'
import CtConstants from '@/config/constants/ctConstants' // 컨설팅 상수

export default {

  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCT122D",
  screenId: "MSPCT122D",
  components: {
   UrBoxContainer
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    pSlctIdx: {
      type: Number,
      default: 1
    } // 부모 선택한 현재 탭 인덱스
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      scrnDataList: [],
      idx: CtConstants.showCovrIdx, // API에서 가져온 값중 실제로 화면에 보여질 값의 idx
      ageList: [],
      wlifeAge: CtConstants.wlifeAge,
      blockData: {},
      prdt: {}, // 상품별 보장별 금액
      prdCovrAmt: { 
        type: '',
        before: '',
        after: '',
        data: [] 
      },
      yearList: [],
      year: '',
      slctIdx: 0
    }
  },//data

  created () {
  },//created
  beforeMount() {
    // 마운트되기 직전에 조회기준기간 콤보값 셋팅 (현화면 보장분석 >>> 상품별:TSSCT006M 에서는 disabled, 보장분석 >>> 기간별 화면:TSSCT007M 에서 사용)
    let maxInqrStndPrd = Number(this.getStore('ctStore').getters.getCovanData.data.gtplCllgHoshVO.gtplCllgVO.maxInqrStndPrd) // 최대조회기준기간
    maxInqrStndPrd = maxInqrStndPrd === undefined ? 0 : maxInqrStndPrd
    let inqrStndPrd = Number(this.getStore('ctStore').getters.getCovanData.data.gtplCllgHoshVO.gtplCllgVO.inqrStndPrd) // 조회기준기간
    inqrStndPrd = inqrStndPrd === undefined ? 0 : inqrStndPrd

    // 최대조회기준기간 을 조회기준년도 콤보값 max 값 , 조회기준기간을 조회기준년도 콤보값의 현재값
    this.yearList = []
    for ( let inqrPrdIdx = 0; inqrPrdIdx < maxInqrStndPrd; inqrPrdIdx++ ) {
      let lv_item = {}
      lv_item.key = inqrPrdIdx + 1
      lv_item.label = inqrPrdIdx + 1 + '년'
      this.yearList.push(lv_item)
    }
    this.year = inqrStndPrd
  },
  mounted () {
    this.scrnDataList = this.tableHeaderMock[this.slctIdx].columns[0].data
    this.contData = [].concat(this.getStore('ctStore').getters.getHoshContData.data)
    console.log('this.contData ::: ', this.contData.length, this.contData)
  },
  watch: {
    slctIdx(v){
      this.fn_initPrdCovrAmtDataSet()
      this.scrnDataList = this.tableHeaderMock[v].columns[0].data
    },
    // pSlctIdx(){
    //   console.log(this.$options.screenId + " : this.pSlctIdx = "+ this.pSlctIdx)
    //   this.fn_initPrdCovrAmtDataSet()
    //   this.scrnDataList = this.tableHeaderMock[this.$props.pSlctIdx].columns[0].data
    // }
  },//watch
  computed: {
    tableHeaderMock: function () {
      return this.$bizUtil.ctUtils.fn_GetTableTitles()
    },
    lv_BaseData: function () {
      // 고객 기본정보
      let returnValue = this.getStore('ctStore').getters.getCustBaseInfo
      return returnValue
    }
  },

  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    fn_covrPrdTabChange(idx){
      this.slctIdx = idx
    },
    fn_init() {
      let lv_Vm = this

      this.TSSCT005MParam = this.lv_BaseData
      // this.$bizUtil.ctUtils.fn_PrmsRgstdt(lv_Vm, true) // 기계약 최신화 프로미스 대기
      let lv_PostPrm = {
        chnlCustId: this.lv_BaseData.data.chnlCustId,
        custNm: this.lv_BaseData.data.custNm,
        cnsltNo: this.lv_BaseData.data.cnsltNo,
        conctScCd: '3' // 접속구분 코드 추가 2 : 모바일
      }
      Promise.all([this.$bizUtil.ctUtils.fn_rePrmsRgstdt(lv_Vm, lv_PostPrm, false), lv_Vm.$bizUtil.ctUtils.fn_PrpslCovrAnly(lv_Vm, false)])  // 기계약 최신화 프로미스 대기
        .then(function (response) {
          lv_Vm.$bizUtil.ctUtils.fn_DrwRgstdt(lv_Vm)
          lv_Vm.$bizUtil.ctUtils.fn_planAfCovan(lv_Vm)
          let totalData_all = {}
          let ctTableEngNmLoadMap = lv_Vm.$bizUtil.ctUtils.fn_GetTableTitlesEngNm()

          // 설계전
          let rmovDupData_before = lv_Vm.$bizUtil.ctUtils.fn_getAcontRmovDup(lv_Vm, 'before', lv_Vm.getStore('ctStore').getters.getCovanData.data)
          let totalData_before = lv_Vm.$bizUtil.ctUtils.fn_getSumByYear(rmovDupData_before.type, lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.age, rmovDupData_before.tcompAcontVOFt, rmovDupData_before.otcompAcontVOFt, rmovDupData_before.entplPrpslPrdtVO, rmovDupData_before.gtplInqrStndYrVO)
          // 설계후
          let rmovDupData_after = lv_Vm.$bizUtil.ctUtils.fn_getAcontRmovDup(lv_Vm, 'after', lv_Vm.getStore('ctStore').getters.getCovanData.data)
          let totalData_after = lv_Vm.$bizUtil.ctUtils.fn_getSumByYear(rmovDupData_after.type, lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.age, rmovDupData_after.tcompAcontVOFt, rmovDupData_after.otcompAcontVOFt, rmovDupData_after.entplPrpslPrdtVO, rmovDupData_after.gtplInqrStndYrVO)

          // 설계전 정보가 無, 설계후 정보가 有 인경우 나이구간 정보를 설계후 로 대체
          let lv_ageList = totalData_before.age.length === 0 ? totalData_after.age : totalData_before.age
          lv_Vm.ageList = lv_Vm.$bizUtil.ctUtils.fn_GetArrayNum(lv_Vm, 'age', lv_ageList, lv_Vm.idx)
          console.log('totalData before :: ', totalData_before)
          console.log('totalData after :: ', totalData_after)

          console.log('lv_Vm.ageList >>> ', lv_Vm.ageList)

          // 전/후 데이터 한 객체에 담기
          let initCovanAmt = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] // 연령별 금액 (10구간) 데이터 가 없는경우 초기값
          for (let i in ctTableEngNmLoadMap) {
            let group = ctTableEngNmLoadMap[i]
            for (let j in group) {
              // 설계전 정보가 無, 설계후 정보가 有 인경우 년도 / 나이구간 정보를 설계후 로 대체
              totalData_all.yr = totalData_before.yr.length === 0 ? totalData_after.yr : totalData_before.yr
              totalData_all.age = totalData_before.age.length === 0 ? totalData_after.age : totalData_before.age
              // 설계전 데이터가 없는경우 보장금액 0 표시
              totalData_all[group[j]] = (totalData_before[group[j]] === undefined ? initCovanAmt : totalData_before[group[j]])
              totalData_all['AFTER_' + group[j]] = (totalData_after[group[j]] === undefined ? initCovanAmt : totalData_after[group[j]])
            }
          }

          console.log('totalData_all :: ', totalData_all)
          lv_Vm.blockData = {}

          for (let i in ctTableEngNmLoadMap) {
            let group = ctTableEngNmLoadMap[i]
            for (let j in group) {
              let rtnArray = []
              let arr_before = totalData_all[group[j]] // 설계전
              let arr_after = totalData_all['AFTER_' + group[j]] // 설계후
              let arr_Colname = group[j]

              for (let k in lv_Vm.idx) {
                let covrNameList = [
                'cllgCncrMdhspCovrAmt',     // 종합병원암통원
                'cncrHspzCovrAmt',          // 암입원
                'cncrMdhspRmdyCovrAmt',     // 암통원
                'dstrHspzCarerUseCovrAmt',  // 재해입원간병인사용
                'dstrHspzCovrAmt',          // 재해입원
                'genDiseHspzCovrAmt',       // 질병입원
                'hspzCarerUseDignAmt',      // 질병입원간병인사용
                'ondayGenHspzCovrAmt',      // 1일입원
                'uppHsplCncrMdhspCovrAmt',  // 상급병원암통원
                'uppWardHspzCovrAmt'        // 상급병실입원
                ]
                if (covrNameList.indexOf(arr_Colname) > -1) {
                  rtnArray[k] = {age: lv_Vm.ageList[k], price: (arr_before[lv_Vm.idx[k]]/10000).toFixed(1), afterPrice: (arr_after[lv_Vm.idx[k]]/10000).toFixed(1)}
                } else {
                  rtnArray[k] = {age: lv_Vm.ageList[k], price: Math.floor(arr_before[lv_Vm.idx[k]]/10000), afterPrice: Math.floor(arr_after[lv_Vm.idx[k]]/10000)}
                }
              }
              lv_Vm.blockData[arr_Colname] = rtnArray
            }
          }

          // 기간별 전후 나이 값 추가
          lv_Vm.tableHeaderMock.forEach((lv_BnkOtrItem) => {
            lv_BnkOtrItem.columns.forEach((lv_BnkInrItem) => {
              lv_BnkInrItem.data.forEach((lv_BnkChkItem) => {
                let beforeYn = true
                let afterYn = true
                for(let i=0; i<lv_Vm.blockData[lv_BnkChkItem.attr].length; i++){
                  let idx = 0
                  if(i > 0) idx = i-1
                  else idx = 0
                  
                  if(beforeYn){
                    if(lv_Vm.blockData[lv_BnkChkItem.attr][i].price == 0){
                      lv_BnkChkItem.beforeCovrAge = lv_Vm.blockData[lv_BnkChkItem.attr][idx].age
                      beforeYn = false
                    }else{
                      if(i == lv_Vm.blockData[lv_BnkChkItem.attr].length-1){
                        lv_BnkChkItem.beforeCovrAge = lv_Vm.blockData[lv_BnkChkItem.attr][i].age
                        beforeYn = false
                      }
                    }
                  }
                  if(afterYn){
                    if(lv_Vm.blockData[lv_BnkChkItem.attr][i].afterPrice == 0){
                      lv_BnkChkItem.afterCovrAge = lv_Vm.blockData[lv_BnkChkItem.attr][idx].age
                      afterYn = false
                    }else{
                      if(i == lv_Vm.blockData[lv_BnkChkItem.attr].length-1){
                        lv_BnkChkItem.afterCovrAge = lv_Vm.blockData[lv_BnkChkItem.attr][i].age
                        afterYn = false
                      }
                    }
                  }
                  if(!beforeYn && !afterYn) break
                }
              })
            })
          })

          console.log('this.blockData :: ', lv_Vm.blockData)

          // 보험료,상품수정갯수 설정
          lv_Vm.$emit('setPrmMdfcCnt')
        })
    },
    fn_openPrdCovrAmt(item) {
      this.prdCovrAmt = item
      this.prdCovrAmt.data = this.blockData[item.attr]
      this.prdCovrAmt.data.attr = item.attr

      this.$refs.bottomSheet1.open()
    },
    fn_closePrdCovrAmt() {
      this.$refs.bottomSheet1.close()
      this.fn_initPrdCovrAmtDataSet()
    },
    fn_initPrdCovrAmtDataSet() {
      this.prdCovrAmt = { 
        type: '',
        beforeCovrAge: '',
        afterCovrAge: '',
        data: [] 
      }
    },
    /******************************************************************************
    * Function명 : fn_loadInqrStndPrdSet
    * 설명       : 조회기준 변경시 호출 (설정한 조회기준년도로 보장분석 쟤계산 호출)
    ******************************************************************************/
    fn_loadInqrStndPrdSet (inqrStndPrdVal) {
      console.log('fn_loadInqrStndPrdSet ::: ' + inqrStndPrdVal)
      let lv_CstInfo = this.getStore('ctStore').getters.getCustBaseInfo.data
      let lv_PostPrm = {
        chnlCustId: lv_CstInfo.chnlCustId,
        custNm: lv_CstInfo.custNm,
        cnsltNo: lv_CstInfo.cnsltNo,
        inqrStndPrd: inqrStndPrdVal // 설정한 조회기준년도
      }
      let lv_PrmSelf = this
      lv_PrmSelf.getStore('ctStore').dispatch('CUSTREGMDF_F', true)
      this.$bizUtil.ctUtils.fn_rePrmsRgstdt(lv_PrmSelf, lv_PostPrm, false).then(function (response) {
        lv_PrmSelf.fn_init() // 본화면 재 load
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    fn_calCovrAge(age) {
      let rate = undefined
      this.ageList.some((item, i) => {
        if(item == age){
          rate = i/(this.ageList.length-1)*100
          return true
        }
      })
      return rate
    }
 
 }// methods

}//export default


</script>
<style scoped>
</style>