<template>
 <ur-page-container :title="tcompKcisScTxt1" :show-title="true" type="subpage" class="msp" @on-header-left-click="fn_close"><!-- <popup> -->

    <ur-box-container direction="column" alignV="start" class="">
      <ur-box-container alignV="start" componentid="" direction="column" class="pal2430">
        <div class="fexTy3-1">
            <span class="fs19rem fwb crTy-blue3" @click="fn_OpenCustNmUiCard">{{fn_getCurrentCustNm}}</span>
            <span class="fs19rem fwb">고객님</span>
            <span class="fs19rem ">의 {{tcompKcisScTxt2}}</span>
        </div>
        <div>
            <span class="fs16rem mt10">
                신용정보원에서 제공하는 정보는 생명보험사의 경우 00.04月, 손해보험사의 경우 02.12月 (농/축/수협 등 공제 및 체신관서의 경우 09.10月) 이후 집적된 자료입니다.  보험가입현황 및 보장내용 등이 실제와 다를 수 있으며, 한국신용정보원 조회 상황에 따라 일부 상이할 수 있습니다. (1일 1회 限 신용정보원 가입정보 수신이 가능합니다.)
            </span>
        </div>
      </ur-box-container>
      <ur-box-container componentid="" direction="column" class="bgcolor-1 h10px">
      </ur-box-container>
      <ur-box-container direction="column" alignV="start" class="" v-if="fn_getCustInsrLmtChkContDtlListVO.length > 0">
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
            <mo-list-item>
            <mo-checkbox v-model="isSelectAll" size="small" @input="selectAllItemsFunc(isSelectAll)" />
            <div class="list-item__contents">
                <div class="list-item__contents__title">
                <span class="sum">{{checkCount === 0 ? '총': ''}}<span class="count">{{checkCount > 0 ? checkCount: fn_getCustInsrLmtChkContDtlListVO.length}}</span>건{{checkCount > 0 ? ' 선택': ''}}</span>
                </div>
            </div>
            </mo-list-item>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area pb100">
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-radio-list2 pb42">
            <mo-list :list-data="fn_getCustInsrLmtChkContDtlListVO">
              <template #list-item="{item}">
                <mo-list-item>
                  <mo-checkbox v-model="item.checked" class="mr10"></mo-checkbox>
                  <div class="list-item__contents">
                    <div class="list-item__contents__title">
                      <span class="name txtSkip">{{item.kcisCoCdNm}}</span>
                    </div>
                    <div>
                        <span class="">{{item.kcisPrdtNm}}</span>
                    </div>
                    <div class="list-item__contents__info mt10">
                      <div>
                        <span class="fs14rem">보장시작</span><span class="ml10 crTy-bk1">{{fn_DateDashFormat(item.strYmd)}}</span>
                        <em></em>
                        <span class="fs14rem">보장종료</span><span class="ml10 crTy-bk1">{{fn_DateDashFormat(item.endYmd)}}</span>
                      </div>
                      <div>
                        <span class="fs14rem">보험료</span><span class="ml10 crTy-blue3 fwb">{{fn_NumberWithCommas(item.padPrm.longAmount)}}원</span>
                        <span class="ml10 crTy-bk1">({{item.kcisPadCylCdNm}},{{item.kcisPadPrd === 0 || item.kcisPadCylCdNm === '일시납' ? '일시납' : item.kcisPadPrd + '년'}})</span>
                      </div>
                    </div>
                  </div>
                </mo-list-item>
              </template>
            </mo-list>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="no-data" v-else>
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info small-space">
              <span class="ns-ftcr-gray"><p class="mt10">조회된 가입정보가 없어요.</p></span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>

      
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="info-box">
            <ul class="terms-list-area pb10">
              <li class="fs16rem crTy-bk7 txt-left">수정사항이 있을 경우 '적용'을 선택해야 반영됩니다.</li>
            </ul>
          </div>
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_close">취소</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_PrcsOtcomAcontInfoAplc">적용</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT116P",
    screenId: "MSPCT116P",
    components: {},
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created () {
      // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_close)
    },
    beforeDestroy () {
      // backKey event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_close)
    },
    mounted () {
      this.$bizUtil.insSrnLog(this.$options.screenId)

      // 변수 초기화
      this.fn_initVal()

      // 고객정보 set
      this.custInfo.chnlCustId = this.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId
      this.custInfo.custNm = this.getStore('ctStore').getters.getCustBaseInfo.data.custNm

      this.selCustInfo.chnlCustId = this.custInfo.chnlCustId
      this.selCustInfo.custNm = this.custInfo.custNm

      // 세대원 타사계약정보 조회
      this.fn_SelListOtcomAcontInfo()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        stndKeyList: [], // 변경된 컨설턴트
        currentChnlCust: [], // 현재 선택된 고객
        gtplCllgCustSVO: [], // 세대원 타사계약정보 조회결과VO
        isSelectAll: false, // 그리드 전체선택 Flag
        custInfo: {}, // 채널고객ID, 고객명을 담을 객체(현재 보장분석 고객)
        selCustInfo: {}, // 채널고객ID, 고객명을 담을 객체(현재 타사계약정보 확인고객)
        reload: false,
      }
    },
    /***********************************************************************************
    * watch 정의 영역
    ***********************************************************************************/
    watch: {},
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      tcompKcisSc () {
        return this.getStore('ctStore').getters.getTcompKcisSc // 당사,신정원 정보출처구분
      },
      tcompKcisScTxt1 () {
        return this.tcompKcisSc === '1' ? '타사정보 확인' : '신용정보원 정보확인'
      },
      tcompKcisScTxt2 () {
        return this.tcompKcisSc === '1' ? '타사 가입정보' : '신용정보원 가입정보'
      },
      checkCount () {
        let rtnCnt = this.fn_getCustInsrLmtChkContDtlListVO.filter(item => item.checked).length
        if(rtnCnt == this.fn_getCustInsrLmtChkContDtlListVO.length){
          this.isSelectAll = true
        }else{
          this.isSelectAll = false
        }
        return rtnCnt
      },
      /******************************************************************************
      * Function명  : fn_getCurrentgtplCllgCustSVO
      * 설명        : 현재 선택된 고객의 세대정보 retrun
      * Params      :
      ******************************************************************************/
      fn_getCurrentgtplCllgCustSVO () {
        if ( this.currentChnlCust && this.currentChnlCust[0] && this.currentChnlCust[0].key ) {
          return this.gtplCllgCustSVO.filter( x => { return (x.chnlCustId === this.currentChnlCust[0].key) })[0]
        } else {
          return []
        }
      },
      /******************************************************************************
      * Function명  : fn_getCurrentgtplCllgCustSVO
      * 설명        : 현재 선택된 고객의 세대정보의 타사계약정보 retrun
      * Params      :
      ******************************************************************************/
      fn_getCustInsrLmtChkContDtlListVO () {
        let vo = this.fn_getCurrentgtplCllgCustSVO?.pICustInsrLmtChkContDtlListVO
        return vo === undefined ? [] : vo
      },
      /******************************************************************************
      * Function명  : fn_getCurrentCustNm
      * 설명        : 현재 선택된 고객명 retrun
      * Params      :
      ******************************************************************************/
      fn_getCurrentCustNm () {
        if ( this.currentChnlCust && this.currentChnlCust[0] && this.currentChnlCust[0].key ) {
          return this.currentChnlCust[0].label
        } else {
          return ''
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명  : fn_initVal
      * 설명        : 변수 초기화 함수
      * Params      :
      ******************************************************************************/
      fn_initVal () {
        // 현재 선택된 고객
        this.currentChnlCust = []

        this.gtplCllgCustSVO = [] // 세대원 타사계약정보 조회결과VO
        this.isSelectAll = false // 전체선택여부 Flag
        this.custInfo = {} // 채널고객ID, 고객명을 담을 객체
        this.reload = false
      },
      /******************************************************************************
      * Function명  : fn_SelListOtcomAcontInfo
      * 설명        : 세대원 타사계약정보 조회
      * Params      :
      ******************************************************************************/
      fn_SelListOtcomAcontInfo () {
        let lv_Vm = this
        let trnstId = 'txTSSCT44S1'
        let auth = 'S'
        let pParams = {
          'mnCustId': this.custInfo.chnlCustId,
          'custNm': this.custInfo.custNm,
          conctScCd: '3',
          infoOrgplCd: this.getStore('ctStore').getters.getTcompKcisSc // 당사,신정원 정보출처구분
        }

        // GA 매니저 대행 컨설턴트 변경시
        if(this.$cmUtil.fn_GachangeTF()){
          pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo // 10번째 호출까지 제대로 셋팅되는지 확인!!!!!!
        }else{
          pParams.cnsltNo = window.vue.getStore('userInfo').getters.getUserInfo.userId
        }

        //매니저가 컨설턴트를 변경했을경우
        this.stndKeyList = []
        if(this.$cmUtil.fn_GachangeTF()){
          this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
        }

        // 서비스 호출
        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {

          console.log('타사증권 response >>> ', response)
          // 성공여부 확인
          if ( response.msgComm.msgCd === 'EFWS003' ) { // 정상 메시지코드
            // 조회결과 확인
            if ( !response || !response.body || !response.body.gtplCllgCustVO || response.body.gtplCllgCustVO.length <= 0 ) {
              let lv_Msg = lv_Vm.$t('ct')['ECTC048'].replace('{0}', '세대원정보') // {0}이(가) 존재하지 않습니다.
              lv_Vm.getStore('confirm').dispatch('ADD', lv_Msg)
              return
            }

            // 세대원정보 설정
            // 1) 조회 결과VO 설정
            lv_Vm.gtplCllgCustSVO = []
            // 당사
            if(lv_Vm.tcompKcisSc === '1'){
              for(let i=0; i<response.body.gtplCllgCustVO.length; i++){
                if (lv_Vm.$bizUtil.isEmpty(response.body.gtplCllgCustVO[i].pICustInsrLmtChkContDtlListVO)) continue
                for(let j=0; j<response.body.gtplCllgCustVO[i].pICustInsrLmtChkContDtlListVO.length; j++){
                  if(response.body.gtplCllgCustVO[i].pICustInsrLmtChkContDtlListVO[j].kcisCoCd === 'L03') continue
                  lv_Vm.gtplCllgCustSVO.push(response.body.gtplCllgCustVO[i])
                }
              }
            }
            // 신용정보원
            else if(lv_Vm.tcompKcisSc === '2'){
              lv_Vm.gtplCllgCustSVO = response.body.gtplCllgCustVO
            }

            // 2) 적용여부 관련 설정
            for ( let i = 0; i < lv_Vm.gtplCllgCustSVO.length; i++ ) {
              if ( lv_Vm.gtplCllgCustSVO[i].pICustInsrLmtChkContDtlListVO && lv_Vm.gtplCllgCustSVO[i].pICustInsrLmtChkContDtlListVO.length > 0 ) {
                if (lv_Vm.$bizUtil.isEmpty(lv_Vm.gtplCllgCustSVO[i].pICustInsrLmtChkContDtlListVO)) continue
                for ( let j = 0; lv_Vm.gtplCllgCustSVO[i].pICustInsrLmtChkContDtlListVO.length > j; j++ ) {
                  if ( lv_Vm.gtplCllgCustSVO[i].pICustInsrLmtChkContDtlListVO[j].aplcYn === 'Y' ) {
                    lv_Vm.$set(lv_Vm.gtplCllgCustSVO[i].pICustInsrLmtChkContDtlListVO[j], 'checked', true)
                  }else{
                    lv_Vm.$set(lv_Vm.gtplCllgCustSVO[i].pICustInsrLmtChkContDtlListVO[j], 'checked', false)
                  }
                }
              }
            }

            // 초기값 설정
            lv_Vm.currentChnlCust = [{
              'key': lv_Vm.selCustInfo.chnlCustId,
              'label': lv_Vm.selCustInfo.custNm
            }] // 현재채널고객
          } else {
            // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            window.vue.error(response.msgComm.msgDesc)
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
      * Function명  : fn_PrcsOtcomAcontInfoAplc
      * 설명        : 세대원 타사계약정보 적용 관리
      * Params      :
      ******************************************************************************/
      fn_PrcsOtcomAcontInfoAplc () {
        let lv_GtplCllgCustVO = [] // INPUT VO
        let lv_PICustInsrLmtChkContDtlListVO = this.fn_getCustInsrLmtChkContDtlListVO
        let lv_TargetListVO = [] // 처리대상 계약정보List VO
        let lv_ChnlCustId = this.fn_getCurrentgtplCllgCustSVO.chnlCustId // 채널고객ID
        let lv_CustNm = this.fn_getCurrentgtplCllgCustSVO.custNm // 고객명

        // 채널고객ID check
        if ( !lv_ChnlCustId || lv_ChnlCustId === '' ) {
          let lv_Msg = this.$t('ct')['ECTC052'].replace('{0}', '채널고객ID') // {0}이(가) 유효하지 않습니다.
          this.getStore('confirm').dispatch('ADD', lv_Msg)
          return
        }

        // 고객명 check
        if ( !lv_CustNm || lv_CustNm === '' ) {
          let lv_Msg = this.$t('ct')['ECTC052'].replace('{0}', '고객명') // {0}이(가) 유효하지 않습니다.
          this.getStore('confirm').dispatch('ADD', lv_Msg)
          return
        }

        // 타사계약정보 check
        if ( !lv_PICustInsrLmtChkContDtlListVO || lv_PICustInsrLmtChkContDtlListVO.length <= 0 ) {
          let lv_Msg = this.$t('ct')['ECTC048'].replace('{0}', '타사계약정보') // {0}이(가) 존재하지 않습니다.
          this.getStore('confirm').dispatch('ADD', lv_Msg)
          return
        }

        // 처리대상 설정
        // 타사계약정보 List만큼 반복
        let lv_TempListVo = []
        let selectItems = lv_PICustInsrLmtChkContDtlListVO.filter(item => item.checked)
        console.log('selectItems >> ', selectItems)
        for ( let i = 0; i < lv_PICustInsrLmtChkContDtlListVO.length; i++ ) {
          // 현재 적용으로 선택되어 있는지 selectItems List에 해당 신용정보원 증권번호 기준으로 존재여부 확인
          lv_TempListVo = selectItems.filter( x => { return (x.kcisSecusNo === lv_PICustInsrLmtChkContDtlListVO[i].kcisSecusNo) })

          // 1. 최초 적용건이 였으나 현재 미적용으로 되어 있을 경우 삭제(미적용)처리 대상
          if ( lv_PICustInsrLmtChkContDtlListVO[i].aplcYn === 'Y' && lv_TempListVo.length <= 0 ) {
            // 처리대상 VO에 설정함(삭제(미적용)처리를 하기 위해 aplcYn를 N으로 설정)
            lv_TargetListVO.push(
              this.fn_GetPrcsTargetVO(lv_PICustInsrLmtChkContDtlListVO[i], 'N')
            )
          }

          // 2. 최초 미적용건이 였으나 현재 적용으로 되어 있을 경우 적용처리 대상
          if ( lv_PICustInsrLmtChkContDtlListVO[i].aplcYn === 'N' && lv_TempListVo.length > 0 ) {
            // 처리대상 VO에 설정함(적용처리를 하기 위해 aplcYn를 Y으로 설정)
            lv_TargetListVO.push(
              this.fn_GetPrcsTargetVO(lv_PICustInsrLmtChkContDtlListVO[i], 'Y')
            )
          }
        }

        // 처리대상 check
        if ( !lv_TargetListVO || lv_TargetListVO.length <= 0 ) {
          this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC051'])
          return
        }

        lv_GtplCllgCustVO.push({
          'chnlCustId': this.fn_getCurrentgtplCllgCustSVO.chnlCustId,
          'custNm': this.fn_getCurrentgtplCllgCustSVO.custNm,
          'pICustInsrLmtChkContDtlListVO': lv_TargetListVO
        })

        let lv_Vm = this
        let trnstId = 'txTSSCT44I1'
        let auth = 'I'
        let pParams = {
          'gtplCllgCustVO': lv_GtplCllgCustVO
        }

        // 서비스 호출
        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          // 성공여부 확인
          if ( response.msgComm.msgCd === 'EFWS003' ) { // 정상 메시지코드
            lv_Vm.reload = true

            // 타사계약정보 재조회
            lv_Vm.selCustInfo.chnlCustId = lv_ChnlCustId
            lv_Vm.selCustInfo.custNm = lv_CustNm
            // lv_Vm.fn_SelListOtcomAcontInfo()
            lv_Vm.getStore('ctStore').dispatch('CUSTREGMDF_F', true)
            // lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('ct')['ECTC022'])
            lv_Vm.$addSnackbar('적용되었습니다.')
            lv_Vm.fn_close()
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            window.vue.error(response.msgComm.msgDesc)
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
      * Function명  : fn_GetPrcsTargetVO
      * 설명        : 처리대상VO의 항목을 설정하여 return
      * Params      : 원본 VO
      ******************************************************************************/
      fn_GetPrcsTargetVO (pOrgVo, pAplcYn) {
        return {
          'aplcYn': pAplcYn,
          'coltrAmt': pOrgVo.coltrAmt,
          'contrNm': pOrgVo.contrNm,
          'crncyCd': pOrgVo.crncyCd,
          'endYmd': pOrgVo.endYmd,
          'kcisCoCd': pOrgVo.kcisCoCd,
          'kcisCoCdNm': pOrgVo.kcisCoCdNm,
          'kcisColtrCd': pOrgVo.kcisColtrCd,
          'kcisColtrCdNm': pOrgVo.kcisColtrCdNm,
          'kcisPadCylCd': pOrgVo.kcisPadCylCd,
          'kcisPadCylCdNm': pOrgVo.kcisPadCylCdNm,
          'kcisPadPrd': pOrgVo.kcisPadPrd,
          'kcisPrdtNm': pOrgVo.kcisPrdtNm,
          'kcisSecusNo': pOrgVo.kcisSecusNo,
          'otcomContSno': pOrgVo.otcomContSno,
          'padPrm': pOrgVo.padPrm,
          'strYmd': pOrgVo.strYmd
        }
      },
      /******************************************************************************
      * Function명  : fn_DateDashFormat
      * 설명        : 날짜형식 변경 함수
      * Params      :
      ******************************************************************************/
      fn_DateDashFormat (pDate) {
        return this.$commonUtil.dateDashFormat(pDate)
      },
      /******************************************************************************
      * Function명  : fn_NumberWithCommas
      * 설명        : 숫자형식 변경 함수
      * Params      :
      ******************************************************************************/
      fn_NumberWithCommas (pPrm) {
        return this.$commonUtil.numberWithCommas(pPrm)
      },
      /******************************************************************************
      * Function명  : selectAllItemsFunc
      * 설명        : 그리드 전체 선택/해제 처리 함수
      * Params      : state - 선택/해제Flag
      ******************************************************************************/
      selectAllItemsFunc (state) {
        if (state) {
          // checked
          this.fn_getCustInsrLmtChkContDtlListVO.forEach(item => item.checked = true )
        } else {
          // unchecked
          this.fn_getCustInsrLmtChkContDtlListVO.forEach(item => item.checked = false)
        }
      },
      fn_close() {
        this.$emit('onPopupClose', this.reload)
        // 변수 초기화
        this.fn_initVal()
      },
      /******************************************************************************
       * Function명 : fn_OpenCustNmUiCard
       * 설명       : 고객네임카드
       * 
       ******************************************************************************/
      fn_OpenCustNmUiCard () {
        let nmUiObject = {
          chnlCustId : this.selCustInfo.chnlCustId, //고객채널 ID
          parentId   : 'MSPCT116P', //화면ID+
          cnsltNo : this.getStore('userInfo').getters.getUserInfo.cnsltNo,
          custNm : this.selCustInfo.custNm,
          contNo : ''
        }

        this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
          //Close 콜백 처리
          return
        })
      }
    }
  }
</script>
<style scoped>
</style>