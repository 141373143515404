<template>
  <ur-page-container ref="page" class="msp" title="보장설계" :show-title="false" type="subpage" :topButton="true" :topButtonBottom="160">
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <mo-button class="btn_popup_back" @click="fn_ClickBtnPrevious" />
              <div slot="nav" class="icon-wrapper" @click="fn_ClickBtnPrevious">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '보장설계' : '보장설계'}}</div>
              <div slot="action">
                <mo-button shape="border" class="ns-btn-round btn-title-style-1 blue" @click="fn_MoveMSPPI001M">가입설계</mo-button> 
                <mo-icon icon-size="30px" v-show="vGssAuthYN !== 'Y'" type="picto" @click="fn_open('MSPCT110M_B01')">more</mo-icon>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <ur-box-container alignV="start" direction="column" class="pal3024">
              <div class="text-blue-box txt-center">
                <div class="fs16rem"><b class="crTy-blue3 mr6" @click="fn_OpenCustNmUiCard">{{name}}</b>고객님을 위한<b class="ml6">삼성생명 보장설계</b></div>
              </div>
              <div class="mt20 w100 fexTy3">
                <span class="fs16rem fexTy5">
                  가입계약<b class="crTy-blue3 ml6 mr2">{{$commonUtil.numberWithCommas(contCnt)}}</b>건
                  <mo-icon icon="msp-tool-tip" class="crTy-bk1 ml10" @click="fn_open('MSPCT110M_B03')" />
                </span>
                <mo-button class="ns-btn-round h28 white" @click="fn_SelRcalCovrAnly(slctTabIdx)"><mo-icon icon="msp-refresh" class="mr4 white" />최신정보</mo-button>
              </div>
              <div class="mt20 bd-radius-10 box-shadow-type-3 w100 pal20">
                <div class="w100 fexTy3 fs16rem">
                  <span>월 보험료</span>
                  <span class="crTy-blue3"><b class="fs20rem mr4">{{$commonUtil.numberWithCommas(totMonAmt)}}</b>원</span>
                </div>
                <div class="w100 mt10 fexTy3 fs16rem">
                  <span>총 납입</span>
                  <span><b class="fs20rem mr4">{{$commonUtil.numberWithCommas(totAlpadPrmAmt)}}</b>원</span>
                </div>
              </div>
            </ur-box-container>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <mo-tab-box :defaultIdx="1" @tab-change="fn_TabChangeHandler" ref="tabbox"  class="ns-move-tab-box">
              <mo-tab-content :idx="1" label="보장요약" ></mo-tab-content>
              <mo-tab-content :idx="2" label="계약현황" ></mo-tab-content>
              <mo-tab-content :idx="3" label="보장현황" ></mo-tab-content>      
            </mo-tab-box>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="">
              <MSPCT111D ref="refMSPCT111D" v-show="slctTabIdx === 1" :pCustBaseInfo="TSSCT005MParam" @moveDetail="fn_moveDetail"/>
              <MSPCT112D ref="refMSPCT112D" v-show="slctTabIdx === 2" @reloadData="fn_reloadData"/>
              <MSPCT113D ref="refMSPCT113D" v-show="slctTabIdx === 3" />
            </ur-box-container>

          </template>
        </mo-collapsing-header-view>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative mb56">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_openCT120P">설계전후비교</mo-button> <!-- disabled -->
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" v-show="vGssAuthYN !== 'Y'" @click="fn_openMSPCT160P">인쇄 및 발송</mo-button>
          </div>
        </ur-box-container>

      </div>
    </ur-box-container> 

    <!-- 더보기 -->
    <mo-bottom-sheet ref="MSPCT110M_B01" class="ns-bottom-sheet" @before-close="fn_beForeCloseSubMenu" :close-btn="true">
      <template v-slot:title>
        더보기
      </template>
      <div class="content-area pal0 pb30">
        <ur-box-container alignV="start" componentid="" direction="column" class="btn-list-type">
          <!-- class명 on시 파란색 선택 입니다. -->
          <mo-button @click="fn_btnSubMenu('1')" class="ns-btn-check" :class="{ on: btn_index === '1' }">실손가입 조회<mo-icon icon="msp-check-bold"/></mo-button>
          <mo-button @click="fn_btnSubMenu('2')" class="ns-btn-check" :class="{ on: btn_index === '2' }">타사증권 입력<mo-icon icon="msp-check-bold"/></mo-button>
          <mo-button @click="fn_btnSubMenu('3')" class="ns-btn-check" :class="{ on: btn_index === '3' }">권장금액 선택<mo-icon icon="msp-check-bold"/></mo-button>
          <mo-button @click="fn_btnSubMenu('5')" class="ns-btn-check" :class="{ on: btn_index === '5' }">당사계약 정보 출처 선택<mo-icon icon="msp-check-bold"/></mo-button>
          <mo-button @click="fn_btnSubMenu('4')" class="ns-btn-check" :class="{ on: btn_index === '4' }">보장설계 발송<mo-icon icon="msp-check-bold"/></mo-button>
          <!-- <mo-button @click="fn_btnSubMenu('6')" class="ns-btn-check" :class="{ on: btn_index === '6' }">연금계산기<mo-icon icon="msp-check-bold"/></mo-button> -->
        </ur-box-container>
      </div>

    </mo-bottom-sheet>

    <!-- 가입계약 조회 안내 -->
    <mo-bottom-sheet ref="MSPCT110M_B02" class="ns-bottom-sheet"  :close-btn="true">
      <template v-slot:title>
        당사계약  정보 출처 선택
      </template>
      <div class="content-area pb30">
        <!-- TODO cjs -->
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-radio class="radio" v-model="tcompKcisSc" value="1">당사 내부정보</mo-radio>
          <mo-radio class="radio mt20" v-model="tcompKcisSc" value="2">신용정보원</mo-radio>
        </ur-box-container>
        <div class="text-gray-box mt20">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li class="break-word">신용정보원에서 제공하는 삼성생명 정보는 00.04月이후 집적된 자료입니다. </li>
            <li class="break-word">삼성생명 계약/담보 상세분석 발행 시 당사 내부정보를 선택했을 경우와 데이터 차이가 있을 수 있습니다.</li>
            <li class="break-word">분석제외 계약: 금융정보보호계약, 기업주가 보험료를 부담하는 계약, 방카슈랑스 판매계약, 보험선물하기</li>
          </ul>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_close('MSPCT110M_B02')" class="ns-btn-round white">취소</mo-button>
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_tcompKcisSc" class="ns-btn-round blue">조회</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
   
    <!-- 가입계약 조회 안내 -->
    <mo-bottom-sheet ref="MSPCT110M_B03" class="ns-bottom-sheet"  :close-btn="true">
      <template v-slot:title>
        가입계약 조회 안내
      </template>
      <div class="content-area pal0 pb30 mh75px">
        <ur-box-container alignV="start" componentid="" direction="column" class="con-area">
          <span class="">
            ‘삼성생명’ 기계약건이 모두 조회되지 않는 경우 ‘최신정보’ 버튼으로 다시 조회해 주세요. 단, 계약상태가 정상이 아닌 건은 조회되지 않습니다.
          </span>
        </ur-box-container> 
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_close('MSPCT110M_B03')" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 진입시 안내 -->
    <mo-bottom-sheet ref="MSPCT110M_B04" class="ns-bottom-sheet msp-bottomSt-alert" :close-btn="true">
      <div class="con-area mt40 mb40 txt-center">
         <span class="fs17rem" v-html="msgCovrAnlyConfirm" />
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="fn_MoveMSPPI001M">가입설계로 이동</mo-button>
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_close('MSPCT110M_B04')">보장설계 진행</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    
    <!-- 로딩 -->
    <!-- 당사정보 / 타사정보 / 실손정보 / 보장설계 db 불러오는 성격에 다른 text 수정요청드립니다.-->
    <div class="msp loading--dim msp-in-page" v-show="isLoading">
      <div class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div>
      <div class="loading-text-box">
        <span class="title">{{loadingMsg}}</span>
        <span class="txt" v-html="loadingSubMsg" />
      </div>
    </div>
    <!-- <div class="ns-loading msp-in-page" v-show="isLoading">
      <div class="ns-dimm"></div>
      <div class="ns-loading-img">
        <img src="/images/loading_heri.gif" alt="로딩 이미지">
      </div>
      <div class="loading-text-box">
        <span class="title">{{loadingMsg}}</span>
        <span class="txt" v-html="loadingSubMsg" />
      </div>
    </div> -->

  </ur-page-container>
</template>
<script>
import UrBoxContainer from '@/_uidev/containers/box/urBoxContainer.vue'
import Msg from '@/systems/webkit/msg/msg'
import CtConstants from '@/config/constants/ctConstants' // 컨설팅 상수
import Screen from '~systems/mixin/screen'

import MSPCT111D from '@/ui/ct/MSPCT111D'
import MSPCT112D from '@/ui/ct/MSPCT112D'
import MSPCT113D from '@/ui/ct/MSPCT113D'
import MSPCT120P from '@/ui/ct/MSPCT120P' // 상세 보장설계
import MSPCT130P from '@/ui/ct/MSPCT130P' // 실손가입 조회
import MSPCT140P from '@/ui/ct/MSPCT140P' // 타사증권 입력
import MSPCT150P from '@/ui/ct/MSPCT150P' // 표준모델 선택 > 권장금액 선택
import MSPCT160P from '@/ui/ct/MSPCT160P' // 보장설계 발송

import MSPAP511P from '@/ui/ap/MSPAP511P'

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT110M",
    screenId: "MSPCT110M",
    mixins: [Screen],
    components: {
      UrBoxContainer,
      MSPCT111D,
      MSPCT112D,
      MSPCT113D
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        vGssAuthYN: '', //ga매니저 권한
        stndKeyList: [], // 변경된 컨설턴트
        name: '',
        chnlCustId: '',
        lv_HeaderviewScrollCompID: '',
        isLoading: false,
        tcompKcisSc: '1', // 당사 신용정보원 구분

        value1: '',
        slctTabIdx : 1, // 현재 선택된 탭 인덱스 값
        btn_index: '',

        contData: [
        ],
        custData: [
        ],
        contCnt: 0,
        totMonAmt: 0,
        totAlpadPrmAmt: 0,
        contCovrList: {},
        stdCovr: {},
        covanDtm: new Date(),
        visibleIndex: 0,
        
        // hideIndicator: true

        // 보장분석시스템 고도화 프로젝트 관련 변수 추가
        gtplCllgHoshVO: [], // 세대정보 VO
        gtplHoshInfoList: [], // 세대정보 List
        kcisSrvcStrHoshList: [], // 신용정보원 송신대상 세대정보 List
        kcisReciCnfHoshList: [], // 신용정보원 수신확인대상 세대정보 List
        selAdmSrvcStatChkVO: {}, // 관리자 보험가입한도 서비스의 운영/중지 상태 조회 결과 VO
        curPrcsHoshIdx: -1, // 현재 수행 중인 세대원 Index
        curPrcsCnt: -1, // 현재 수행 중인 건수
        curYearMmDdHh: '', // 현재일시
        msgCovrAnlyConfirm: '', // 보장분석확인 메시지
        msgKcisError: '', // 신용정보원 오류응답 관련 메시지
        msgAdmSrvcStatChk: '', // 관리자 보험한도서비스 상태체크 메시지

        loadingMsg: '',
        loadingSubMsg: '',

        selRldmEntData: {}, // 실손가입조회 데이터

        msgCovrAnlyConfirmIsFirstFlag: true, // 보장분석확인 confirm 창 처음여부 flag
        showMsgYn: '', // 안내메시지 출력여부

        state: '',
        interval: '',
        count: 0,

        mndtCnstgCnsntYn: '' // 필컨여부

      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      TSSCT005MParam: function () {
        // 고객 기본정보
        let returnValue = this.getStore('ctStore').getters.getCustBaseInfo
        return returnValue
      },
      params () {
        console.log('this.$route.params >>> ', this.$route.params)
        if(this.$route.params != null && this.$route.params.chnlCustId != null){
          return this.$route.params
        }else{
          console.log('computed this.TSSCT005MParam >>> ', this.TSSCT005MParam)
          if(this.TSSCT005MParam != null && this.TSSCT005MParam.data != null && !this.$bizUtil.isEmpty(this.TSSCT005MParam.data.chnlCustId)){
            return this.TSSCT005MParam.data
          }else{
            this.$bizUtil.ctUtils.fn_confirm(this, {single: true, content: '고객정보가 없습니다.', fn_pos: this.fn_ClickBtnPrevious})
          }
        }
      }
    },
    watch: {
      slctTabIdx(v){
        this.$refs.tabbox.setIdx(v)
      },
      tcompKcisSc(v){
        this.getStore('ctStore').dispatch('TCOMPKCISSC_REG', v)
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      // window.vue.getStore('progress').dispatch('NONE')
      window.vue.getStore('progress').dispatch('INIT')

      // backButton event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickBtnPrevious)

      console.log('created this.params >>> ', this.params)

      this.$bizUtil.ctUtils.fn_tmpAftSrchCust(this)

      this.showMsgYn = this.params.showMsgYn || ''
      
      console.log('created : 27M' , this.getStore('ctStore').getters.getCustBaseInfo)
      this.name = this.getStore('ctStore').getters.getCustBaseInfo.data.custNm
      this.chnlCustId = this.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId
      let lv_Vm = this

      
      // 보장분석시스템 고도화 프로젝트 관련 수정 - 보장플랜기계약보장분석조회 함수 호출 전 신용정보원 계약상세정보 통합 프로세스 실행
      lv_Vm.msgCovrAnlyConfirm = lv_Vm.$t('ct')['ECTC049']
      let t_CstInfo = lv_Vm.getStore('ctStore').getters.getCustBaseInfoMdfF

      console.log('t_CstInfo >>>> ', t_CstInfo)
      if ( t_CstInfo ) { // 실제 보장분석조회가 서버처리(재조회)될때만 실행
        // 현재일시 조회 - 한도조회 서비스 운영/중지 상태 판단하기 위한 현재일시
        lv_Vm.$bizUtil.ctUtils.fn_SyncSrvTime(lv_Vm).then(function (resTime) {
          lv_Vm.curYearMmDdHh = resTime.toString().replace(/[^A-Za-z0-9\+\/]/g, '').substr(0, 10)
          console.log('lv_Vm.curYearMmDdHh >> ', lv_Vm.curYearMmDdHh)

          lv_Vm.fn_PrcsKcisContDtlUnty()
        })
      } else {
        lv_Vm.fn_SelCovrAnly()
      }

      //ga매니저 권한
      this.vGssAuthYN = this.getStore('cmStore').getters.getState.gssAuthYN
    },
    mounted () {
      this.$bizUtil.insSrnLog(this.$options.screenId)

      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

      this.$bizUtil.ctUtils.fn_setVm(this)
    },
    beforeDestroy () {
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickBtnPrevious)
    },
    beforeRouteLeave (to, from, next) {
      if (CtConstants.ignoreReload.covan.indexOf(to.name) > -1) {
        next()
      } else {
        this.$destroy()
        // 세대원 정보 초기화
        this.getStore('ctStore').dispatch('HSMBREG', {'data': ''})
        this.getStore('ctStore').dispatch('UNOTCOMREG', {'data': ''})
        this.getStore('ctStore').dispatch('CUSTREGMDF_F', true) // 기계약 최신화를 위한 강제셋팅
        next()
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_TabChangeHandler(idx, idx2){      
        this.slctTabIdx = idx
        if(this.slctTabIdx == 3){
          this.$refs.refMSPCT113D.fn_initTSSCT006MLoadData(idx2)
        }
      },
      fn_beForeCloseSubMenu(fn) {
        this.btn_index = ''
        console.log('this.btn_index >> ', this.btn_index)
        fn()
      },
      fn_btnSubMenu (str, bool, attr) {
        // if(!bool) this.btn_index = str

        this.fn_close('MSPCT110M_B01')

        switch(str) {
          case '1' : // 실손가입 조회
            this.lv_AlertPop = this.$bottomModal.open(MSPCT130P, {
              properties: {
                pSelRldmEntData: this.selRldmEntData
              },
              listeners: {
                onPopupClose: () => {
                  this.$bottomModal.close(this.lv_AlertPop)
                }
              }
            },
            {
              properties: {
                noHeader: true
              }
            })
            break
            case '2' : // 타사증권 입력
              this.lv_AlertPop = this.$bottomModal.open(MSPCT140P, {
                properties: {
                  pSelRldmEntData: this.selRldmEntData
                },
                listeners: {
                  onPopupSave: () => {
                    console.log('적용되면 갱신시켜줘야함!!!')
                    this.$bottomModal.close(this.lv_AlertPop)
                    this.fn_SelRcalCovrAnly() // 재조회
                  },
                  onPopupClose: () => {
                    console.log('그냥종료!!!')                    
                    this.$bottomModal.close(this.lv_AlertPop)
                  }
                }
              },
              {
                properties: {
                  noHeader: true
                }
              })
              break
          case '3' : // 권장금액 선택
            this.lv_AlertPop = this.$bottomModal.open(MSPCT150P, {
                properties: {
                  pAttr: attr
                },
                listeners: {
                  onPopupSave: () => {
                    this.fn_reloadData() // 재조회
                    this.$bottomModal.close(this.lv_AlertPop)
                    // this.fn_close('MSPCT110M_B01')
                  },
                  onPopupClose: () => {
                    this.$bottomModal.close(this.lv_AlertPop)
                  }
                }
              },
              {
                properties: {
                  noHeader: true
                }
              })
            break
          case '4' : // 보장설계 발송
            this.fn_openMSPCT160P()
            break
          case '5' : // 당사계약 정보 출처 선택
            this.fn_open('MSPCT110M_B02')
            break
          case '6' : // 연금계산기
            this.$router.push({name:'MSPCT300M', params: {custNm: this.name, chnlCustId: this.chnlCustId}})
            break
        }
      },
      fn_tcompKcisSc() {
        this.fn_SelRcalCovrAnly()
        this.fn_close('MSPCT110M_B02')
        this.fn_close('MSPCT110M_B01')
      },
      fn_close(btsNm) {
        this.$refs[btsNm].close()
      },
      fn_open(btsNm) {
        this.$refs[btsNm].open()
      },
      fn_MoveMSPPI001M() {
        this.$router.push({ name: 'MSPPI001M', params: {zaAgtFileId: this.chnlCustId} })
      },
      fn_ClickBtnPrevious () {
        console.log('fn_ClickBtnPrevious >>>> ', this.$route.params)
        if(this.$route.params != null && this.$route.params.screenNumber === 'MOPI_PNPQ00100M'){
          this.$router.push({ name: 'MSPPI001M', params: {uuid: new Date().getTime()} })
        }else{
          this.$router.go(-1)
        }
      },
      fn_ContCnt: function () {
        this.custData.contCnt = this.contCnt = this.contData.length
      },
      fn_totMonAmt: function () {
        let lvTotMonAmt = 0
        for (let i=0; i<this.contData.length; i++) {
          let lvPadCylCd = this.contData[i].padCylCd

          let lvSmtotPrm = this.contData[i].smtotPrm.longAmount
          let lvPadParngTotPrm = this.contData[i].padParngTotPrm.longAmount
          // console.log('lvSmtotPrm' + lvSmtotPrm)
          // 일시납인 보험상품은 제외 && 향후 납입보험료가 0이상인 것
          if ( lvPadCylCd !== '99' && Number(lvPadParngTotPrm) > 0 ) {
            // 2월납,3월납,6월납 ->월납 보험료로  수정
            if ( lvPadCylCd === '02' ) {
              lvSmtotPrm = parseInt( lvSmtotPrm / 2 )
            } else if ( lvPadCylCd === '03' ) {
              lvSmtotPrm = parseInt( lvSmtotPrm / 3 )
            } else if ( lvPadCylCd === '06' ) {
              lvSmtotPrm = parseInt( lvSmtotPrm / 6 )
            } else if ( lvPadCylCd === '12' ) {
              lvSmtotPrm = parseInt( lvSmtotPrm / 12 )
            }
            lvTotMonAmt += Number(lvSmtotPrm)
          }
        }
        this.totMonAmt = lvTotMonAmt
        this.custData.totMonAmt = this.totMonAmt
      },
      fn_totAlpadPrmAmt: function () {
        let lvTotAlpadPrmAmt = 0

        for (let i=0; i<this.contData.length; i++) {
          lvTotAlpadPrmAmt += Number(this.contData[i].alpadTotPrm.longAmount)
        }
        this.totAlpadPrmAmt = lvTotAlpadPrmAmt
        this.custData.totAlpadPrmAmt = this.totAlpadPrmAmt
      },
      /*****************************************************************************************************************************************
       * Function명 : fn_PrcsKcisContDtlUnty
       * 설명       : 신용정보원 계약상세정보 통합 프로세스
       *              통합 프로세스 진행 중 오류 발생 시 보장플랜기계약보장분석조회(fn_SelCovrAnly)로 넘어가도록 함
       *              진행 순서
       *              1. 관리자 보험가입한도 서비스의 운영/중지 상태 조회
       *                 (중지상태인 경우 안내메시지 출력 및 보장플랜기계약보장분석조회 호출을 위한 정보)
       *              2. 세대원 조회
       *              3. 세대원 중 필수컨설팅 Y인 세대원 신용정보원 대외계서비스 계약상세정보 내역확인 및 송신 처리
       *                 (1) 필수컨설핑 Y인 세대원의 고객 신용정보원 계약상세정보 통신정보 확인(이력 존재여부 및 수신정상여부 확인)
       *                 (2) 통신정보 미존재 및 비정상 내역 존재 시 전문관리번호 생성, 신용정보원 대외계서비스 계약상세정보 송신처리
       *                 (3) 관리자 보험가입한도 서비스 중지 상태인 경우 한도조회개인정보로깅(TB_ICEPD95) 테이블에만 이력 쌓음
       *                     서비스 중지 상태인 경우에도 보장설계를 완료한 것으로 보는 기준이 되는 정보(신계약(가입설계) 프로세스 진행가능하도록 하기 위함)
       *                 세대원 중 필수컨설팅 N인 세대원 타사계약정보 중 신용정보원 계약 초기화 처리(필컨Y->N 변경 되는 경우 대비 기존 데이터 초기화)
       *              4. 신용정보원 대외계서비스 계약상세정보 수신 확인 및  처리
       *                 (1) 신용정보원 대외계서비스 계약상세정보 송신처리를 한 세대원의 신용정보원 계약상세정보 통신정보 확인(수신정상여부 확인)
       *                 (2) 통신정보 정상 내역 존재 시 신용정보원 계약상세정보를 보장설계 업무영역인 타사계약정보, 타사보장내역 테이블에 이관
       *                 (3) 세대원 당 수신확인 완료 시 까지 최대 10회 반복하여 수행
      *********************************************************************************************************************************************/
      fn_PrcsKcisContDtlUnty () {
        // 보장분석시스템 고도화 프로젝트 관련 변수 초기화
        this.gtplCllgHoshVO = [] // 세대정보 VO
        this.gtplHoshInfoList = [] // 세대정보 List
        this.kcisSrvcStrHoshList = [] // 신용정보원 송신대상 세대정보 List
        this.kcisReciCnfHoshList = [] // 신용정보원 수신확인대상 세대정보 List
        this.selAdmSrvcStatChkVO = {} // 관리자 보험가입한도 서비스의 운영/중지 상태 조회 결과 VO
        this.curPrcsHoshIdx = -1 // 현재 수행 중인 세대원 Index
        this.curPrcsCnt = -1 // 현재 수행 중인 건수
        this.msgAdmSrvcStatChk = '' // 관리자 보험한도서비스 상태체크 메시지
        this.msgKcisError = '' // 신용정보원 오류응답 관련 메시지
        this.isLoading = false // progress 메시지 화면 노출여부
        this.loadingMsg = '' // progress 메시지
        this.loadingSubMsg = '' // progress sub 메시지

        // 세대원조회
        this.fn_getServiceData('S0')
      },
      /******************************************************************************
      * Function명  : fn_getServiceData
      * 설명        : 서비스종류의 이름을 작성하여 switch문을 이용하여 분기한다
      * Params      : serviceNm - 서비스명
      ******************************************************************************/
      fn_getServiceData (serviceNm) {
        let lv_Vm = this
        let pParams = {
          chnlCustId: this.TSSCT005MParam.data.chnlCustId,
          custNm: this.TSSCT005MParam.data.custNm
        }
        let trnstId = ''
        let auth = 'S'

        
        // GA 매니저 대행 컨설턴트 변경시
        if(this.$cmUtil.fn_GachangeTF()){
          pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo // 10번째 호출까지 제대로 셋팅되는지 확인!!!!!!
        }

        //매니저가 컨설턴트를 변경했을경우
        this.stndKeyList = []
        if(this.$cmUtil.fn_GachangeTF()){
          this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
        }
        if ( serviceNm === 'S0' ) { // 관리자 보험가입한도 서비스의 운영/중지 상태 조회
          console.log('>>>> S0 // 관리자 보험가입한도 서비스의 운영/중지 상태 조회')
          // pParams = {}
          // trnstId = 'txTSSCT39S1'
          trnstId = 'txTSSCT56S1'
          this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
            // 성공여부 확인
            if ( response.msgComm.msgCd === 'EFWS003' ) { // 정상 메시지코드
              // 콜백함수 호출
              lv_Vm.fn_getServiceDataResult(serviceNm, response.body)
            } else {
              lv_Vm.fn_SetProgressMsgPop(false)

              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)

              // 오류 발생 시 기존 보장분석 프로세스 실행
              lv_Vm.fn_SelAcontCovrAnly()
            }
          }).catch(function (error) {
            // 오류 발생 시 메시지 처리 및 기존 보장분석 프로세스 실행
            lv_Vm.fn_SetProgressMsgPop(false)
            window.vue.error(error)
            lv_Vm.fn_SelAcontCovrAnly()
          })
        } else if ( serviceNm === 'S1' ) { // 세대원 조회
          console.log('>>>> S1 // 세대원 조회')
          pParams = {
            mnCustId: this.TSSCT005MParam.data.chnlCustId
          }
          // 세대원 정보 초기화하여 세대원 정보 다시 조회하도록 유도
          this.getStore('ctStore').dispatch('HSMBREG', {'data': ''})
          this.fn_SetProgressMsgPop(true, 'ECTC057', 'ECTC059')
          this.$bizUtil.ctUtils.fn_SelGtplHsmbInfo(lv_Vm, pParams).then(function (response) {
            if ( !response || !response.gtplCllgCustVO || response.gtplCllgCustVO.length <= 0 ) {
              // 세대원 조회결과 미존재 시 기존 보장분석 프로세스 실행
              lv_Vm.fn_SetProgressMsgPop(false)
              window.vue.error('세대원 조회결과 없음')
              lv_Vm.fn_SelCovrAnly()
            } else {
              // 콜백함수 호출
              lv_Vm.fn_getServiceDataResult(serviceNm, response)
            }
          }).catch(function (error) {
            // 오류 발생 시 기존 보장분석 프로세스 실행
            lv_Vm.fn_SetProgressMsgPop(false)
            window.vue.error(error)
            lv_Vm.fn_SelCovrAnly()
          })
          
        } else if ( serviceNm === 'S2' ) { // (신정원) 첫번째 호출
          console.log('>>>> S2 : selCustInsrLmtChkInfo')
          trnstId = 'txTSSCT27S1'
          this.fn_SetProgressMsgPop(true, 'ECTC056', 'ECTC059')
          this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
            // 성공여부 확인
            if ( response.msgComm.msgCd === 'EFWS003' ) { // 정상 메시지코드
              // 콜백함수 호출
              lv_Vm.fn_getServiceDataResult(serviceNm, response.body)
            } else {
              lv_Vm.fn_SetProgressMsgPop(false)

              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)

              // 오류 발생 시 기존 보장분석 프로세스 실행
              lv_Vm.fn_SelAcontCovrAnly()
            }
          }).catch(function (error) {
            // 오류 발생 시 메시지 처리 및 기존 보장분석 프로세스 실행
            lv_Vm.fn_SetProgressMsgPop(false)
            window.vue.error(error)
            lv_Vm.fn_SelAcontCovrAnly()
          })
        } else if ( serviceNm === 'S3' ) { // 두번째 호출
          console.log('>>>> S3 : tlgmMngNoInsrt')
          trnstId = 'txTSSCT46S1'
          this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
            // 성공여부 확인
            if ( response.msgComm.msgCd === 'EFWS003' ) { // 정상 메시지코드
              // 콜백함수 호출
              lv_Vm.fn_getServiceDataResult(serviceNm, response.body)
            } else {
              lv_Vm.fn_SetProgressMsgPop(false)

              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)

              // 오류 발생 시 기존 보장분석 프로세스 실행
              lv_Vm.fn_SelAcontCovrAnly()
            }
          }).catch(function (error) {
            // 오류 발생 시 메시지 처리 및 기존 보장분석 프로세스 실행
            lv_Vm.fn_SetProgressMsgPop(false)
            window.vue.error(error)
            lv_Vm.fn_SelAcontCovrAnly()
          })
        } else if (serviceNm === 'S4') { // 세번재 호출
          console.log('>>>> S4 : prcsInitDtInpt')
          trnstId = 'txTSSCT31S1'
          this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
            // 성공여부 확인
            if ( response.msgComm.msgCd === 'EFWS003' ) { // 정상 메시지코드
              // 콜백함수 호출
              lv_Vm.fn_getServiceDataResult(serviceNm, response.body)
            } else {
              lv_Vm.fn_SetProgressMsgPop(false)

              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)

              // 오류 발생 시 기존 보장분석 프로세스 실행
              lv_Vm.fn_SelAcontCovrAnly()
            }
          }).catch(function (error) {
            // 오류 발생 시 메시지 처리 및 기존 보장분석 프로세스 실행
            lv_Vm.fn_SetProgressMsgPop(false)
            window.vue.error(error)
            lv_Vm.fn_SelAcontCovrAnly()
          })
        } else if (serviceNm === 'S5') { // 네번재 호출
          console.log('>>>> S5 : prcsExerSrvcStr')
          trnstId = 'txTSSCT32S1'
          this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
            // 성공여부 확인
            if ( response.msgComm.msgCd === 'EFWS003' ) { // 정상 메시지코드
              // 콜백함수 호출
              lv_Vm.fn_getServiceDataResult(serviceNm, response.body)
            } else {
              lv_Vm.fn_SetProgressMsgPop(false)

              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)

              // 오류 발생 시 기존 보장분석 프로세스 실행
              lv_Vm.fn_SelAcontCovrAnly()
            }
          }).catch(function (error) {
            // 오류 발생 시 메시지 처리 및 기존 보장분석 프로세스 실행
            lv_Vm.fn_SetProgressMsgPop(false)
            window.vue.error(error)
            lv_Vm.fn_SelAcontCovrAnly()
          })
        } else if (serviceNm === 'S6') { // 다섯번째 호출
          console.log('>>>> S6 : tlgmMngNoInsrtCont')
          trnstId = 'txTSSCT47S1'
          this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
            // 성공여부 확인
            if ( response.msgComm.msgCd === 'EFWS003' ) { // 정상 메시지코드
              // 콜백함수 호출
              lv_Vm.fn_getServiceDataResult(serviceNm, response.body)
            } else {
              lv_Vm.fn_SetProgressMsgPop(false)

              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)

              // 오류 발생 시 기존 보장분석 프로세스 실행
              lv_Vm.fn_SelAcontCovrAnly()
            }
          }).catch(function (error) {
            // 오류 발생 시 메시지 처리 및 기존 보장분석 프로세스 실행
            lv_Vm.fn_SetProgressMsgPop(false)
            window.vue.error(error)
            lv_Vm.fn_SelAcontCovrAnly()
          })
        } else if (serviceNm === 'S7') { // 여섯번째 호출
          console.log('>>>> S7 : prcsExerSrvcStrCont')
          trnstId = 'txTSSCT48S1'
          this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
            // 성공여부 확인
            if ( response.msgComm.msgCd === 'EFWS003' ) { // 정상 메시지코드
              // 콜백함수 호출
              lv_Vm.fn_getServiceDataResult(serviceNm, response.body)
            } else {
              lv_Vm.fn_SetProgressMsgPop(false)

              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)

              // 오류 발생 시 기존 보장분석 프로세스 실행
              lv_Vm.fn_SelAcontCovrAnly()
            }
          }).catch(function (error) {
            // 오류 발생 시 메시지 처리 및 기존 보장분석 프로세스 실행
            lv_Vm.fn_SetProgressMsgPop(false)
            window.vue.error(error)
            lv_Vm.fn_SelAcontCovrAnly()
          })
        } else if (serviceNm === 'S8') { // 일곱번째 호출
          console.log('>>>> S8 : tlgmMngNoInsrtContDtl')
          trnstId = 'txTSSCT72S1'
          this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
            // 성공여부 확인
            if ( response.msgComm.msgCd === 'EFWS003' ) { // 정상 메시지코드
              // 콜백함수 호출
              lv_Vm.fn_getServiceDataResult(serviceNm, response.body)
            } else {
              lv_Vm.fn_SetProgressMsgPop(false)

              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)

              // 오류 발생 시 기존 보장분석 프로세스 실행
              lv_Vm.fn_SelAcontCovrAnly()
            }
          }).catch(function (error) {
            // 오류 발생 시 메시지 처리 및 기존 보장분석 프로세스 실행
            lv_Vm.fn_SetProgressMsgPop(false)
            window.vue.error(error)
            lv_Vm.fn_SelAcontCovrAnly()
          })
        } else if (serviceNm === 'S9') { // 여덟번째 호출
          console.log('>>>> S9 : prcsExerSrvcStrContDtl')
          let pParams = {
            chnlCustId: this.kcisSrvcStrHoshList[this.curPrcsHoshIdx].chnlCustId,
            custNm: this.kcisSrvcStrHoshList[this.curPrcsHoshIdx].custNm,
            gtplCllgCustVO: this.kcisSrvcStrHoshList[this.curPrcsHoshIdx].gtplCllgCustVO
          }
          // GA 매니저 대행 컨설턴트 변경시
          if(this.$cmUtil.fn_GachangeTF()){
            pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo // 10번째 호출까지 제대로 셋팅되는지 확인!!!!!!
          }
          trnstId = 'txTSSCT73S1'
          this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
            // 성공여부 확인
            if ( response.msgComm.msgCd === 'EFWS003' ) { // 정상 메시지코드
              // 콜백함수 호출
              lv_Vm.fn_getServiceDataResult(serviceNm, response.body)
            } else {
              lv_Vm.fn_SetProgressMsgPop(false)

              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)

              // 오류 발생 시 기존 보장분석 프로세스 실행
              lv_Vm.fn_SelAcontCovrAnly()
            }
          }).catch(function (error) {
            // 오류 발생 시 메시지 처리 및 기존 보장분석 프로세스 실행
            lv_Vm.fn_SetProgressMsgPop(false)
            window.vue.error(error)
            lv_Vm.fn_SelAcontCovrAnly()
          })
        } else if (serviceNm === 'S10') { // 아홉번째 호출
          console.log('>>>> S10 : selCustInsrEntInfoInqr')
          trnstId = 'txTSSCT33S1'
          this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
            if ( response.msgComm.msgCd === 'EFWS003' ) { // 정상 메시지코드
              // 콜백함수 호출
              lv_Vm.fn_getServiceDataResult(serviceNm, response.body)
            } else {
              lv_Vm.fn_SetProgressMsgPop(false)

              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)

              // 오류 발생 시 기존 보장분석 프로세스 실행
              lv_Vm.fn_SelAcontCovrAnly()
            }
          }).catch(function (error) {
            // 오류 발생 시 메시지 처리 및 기존 보장분석 프로세스 실행
            lv_Vm.fn_SetProgressMsgPop(false)
            window.vue.error(error)
            lv_Vm.fn_SelAcontCovrAnly()
          })
        }
      },
      /******************************************************************************
      * Function명  : fn_getServiceDataResult
      * 설명        : 서비스종류의 이름을 작성하여 switch문을 이용하여 분기하여 콜백처리
      * Params      : serviceNm - 서비스명
      *               dataVO   - 처리결과 data
      ******************************************************************************/
      fn_getServiceDataResult (serviceNm, dataVO) {
        switch (serviceNm) {
          case 'S0':
            if ( dataVO !== null ) {
              this.selAdmSrvcStatChkVO = dataVO || {}
            }
            console.log(this.selAdmSrvcStatChkVO)
            console.log('관리자 보험가입한도 서비스의 운영/중지 상태 조회 완료!')
            this.curPrcsHoshIdx = 0

            // 관리자 보험가입한도 서비스 운영중지 상태일 경우 안내메시지 처리
            if (this.selAdmSrvcStatChkVO.useYn === 'N' && (this.selAdmSrvcStatChkVO.strDtm <= this.curYearMmDdHh && this.selAdmSrvcStatChkVO.endDtm >= this.curYearMmDdHh)) { // 운영중지 체크
              // 보장설계 완료 후 노출 될 메시지 설정
              this.msgKcisError = this.$t('ct')['ECTC050']

              // 서비스 중지 안내메시지 설정
              // this.msgAdmSrvcStatChk = this.$t('ct')['ECTC058']
              this.msgAdmSrvcStatChk = this.$t('ct')['ECTC063'] // // 현업 이미지프로 요청으로 메시지 수정 및 관리자 서비스 운영/중지 로그 저장(TB_ICEPD95)
              // let tmpMsg = ''
              // if (this.selAdmSrvcStatChkVO.strDtm.length === 10) {
              //   tmpMsg = this.selAdmSrvcStatChkVO.strDtm.substring(0, 4) + '년 ' + this.selAdmSrvcStatChkVO.strDtm.substring(4, 6) + '월 ' + this.selAdmSrvcStatChkVO.strDtm.substring(6, 8) + '일 ' + this.selAdmSrvcStatChkVO.strDtm.substring(8, 10) + '시'
              //   tmpMsg += '~\n'
              // }
              // if (this.selAdmSrvcStatChkVO.endDtm.length === 10) {
              //   tmpMsg += this.selAdmSrvcStatChkVO.endDtm.substring(0, 4) + '년 ' + this.selAdmSrvcStatChkVO.endDtm.substring(4, 6) + '월 ' + this.selAdmSrvcStatChkVO.endDtm.substring(6, 8) + '일 ' + this.selAdmSrvcStatChkVO.endDtm.substring(8, 10) + '시'
              // }
              // this.msgAdmSrvcStatChk = this.msgAdmSrvcStatChk + '<br/>' + tmpMsg
              this.msgAdmSrvcStatChk = this.msgAdmSrvcStatChk
              let lv_vm = this
              this.$bizUtil.ctUtils.fn_confirm(lv_vm, {
                single: true,
                content: lv_vm.msgAdmSrvcStatChk,
                fn_pos: lv_vm.fn_msgAdmSrvcStatShowCallback
              })
            } else {
              // this.curPrcsHoshIdx = 0
              this.fn_getServiceData('S1')
            }
            
            break
          case 'S1':
            // 세대원정보 설정
            this.gtplCllgHoshVO = dataVO
            if ( this.gtplCllgHoshVO !== null && this.gtplCllgHoshVO.gtplCllgCustVO ) {
              this.gtplHoshInfoList = this.gtplCllgHoshVO.gtplCllgCustVO || []
            }
            console.log(this.gtplHoshInfoList)
            console.log('세대원 조회 완료')

            // 세대원 정보 초기화하여 세대원조회 팝업화면에서 세대원조회 다시 하도록 유도함(보장설계일자 재조회를 위해)
            this.getStore('ctStore').dispatch('HSMBREG', {'data': ''})
            // 세대원 중 신용정보원 송신대상 고객 설정
            for ( let i = 0; i < this.gtplHoshInfoList.length; i++ ) {
              // 신용정보원 송신대상 고객 List에 Set (필컨 Y인 고객은 송신관련 처리를, N인 고객은 신정원 타사계약 초기화 관련 처리를 수행함)
              this.kcisSrvcStrHoshList.push({
                'chnlCustId': this.gtplHoshInfoList[i].chnlCustId, // 채널고객 ID
                'custNm': this.gtplHoshInfoList[i].custNm, // 고객명
                'mndtCnstgCnsntYn': this.gtplHoshInfoList[i].gtplCllgCustInfoPrcusCnsntVO.mndtCnstgCnsntYn, // 필수컨설팅
                'gtplCllgCustVO': this.gtplHoshInfoList[i]
              })

              console.log('>>>> chnlCustId : ', this.TSSCT005MParam.data.chnlCustId , '   ' , this.gtplHoshInfoList[i].chnlCustId)
              if(this.TSSCT005MParam.data.chnlCustId == this.gtplHoshInfoList[i].chnlCustId){
                this.mndtCnstgCnsntYn = this.gtplHoshInfoList[i].gtplCllgCustInfoPrcusCnsntVO.mndtCnstgCnsntYn
                console.log(this.mndtCnstgCnsntYn)
              }
            }
            console.log('>>>> kcisSrvcStrHoshList ', this.kcisSrvcStrHoshList)
            if ( this.kcisSrvcStrHoshList[this.curPrcsHoshIdx].mndtCnstgCnsntYn === 'Y' ) {
              console.log('필컨 :: ' + this.kcisSrvcStrHoshList[this.curPrcsHoshIdx].mndtCnstgCnsntYn)

              // 신용정보원 대외계서비스 계약상세정보 송신 처리 대상이 없을 경우 기존의 보장분석조회 프로세스 호출
              if ( this.kcisSrvcStrHoshList.length <= 0 ) {
                // 보장플랜기계약보장분석조회
                this.fn_SelAcontCovrAnly()
              } else {
                this.fn_getServiceData('S2')
              }
            } else {
              // 보장플랜기계약보장분석조회
              this.fn_SelAcontCovrAnly()
            }

            break
          case 'S2':
            // 관리자 보험가입한도 서비스의 운영/중지 상태 check - 중지상태 일 경우 기존의 보장분석조회 프로세스 호출
            if (this.selAdmSrvcStatChkVO.useYn === 'N' && (this.selAdmSrvcStatChkVO.strDtm <= this.curYearMmDdHh && this.selAdmSrvcStatChkVO.endDtm >= this.curYearMmDdHh)) { // 운영중지 체크
              console.log('관리자 보험가입한도 서비스 중지 상태')
              console.log(this.selAdmSrvcStatChkVO)

              // 보장플랜기계약보장분석조회
              this.fn_SelAcontCovrAnly()
              // 서비스 중지 상태 일 경우 보장분석조회도 임시로 정지시킴
              // 추후 보장분석조회 기능 다시 살릴 예정(살릴 경우 위의 주석 해제하고 여기 로직 삭제)
              // 가입설계 페이지로 이동함
              // this.fn_MoveMSPPI001M()
              return
            }
            if (dataVO.pCustYn !== 'Y') {
              // this.getStore('confirm').dispatch('ADD', '[조회 권한이 없습니다.]')
              console.log('[조회 권한이 없습니다 :: ' + dataVO.pCustYn)
            } else if (dataVO.exrsTlgmTrnsStatCd === 'E') {
              console.log('전문관리번호 생성 실패 :: ' + dataVO.exrsTlgmTrnsStatCd)
            } else if (dataVO.exrsTlgmTrnsStatCd === 'F') {
              // this.getStore('confirm').dispatch('ADD', '[보장급부데이터가 없습니다.]')
              console.log('보장급부데이터가 없습니다 :: ' + dataVO.exrsTlgmTrnsStatCd)
            } else if (dataVO.exrsTlgmTrnsStatCd === 'Y') { // 대외계 서비스가 시작
              this.fn_getServiceData('S3')
            } else {
              if (dataVO.trnsYn !== 'Y') {
                console.log('전문정보가 존재않거나 송수신결과코드가 오류(8) 인 경우')
              } else {
                if(this.mndtCnstgCnsntYn == 'Y'){
                  let lv_vm = this
                  this.fn_reqRldmEntInqr(this).then( function (response) {
                    // 보장플랜기계약보장분석조회
                    lv_vm.fn_SelAcontCovrAnly()
                  })
                }else{
                  // 보장플랜기계약보장분석조회
                  this.fn_SelAcontCovrAnly()
                }
              }
            }
            break
          case 'S3':
            if (dataVO.exrsTlgmTrnsStatCd === 'E') {
              console.log('전문관리번호 생성 실패 :: ' + dataVO.exrsTlgmTrnsStatCd)
            } else {
              this.fn_getServiceData('S4')
            }
            break
          case 'S4':
            this.fn_getServiceData('S5')
            break
          case 'S5':
            if (dataVO.trnsYn === 'N') {
              console.log('전문정보가 존재않거나 송수신결과코드가 오류(8) 인 경우')
            } else {
              this.fn_getServiceData('S6')
            }
            break
          case 'S6':
            if (dataVO.exrsTlgmTrnsStatCd === 'E') {
              console.log('전문관리번호 생성 실패 :: ' + dataVO.exrsTlgmTrnsStatCd)
            } else {
              this.fn_getServiceData('S7')
            }
            break
          case 'S7':
            if (dataVO.exrsTlgmTrnsStatCd === 'E') {
              console.log('전문관리번호 생성 실패 :: ' + dataVO.exrsTlgmTrnsStatCd)
            } else {
              this.fn_getServiceData('S8')
            }
            break
          case 'S8':
            if (dataVO.exrsTlgmTrnsStatCd === 'E') {
              console.log('전문관리번호 생성 실패 :: ' + dataVO.exrsTlgmTrnsStatCd)
            } else {
              // 신용정보원 대외계서비스 계약상세정보 송신 처리 시작
              this.curPrcsHoshIdx = 0
              this.fn_getServiceData('S9')
            }
            break
          case 'S9':
            if (dataVO.trnsYn === 'N') {
              console.log('전문정보가 존재않거나 송수신결과코드가 오류(8) 인 경우')
            } else {
              this.curPrcsCnt = 1
              this.fn_getServiceData('S10')
            }
            break
          case 'S10':
            if (dataVO.exrsTlgmTrnsStatCd !== 'Y') { // N이 반환 되기 전까지 반복 조회
              if (dataVO.trnsYn !== 'Y') {
                console.log('전문정보가 존재않거나 송수신결과코드가 오류(8) 인 경우')

                // 신용정보원 통신 오류 관련 안내메시지 설정
                this.msgKcisError = this.$t('ct')['ECTC050']

                if(this.mndtCnstgCnsntYn == 'Y'){
                  let lv_vm = this
                  this.fn_reqRldmEntInqr(this).then( function (response) {
                    // 보장플랜기계약보장분석조회
                    lv_vm.fn_SelAcontCovrAnly()
                  })
                }else{
                  // 보장플랜기계약보장분석조회
                  this.fn_SelAcontCovrAnly()
                }
              } else {
                console.log('전문정보 송수신결과코드가 정상')

                if(this.mndtCnstgCnsntYn == 'Y'){
                  let lv_vm = this
                  this.fn_reqRldmEntInqr(this).then( function (response) {
                    // 보장플랜기계약보장분석조회
                    lv_vm.fn_SelAcontCovrAnly()
                  })
                }else{
                  // 보장플랜기계약보장분석조회
                  this.fn_SelAcontCovrAnly()
                }
              }
            } else {
              if ( this.curPrcsCnt >= 10 ) {
                // 10회까지 반복 수행했을 경우
                console.log('10회까지 반복 수행 완료')

                if(this.mndtCnstgCnsntYn == 'Y'){
                  let lv_vm = this
                  this.fn_reqRldmEntInqr(this).then( function (response) {
                    // 보장플랜기계약보장분석조회
                    lv_vm.fn_SelAcontCovrAnly()
                  })
                }else{
                  // 보장플랜기계약보장분석조회
                  this.fn_SelAcontCovrAnly()
                }
              } else {
                // 수신확인 재수행
                this.curPrcsCnt++
                this.fn_getServiceData('S10')
              }
            }
            break
          default:
            break
        }
      },
      /******************************************************************************
      * Function명 : fn_SelCovrAnly
      * 설명       : 보장플랜기계약보장분석조회
      ******************************************************************************/
      fn_SelCovrAnly (idx) {
        let lv_Vm = this
        // let pParams = this.TSSCT005MParam.data // 사용되지 않은 변수여서 추석 처리(eslint에 걸림) - 보장분석시스템 고도화 PJT
        // let trnstId = 'txTSSCT03S1' // 사용되지 않은 변수여서 추석 처리(eslint에 걸림) - 보장분석시스템 고도화 PJT

        this.getStore('ctStore').dispatch('TCOMPKCISSC_REG', this.tcompKcisSc)
        
        console.log('>>>> fn_SelCovrAnly start~~')
        this.fn_SetProgressMsgPop(true, 'ECTC057', 'ECTC061')
        this.$bizUtil.ctUtils.fn_PrmsRgstdt(lv_Vm, false).then( function (response) {
          console.log('fn_SelCovrAnly fn_PrmsRgstdt response >>> ', response)
          lv_Vm.fn_SetProgressMsgPop(false)

          // 보장분석 기준 유효한 고객정보인 경우
          if ( response.body && response.body.gtplCllgHoshVO &&
              response.body.gtplCllgHoshVO.gtplCllgCustVO && response.body.gtplCllgHoshVO.gtplCllgCustVO !== null ) {

            lv_Vm.$nextTick(() => {
              lv_Vm.fn_SelRcalCovrAnly(idx)
            })
          } else {
            if ( response && response.body && response.body.gtplCllgHoshVO &&
              !response.body.gtplCllgHoshVO.gtplCllgCustVO) { // 서비스는 정상이지만, 보장분석 기준 유효한 고객정보가 없는 경우
              // 보장분석 기준 유효한 고객정보가 아닌경우 '동의처리가 유효하지 않습니다.~' 알림 메세지 처리 후, 이전 컨설팅 초기화면으로 이동
              let msgRegConfirm = lv_Vm.$t('ct')['ECTC044']
              lv_Vm.fn_confirmOk(msgRegConfirm) // 확인버튼 창 팝업
            } else {
              if ( response.msgComm.msgCd !== 'EFWS003' ) {
                // 그외 업무 에러코드는 U-Partner 서비스 수행후 에러 메세지를 그대로 뿌린다.
                // 에러코드 : ECIU109 는 고객중 자녀의 보험연령이 부모의 보험연령보다 많을 때는 오류 (U-Partner 서비스 오류) : // 자녀로 등록된 OOO 고객님의 연령이 OO세입니다. 세대구성 및 주민번호(을)를 확인하십시요.
                let msgRegConfirm = '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc
                lv_Vm.fn_confirmOk(msgRegConfirm) // 확인버튼 창 팝업
                // return // 불필요 구분이라 주석 처리(eslint에 걸림) - 보장분석시스템 고도화 PJT
              }
            }
          }
        }).catch(function (error) {
          lv_Vm.getStore('ctStore').dispatch('CUSTREGMDF_F', true)
          window.vue.error(error)
        })
      },
      /******************************************************************************
      * Function명 : fn_SelAcontCovrAnly
      * 설명       : 보장플랜기계약보장분석조회 성능개선
      ******************************************************************************/
      fn_SelAcontCovrAnly () {
        let lv_Vm = this

        this.getStore('ctStore').dispatch('TCOMPKCISSC_REG', this.tcompKcisSc)

        console.log('>>>> fn_SelAcontCovrAnly start~~')
        this.fn_SetProgressMsgPop(true, 'ECTC057', 'ECTC061')
        this.$bizUtil.ctUtils.fn_SelAcontCovrAnlyInfo(lv_Vm, false).then( function (response) {
          console.log('fn_SelAcontCovrAnly fn_SelAcontCovrAnlyInfo response.body >>> ', response.body)
          lv_Vm.fn_SetProgressMsgPop(false)

          // 보장분석 기준 유효한 고객정보인 경우
          if ( response.body && response.body.gtplCllgHoshVO &&
              response.body.gtplCllgHoshVO.gtplCllgCustVO && response.body.gtplCllgHoshVO.gtplCllgCustVO !== null ) {
            
            lv_Vm.$nextTick(() => {
              lv_Vm.fn_SelRcalCovrAnly()
            })
          } else {
            if ( response && response.body && response.body.gtplCllgHoshVO &&
              !response.body.gtplCllgHoshVO.gtplCllgCustVO) { // 서비스는 정상이지만, 보장분석 기준 유효한 고객정보가 없는 경우
              // 보장분석 기준 유효한 고객정보가 아닌경우 '동의처리가 유효하지 않습니다.~' 알림 메세지 처리 후, 이전 컨설팅 초기화면으로 이동
              let msgRegConfirm = lv_Vm.$t('ct')['ECTC044']
              lv_Vm.fn_confirmOk(msgRegConfirm) // 확인버튼 창 팝업
            } else {
              if ( response.msgComm.msgCd !== 'EFWS003' ) {
                // 그외 업무 에러코드는 U-Partner 서비스 수행후 에러 메세지를 그대로 뿌린다.
                // 에러코드 : ECIU109 는 고객중 자녀의 보험연령이 부모의 보험연령보다 많을 때는 오류 (U-Partner 서비스 오류) : // 자녀로 등록된 OOO 고객님의 연령이 OO세입니다. 세대구성 및 주민번호(을)를 확인하십시요.
                let msgRegConfirm = '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc
                lv_Vm.fn_confirmOk(msgRegConfirm) // 확인버튼 창 팝업
                // return // 불필요 구분이라 주석 처리(eslint에 걸림) - 보장분석시스템 고도화 PJT
              }
            }
          }
        }).catch(function (error) {
          lv_Vm.fn_SetProgressMsgPop(false)
          lv_Vm.getStore('ctStore').dispatch('CUSTREGMDF_F', true)
          window.vue.error(error)
        })
      },
      fn_confirmOk(msgRegConfirm) {
        this.$bizUtil.ctUtils.fn_alert(this, {content: msgRegConfirm, title_pos_btn: '확인'})
      },
      /******************************************************************************
      * Function명  : fn_msgAdmSrvcStatShowCallback
      * 설명        : 보험가입한도 서비스 운영중지 상태 메시지 노출 콜백함수
      * Params
      ******************************************************************************/
      fn_msgAdmSrvcStatShowCallback () {
        // 세대원 조회
        this.fn_getServiceData('S1')
      },
      /******************************************************************************
      * Function명  : fn_SetProgressMsgPop
      * 설명        : 보장분석 Progress 화면 설정
      * Params      : pShowFlag - Progress 화면 설정 Flag
      *               pMsg - Progress 화면 내 Msg
      ******************************************************************************/
      fn_SetProgressMsgPop(pShowFlag, pMsg, pSubMsg) {
        this.isLoading = pShowFlag
        this.loadingMsg = this.$t('ct')[pMsg]
        this.loadingSubMsg = this.$t('ct')[pSubMsg]
      },
      
      /******************************************************************************
      * Function명 : fn_selTSSCT003M
      * 설명       : 기계약현황 조회(세대중심)
      ******************************************************************************/
      fn_selTSSCT003M () {
        let lv_prmSelf = this
        this.$bizUtil.ctUtils.fn_HoshACont(lv_prmSelf)

        // 기계약 항목 화면 표시
        this.contData = [].concat(lv_prmSelf.getStore('ctStore').getters.getHoshContData.data)
        console.log('fn_HoshACont getHoshContData.data >>> ', this.contData)

        this.fn_ContCnt()
        this.fn_totMonAmt()
        this.fn_totAlpadPrmAmt()
      },
      /************************************************************************************************
       * Function명 : fn_baseAcont
       * 설명       : 기계약조회 조회(계약자포함)
      ************************************************************************************************/
      fn_baseAcont () {
        let lv_prmSelf = this
        this.$bizUtil.ctUtils.fn_DrwRgstdt(lv_prmSelf)

        console.log('fn_DrwRgstdt getContData.data >>> ', lv_prmSelf.getStore('ctStore').getters.getContData.data)

        this.$refs.refMSPCT112D.changeData()
      },
      /************************************************************************************************
       * Function명 : fn_selTSSCT009M
       * 설명       : 설계후보장분석
      ************************************************************************************************/
      fn_selTSSCT009M () {
        let lv_prmSelf = this
        this.$bizUtil.ctUtils.fn_planAfCovan(lv_prmSelf)
      },
      /******************************************************************************
      * Function명  : fn_OpenCovrAnlyConfirm
      * 설명        : 보장분석안내메시지 제어 함수
      * Params      : 가입설계 바로가기 보장설계 계속하기 분기 태우는 팝업 인듯 추후 수정
      ******************************************************************************/
      fn_OpenCovrAnlyConfirm () {
        console.log('msgCovrAnlyConfirmIsFirstFlag :: ' + this.msgCovrAnlyConfirmIsFirstFlag)
        console.log('planId :: ' + this.planId)
        console.log(this.mndtCnstgCnsntYn)
        
        // 필수컨설팅 없는 경우 메시지
        if (this.mndtCnstgCnsntYn !== 'Y') {
          this.msgCovrAnlyConfirm = this.$t('ct')['ECTC000']
        }

        let lv_ShowMsgYn = this.showMsgYn || ''
        console.log('showMsgYn :: ' + this.showMsgYn)
        // 최초 화면 진입 시에만 출력
        if ( this.msgCovrAnlyConfirmIsFirstFlag === true ) {
          this.msgCovrAnlyConfirmIsFirstFlag = false
          // 안내메시지 출력여부에 따라 메시지 출력
          if ( lv_ShowMsgYn !== undefined && lv_ShowMsgYn !== null && lv_ShowMsgYn === 'Y' ) {
            if ( this.msgKcisError !== '' && this.kcisReciCnfHoshList.length > 0 ) {
              this.msgCovrAnlyConfirm = this.msgKcisError + '<br/>' + this.msgCovrAnlyConfirm
            }
            this.$refs.MSPCT110M_B04.open()
          } else if ( this.msgKcisError !== '' && this.kcisReciCnfHoshList.length > 0 ) { // 보험가입한도 서비스 중지 시 안내메시지 출력(신용정보원 대외계 연동한 고객이 있는 경우에만)
            this.getStore('confirm').dispatch('ADD', this.msgKcisError)
            console.log('this.msgKcisError >>>>>', this.msgKcisError)
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_SelRcalCovrAnly
      * 설명       : 기계약보장분석재계산(최신정보불러오기)
      ******************************************************************************/
      fn_SelRcalCovrAnly (idx) {
        // 보장요약탭이 활성화가 되어있지 않은 상태에서 차트를 그리면 안그려지는 현상으로 차트탭으로 이동 차트 그려진 이후 원래 탭으로 복귀 강제 셋팅
        if(this.slctTabIdx !== 1){
          this.slctTabIdx = 1
        }
        let lv_Vm = this

        let custInfo = this.TSSCT005MParam.data
        let lv_PostPrm = {
          chnlCustId: custInfo.chnlCustId,
          custNm: custInfo.custNm,
          cnsltNo: custInfo.cnsltNo
        }
        // GA 매니저 대행 컨설턴트 변경시
        if(this.$cmUtil.fn_GachangeTF()){
          lv_PostPrm.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
        }

        this.getStore('ctStore').dispatch('TCOMPKCISSC_REG', this.tcompKcisSc)

        console.log('기계약보장분석재계산(최신정보불러오기) 시작')
        this.fn_SetProgressMsgPop(true, 'ECTC057', 'ECTC061')
        this.getStore('ctStore').dispatch('CUSTREGMDF_F', true)
        this.$bizUtil.ctUtils.fn_rePrmsRgstdt(lv_Vm, lv_PostPrm).then(function (response) {
          lv_Vm.fn_SetProgressMsgPop(false)
          console.log('기계약보장분석재계산(최신정보불러오기) 리턴')
          // 보장분석요약 조회
          lv_Vm.$refs.refMSPCT111D.fn_selTSSCT005M()

          // 기계약현황 조회(세대중심)
          lv_Vm.fn_selTSSCT003M()

          // 기계약조회 조회(계약자포함)
          lv_Vm.fn_baseAcont()

          // 설계후 보장분석
          lv_Vm.fn_selTSSCT009M()

          // 고객 나이를 세팅(고객파트에서 파라미터로 주는 나이가 보험나이가 아니여서 보험나이로 다시 세팅함)
          let gtplCllgCustVO = response.body.gtplCllgHoshVO.gtplCllgCustVO
          let custBaseInfo = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data
          gtplCllgCustVO.some( function (custInfo, custIdx) {
            let condition = custInfo.chnlCustId === custBaseInfo.chnlCustId
            if (condition) {
              custBaseInfo.age = custInfo.insrAge
              lv_Vm.getStore('ctStore').dispatch('CUSTREG', {'data': custBaseInfo})
              return (condition) // some내에서는 for문의 break와 같은 역할
            }
          })
          // 보장분석 진입시 TTSC00004 인서트
          lv_Vm.$bizUtil.ctUtils.fn_covanAnlyHisIns(lv_Vm)

          // 보장분석 관련 안내메시지
          lv_Vm.fn_OpenCovrAnlyConfirm()
          
          console.log('기계약보장분석재계산(최신정보불러오기) 종료')

          // 보장요약탭이 활성화가 되어있지 않은 상태에서 차트를 그리면 안그려지는 현상으로 차트탭으로 이동 차트 그려진 이후 원래 탭으로 복귀 강제 셋팅
          if(idx === 2 || idx === 3){
            setTimeout( function () {
              lv_Vm.slctTabIdx = idx
            }, 300)
          }
        }).catch(function (error) {
          lv_Vm.fn_SetProgressMsgPop(false)
          window.vue.error(error)
        })
      },
      fn_moveDetail(idx) {
        this.fn_TabChangeHandler(3, idx)
      },
      fn_reqRldmEntInqr() {
        let lv_Vm = this
        return new Promise(function (resolve, reject) {
          console.log('========================== 실손가입예약신청 ===================================================')
          lv_Vm.fn_SetProgressMsgPop(true, 'ECTC064', 'ECTC059')
          // 실손가입예약신청 I/F
          lv_Vm.$bizUtil.ctUtils.fn_reqRldmEntInqr(lv_Vm, true, true).then( function(result) {
            console.log('실손조회 완료', lv_Vm.selRldmEntData)
            lv_Vm.fn_SetProgressMsgPop(false)
            lv_Vm.selRldmEntData = result
            resolve(result)
          }).catch(function (error) {
            lv_Vm.fn_SetProgressMsgPop(false)
            //window.vue.error(error)
            reject(error)
          })
        })
      },
      fn_openCT120P() {
        let lp_Vm = this  
        this.popupCT120P = this.$bottomModal.open(MSPCT120P, {
          properties: {
            pPage: 'MSPCT110M'
          },
          listeners: {
            onPopupSearch:() => {
              lp_Vm.$bottomModal.close(lp_Vm.popupCT120P)
            },
            onPopupClose:() => {
              lp_Vm.$bottomModal.close(lp_Vm.popupCT120P)
            },
            onMovePlan:() => {
              lp_Vm.$bottomModal.close(lp_Vm.popupCT120P)
              lp_Vm.fn_MoveMSPPI001M()
            },
            openSubMenu:(data) => {
              lp_Vm.$bottomModal.close(lp_Vm.popupCT120P)
              setTimeout( function () {
                lp_Vm.$nextTick(() => {
                  if(typeof(data) === 'object'){
                    lp_Vm.fn_btnSubMenu(data.dvcd, true, data.attr)
                  }else{
                    lp_Vm.fn_btnSubMenu(data, true)
                  }
                })
              }, 500)
            }
          }
        },
        {
          properties: {
            noHeader: true
          }
        })
      },
      fn_reloadData() {
        this.fn_SelCovrAnly(this.slctTabIdx)
      },
      fn_openMSPCT160P() {
        this.lv_AlertPop = this.$bottomModal.open(MSPCT160P, {
            properties: {
            },
            listeners: {
              onPopupSave: () => {
                this.$bottomModal.close(this.lv_AlertPop)
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.lv_AlertPop)
              }
            }
          },
          {
            properties: {
              noHeader: true
            }
          })
      },
      /******************************************************************************
       * Function명 : fn_OpenCustNmUiCard
       * 설명       : 고객네임카드
       * 
       ******************************************************************************/
      fn_OpenCustNmUiCard () {
        let nmUiObject = {
          chnlCustId : this.chnlCustId, //고객채널 ID
          parentId   : 'MSPCT110M', //화면ID+
          cnsltNo : this.getStore('userInfo').getters.getUserInfo.cnsltNo,
          custNm : this.name,
          contNo : ''
        }

        this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
          //Close 콜백 처리
          return
        })
      }

      
    }
  }
</script>
<style scoped>
</style>