 /*
 * 업무구분: 타사증권 입력
 * 화 면 명: MSPCT140P
 * 화면설명: 타사증권 입력
 * 접근권한:
 * 작 성 일: 2023.03
 * 작 성 자: 정지윤
 */
 <template>
  <ur-page-container ref="page" class="msp" :show-title="false" title="타사증권 입력" :topButton="true" :action-type="actionSlot" type="subpage">
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_stepBack">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '타사증권 입력' : '타사증권 입력'}}</div>
              <div slot="action" class="icon-wrapper">
                <mo-icon icon="close" class="fs26rem svg-closeTy1" @click="fn_openPopup(1)">close</mo-icon> <!-- Full 팝업(전체 팝업)이라 타이틀 오른쪽에 X버튼 제거 -->
              </div>
            </mo-top-bar>

            <ur-box-container alignV="start" direction="column" class="bd-b-Ty12">
              <!-- 진행단계 -->
              <ur-box-container alignV="start" direction="column" class="msp-step-type overflow-hidden">
                <div class="ing-content mlm34 mrm34 w100calc68">
                  <div :class="nowStepIdx == '1' ? 'ing-item checked' : 'ing-item completed'" @click="fn_TabChangeHandler(1)" v-if="entryType !== 'upd'"><!-- // checked completed  || entryType == 'upd'-->
                    <span class="step">1</span>
                    <mo-checkbox size="small"  class="com-check" checked="checked" />
                    <span class="list-step">상품선택</span>
                  </div>
                  <div :class="'ing-item'" v-if="entryType == 'upd'"> <!-- 등록상품 수정 시 비활성화 ing-item completed -->
                    <span class="step">1</span>
                    <mo-checkbox size="small"  class="com-check" checked="checked" />
                    <span class="list-step">상품선택</span>
                  </div>
                  <div :class="nowStepIdx == '2' ? 'ing-item checked' : nowStepIdx == '3' ? 'ing-item completed' : 'ing-item'" @click="fn_TabChangeHandler(2)">
                    <span class="step">2</span>
                    <mo-checkbox size="small"  class="com-check" checked="checked" />
                    <span class="list-step">기본정보</span>
                  </div>
                  <div :class="nowStepIdx == '3' ? 'ing-item checked' : 'ing-item'">
                    <span class="step">3</span>
                    <mo-checkbox size="small"  class="com-check" checked="checked" />
                    <span class="list-step">특약정보</span>
                  </div>
                </div>
              </ur-box-container>
              <!-- 진행단계 //-->
            </ur-box-container>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>       
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- Content영역 -->
            <template v-if="nowStepIdx === '1'">
              <!-- <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt30">  -->
              <ur-box-container alignV="start" componentid="" class="ns-input-box mt30">
                <b class="fs22rem">상품선택</b>
                <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt30">
                  <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                    <mo-segment-wrapper solid primary v-model="pdBtnIdx" class="chip-type-wrap">
                      <mo-segment-button value="1" v-show="entryType!=='unReg' && entryType!=='kcis'">등록상품</mo-segment-button>
                      <mo-segment-button value="2" v-show="entryType!=='kcis'">미등록상품</mo-segment-button>
                      <mo-segment-button value="2" v-show="entryType=='kcis'">신용정보원</mo-segment-button>
                    </mo-segment-wrapper>
                  </ur-box-container> 
                </ur-box-container> 
              </ur-box-container>  
            </template>
            <!-- <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30"> -->
            <ur-box-container alignV="start" componentid="" direction="column" class="">
              <MSPCT141D ref="refMSPCT141D" v-show="pdBtnIdx =='1' && nowStepIdx === '1'" @isSelectedItem="isSelectedItem" @scrollTop="scrollTop"/>
              <MSPCT142D ref="refMSPCT142D" v-show="pdBtnIdx =='1' && nowStepIdx === '2'" :vind141D="InfoMSPCT141D" @isContrNmParngIrt="isContrNmParngIrt"/>
              <MSPCT143D ref="refMSPCT143D" v-show="pdBtnIdx =='1' && nowStepIdx === '3'" :vind142D="Info" @isOtCompPrdtTrtyData="isOtCompPrdtTrtyData"/>
              <MSPCT144D ref="refMSPCT144D" v-show="pdBtnIdx =='2' && nowStepIdx === '1'" @isComPro="isComPro"/>
              <MSPCT145D ref="refMSPCT145D" v-show="pdBtnIdx =='2' && nowStepIdx === '2'" :vind144D="Data" @isContNumContractor="isContNumContractor"/>
              <MSPCT146D ref="refMSPCT146D" v-show="pdBtnIdx =='2' && nowStepIdx === '3'" />
            </ur-box-container>  
            <!-- Content 영역 end -->

            <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" v-show="entryType==''" @click="fn_openPopup(1)">취소</mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" v-show="nowStepIdx!=='3' && entryType!==''" @click="fn_openPopup(1)">취소</mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" v-show="nowStepIdx=='3' && entryType!==''" @click="fn_openPopup(2)">삭제</mo-button>

                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" v-show="nowStepIdx!=='3' && pdBtnIdx =='1'" @click="fn_nextBtn()" :disabled="disabled1">다음</mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" v-show="nowStepIdx!=='3' && pdBtnIdx =='2'" @click="fn_nextBtn()" :disabled="disabled2">다음</mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" v-show="nowStepIdx=='3' && entryType==''" @click="fn_finishBtn()">등록</mo-button> 
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" v-show="nowStepIdx=='3' && entryType!==''" @click="fn_openPopup(3)">수정</mo-button> 
              </div>
            </ur-box-container>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container> 

    <!-- Popup 취소확인 Alert -->
    <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
      <div class="customer-info-txt txt-center">
        <p class="ment" v-if="cancelAlert">증권등록을 중단하시겠습니까?<br/>변경한 정보는 저장되지 않습니다.</p>
        <p class="ment" v-if="deleteAlert">해당 보유계약을 삭제하시겠습니까?</p>
        <p class="ment" v-if="updateAlert">해당 보유계약을 수정하시겠습니까?</p>
        <p class="ment" v-if="selectAlert">특약을 입력해주세요.</p>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" v-if="!selectAlert" @click="close" name="취소">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" v-if="selectAlert" @click="close" name="확인">확인</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" name="중단" v-if="cancelAlert" @click="fn_exit()">중단</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" name="삭제" v-if="deleteAlert" @click="fn_delete()">삭제</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" name="수정" v-if="updateAlert" @click="fn_finishBtn()">수정</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

  </ur-page-container>
</template>
<script>
import UrBoxContainer from '@/_uidev/containers/box/urBoxContainer.vue'

import MSPCT141D from '@/ui/ct/MSPCT141D' // 상품선택(등록상품)
import MSPCT142D from '@/ui/ct/MSPCT142D' // 특약선택(등록상품)
import MSPCT143D from '@/ui/ct/MSPCT143D' // 특약선택(등록상품)
import MSPCT144D from '@/ui/ct/MSPCT144D' // 상품정보(미등록상품)
import MSPCT145D from '@/ui/ct/MSPCT145D' // 기본정보(미등록상품)
import MSPCT146D from '@/ui/ct/MSPCT146D' // 보장내역(미등록상품)
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT140P",
    screenId: "MSPCT140P",
    components: {
      UrBoxContainer,
      MSPCT141D,
      MSPCT142D,
      MSPCT143D,
      MSPCT144D,
      MSPCT145D,
      MSPCT146D,
     },
    props: {
      pEntryType: {
        type: String,
        default: ''
      },
      pSelOtcomInsr: {
        type: Object,
        default: () => {}
      },
      pContNoChnlCustId: {
        type: Object,
        default: () => {}
      }
    },
     // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      // 상단 검색, 카테고리 버튼 생성
      this.actionSlot = {
        actionComponent: {
          template:'<span><mo-button shape="border" class="ns-btn-round btn-title-style-1">보장설계</mo-button> <mo-icon icon-size="30px" type="picto" >more</mo-icon></span>',
          methods: {

          }
        }
      }

      this.entryType = this.pEntryType
      this.getSelOtcomInsr = this.pSelOtcomInsr
      console.log('pSelOtcomInsr>>>', this.pSelOtcomInsr)
      this.contNoChnlCustId = this.pContNoChnlCustId
      console.log('pContNoChnlCustId>>>', this.pContNoChnlCustId)
    },
    mounted () {
      this.$bizUtil.insSrnLog(this.$options.screenId)

     // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_HeaderviewScrollCompID: '',
        disabled1: true, // 다음, 등록 버튼(등록상품)
        disabled2: true, // 다음, 등록 버튼(미등록상품)
        cancelAlert: false, // 취소 확인 alert
        deleteAlert: false, // 삭제 확인 alert
        updateAlert: false, // 수정 확인 alert
        selectAlert: false, // 특약 입력 alert
        isPrdt: false, // 상품선택여부
        ischecked: false, // 등록상품 특약 선택여부
        pdBtnIdx: '1', // 1:등록상품, 2:미등록상품
        nowStepIdx: '1', // 현재 입력 단계 탭 인덱스 값
        nextBtnText: '다음', // 1:다음, 2:등록
        Info: {}, // MSPCT142D 입력정보
        Data: {}, // 미등록상품 회사명, 상품명 정보
        InfoMSPCT141D: {}, // 등록상품 MSPCT141D 입력정보

        validateRuleRequire1: [
          v => !!v || '보험사를 선택해 주세요'
        ],
        validateRuleRequire2: [
          v => !!v || '상품명을 입력해 주세요'
        ],

        entryType: '', // 진입 구분 값
        getSelOtcomInsr: {}, // 등록된 타사 정보 값
        contNoChnlCustId: {}
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      
    },
    
    /** watch 정의 영역 */
    watch: {
      entryType() {
        // 등록타사 수정or삭제
        if(this.entryType == 'upd') {
          this.pdBtnIdx = '1'
          this.nowStepIdx = '2'
          this.$refs.refMSPCT142D.fn_getOtInfo(this.getSelOtcomInsr, this.contNoChnlCustId)
        }
        // 미등록타사 수정or삭제
        if(this.entryType == 'unReg') {
          this.pdBtnIdx = '2'
          this.$refs.refMSPCT144D.fn_getOtInfo(this.getSelOtcomInsr, this.contNoChnlCustId)
        }
        // 신용정보원 수정or삭제
        if(this.entryType=='kcis') {
          this.pdBtnIdx = '2'
          this.$refs.refMSPCT144D.fn_getOtInfoKcis(this.getSelOtcomInsr, this.contNoChnlCustId)
        }
      },

      nowStepIdx() {
        this.scrollTop() // 페이지 최상단으로 스크롤링
      }
      
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : scrollTop
      * 설명       : 페이지 최상단으로 스크롤링
      ******************************************************************************/
      scrollTop() {
        this.$bizUtil.ctUtils.fn_PageContainerScrollTop(this.$refs.page)
      },

      /******************************************************************************
      * Function명 : 
      * 설명       : 다음/등록 버튼 활성화(비활성화) 여부
      ******************************************************************************/
      // 등록상품
      isSelectedItem(isSelectedItem) { // 상품 선택
        this.disabled1 = isSelectedItem
        this.isPrdt = !isSelectedItem
      },
      isContrNmParngIrt(isContrNmParngIrt) { // 계약자, 예정이율
        if(this.isPrdt && this.entryType !== 'upd') { // isSelectedItem()에서 다음버튼 활성화된 경우 (조건문 없으면 일반/유니버설 버튼 클릭 시 다음버튼 활성화됨)
          this.disabled1 = isContrNmParngIrt
        } else if(this.entryType === 'upd') {
          this.disabled1 = isContrNmParngIrt
        }
      },
      isOtCompPrdtTrtyData(checkCount) { // 특약 선택
        if(checkCount > 0) {
          this.ischecked = true
          this.selectAlert = false
        } else {
          this.ischecked = false
          this.selectAlert = true
        }
      },
      // 미등록상품
      isComPro(isComPro) { // 회사명, 상품명
        this.disabled2 = isComPro
      },
      isContNumContractor(isContNumContractor) { // 계약번호, 계약자
        this.disabled2 = isContNumContractor
      },
     
      /******************************************************************************
      * Function명 : fn_openPopup
      * 설명       : 증권등록 중단 확인 bottomsheet 열기
      ******************************************************************************/
      fn_openPopup(type) {
        // 취소 확인 Alert
        if(type == 1) {
          this.cancelAlert = true
          this.deleteAlert = false
          this.updateAlert = false
          this.selectAlert = false // test
          this.$refs.nsbottomsheet.open();  
        }
        // 삭제 확인 Alert
        if(type == 2){
          this.deleteAlert = true
          this.cancelAlert = false
          this.updateAlert = false
          this.selectAlert = false // test
          this.$refs.nsbottomsheet.open();  
        }
        // 수정 확인 Alert
        if(type == 3) {
          this.updateAlert = true
          this.deleteAlert = false
          this.cancelAlert = false
          this.$refs.nsbottomsheet.open();
        }
        },

      /******************************************************************************
      * Function명 : close
      * 설명       : 증권등록 중단 확인 bottomsheet 닫기
      ******************************************************************************/
      close() { 
        this.$refs.nsbottomsheet.close(); 
        },

      /******************************************************************************
      * Function명 : fn_stepBack
      * 설명       : 이전 step으로 이동 및 저장 없이 팝업종료
      ******************************************************************************/
      fn_stepBack() {
        if(this.entryType == 'upd'){  // 등록상품 수정인 경우 step1 이동 불가
          if(this.nowStepIdx === '3'){
            this.nowStepIdx = '2'
          }else if(this.nowStepIdx === '2') {
            this.$emit('onPopupClose') 
            this.getStore('ctStore').dispatch('OTCOMPCONTDEL') 
            this.getStore('ctStore').dispatch('UNOTCOMREGDEL') // 미등록타사초기화
          }
        } else {
          if(this.nowStepIdx === '3'){
            this.nowStepIdx = '2'
          }else if(this.nowStepIdx === '2') {
            this.nowStepIdx = '1'
          }else {
            this.$emit('onPopupClose') 
            this.getStore('ctStore').dispatch('OTCOMPCONTDEL') 
            this.getStore('ctStore').dispatch('UNOTCOMREGDEL') // 미등록타사초기화
          }
        }
      },
      
      /******************************************************************************
      * Function명 : fn_exit
      * 설명       : 저장 없이 팝업종료
      ******************************************************************************/
      fn_exit(){
        this.$emit('onPopupClose') 
        this.getStore('ctStore').dispatch('OTCOMPCONTDEL') 
        this.getStore('ctStore').dispatch('UNOTCOMREGDEL') // 미등록타사초기화
      },

      /******************************************************************************
      * Function명 : save
      * 설명       : 저장 후 팝업종료
      ******************************************************************************/
      save() {
        this.$emit('onPopupSave') 
        this.getStore('ctStore').dispatch('OTCOMPCONTDEL')
        this.getStore('ctStore').dispatch('UNOTCOMREGDEL') // 미등록타사초기화
      },

      /******************************************************************************
      * Function명 : fn_TabChangeHandler
      * 설명       : 클릭 시 해당 진행 단계로 이동(현재보다 이후 단계 이동 불가)
      ******************************************************************************/
      fn_TabChangeHandler(moveTab) {
        // console.log(`야${moveTab}`)
        // 1 상품선택으로 이동
        if(moveTab == 1){
          this.nowStepIdx = '1'
          if(this.pdBtnIdx == '1' && this.isPrdt){
            this.disabled1 = false
          } else {
            this.disabled2 = false
          }
        }
        // 2 기본정보로 이동
        else {
          // 현재 step3일 때만
          if (this.nowStepIdx == '3') {
            this.nowStepIdx = '2'
            if(this.pdBtnIdx == '1'){
            this.disabled1 = false
          } else {
            this.disabled2 = false
          }
          }
        }
      },

      /******************************************************************************
      * Function명 : fn_nextBtn
      * 설명       : 다음 버튼 클릭 시 탭 이동
      ******************************************************************************/
      fn_nextBtn() {
        // 현재 탭 1이면
        if(this.nowStepIdx == '1'){ 
          // 등록상품  
          if(this.pdBtnIdx == '1') { 
            this.InfoMSPCT141D = {
            'otcomPrdtNm' : this.$refs.refMSPCT141D.selectedItem.name, // 타사상품명
            'otcomPrdtScCd' : this.$refs.refMSPCT141D.selectedItem.otcomPrdtScCd, // 
            'comyProfrOprcd' : this.$refs.refMSPCT141D.selectedItem.comyProfrOprcd, // 업체제공외부상품코드
            'CustInfo141' : {
              'srcCustId' : this.$refs.refMSPCT141D.select2.srcCustId, // 채널고객ID
              'chnlCustId' : this.$refs.refMSPCT141D.select2.srcCustId, // 등록시에는 채널고객ID와 동일, 수정 시 보험대상자를 변경하는 경우에 변경 전 보험대상자ID
              'insrCoNm': this.$refs.refMSPCT141D.select1.label, // 보험회사명
              'othInsrCoCd': this.$refs.refMSPCT141D.select1.key,// 타보험회사코드
              'gtplInsrScCd': this.$refs.refMSPCT141D.ProductGubun, // 보장플랜보험구분코드 '':일반, '03':유니버설
              'gtplOtcomPrdtInptScCd': this.pdBtnIdx, // 보장플랜타사상품입력구분코드 1 : 등록 타사 상품 2 : 미등록 타사상품
              }
            }
            this.nowStepIdx = '2'       // 2로 이동
            if(this.$refs.refMSPCT142D.contrNm!=='' && this.$refs.refMSPCT142D.parngIrt!=='') {
              this.disabled1 = false
            } else {
              this.disabled1 = true
            }
          }
          // 미등록상품
          if(this.pdBtnIdx == '2') { 
            if(this.entryType == 'unReg'){ // 미등록상품 수정
              this.Data = {
              'contractorCompany': this.$refs.refMSPCT144D.contractorCompany, // 가입회사
              'productName': this.$refs.refMSPCT144D.productName, // 상품명
              'gtplInsrScCd': this.$refs.refMSPCT144D.gtplInsrScCd, // 보장플랜보험구분코드 '':일반, '03':유니버설
              'insrdObjScCd': this.getSelOtcomInsr.body.insrdObjScCd, // 피보험자대상구분코드(01.주피, 02종피)
              'insrSecusNo': this.getSelOtcomInsr.body.insrSecusNo, // 보험증권번호 (계약번호)
              'contrNm': this.getSelOtcomInsr.body.contrNm, // 계약자명
              'contYm': this.getSelOtcomInsr.body.contYm, // 계약년월 (['2000','01'] -> '200001')
              'expYm': this.getSelOtcomInsr.body.expYm, // 만기년월
              'fprdDthTrtyExpYm': this.getSelOtcomInsr.body.fprdDthTrtyExpYm, // 정기사망특약만기년월
              'trtyExpYm': this.getSelOtcomInsr.body.trtyExpYm, // 특약만기년월
              'padCylCd': this.getSelOtcomInsr.body.padCylCd, // 납입주기코드
              'padCmpltYm': this.getSelOtcomInsr.body.padCmpltYm, // 납입완료년월
              'insurance': this.getSelOtcomInsr.body.prm.amount, // 보험료
              'entryType': this.entryType,
              'contNo': this.contNoChnlCustId.otcomContSno, // 계약번호
              // 'chnlCustId': this.contNoChnlCustId.chnlCustId
            }
            this.disabled2 = false
            } else if(this.entryType == 'kcis') { // 신용정보원 수정
              this.Data = {
              'contractorCompany': this.$refs.refMSPCT144D.contractorCompany, // 가입회사
              'productName': this.$refs.refMSPCT144D.productName, // 상품명
              'gtplInsrScCd': this.$refs.refMSPCT144D.gtplInsrScCd, // 보장플랜보험구분코드 '':일반, '03':유니버설
              'insrdObjScCd': this.getSelOtcomInsr.body.insrdObjScCd, // 피보험자대상구분코드(01.주피, 02종피)
              'insrSecusNo': this.getSelOtcomInsr.body.insrSecusNo, // 보험증권번호 (계약번호)
              'contrNm': this.getSelOtcomInsr.body.contrNm, // 계약자명
              'contYm': this.getSelOtcomInsr.body.contYm, // 계약년월 (['2000','01'] -> '200001')
              'expYm': this.getSelOtcomInsr.body.expYm, // 만기년월
              'fprdDthTrtyExpYm': this.getSelOtcomInsr.body.fprdDthTrtyExpYm, // 정기사망특약만기년월
              'trtyExpYm': this.getSelOtcomInsr.body.trtyExpYm, // 특약만기년월
              'padCylCd': this.getSelOtcomInsr.body.padCylCd, // 납입주기코드
              'padCmpltYm': this.getSelOtcomInsr.body.padCmpltYm, // 납입완료년월
              'insurance': this.getSelOtcomInsr.body.prm.amount, // 보험료
              'entryType': this.entryType,
              'contNo': this.contNoChnlCustId.otcomContSno, // 계약번호
              'kcisSecusNo' : this.getSelOtcomInsr.body.kcisSecusNo // 신용정보원증권번호
              // 'chnlCustId': this.contNoChnlCustId.chnlCustId
            }
            this.disabled2 = false
            }else { // 미등록상품 등록
              if(this.$refs.refMSPCT145D.contractorNumber!=='' && this.$refs.refMSPCT145D.contractor!=='') {
                this.disabled2 = false
              } else {
                this.disabled2 = true
              }
              this.Data = {
                'contractorCompany': this.$refs.refMSPCT144D.contractorCompany, // 가입회사
                'productName': this.$refs.refMSPCT144D.productName, // 상품명
                'gtplInsrScCd': this.$refs.refMSPCT144D.gtplInsrScCd, // 보장플랜보험구분코드 '':일반, '03':유니버설
                'contNo': '', // 계약번호
              }
            } 
            this.nowStepIdx = '2'       // 2로 이동            
          }
        }
        // 현재 탭 2이면
        else if(this.nowStepIdx == '2'){
          // 등록상품
          if(this.pdBtnIdx == '1') { 
            this.$refs.refMSPCT142D.fn_validationCheck() // 날짜 유효성 체크
            if(!this.$refs.refMSPCT142D.validationCheck) {
              return 
            }
            this.$refs.refMSPCT142D.fn_beforeMoveMSPCT143D()
            this.Info = {
              'contrNm': this.$refs.refMSPCT142D.contrNm,// 계약자
              'isdNM' : this.$refs.refMSPCT142D.isdNM, // 대상자명(주종피보험자명) 
              'insrdObjScCd' : this.$refs.refMSPCT142D.insrdObjScCd, // 피보험자대상구분코드 '01':주피, '02':종피
              'custContRltnCd': this.$refs.refMSPCT142D.custContRltnCd, // 고객계약관계코드 '12-계약자+주피, 13-계약자+종피, 21-주피보험자, 23-종피보험자
              'padCylCd' : this.$refs.refMSPCT142D.padCylCd, // 납입주기
              'contYm' : this.$refs.refMSPCT142D.contYm, // 계약년월
              'expYm' : this.$refs.refMSPCT142D.expYm, // 만기년월
              'padCmpltYm' : this.$refs.refMSPCT142D.padCmpltYm, // 납인완료년월
              'insurance' : this.$refs.refMSPCT142D.insurance, // 보험료
              'parngIrt' : this.$refs.refMSPCT142D.parngIrt, // 예정이율
              'entryType' : this.entryType
            }
            console.log('입력 정보 가져오기!!', this.Info)
            
            this.nowStepIdx = '3'          // 3으로 이동
            // this.disabled1 = true
          // 미등록상품
          } else { 
            this.$refs.refMSPCT145D.fn_validationCheck() // 날짜 유효성 체크
            if(!this.$refs.refMSPCT145D.validationCheck) {
              return 
            }
            this.$refs.refMSPCT145D.fn_setMSPCT145D() // 스토어 데이터 저장
            if(this.entryType == 'unReg' || this.entryType == 'kcis') { // 수정(미등록상품, 신용정보원)
              this.$refs.refMSPCT146D.fn_updateData(this.getSelOtcomInsr, this.entryType)
            }
            this.nowStepIdx = '3'          // 3으로 이동
            this.disabled2 = true
          }
        }
      },

      /******************************************************************************
      * Function명 : fn_finishBtn
      * 설명       : 등록/수정 버튼
      ******************************************************************************/
      fn_finishBtn() {        
        //보험정보 보장설계에 반영
        if(this.pdBtnIdx == '1') { // 등록상품
          if(this.ischecked) { // 특약 선택 시
            this.$refs.refMSPCT143D.fn_BtnI1OnClick()
            // 저장 후 팝업닫기
            this.save()
            //toast
            this.$addSnackbar("적용되었습니다.")
          } else {
            this.selectAlert = true
            this.cancelAlert = false  // test
            this.$refs.nsbottomsheet.open();
            // this.getStore('confirm').dispatch('ADD', '특약을 입력해주세요.')
          }
        } else {                   // 미등록상품
          console.log('미등록상품 저장/수정')
          this.$refs.refMSPCT146D.fn_BtnI1OnClick()
          // 저장 후 팝업닫기
          this.save()
          //toast
          this.$addSnackbar("적용되었습니다.")
        }
      },

      /******************************************************************************
      * Function명 : fn_delete
      * 설명       : 해당 보유계약 삭제 버튼
      ******************************************************************************/
      fn_delete() {
        let lv_Vm = this
        // let custInfo = lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data
        // let otCompContBasicInfo = lv_Vm.getStore('ctStore').getters.getOtCompCont.data // 값 undefined 확인 
        let pParams = {
          // cnsltNo: custInfo.cnsltNo,
          cnsltNo: lv_Vm.getSelOtcomInsr.body.cnsltNo,
          // chnlCustId: otCompContBasicInfo.chnlCustId,
          chnlCustId: lv_Vm.getSelOtcomInsr.body.chnlCustId,
          // otcomContSno: otCompContBasicInfo.otcomContSno,
          otcomContSno: lv_Vm.getSelOtcomInsr.body.otcomContSno,
        }
        let trnstId = ''
        let auth = 'D'

        // if (lv_Vm.scrpFlag) {
        //   // 스크래핑 삭제 (레거시 직접호출)
        //   trnstId = 'txTSSCT86D1'
        //   lv_Vm.$bizUtil.ctUtils.fn_DoTrz2SvrLegacy(lv_Vm, pParams, trnstId, auth).then(function (response) {
        //     console.log('####txTSSCT86D1', response)
        //     lv_Vm.fn_RefreshParent(lv_Vm, response, 'del')
        //   }).catch(function (error) {
        //     window.vue.error(error)
        //   })
        // } else {
          // 등록된 타사상품 삭제 (AP 호출)
          trnstId = 'txTSSCT15D1'
          this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
            console.log('####txTSSCT15D1 :: ', response)
            // lv_Vm.fn_RefreshParent(lv_Vm, response, 'del')
          }).catch(function (error) {
            window.vue.error(error)
          })
        // }

        // 저장 후 팝업닫기
        this.$emit('onPopupSave') 
        //toast
        this.$addSnackbar("삭제되었습니다.")
      }
      
    },


  };
</script>
<style scoped>
  /* .msp .msp-step-type .ing-content .ing-item.completed .com-check .mo-checkbox__icon {
    background-color: #dfdfdf;
  } */
</style>