<template>
  <ur-page-container class="msp" title="보장설계 보장현황" :show-title="false" :topButton="true">
    
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap pb50 bgcolor-1">
      <ur-box-container alignV="start" componentid="" direction="column" class="pl24 pb60">
        <ur-box-container direction="column" class="align-item-center mt30">
          <div class="fex-rw" @click="fn_openBs">
            <span class="fs18rem fwm">보장지수 준비도</span><mo-icon icon="msp-question" class="ml10">msp-question</mo-icon>
          </div>
          <span class="fs17rem crTy-blue3 fwb mt10 align-self-start">{{fn_getCovrRate()}}%</span>
          <div class="bar-chart--ty2 mt10">
            <div class="fill-bar" :style="{width:'calc('+fn_getCovrRate()+'%)'}"></div>
          </div>
        </ur-box-container>
        
        <ur-box-container direction="column" class="align-item-center mt30">
          <div class="fex-rw" @click="fn_openBs2">
            <span class="fs18rem fwm">{{clsfList.totCnt}}개 보장 중</span><mo-icon icon="msp-question" class="ml10">msp-question</mo-icon>
          </div>          
          <div class="guarantee mt10">
            <div class="item" @click="fn_moveByStat(0)">
              <mo-icon icon="emoticon2" class="fs28">emoticon2</mo-icon>
              <span class="mt10">우수 <em>{{clsfList.totStatusCnt1}}</em></span>
            </div>
            <div class="item" @click="fn_moveByStat(1)">
              <mo-icon icon="emoticon3" class="fs28">emoticon3</mo-icon>
              <span class="mt10">보통 <em>{{clsfList.totStatusCnt2}}</em></span>
            </div>
            <div class="item" @click="fn_moveByStat(2)">
              <mo-icon icon="emoticon4" class="fs28">emoticon4</mo-icon>
              <span class="mt10">부족 <em>{{clsfList.totStatusCnt3}}</em></span>
            </div>
            <div class="item" @click="fn_moveByStat(3)">
              <mo-icon icon="emoticon5" class="fs28">emoticon5</mo-icon>
              <span class="mt10">매우부족 <em>{{clsfList.totStatusCnt4}}</em></span>
            </div>
          </div>
        </ur-box-container>

        <ur-box-container direction="column" class="">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt30">
            <mo-segment-wrapper solid primary v-model="tabIndex" class="">
              <mo-segment-button value="1">급부별</mo-segment-button>
              <mo-segment-button value="2">상태별</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
          <ur-box-container direction="row" class="fexTy3 mt15 mb15">
            <div class="fexTy1">
              <span>전체펼침</span>
              <mo-switch v-model="allExpanded" small class="ns-switch ml10" v-if="tabIndex === '1'"/>
              <mo-switch v-model="allExpanded2" small class="ns-switch ml10" v-if="tabIndex === '2'"/>
            </div>
            <span class="crTy-bk7">(단위:만원)</span>
          </ur-box-container>          

          <!-- 급부별 -->
          <ur-box-container alignV="start" componentid="" direction="column" v-show="tabIndex == '1'">
            <mo-list-item v-for="(list,idx) in clsfList" :key="'benf'+idx" class="border-box--1"> 
              <msp-expand :ref="'refBenf'+idx" :expanded="false" btn-area-first title-first expand-only-btn class="mo-list-expand" btn-area-class="align-item-start">
                <template #title>
                  <div class="list-item__contents hauto box--2">
                    <div class="list-item__contents__title align-item-center mt5">
                      <span class="name txtSkip fs18rem">{{list.title}}</span><span class="fs14rem crTy-bk7 fwn ml10">{{list.columns[0].data.length}}개 보장</span>
                    </div>
                    <div class="full mt10 mb20">
                      <div class="guarantee ty--2 mt10 full">
                        <div class="item mhAuto">
                          <span>우수</span>
                          <span class="mt4 fs16rem crTy-blue4">{{list.columns[0].statusCnt1}}</span>
                        </div>
                        <div class="item mhAuto">
                          <span>보통</span>
                          <span class="mt4 fs16rem crTy-green1">{{list.columns[0].statusCnt2}}</span>
                        </div>
                        <div class="item mhAuto">
                          <span>부족</span>
                          <span class="mt4 fs16rem crTy-orange4">{{list.columns[0].statusCnt3}}</span>
                        </div>
                        <div class="item mhAuto">
                          <span>매우부족</span>
                          <span class="mt4 fs16rem crTy-red1">{{list.columns[0].statusCnt4}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template #btn>
                  <mo-button class="link-arrow down"></mo-button>
                </template>
                <template #content>
                  <!-- <mo-list :list-data="list.columns[0].data" class="">
                    <template #list-item="{item}"> -->
                      <mo-list-item v-for="(item,idx2) in list.columns[0].data" :key="'refBenfCont'+idx2" >
                        <div class="list-item__contents mb30" :class="{'ty-blue' : item.status === '1', 'ty-green' : item.status === '2', 'ty-orange' : item.status === '3', 'ty-red' : item.status === '4'}">
                          <div class="list-item__contents__info" tabindex="-1" :ref="'refBenfCont'+idx+idx2">
                            <div class="fexTy3" @click="fn_openDetail(item)">
                              <span class="fs16rem fwb">{{item.type}}</span>
                              <span class="fs14rem fwb cnt">{{$bizUtil.numberWithCommasByNumberCovr(item.custAmount - item.requireAmount, item)}}</span>
                            </div>
                            <div class="bar-chart--ty2 mt10">
                              <div class="fill-bar" :style="{width:'calc('+fn_calcChart(item)+'%)'}"></div>
                            </div>
                            <div class="fexTy3 mt6">
                              <span class="fs12rem crTy-bk7">권장 {{$bizUtil.numberWithCommasByNumber(item.requireAmount)}}</span>
                              <!-- <span class="fs14rem">{{item.custAmount == 0 ? '미가입' : '현재 ' + $bizUtil.numberWithCommasByNumber(item.custAmount)}}</span> -->
                              <span class="fs14rem">{{'현재 ' + $bizUtil.numberWithCommasByNumber(item.custAmount)}}</span>
                            </div>
                          </div>
                        </div>
                      </mo-list-item>
                    <!-- </template>
                  </mo-list> -->
                </template>
              </msp-expand>
            </mo-list-item>
          </ur-box-container>

          <!-- 상태별 -->
          <ur-box-container alignV="start" componentid="" direction="column" v-show="tabIndex == '2'">
            <mo-list-item v-for="(data,idx) in byStatList" :key="'byStat'+idx" class="border-box--1 ty--2"> 
              <msp-expand :ref="'refByStat'+idx" :expanded="false" btn-area-first title-first expand-only-btn class="mo-list-expand" btn-area-class="align-item-start">              
                <template #title>
                  <div class="list-item__contents hauto box--2">
                    <div class="list-item__contents__title align-item-center mt5">
                      <mo-icon :icon="idx == 0 ? 'emoticon2' : idx == 1 ? 'emoticon3' : idx == 2 ? 'emoticon4' : idx == 3 ? 'emoticon5' : ''" class="fs28">emoticon</mo-icon>
                      <span class="name txtSkip fs18rem ml10">{{idx == 0 ? '우수' : idx == 1 ? '보통' : idx == 2 ? '부족' : idx == 3 ? '매우부족' : ''}}</span>
                    </div>
                    <div class="list-item__contents__title align-item-center mt10">
                      <mo-badge v-if="data.length > 0" class="badge-box min40 sm" :class="{'lightblue': idx == 0, 'lightgreen': idx == 1, 'lightyellow': idx == 2, 'lightred': idx == 3}">{{data[0].type}}</mo-badge>
                      <span class="fs14rem crTy-bk7 fwn ml10" v-if="data.length > 0">{{data.length > 1 ? '등 ' : ''}}{{data.length}}개 보장</span>
                      <span class="fs14rem crTy-bk7 fwn ml10" v-else tabindex="-1" :ref="'refByStatContNoData'+idx">{{idx == 0 ? '우수' : idx == 1 ? '보통' : idx == 2 ? '부족' : idx == 3 ? '매우부족' : ''}} 상태의 보장이 없어요</span>
                    </div>
                  </div>
                </template>
                <template #btn>
                  <mo-button class="link-arrow down"></mo-button>
                </template>
                <template #content>
                  <div class="expand_topline mt20 mb20 margin--full"></div>
                  <!-- <mo-list :list-data="data"> -->
                      <!-- <template #list-item="{item, idx2}"> -->
                        <mo-list-item v-for="(item, idx2) in data" :key="'refByStatCont'+idx2">
                          <div class="list-item__contents mb30" :class="{'ty-blue' : idx == 0, 'ty-green' : idx == 1, 'ty-orange' : idx == 2, 'ty-red' : idx == 3}">
                            <div class="list-item__contents__info" tabindex="-1" :ref="'refByStatCont'+idx+idx2">
                              <div class="fexTy3" @click="fn_openDetail(item)">
                                <span class="fs16rem fwb">{{item.type}}</span>
                                <span class="fs14rem fwb cnt">{{$bizUtil.numberWithCommasByNumberCovr(item.custAmount - item.requireAmount, item)}}</span>
                              </div>
                              <div class="bar-chart--ty2 mt10">
                                <div class="fill-bar" :style="{width:'calc('+fn_calcChart(item)+'%)'}"></div>
                              </div>
                              <div class="fexTy3 mt6">
                                <span class="fs12rem crTy-bk7">권장 {{$bizUtil.numberWithCommasByNumber(item.requireAmount)}}</span>
                                <!-- <span class="fs14rem">{{item.custAmount == 0 ? '미가입' : '현재 ' + $bizUtil.numberWithCommasByNumber(item.custAmount)}}</span> -->
                                <span class="fs14rem">{{'현재 ' + $bizUtil.numberWithCommasByNumber(item.custAmount)}}</span>
                              </div>
                            </div>
                          </div>
                        </mo-list-item>
                      <!-- </template> -->
                    <!-- </mo-list> -->
                    <div class="footer--info">
                      <mo-icon icon="msp-tool-tip" class="ml24">msp-tool-tip</mo-icon>
                      <!-- <span class="ml10" v-if="idx == 0">우수:표준모델 대비 90% 이상</span>
                      <span class="ml10" v-if="idx == 1">보통:표준모델 대비 70~89% 이상</span>
                      <span class="ml10" v-if="idx == 2">부족:표준모델 대비 30~69% 이상</span>
                      <span class="ml10" v-if="idx == 3">매우부족:표준모델 대비 30% 미만</span> -->
                      <span class="ml10" v-if="idx == 0">우수:권장금액 대비 90% 이상</span>
                      <span class="ml10" v-if="idx == 1">보통:권장금액 대비 70~89% 이상</span>
                      <span class="ml10" v-if="idx == 2">부족:권장금액 대비 30~69% 이상</span>
                      <span class="ml10" v-if="idx == 3">매우부족:권장금액 대비 30% 미만</span>
                    </div>
                </template>
              </msp-expand>
            </mo-list-item>
          </ur-box-container>
          
        </ur-box-container>
        <!-- <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white">보장설계 발송</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue">상세 보장설계</mo-button>
          </div>
        </ur-box-container> -->
      </ur-box-container>

      <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet closebtn">
        <template v-slot:title>보장지수 준비도</template>
        <div class="ns-btn-close" @click="fn_closeBs"></div>

        <div class="content-area mhAuto">
          <div class="bottom_sheet_text">
            보장지수 준비도는 <span class="fs17rem crTy-blue4 fwb">우수</span><span class="fwb">X100,</span>
            <span class="fs17rem crTy-green1 fwb">보통</span><span class="fwb">X80,</span>
            <span class="fs17rem crTy-orange3 fwb">부족</span><span class="fwb">X50,</span>
            <span class="fs17rem crTy-orange2 fwb">매우부족</span><span class="fwb">X15</span>(이상 각 항목의 중앙값)을 곱하여 백분율로 변환하여 표기합니다.
          </div>
        </div>

        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_closeBs">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <mo-bottom-sheet ref="nsbottomsheet2" class="ns-bottom-sheet closebtn">
        <template v-slot:title>보장급부별 평가방법</template>
        <div class="ns-btn-close" @click="fn_closeBs2"></div>

        <div class="content-area mhAuto">
          <ul class="detail-bullet-blue">
            <li>
              <!-- 보장급부 39개는 보험업계 대표 보장 중 사망 4개, 재해장해 3개, 진단 15개, 수술 5개, 입원 5개, 통원 7개로 구성됩니다. -->
              보장급부 43개는 보험업계 대표 보장 중 사망 4개, 후유장해 2개, 진단 8개, 암치료 7개, 간병 5개, 수술 7개, 입원 7개, 생활보장 3개로 구성됩니다.
            </li>
            <li class="mt10">
              보장자산 권장금액 대비<br /> 
              <span class="fs17rem fwb">90%이상</span> <span class="fs17rem crTy-blue4 fwb">우수</span>,
              <span class="fs17rem fwb">70~89%</span> <span class="fs17rem crTy-green1 fwb">보통</span>, 
              <span class="fs17rem fwb">30~69%</span> <span class="fs17rem crTy-orange3 fwb">부족</span>, 
              <span class="fs17rem fwb">30%미만</span> <span class="fs17rem crTy-orange2 fwb">매우부족</span>으로 평가합니다.
            </li>
          </ul>
        </div>

        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_closeBs2">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <mo-bottom-sheet ref="nsbottomsheet3" class="ns-bottom-sheet closebtn">
        <template v-slot:title>{{pdtDetailItem.type}}</template>
        <div class="ns-btn-close" @click="fn_closeDetail"></div>  

        <ur-box-container direction="column" class="">
          <div class="pl24">
            <template v-if="pdtDetailItem.custAmount == 0">
              <span class="fs30rem fwb">미가입</span>
            </template>
            <template v-else>
              <span class="fs30rem fwb">
                {{$bizUtil.numberWithCommasByNumber(pdtDetailItem.custAmount)}}<em class="fs26rem em_normal--2">만원</em>
              </span>
              <span class="fs26rem"> 가입</span>
            </template>
          </div>
          <div class="pl24">
            <!-- <span class="fs14rem crTy-blue2" v-if="pdtDetailItem.custAmount == pdtDetailItem.requireAmount">표준모델과 동일</span> -->
            <span class="fs14rem crTy-blue2" v-if="pdtDetailItem.custAmount == pdtDetailItem.requireAmount">권장금액과 동일</span>
            <!-- <span class="fs14rem" :class="(pdtDetailItem.custAmount - pdtDetailItem.requireAmount) > 0 ? 'crTy-blue2' : 'crTy-red2'" v-else>표준모델 대비 -->
            <span class="fs14rem" :class="(pdtDetailItem.custAmount - pdtDetailItem.requireAmount) > 0 ? 'crTy-blue2' : 'crTy-red2'" v-else>권장금액 대비
              <em class="fwb em_normal--2">{{$bizUtil.numberWithCommasByNumberCovr(pdtDetailItem.custAmount - pdtDetailItem.requireAmount, pdtDetailItem)}}</em>만원
              {{(pdtDetailItem.custAmount - pdtDetailItem.requireAmount) > 0 ? '초과' : '부족'}}
            </span>
          </div>
          <div class="mhAuto full expand_topline mt20 maxH350">
            <mo-list :list-data="pdtDetailItem.data">
                <template #list-item="{item}">
                    <mo-list-item class="">
                        <div class="list-item__contents expand_bottomline">
                            <div class="list-item__contents__info"> 
                                <div class="list_content_date pal1524">
                                    <div class="fexTy3">
                                      <span class="fs17rem">{{item.prdtNm}}</span>
                                      <mo-badge class="badge-box min50 ml10 sm" shape="status" :class="!item.isOtherProduct ? 'lightblue' : item.gtplOtcomPrdtInptScCd === '4' ? 'lightpurple' : 'lightgray'">
                                        {{!item.isOtherProduct ? '당사' : item.gtplOtcomPrdtInptScCd === '4' ? '신정원' : '타사'}}
                                      </mo-badge>
                                    </div>
                                    <div class="fexTy3 mt10">
                                      <span class="fs14rem">{{item.insrCoNm}}</span>
                                      <span class="fs16rem fwb">{{item.value == 0 ? '-' : $bizUtil.numberWithCommasByNumber(item.value)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mo-list-item>
                </template>
            </mo-list>
          </div>
        </ur-box-container>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_closeDetail">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

    </ur-box-container> 
    <!-- Content 영역 end -->

  </ur-page-container>
</template>
<script>

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT113D",
    screenId: "MSPCT113D",
    components: {
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isEmpty: false,
        productList: [],
        custInfo: [],
        allExpanded: false,
        allExpanded2: false,
        tabIndex: '1',
        contractor: this.getStore('ctStore').getters.getCustBaseInfo.data.custNm,
        pdtDetailItem: { 
          type: '',
          custAmount: 0,
          requireAmount: 0,
          data: [] 
        },
        clsfList: [],
        byStatList: []
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      tableHeaderMock: function () {
        return this.$bizUtil.ctUtils.fn_GetTableTitles()
      },
      lv_CovrStnrd: function () {
        // 표준모델 > 권장금액
        let returnValue = this.getStore('ctStore').getters.getCustCovrStnrd
        return returnValue
      }
    },
    watch: {
      allExpanded(newVal, oldVal) {
        this.fn_expand(newVal)
      },
      allExpanded2(newVal, oldVal) {
        this.fn_expand(newVal)
      },
      // tabIndex(newVal, oldVal) {
      //   console.log('allExpanded >> ', this.allExpanded)
      //   if(newVal === '1'){
      //     this.fn_expand(this.allExpanded)
      //   }else{
      //     this.fn_expand(this.allExpanded2)
      //   }
      // }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_moveByStat(idx) {
        if(this.tabIndex !== '2'){
          this.tabIndex = '2'
        }
        let lv_Vm = this
        lv_Vm.$nextTick(() => {
          lv_Vm.$refs['refByStat'+idx][0].isExpand = true
          setTimeout(() => {
            if(lv_Vm.$refs['refByStatCont'+idx+'0'] != undefined && lv_Vm.$refs['refByStatCont'+idx+'0'][0] != undefined){
              lv_Vm.$refs['refByStatCont'+idx+'0'][0].focus()
            }else{
              lv_Vm.$refs['refByStatContNoData'+idx][0].focus()
            }
          }, 300)
        })
      },
      fn_getCovrRate() {
        if(this.clsfList.length > 0){
          let rateR = this.clsfList.totStatusCnt1*100/this.clsfList.totCnt
          let rateU = this.clsfList.totStatusCnt2*80/this.clsfList.totCnt
          let rateS = this.clsfList.totStatusCnt3*50/this.clsfList.totCnt
          let rateE = this.clsfList.totStatusCnt4*15/this.clsfList.totCnt
          
          return Math.round(Math.floor(rateR+rateU+rateS+rateE), 10)
        }
      },
      fn_expand(bool) {
        if(this.tabIndex == '1'){
          this.clsfList.forEach((item, idx) => {
            this.$refs['refBenf'+idx][0].isExpand = bool
          })
        }else if(this.tabIndex == '2'){
          this.byStatList.forEach((item, idx) => {
            this.$refs['refByStat'+idx][0].isExpand = bool
          })
        }
      },
      fn_calcChart(item) {
        let calc = (item.custAmount/item.requireAmount)*100
        if(Number.isNaN(calc)){
          calc = 0
        }else{
          if(calc >= 100){
            calc = 100
          }
        }
        return calc
      },
      fn_initTSSCT006MLoadData: function (idx) {
        let lv_TargetSelf = this
        Promise.all([this.$bizUtil.ctUtils.fn_PrmsRgstdt(lv_TargetSelf, true)])
          .then(function (response) {
            console.log('fn_initTSSCT006MLoadData response >> ', response)
            lv_TargetSelf.$bizUtil.ctUtils.fn_DrwRgstdt(lv_TargetSelf)
            lv_TargetSelf.isEmpty = false
            lv_TargetSelf.productList = []
            lv_TargetSelf.custInfo = []
            let dataBucket = []
            lv_TargetSelf.custInfo = lv_TargetSelf.$bizUtil.cloneDeep(lv_TargetSelf.getStore('ctStore').getters.getContData.data)
            lv_TargetSelf.custInfo.filter( x => { return x.mnisdNm === lv_TargetSelf.contractor || x.aisdNm === lv_TargetSelf.contractor })

            console.log('lv_TargetSelf.custInfo >> ', lv_TargetSelf.custInfo) 

            for (let custItem in lv_TargetSelf.custInfo) {
              lv_TargetSelf.productList.push(lv_TargetSelf.$bizUtil.ctUtils.fn_SettingListItem(lv_TargetSelf.contractor, lv_TargetSelf.custInfo[custItem], true, false, false))
            }

            console.log('lv_TargetSelf.productList >> ', lv_TargetSelf.productList) 

            if (lv_TargetSelf.productList.length !== 0) {
              lv_TargetSelf.isEmpty = true
            }

            console.log('lv_TargetSelf.isEmpty >> ', lv_TargetSelf.isEmpty) 

            let lv_TempArray = []
            lv_TempArray = lv_TempArray.concat(lv_TargetSelf.productList.slice())

            // 표준 모델을 노출시킨다.
            lv_TargetSelf.$bizUtil.ctUtils.fn_showStndModel(lv_TargetSelf.tableHeaderMock, lv_TargetSelf.lv_CovrStnrd)

            console.log('lv_TargetSelf.tableHeaderMock >> ', lv_TargetSelf.tableHeaderMock) 

            // 총 보장자산 노출
            dataBucket = lv_TargetSelf.$bizUtil.ctUtils.fn_CalcAmount(true, false, lv_TempArray) // 총보장자산 계산
            lv_TargetSelf.$bizUtil.ctUtils.fn_DoSettingAmount(true, dataBucket, lv_TargetSelf) // 총보장자산 노출

            // 아이템정렬
            lv_TargetSelf.$bizUtil.ctUtils.fn_OrdCrdList(lv_TargetSelf, false)

            // 급부별
            lv_TargetSelf.clsfList = lv_TargetSelf.tableHeaderMock
            // 상태별
		        lv_TargetSelf.byStatList = lv_TargetSelf.getStore('ctStore').getters.getByStatList

            console.log(lv_TargetSelf.clsfList)
            console.log(lv_TargetSelf.byStatList)

            if(idx != null){
              console.log(idx)
              lv_TargetSelf.tabIndex = '1'
              lv_TargetSelf.$nextTick(() => {
                lv_TargetSelf.$refs['refBenf'+idx][0].isExpand = true
                // lv_TargetSelf.$refs['refBenf'+idx][0].$nextTick(() => {
                setTimeout(() => {
                  if(idx === 0){
                    lv_TargetSelf.$refs['refBenfCont'+idx+'1'][0].focus()
                  }else{
                    lv_TargetSelf.$refs['refBenfCont'+idx+'0'][0].focus()
                  }
                }, 500)
                // })
              })
            }

          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      fn_openBs() { this.$refs.nsbottomsheet.open()  },
      fn_closeBs() { this.$refs.nsbottomsheet.close() },
      fn_openBs2() { this.$refs.nsbottomsheet2.open()  },
      fn_closeBs2() { this.$refs.nsbottomsheet2.close() },
      fn_openDetail(item) {
        this.pdtDetailItem = item
        this.pdtDetailItem.data = []
        this.productList.forEach((info) => {
          let data = {}
          data.prdtNm = info.name // 상품명
          data.insrCoNm = info.insrCoNm // 회사명
          data.gtplOtcomPrdtInptScCd = info.gtplOtcomPrdtInptScCd // 신정원코드
          data.isOtherProduct = info.isOtherProduct // 타사여부
          for(let dKey in info.details){
            let valueList = info.details[dKey]
            for(let vKey in valueList){
              if(valueList[vKey].attr == this.pdtDetailItem.attr){
                // console.log(this.pdtDetailItem.type, '매핑됨 >>> ', this.pdtDetailItem.attr, ' >>> ', valueList[vKey].value, this.pdtDetailItem.data.length)
                data.value = valueList[vKey].value  // 보장금액

                this.pdtDetailItem.data.push(data)
                return false
              }
            }
          }
        })
        
        this.$refs.nsbottomsheet3.open()
      },
      fn_closeDetail() {
        this.$refs.nsbottomsheet3.close()
        this.pdtDetailItem = { 
          type: '',
          custAmount: 0,
          requireAmount: 0,
          data: [] 
        }
      }
    }
  };
</script>
<style scoped>
</style>