import { render, staticRenderFns } from "./MSPCT144D.vue?vue&type=template&id=33f7160d&scoped=true&"
import script from "./MSPCT144D.vue?vue&type=script&lang=js&"
export * from "./MSPCT144D.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33f7160d",
  null
  
)

export default component.exports