<template>
  <ur-page-container ref="page" class="msp" title="수신고객목록" :show-title="true" type="subpage" action-type="none" @on-header-left-click="fn_HistoryBack"  :topButton="true">   

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list pt15">  
        <mo-list-item>
          <div class="list-item__contents pb10">
            <div class="list-item__contents__info pal0">
              <span class="crTy-bk1"><b class="crTy-blue2">{{lv_infoPrcusCnsntList.length}} 명</b>의 고객에게 {{pRadioValue == '2'? '이메일을':'문자를'}} 발송합니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024 mb20">  
        <mo-list :list-data="lv_infoPrcusCnsntList">
          <template #list-item="{item}">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip">{{item.name}}</span>
                  <mo-badge v-if="lv_possibleYn ==='1'" class="badge-sample-badge green sm" text="" shape="status">가능</mo-badge>
                  <mo-badge v-if="lv_possibleYn ==='2'" class="badge-sample-badge red2 sm" text="" shape="status">불가</mo-badge>   
                </div>

                <template v-if="pRadioValue !== '2'">
                  <div class="list-item__contents__info">
                    <span class="crTy-bk1">{{item.birthDay}}</span>
                 </div>
                </template>
                <template v-if="pRadioValue !== '2'">
                  <div class="list-item__contents__info">
                    <span class="crTy-bk1">필수컨설팅(휴대폰.카카오) <b class="ml3" :class="{'crTy-blue2' : item.consulting === 'Y', 'crTy-orange2' : item.consulting === 'N'}">{{item.consulting}}</b></span><em>|</em><span class="crTy-bk1">마케팅동의(문자) <b class="ml3" :class="{'crTy-blue2' : item.marketing === 'Y', 'crTy-orange2' : item.marketing === 'N'}">{{item.marketing}}</b></span><em>|</em><span class="crTy-bk1">휴대폰번호 <b class="ml3" :class="{'crTy-blue2' : item.isReception === 'Y', 'crTy-orange2' : item.isReception === 'N'}">{{item.isReception}}</b></span>
                  </div>
                </template>

                <template v-if="pRadioValue === '2'">
                  <div class="list-item__contents__info">
                    <span class="crTy-bk1">{{item.birthDay}}</span><em>|</em><span class="crTy-bk1">마케팅동의(이메일) <b class="ml3" :class="{'crTy-blue2' : item.marketingEmail === 'Y', 'crTy-orange2' : item.marketingEmail === 'N'}">{{item.marketingEmail}}</b></span><em>|</em><span class="crTy-bk1">이메일주소 <b class="ml3" :class="{'crTy-blue2' : item.isReception === 'Y', 'crTy-orange2' : item.isReception === 'N'}">{{item.isReception}}</b></span>
                  </div>
                </template>
              </div>
            </mo-list-item>
          </template>
        </mo-list> 
      </ur-box-container>

      <ur-box-container direction="column" align-v="start" class="con-area pb50" v-if="pRadioValue === '3' || pRadioValue === '4'">            
          <div class="text-gray-box">
            <ul class="terms-list-area crTy-bk7 fs14rem">
              <li>필수컨설팅동의는 본인명의 <b class="crTy-bk1">휴대폰, 카카오로 인증 완료</b>된 고객에 한해 <b class="crTy-blue2">Y</b>로 표시되며, 해당 고객만 전송 가능합니다.</li>
            </ul>
          </div> 
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area" v-if="pRadioValue === '2'">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white"  @click="fn_closeMSPCT161P">취소</mo-button>
              <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="!lv_chkBtnFlag" @click="fn_makeOzPdfFile('1')">다음</mo-button> 
            </div>
          </ur-box-container>  
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area" v-if="pRadioValue === '3'">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" :disabled="!lv_chkBtnFlag"  @click="fn_makeOzPdfFile('2')">터치On</mo-button>
              <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="!lv_chkBtnFlag" @click="fn_makeOzPdfFile('3')">휴대폰문자</mo-button>
            </div>
          </ur-box-container>  
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area" v-if="pRadioValue === '4'">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white"  @click="fn_closeMSPCT161P">취소</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="!lv_chkBtnFlag" @click="fn_kkaotolk_Open()">발송</mo-button>
            </div>
          </ur-box-container>  
      </ur-box-container>
    </ur-box-container>

   <!-- 바텀시트1 : 카카오톡  Alert 팝업입니다. -->
    <mo-bottom-sheet ref="kkaotolk_nsbottomsheet"  class="ns-bottom-sheet msp-bottomSt-alert">
        <div class="customer-info-txt txt-center">
          <p class="ment">보장설계는 고객님이 요청하신 경우에 </p>
          <p class="ment">한하여 발송이 가능합니다.</p>
          <p class="ment">고객님의 요청에 의한 발송이시면,  </p>
          <p class="ment">‘확인’을 눌러주세요.</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_kkaotolk_Close()" name="취소">취소</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_makeOzPdfFile('4')" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import CtConstants from '@/config/constants/ctConstants'
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import moment from 'moment'
import MSPCM154P from '@/ui/cm/MSPCM154P.vue'
import MSPCM192P from '@/ui/cm/MSPCM192P.vue'

export default {
/***********************************************************************************
* Vue 파일 속성 정의 영역	                                                       *
***********************************************************************************/
    name: "MSPCT161P",
    screenId: "MSPCT161P",
    isBackKeyHandle: true ,  // 팝업일때 디바이스 백키 직접 핸들처리여부 오션      
    components: {
      MSPCM154P,
      MSPCM192P,
    },
    props: {
     'pPage':  String,
     'pRadioValue': { type: String, default () { return '' } },
     'pShowPopup': true,
     'pParams': {},   // 
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        showPopup: true,
        lv_possibleYn: '2',    // 불가능
        pollingCount: 0,
        lv_infoPrcusCnsntList: [{
        name: '-',
        birthDay: '-',
        collect: false,
        consulting: false,
        marketing: false,
        isReception: false
        }],
        lv_renderingFlag: false,
        lv_chkBtnFlag: false,
        lv_imgFileNm: '',
        lv_custNm: '',
        lv_chnlCustId: '',
        lv_dob: '',
        lv_custEmailAddr: '',
        lv_pParams: {},
        lv_lmsText: '',
        lv_lmsconTent: '',
        lv_Popup192: {},  // 터치온고객이메일발송 
        lv_cTMakeCovrAnlySVO: [],

        // index-scroll 초성스크롤 위치지정  스크롤에 따른 위치변경은 개발에서
        indexStyle: {
          top:'150px',
          bottom:'100px',
          height:'calc(100vh - 345px)',
          position:'fixed'
        },
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    watch: {},

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
    ***********************************************************************************/ 
    created () {
    // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)    

     // 상단 검색, 카테고리 버튼 생성      
      this.lv_pParams = this.pParams  
      this.fn_init()
      this.fn_selInfoPrcusCnsnt()   
    },

      /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {

    // 화면접속로그 등록(필수)    
    this.$bizUtil.insSrnLog('MSPCT161P')
   },
  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
  // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack) 
  },    
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
  ***********************************************************************************/
  methods: {

    fn_kkaotolk_Open() {
       this.$refs.kkaotolk_nsbottomsheet.open(); 
    },
    fn_kkaotolk_Close() { 
      this.$refs.kkaotolk_nsbottomsheet.close();
    },    
      
    fn_init () {
      this.lv_userInfo = this.getStore('userInfo').getters.getUserInfo // 컨설턴트 정보 조회
      
      if (this.pRadioValue === '2') { // 이메일인 경우 콤보박스 선택고객
        this.lv_custInfo = this.pParams // 고객정보 조회
      } else {
        this.lv_custInfo = this.getStore('ctStore').getters.getCustBaseInfo.data // 선택한 고객정보 조회
      }

      this.lv_custNm = this.lv_custInfo.custNm
      this.lv_chnlCustId = this.lv_custInfo.chnlCustId

     // 고객명 마스킹 처리
      if (this.lv_custInfo.custNm.length > 2) { // 3자리이상
        this.lv_custInfo.maskedCustNm = this.lv_custInfo.custNm.substring(0, 1) + '*' + this.lv_custInfo.custNm.substring(2)
      } else if (this.lv_custInfo.custNm.length > 1) { // 2자리
        this.lv_custInfo.maskedCustNm = this.lv_custInfo.custNm.substring(0, 1) + '*'
      } else { // 1자리 또는 없을 경우
        this.lv_custInfo.maskedCustNm = this.lv_custInfo.custNm
      }
     this.lv_infoPrcusCnsntList[0].name = this.lv_custInfo.custNm
    },
    /******************************************************************************
      * Function명 : fn_selInfoPrcusCnsnt
      * 설명       : 고객동의정보 조회
      *-----------------------------------------------------------------------------
      	cTCustInfoPrcusCnsntSVO.setCustId(resVO.getICClmnyChgDmalDspchVO().get(0).getCustId()); // 고객ID
				cTCustInfoPrcusCnsntSVO.setDob(resVO.getICClmnyChgDmalDspchVO().get(0).getDob());       // 생년월일
				cTCustInfoPrcusCnsntSVO.setCelno(resVO.getICClmnyChgDmalDspchVO().get(0).getCelno());   // 휴대폰번호
				cTCustInfoPrcusCnsntSVO.setMndtCnstgCnsntYn(resVO.getICClmnyChgDmalDspchVO().get(0).getMndtCnstgCnsntYn()); // 필수컨설팅동의여부
				cTCustInfoPrcusCnsntSVO.setMndtCnstgInfoPrcusCnsntPathCd(resVO.getICClmnyChgDmalDspchVO().get(0).getMndtCnstgInfoPrcusCnsntPathCd()); // 필수컨설팅정보활용동의경로코드
				cTCustInfoPrcusCnsntSVO.setMktCnsntEndYmd(resVO.getICClmnyChgDmalDspchVO().get(0).getMktCnsntEndYmd()); //마케팅동의종료일자
				cTCustInfoPrcusCnsntSVO.setMktCnvsMthEmailYn(resVO.getICClmnyChgDmalDspchVO().get(0).getMktCnvsMthEmailYn()); //마케팅권유방법이메일여부
				cTCustInfoPrcusCnsntSVO.setMktCnvsMthPstYn(resVO.getICClmnyChgDmalDspchVO().get(0).getMktCnvsMthPstYn()); //마케팅권유방법우편여부
				cTCustInfoPrcusCnsntSVO.setMktCnvsMthSmsYn(resVO.getICClmnyChgDmalDspchVO().get(0).getMktCnvsMthSmsYn()); // 마케팅동의여부(문자)
				cTCustInfoPrcusCnsntSVO.setMktCnvsMthTelYn(resVO.getICClmnyChgDmalDspchVO().get(0).getMktCnvsMthTelYn()); //마케팅권유방법전화여부
				cTCustInfoPrcusCnsntSVO.setEmailAddr(resVO.getICClmnyChgDmalDspchVO().get(0).getEmailAddr()); //이메일주소
    ******************************************************************************/
    fn_selInfoPrcusCnsnt () {
      let params = {}
      params.cnsltNo = this.lv_userInfo.userId // 컨설턴트번호
      params.chnlCustId = this.lv_custInfo.chnlCustId // 채널고객id

      let lv_Vm = this
      const trnstId = 'txTSSCT76S2'
      const auth = 'S'

      this.post(lv_Vm, params, trnstId, auth).then(function (response) {
        let successYn = response.msgComm.msgCd

        if (successYn === 'EFWS003') {
          let result = response.body
           console.log('@@ 161p_data result 1111>>' , result )
          lv_Vm.lv_custInfo.celno = result.celno
          lv_Vm.lv_custInfo.dob = result.dob.substring(2, 8)
          //lv_Vm.lv_infoPrcusCnsntList[0].birthDay = result.dob.substring(2, 4) + '-' + result.dob.substring(4, 6) + '-' + result.dob.substring(6, 8)
          lv_Vm.lv_infoPrcusCnsntList[0].birthDay = result.dob.substring(2, 4) + '-' + '**' + '-' + '**'   // 마스킹처리보완
          lv_Vm.lv_infoPrcusCnsntList[0].collect = !lv_Vm.$bizUtil.isEmpty(result.custId)
          
          if (lv_Vm.fn_chkMndtCnstgCnsnt(result.mndtCnstgCnsntYn, result.mndtCnstgInfoPrcusCnsntPathCd)) {
              lv_Vm.lv_infoPrcusCnsntList[0].consulting = 'Y'   //문자 필컨
          } else {
              lv_Vm.lv_infoPrcusCnsntList[0].consulting = 'N'   //문자 필컨
          }
             
          let today = moment(new Date().toISOString()).format('YYYYMMDD')
 


          // 이메일인경우 마케팅동의 세팅
   				//cTCustInfoPrcusCnsntSVO.setMktCnsntEndYmd(resVO.getICClmnyChgDmalDspchVO().get(0).getMktCnsntEndYmd()); //마케팅동의종료일자
	  			//cTCustInfoPrcusCnsntSVO.setMktCnvsMthEmailYn(resVO.getICClmnyChgDmalDspchVO().get(0).getMktCnvsMthEmailYn()); //마케팅권유방법이메일여부
          if (lv_Vm.pRadioValue === '2') {
            //if (result.mktCnsntEndYmd >= today && (result.mktCnvsMthEmailYn === 'Y' || result.mktCnvsMthPstYn === 'Y' || result.mktCnvsMthSmsYn === 'Y' || result.mktCnvsMthTelYn === 'Y')) {  //2023/06/13-이민지프로
            if (result.mktCnsntEndYmd >= today && result.mktCnvsMthEmailYn === 'Y' ) {  
              lv_Vm.lv_infoPrcusCnsntList[0].marketingEmail = 'Y'
            } else {
              lv_Vm.lv_infoPrcusCnsntList[0].marketingEmail = 'N'
            }
            //lv_Vm.lv_infoPrcusCnsntList[0].mktCnvsMthEmailYn = result.mktCnvsMthEmailYn  
          } else {
            if (result.mktCnvsMthSmsYn === 'Y') {                    //문자수신-마케팅동의
              lv_Vm.lv_infoPrcusCnsntList[0].marketing = 'Y'
            } else {
              lv_Vm.lv_infoPrcusCnsntList[0].marketing = 'N'
            }
          }
           
        if (lv_Vm.pRadioValue === '2') {
             if (!lv_Vm.$bizUtil.isEmpty(result.emailAddr.trim())) {
                lv_Vm.lv_custEmailAddr = result.emailAddr.trim()
                lv_Vm.lv_infoPrcusCnsntList[0].isReception = 'Y'
             } else {
               lv_Vm.lv_infoPrcusCnsntList[0].isReception = 'N'
               lv_Vm.lv_custEmailAddr = ''
             }
         } else {
             if (!lv_Vm.$bizUtil.isEmpty(result.celno.trim())) {
                lv_Vm.lv_infoPrcusCnsntList[0].isReception = 'Y'
             } else {
               lv_Vm.lv_infoPrcusCnsntList[0].isReception = 'N'
             }           
         }    
             
          // 확인버튼 활성화 조건
          // 이메일: 마케팅동의(Y), 이메일수신동의(Y), 이메일주소유무(Y)
          lv_Vm.lv_chkBtnFlag = false;
          lv_Vm.lv_possibleYn = '2' //불가
          if (lv_Vm.pRadioValue === '2') {
            //&&  lv_Vm.lv_infoPrcusCnsntList[0].mktCnvsMthEmailYn === 'Y'
            if (lv_Vm.lv_infoPrcusCnsntList[0].marketingEmail === 'Y' &&  lv_Vm.lv_infoPrcusCnsntList[0].isReception === 'Y') {
                                                                 
                lv_Vm.lv_chkBtnFlag = true
                lv_Vm.lv_possibleYn = '1'
            }    
          // 문자: 필수컨설팅동의(Y), 마케팅동의문자(Y), 수신가능여부(Y)
          } else {
            if (lv_Vm.lv_infoPrcusCnsntList[0].consulting === 'Y' &&  lv_Vm.lv_infoPrcusCnsntList[0].marketing === 'Y'
                                                                 &&  lv_Vm.lv_infoPrcusCnsntList[0].isReception === 'Y') {
               lv_Vm.lv_chkBtnFlag = true
               lv_Vm.lv_possibleYn = '1'
            }
          }
          lv_Vm.lv_renderingFlag = !lv_Vm.lv_renderingFlag
        } else {
          lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
      * Function명 : fn_chkMndtCnstgCnsnt
      * 설명       : 필수컨설팅동의 여부 확인
      * [정보활용동의경로코드]
      * - 02(휴대폰), 04(휴대폰 사랑온), 06(휴대폰스마트CRM), 09(휴대폰동의라운지), 11(카카오페이), 12(카카오페이 사랑온), 13(카카오페이 동의라운지), 14 (휴대폰사랑온미니), 16(카카오페이사랑온미니)
      * - 19(다이렉트휴대폰) // '21.1.19 추가(ASR210100692),
      * - 22(카카오PayGA포탈), 23(휴대폰GA포탈), 24(휴대폰비대면), 25(카카오Pay비대면) // '22.09.02 추가(ASR220800919)
      * [정보활용동의유형코드]
      * - 17(필수컨설팅동의)
    ******************************************************************************/
    fn_chkMndtCnstgCnsnt (cnsntYn, pathCd) {
      let infoPrcusCnsntPathCd = ['02', '04', '06', '09', '11', '12', '13', '14', '16', '19', '22', '23', '24', '25']
      return cnsntYn === 'Y' && infoPrcusCnsntPathCd.indexOf(pathCd) > -1
    },

    /******************************************************************************
      * Function명 : fn_makeOzPdfFile
      * 설명       : OZ PDF파일 생성 요청
    ******************************************************************************/
    fn_makeOzPdfFile (Gbn) {
     if (this.pRadioValue === '2') {
        //this.$emit('onPopupConfirm')
        this.fn_emailOzDspchConfirm()
      } else {
        // LMS(txTSSCT99S2), 알림톡(txTSSCT99S3) - 통합영업자료기본정보번호 구분을 위한 분기
        const trnstId = this.pRadioValue === '3' ? 'txTSSCT99S2' : 'txTSSCT99S3'
        const auth = 'S'
        var lv_Vm = this
        let voArray = []

        let gtplCllgOtmlVO = {
          'fileNm': 'sfcbjp020', // 보장분석LMS/KKOT출력물 - 모바일 간단보장분석
          'chnlCustId': lv_Vm.lv_custInfo.chnlCustId
        }
        voArray.push(gtplCllgOtmlVO)

        let pParams = {
          'custNm': lv_Vm.lv_custInfo.custNm,
          'cnstgCustNm': lv_Vm.lv_custInfo.custNm,
          'chnlCustId': lv_Vm.lv_custInfo.chnlCustId,
          gtplCllgOtmlVO: voArray,
          conctScCd: '3',
          infoOrgplCd: this.getStore('ctStore').getters.getTcompKcisSc // 당사,신정원 정보출처구분
        }

        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response.body !== null && response.body !== '') {
              lv_Vm.lv_imgFileNm = response.body.tbtPblNo
              lv_Vm.lv_dob = lv_Vm.lv_custInfo.dob
              if (lv_Vm.pRadioValue === '3') {
                 if (Gbn === '2') {                                          
                     lv_Vm.fn_openLmsTouchOnPopup()  //LMS터치온발송
                 } else {
                     lv_Vm.fn_openLmsMoileProc()   //LMS휴대폰발송
                 }
              } else {
                     lv_Vm.fn_sendKkaotalk()    //카카오톡발송
              }      
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            lv_Vm.getStore('confirm').dispatch('ADD', 'PDF 생성에 실패하였습니다.\n\n' + error)
          })
      }
    },
    /******************************************************************************
      * Function명 : fn_openLmsMoileProc
      * 설명       : LMS/KKOT 팝업 열기 - LMS팝업(3) 
    ******************************************************************************/
    fn_openLmsMoileProc() {

    //  this.lv_SMSTargetCustList = [] // 문자 전송 대상 초기화
    //   if (this.lv_custInfo.length === 0) {
    //     return
    //   } else {
        // 문자 전송 데이터 세팅
        //this.fn_DataSet()

        // 문자 전송내용 적재 서비스 호출
        //this.fn_SendSMSRslt(this.lv_SMSTargetCustList)

        // sms 문자 보내기
       // if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development'){ 
       //   this.lv_pPhoneNum = '010-7123-4498'
        //} 
        //else {
        //  window.fdpbridge.exec('SMSPlugin', {number: this.lv_pPhoneNum, message: this.lv_txtMsg}, () => {}, () => {})
        //}

      var lv_Vm = this
 
      let UserName = this.getStore('userInfo').getters.getUserInfo.userNm // 사용자 정보
      let params = {}
      params.tbtPblNo = lv_Vm.lv_imgFileNm // 문번번호
      params.dob = lv_Vm.lv_dob // 생년월일

      const trnstId = 'txTSSCT78S2'
      const auth = 'S'

      this.post(lv_Vm, params, trnstId, auth).then(function (response) {
        let successYn = response.msgComm.msgCd

        if (response.body !== null && response.body !== '') {
            let result = response.body
            console.log('@@ 161p_data result hp lms result  >>' , result )

            lv_Vm.lv_lmsText = '(광고) '+ '[삼성생명 보장안내]\n' +
                                '안녕하세요. ' + lv_Vm.lv_custInfo.maskedCustNm + '고객님, 삼성생명입니다.\n' +
                                '고객님이 요청하신 보험의 보장내용을 보내드립니다.\n' +
                                '추가적으로 궁금하신 사항이 있다면, 언제든지 연락주시길 바랍니다.\n' 
                             + '\n'
                             + '담당FC: ' + UserName + '\n'
                             + '연락처: ' + result.celno + '\n'
                             + '\n' 
                             + result.pdfUrl ;

          console.log('@@ 161p_data result hp lms_text  >>' , lv_Vm.lv_lmsText )
           window.fdpbridge.exec('SMSPlugin', {number: lv_Vm.lv_custInfo.celno, message: lv_Vm.lv_lmsText}, () => {}, () => {})
        } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
        }
      })
        .catch(function (error) {
          window.vue.error(error)
      })

        //this.fn_SendLms()
        this.fn_closeMSPCT161P()  // 팝업 닫음(보장발송수신목록)      

    },
    /******************************************************************************
     * Function명: fn_DataSet
     * 설명: 문자 전송을 위한 데이터를 세팅한다.
     ******************************************************************************/
    fn_DataSet () {
      this.lv_pPhoneNum = ''
      if (this.lv_infoPrcusCnsntList[0].consulting === 'Y' &&  !this.$bizUtil.isEmpty(this.lv_custInfo.celno) && this.lv_custInfo.celno.length >= 11) {
          this.lv_SMSTargetCustList = this.lv_custInfo
          this.lv_pPhoneNum = this.lv_custInfo.celno
          this.lv_txtMsg = this.lv_lmsText
      } 
    },

    /******************************************************************************
     * Function명: fn_SendSMSRslt
     * 설명: 문자 전송 내역을 서버로 보낸다.
     ******************************************************************************/
    fn_SendSMSRslt (custList) {
      var lv_Vm = this
      let pParams = { bCFcTouchSVO: [], sACntntBasInfoSVO: [] }

      // 고객정보
      for (let index = 0; index < custList.length; index++) {
        pParams.bCFcTouchSVO.push({ custCntcId: 'HC09', chnlCustId: custList[index].chnlCustId, custNm: custList[index].custNm, phclTm: '' })
      }

      // 선택한 안내자료 정보

      this.post(lv_Vm, pParams, 'txTSSBC22I2', 'I', {})
        .then(function (response) {
          if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local' ) {
            // window.fdpbridge.exec('ToastPlugin', {message: '문자 전송 완료'}, () => {}, () => {})
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },       
    /******************************************************************************
    * Function명 : fn_sendLms
    * 설명       : LMS 발송
    ******************************************************************************/
    fn_SendLms() {
      const trnstId = 'txTSSCT78I2'
      const auth = 'I'
      var lv_Vm = this
      let pParams = {
        cnsltNo: lv_Vm.lv_userInfo.userId, // 컨설턴트번호
        custNm: lv_Vm.lv_custNm , // 채널고객명
        chnlCustId: lv_Vm.lv_chnlCustId, // 채널고객ID
        tbtPblNo: lv_Vm.lv_imgFileNm, // PDF파일명
        dob: lv_Vm.lv_dob
      }

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.body !== null && response.body !== '') {
            lv_Vm.getStore('toast').dispatch('ADD', '발송되었습니다.')
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          lv_Vm.getStore('confirm').dispatch('ADD', 'LMS전송에 실패하였습니다.\n\n' + error)
        })
    },    
    /******************************************************************************
    * Function명 : fn_sendKkot
    * 설명       : 카카오톡 발송
    ******************************************************************************/
    fn_sendKkaotalk() {
      const trnstId = 'txTSSCT78I3'
      const auth = 'I'
      var lv_Vm = this

      let pParams = {
        cnsltNo: lv_Vm.lv_userInfo.userId, // 컨설턴트번호
        custNm: lv_Vm.lv_custNm, // 채널고객명
        chnlCustId: lv_Vm.lv_chnlCustId, // 채널고객ID
        tbtPblNo: lv_Vm.lv_imgFileNm, // PDF파일명
        dob: lv_Vm.lv_dob
      }

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.body !== null && response.body !== '') {
            lv_Vm.getStore('toast').dispatch('ADD', '발송되었습니다.')
            lv_Vm.fn_kkaotolk_Close()
           // 팝업 닫음
            lv_Vm.fn_closeMSPCT161P()             
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          lv_Vm.getStore('confirm').dispatch('ADD', '알림톡 전송에 실패하였습니다.\n\n' + error)
        })
    },    
    /******************************************************************************
    * Function명  : fn_emailOzDspchConfirm
    * 설명        : Oz이메일 발송
    ******************************************************************************/
    fn_emailOzDspchConfirm() {
      let pParams = this.pParams
      let lv_Vm = this
      const trnstId = 'txTSSCT99S1'
      const auth = 'I'

      window.vue.getStore('progress').dispatch('ADD')
      this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
        let successYn = response.msgComm.msgCd
        if (successYn === 'EFWS003') {
          console.log(response.body)
          let obj = response.body
          let lv_TransId = 'txTSSCT98S1'
          let auth = 'S'
          // moblBusnScCd 업무구분코드, eltrnDoctScCd ??, eltrnDoctId 태블릿발행번호(tbtPblNo)-이메일보내기output으로 나옴.
          pParams = {moblBusnScCd: 'CM', eltrnDoctScCd: '07', eltrnDoctId: obj.tbtPblNo}
          console.log('obj.tbtPblNo :::', obj.tbtPblNo)

          lv_Vm.fn_PollingCount(obj, pParams, lv_TransId, auth)
        } else {
          lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
        }
      }).catch(function (error) {
        window.vue.error(error)
        window.vue.getStore('progress').dispatch('SUB')
      })
    },    
   /******************************************************************************
    * Function명  : fn_PollingCount
    * 설명        : 1.2초 동안 함수 호출 하고 pollingCount 값을 늘린다
    ******************************************************************************/
    fn_PollingCount (obj, pram, trnstId, auth) {
      let lv = this

      this.interval = setInterval(function () {
        lv.fn_PdfYNCheck(obj, pram, trnstId, auth)
        lv.pollingCount++
        console.log('count @@::', lv.pollingCount)
      }, 1200)
    },   
    /******************************************************************************
    * Function명  : fn_PdfYNCheck
    * 설명        : PDF 생성 여부
    ******************************************************************************/
    fn_PdfYNCheck (obj, pram, trnstId, auth) {
      let lv_Vm = this
      lv_Vm.$bizUtil.ctUtils.fn_DoTrz2SvrLegacy(lv_Vm, pram, trnstId, auth).then(function (response) {
        let trfoCmpltYn = response.body.trfoCmpltYn
        let msg = response.msgComm.msgCd
        let email = ''

        if (msg === 'EFWS003') {
          if (trfoCmpltYn === '' || trfoCmpltYn === 'N') {
            if (lv_Vm.pollingCount === 26) {
              clearInterval(lv_Vm.interval)
              lv_Vm.pollingCount = 0
              lv_Vm.getStore('confirm').dispatch('ADD', 'PDF 변환 중 에러가 발생했습니다.')
              window.vue.getStore('progress').dispatch('SUB')
            }
          } else if (trfoCmpltYn === 'Y') {
            clearInterval(lv_Vm.interval)
            lv_Vm.pollingCount = 0
            for (let i in lv_Vm.customerCollectionVO) {
              if (lv_Vm.customerCollectionVO[i].key === obj.chnlCustId) {
                email = lv_Vm.customerCollectionVO[i].email
              }
            }
            lv_Vm.lv_mailObj = {
              emailAddr: email,
              emailDspchVO: obj,
              PSExtInfoAddrSVO: response.body
            }

            lv_Vm.fn_OpenPopEmailCustList()   // 이메일발송

            window.vue.getStore('progress').dispatch('SUB')
          }
        } else {
          lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },       

/*********************************************************
 * Function명: fn_OpenMSPCM154P
 * 설명: 터치On 문자발송 팝업 오픈
 * 
 *********************************************************/
 fn_openLmsTouchOnPopup: _.debounce(function () {

  let lv_Vm = this
 
  let UserName = this.getStore('userInfo').getters.getUserInfo.userNm // 사용자 정보
  let params = {}
  params.tbtPblNo = lv_Vm.lv_imgFileNm // 문번번호
  params.dob = lv_Vm.lv_dob // 생년월일

  const trnstId = 'txTSSCT78S2'
  const auth = 'S'

  this.post(lv_Vm, params, trnstId, auth).then(function (response) {
      let successYn = response.msgComm.msgCd

      if (response.body !== null && response.body !== '') {
            let result = response.body
            console.log('@@ 161p_data result touch result  >>' , result )

            lv_Vm.lv_lmsText = '(광고) '+ '[삼성생명 보장안내]\n' +
                                '안녕하세요. ' + lv_Vm.lv_custInfo.maskedCustNm + '고객님, 삼성생명입니다.\n' +
                                '고객님이 요청하신 보험의 보장내용을 보내드립니다.\n' +
                                '추가적으로 궁금하신 사항이 있다면, 언제든지 연락주시길 바랍니다.\n' 
                       		   + '\n'
                             + '담당FC: ' + UserName + '\n'
                             + '연락처: ' + result.celno + '\n'
                             + '\n' 
                             + result.pdfUrl ;
           console.log('@@ 161p_data result touch lms_text  >>' , lv_Vm.lv_lmsText )
          lv_Vm.lv_Popup154 = lv_Vm.$bottomModal.open(MSPCM154P, {
            properties: {
              pPage: 'MSPCT161P',                   // 모화면 페이지명
              pSmsTargetCustList:  [lv_Vm.lv_custInfo] ,  // 수신고객리스트
              pMsg: lv_Vm.lv_lmsText,
              pIsNotEditable: false           // 메시지 수정가능여부
            },
            listeners: {
             onPopupClose: (pData) => { 
              if (pData.str === 'confirm') {
                   //lv_vm.fn_SendLms()
                   lv_Vm.getStore('toast').dispatch('ADD', '발송되었습니다.')
              }
            // 모달 닫기
              lv_Vm.$bottomModal.close(lv_Vm.lv_Popup154)
              lv_Vm.fn_closeMSPCT161P()  // 팝업 닫음(보장발송수신목록)
            }
           }
         })
  
      } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
      }
  })
    .catch(function (error) {
       window.vue.error(error)
  })


  // lv_vm.lv_Popup154 = lv_vm.$bottomModal.open(MSPCM154P, {
  //   properties: {
  //     pPage: 'MSPCT161P',                   // 모화면 페이지명
  //     pSmsTargetCustList:  [lv_vm.lv_custInfo] ,  // 수신고객리스트
  //     pMsg: lv_vm.lv_lmsText,
  //     pIsNotEditable: false           // 메시지 수정가능여부
  //   },
  //   listeners: {
  //     onPopupClose: (pData) => { 
  //       if (pData.str === 'confirm') {
  //         lv_vm.fn_SendLms()
  //       }
  //       // 모달 닫기
  //       lv_vm.$bottomModal.close(lv_vm.lv_Popup154)
  //       lv_vm.fn_closeMSPCT161P()  // 팝업 닫음(보장발송수신목록)
  //     }
  //   }
  // })
  // }, 300),    
}, 300),    

  /*********************************************************
   * Function명: fn_OpenPopEmailCustList
   * 설명:  이메일 발송 팝업오픈
   * Params: N/A
   * Return: N/A 
   *********************************************************/
    fn_OpenPopEmailCustList() {
      let lv_vm = this

      let custList = []    // 문자수신 고객 list
      let selectPDFfile = []
      let setFileNm = []
      let setFilePathNm = []
 
      lv_vm.lv_custListObj = [lv_vm.lv_custInfo] 
      let tempFileId = {'pdfApndFileId': lv_vm.lv_mailObj.PSExtInfoAddrSVO.eltrnDoctFileNm}       
      let tempFileNm = {'pdfApndFileNm': lv_vm.lv_mailObj.PSExtInfoAddrSVO.eltrnDoctFileNm}
      let tempFilePathNm = {'pdfApndFilePathNm': lv_vm.lv_mailObj.PSExtInfoAddrSVO.eltrnDoctFilePathNm}

      selectPDFfile.push(tempFileId)
      setFileNm.push(tempFileNm)
      setFilePathNm.push(tempFilePathNm)

      let lv_pTitle = '[삼성생명] 가입하신 보험 및 보장내용 분석자료_' + moment(new Date().toISOString()).format('YYYY-MM-DD')
      let lv_pText = lv_vm.lv_custInfo.maskedCustNm + ' 고객님 안녕하세요.\n' +
                     '고객님이 가입하신 보험 및 보장내용을 \n' +
                     '분석한 자료입니다.\n' +
                     '추가적으로 궁금하신 사항은 언제든 \n' +
                     '연락주시면, 최선을 다해 상담하여 드리겠습니다.'  
      let lv_pDataModify = true                
      if (lv_vm.lv_custInfo.custNm.length > 0) {
            // 문자/이메일/DM/택배 클릭시 호출(이벤트 최종 목적지 배분)lv_Vm.customerCollectionVO
            lv_vm.fn_OpenMSPCM192P(lv_vm, lv_vm.lv_custListObj, selectPDFfile, lv_pTitle, lv_pText, lv_pDataModify, setFileNm, setFilePathNm, lv_vm.lv_custEmailAddr )
      }
    }, 
  /*********************************************************
   * Function명: fn_OpenMSPCM192P
   * 설명: 이메일 발송 서비스
   * 
   *********************************************************/
   fn_OpenMSPCM192P: _.debounce(function (el, newCustListObj, selectPDFfile, title, text, modify ,FileNm , FilePathNm , emailId) {     
    const lv_Vm = el
    
    console.log('이메일 발송 서비스', newCustListObj,title,text,modify)

    lv_Vm.lv_Popup192 = lv_Vm.$bottomModal.open(MSPCM192P, {
      properties: {
        pChnlCustIds: newCustListObj, // 발송 고객 리스트
        pPDFfile: selectPDFfile, //첨부파일 리스트
        pTitle: title, // 메일 제목
        pText: text, // 메일 본문 내용
        pDataModify: modify, // 이메일 제목, 내용 수정 가능여부 true: 수정불가, false: 수정가능
        pCallPgmId: 'MSPCT161P',
        pPDFfileNm: FileNm, // pdf화일명
        pPDFfilePathNm: FilePathNm, // pdf화일경로 
        pCustEmailId: emailId
      },
      listeners: {
        onPopupCancel: () => {
          // 모달 닫기
          lv_Vm.$bottomModal.close(lv_Vm.lv_Popup192) 
          lv_Vm.fn_closeMSPCT161P()  // 팝업 닫음(보장발송수신목록)    
        }, 
      }
    })
  }, 300),    
    /******************************************************************************
      * Function명 : fn_closeTSSCT040P
      * 설명       : TSSCT040P 닫기
    ******************************************************************************/
    fn_closeMSPCT161P() {
      this.$emit('onPopupClose')
    },      
     fn_HistoryBack () {
        // 체크박스가 있는 경우
        if (this.lv_infoPrcusCnsntList.length > 0 ) {
                let alertMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'

                let alertObj = this.$bottomModal.open(MSPBottomAlert, {
                  properties: {
                      type: 'B',
                      content: alertMsg,
                      title_pos_btn: "예",
                      title_neg_btn: "아니오"
                  },
                  listeners: {
                    onPopupConfirm: () => {
                     this.$bottomModal.close(alertObj)                
                      // 특정화면 이동 혹은 이전 화면 이동
                      //this.$router.go(-1)
                      console.log('back_1')
                      this.fn_closeMSPCT161P()
                   },
                    onPopupClose: () => {
                     this.$bottomModal.close(alertObj)
                    }
                 }
               })
        } else {
            //this.$router.go(-1) // 이전화면으로 이동
            console.log('back_2')
            this.fn_closeMSPCT161P()
         }
      }          

  }
} ;
</script>
<style scoped>
</style>