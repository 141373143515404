 /*
 * 업무구분: 타사증권 입력
 * 화 면 명: MSPCT142D
 * 화면설명: 타사증권 입력 2.기본정보 단계 (등록상품)
 * 접근권한:
 * 작 성 일: 2023.03
 * 작 성 자: 정지윤
 */
 <template>
  <!-- Content영역 -->
    <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30 mb50">
      <b class="fs22rem">계약 기본정보</b> 
      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">대상자</span>
        </div>               
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-text-field  class="form-input-name full" underline readonly v-model="isdNM" placeholder="보험대상자 이름 입력" />
        </ur-box-container>
      </ur-box-container>  

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">피보험자 구분</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
          <mo-segment-wrapper solid primary v-model="insrdObjScCd" class="chip-type-wrap">
            <mo-segment-button value="01">주피</mo-segment-button>
            <mo-segment-button value="02">종피</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container> 
      </ur-box-container> 

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7 must">계약자</span>
        </div>               
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-text-field  class="form-input-name full" underline placeholder="계약자 이름 입력" v-model="contrNm" :rules="validateRuleRequire1" />
        </ur-box-container>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">계약년월</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-month-picker class="full" v-model="contYm" :max-date="maxContYm"/>
        </ur-box-container>
      </ur-box-container> 

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">만기년월</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-month-picker class="full"  v-model="expYm" :min-date="minExpYm" :disabled="disabled"/>
        </ur-box-container>
        <div class="ns-check flex mt10">
          <mo-checkbox v-model="dateLife" small>종신</mo-checkbox>
        </div>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">납입주기</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <msp-bottom-select class="ns-dropdown-sheet" v-model="selectPrm.key" :items="items1" :itemValue="'key'" :itemText="'label'" @input="fn_padCylCd(selectPrm)" underline bottom-title="납입주기 선택" closeBtn scrolling placeholder="납입주기 선택" />
        </ur-box-container>
      </ur-box-container>  

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">납입완료</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-month-picker class="full" v-model="padCmpltYm" />
        </ur-box-container>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">보험료</span>
        </div>
        <div class="relative">
          <mo-decimal-field numeric maxlength="13" v-model="insurance" class="form-input-name input-clear-pr15" underline clearable placeholder="보험료 입력" />
          <span class="input-txt">원</span>
        </div>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7 must">예정이율</span>
        </div>
        <div class="relative">
          <mo-text-field mask="number" maxlength="3" class="form-input-name input-clear-pr15" underline clearable placeholder="예정이율 입력" v-model="parngIrt" :rules="validateRuleRequire2" /> <!-- mask = "decimal" -->
          <span class="input-txt">%</span>
        </div>
      </ur-box-container>
      <!-- Popup 납입완료가 만기년월보다 클 경우 Alert -->
      <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet msp-bottomSt-alert">
        <!-- content 영역 -->
        <div class="customer-info-txt txt-center">
          <p class="ment" v-if="bs1">납입완료 년월은<br/>만기년월 보다 늦을수 없습니다.</p>
          <p class="ment" v-if="bs2">만기년월은<br/>현재년월보다 늦을 수 없습니다.</p>
          <p class="ment" v-if="bs3">만기년월일은<br/>계약년월일보다 늦을 수 없습니다.</p>
          <p class="ment" v-if="bs4">납입완료 년월은<br/>계약년월일보다 늦을 수 없습니다.</p>
          <p class="ment" v-if="bs5">납입완료 년월은<br/>만기년월일보다 클 수 없습니다.</p>
          <p class="ment" v-if="bs6">계약일자는<br/>현재년월보다 클 수 없습니다.</p>
        </div>

        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" name="확인" @click="closeBS()">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
    </ur-box-container>  
  <!-- Content 영역 end -->

  
</template>
<script>

import moment from 'moment'

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT142D",
    screenId: "MSPCT142D",
    components: { },
    props: {
       vind141D: {
         type: Object,
         default: {}
       }
     },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      
    },
    mounted () {
      if(this.entryType==''){
        this.insurance = '0'
      }
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        InfoMSPCT141D: {}, // MSPCT141D 저장 정보
        // lv_HeaderviewScrollCompID: '',\
        disabled: false, 
        entryType: '',
        getSelOtcomInsr: {},
        validationCheck: true,

        CustInfo: {},
        contrNm: '', // 계약자
        isdNM: '', // 대상자명(보험자명)
        mnisdNm: '', // 주피보험자명
        aisdNm: '', // 종피보험자명
        custContRltnCd: '', // 고객계약관계코드
        insrdObjScCd: '01', // 피보험자대상구분코드 '01':주피, '02':종피
        selectPrm: {key: '01', label: '월납'}, // 납입주기
        padCylCd:'', // 납입주기코드
        dateLife: false, // 종신
        contYm: ['',''], // 계약년월
        expYm: ['',''], // 만기년월
        padCmpltYm: ['',''], // 납인완료년월
        insurance: '', // 보험료
        parngIrt : '', // 예정이율
        isContrNmParngIrt: true, // 계약자, 예정이율 입력확인
        maxContYm : moment(new Date().toISOString()).format('YYYY-MM-DD'), // 계약년월 최대일자
        // minExpYm : `${moment(new Date().toISOString()).format('YYYY')}-01-01`, // 만기년월 최소일자
        minExpYm : moment(new Date().toISOString()).format('YYYY-MM-DD'), // 만기년월 최소일자

        validateRuleRequire1: [
          v => !!v || '계약자를 입력해 주세요'
        ],
        validateRuleRequire2: [
          v => !!v || '예정이율을 입력해 주세요'
        ],

        // bottom-sheet
        bs1 : false,
        bs2 : false,
        bs3 : false,
        bs4 : false,
        bs5 : false,
        bs6 : false,
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      items1() {
        let rtn = [];
        rtn.push({key: '01', label: '월납'});
        rtn.push({key: '02', label: '2월납'});
        rtn.push({key: '03', label: '3월납'});
        rtn.push({key: '06', label: '6월납'});
        rtn.push({key: '12', label: '연납'});
        rtn.push({key: '99', label: '일시납'});
        return rtn;
      },

      MSPCT141MParam() {
        // 타사 등록상품 팝업관련 값
        let returnValue = this.getStore('ctStore').getters.getOtCompCont.data !== undefined ? this.getStore('ctStore').getters.getOtCompCont.data.otcomAcontVO : {}
        console.log('MSPCT141MParam', returnValue)
        return returnValue
      },
      
      getCustInfo () {
      return this.getStore('ctStore').getters.getCustBaseInfo.data.custNm
      }
    },


    watch: {
      dateLife () { // 종신 체크
        if (this.dateLife) {
          this.expYm = ['9999','12']
          // 캘린더 아이콘 비활성화
          this.disabled = true
        } 
        else {
          // this.expYm = this.MSPCT141MParam.expYm.split('-')
          this.expYm = moment(new Date().toISOString()).format('YYYY-MM').split('-')
          this.disabled = false
        }
      },
      contYm() {
        // 담기는 값 형식 맞추기. Month가 1~9 일 때 앞에 0 붙이기
        if (Number(this.contYm[1]) < 10){
          this.contYm[1] = `0${Number(this.contYm[1])}`
        }
      },
      expYm() {
        // 담기는 값 형식 맞추기. Month가 1~9 일 때 앞에 0 붙이기
        if (Number(this.expYm[1]) < 10){
          this.expYm[1] = `0${Number(this.expYm[1])}`
        }
      },
      padCmpltYm() {
        // 담기는 값 형식 맞추기. Month가 1~9 일 때 앞에 0 붙이기
        if (Number(this.padCmpltYm[1]) < 10){
          this.padCmpltYm[1] = `0${Number(this.padCmpltYm[1])}`
        }
        // 납입완료가 만기년월보다 클 경우 Alert
        if(Number(this.padCmpltYm[0]) > Number(this.expYm[0])) {
          this.bs1 = true
          this.$refs.nsbottomsheet.open();
        } else if(Number(this.padCmpltYm[0]) == Number(this.expYm[0]) && Number(this.padCmpltYm[1]) > Number(this.expYm[1])) {
          this.bs1 = true
          this.$refs.nsbottomsheet.open();
        } else{
          this.$refs.nsbottomsheet.close();
        }
      },

      insurance() {
        if(this.insurance == '') {
          this.insurance = '0'
        }
      },

      MSPCT141MParam() {
        if (Object.keys(this.MSPCT141MParam).length > 0) {
          this.contrNm = this.MSPCT141MParam.contrNm
          this.contYm = this.MSPCT141MParam.contYm.split('-')
          this.expYm = this.MSPCT141MParam.expYm.split('-')
          this.padCmpltYm = this.MSPCT141MParam.padCmpltYm.split('-')
          this.parngIrt = this.MSPCT141MParam.parngIrt
          this.mnisdNm = this.MSPCT141MParam.mnisdNm
          this.aisdNm = this.MSPCT141MParam.aisdNm
          this.padCylCd = this.MSPCT141MParam.padCylCd
          this.isdNM = this.mnisdNm!=='' ? this.mnisdNm : this.aisdNm
        }
      },
      contrNm() {
        if(this.contrNm.length > 0 && this.parngIrt.length > 0) {
          this.isContrNmParngIrt = true
          this.$emit('isContrNmParngIrt', !this.isContrNmParngIrt) // MSPCT140P의 다음 버튼 활성화 (계약자, 예정이율 입력 시)
        } else {
          this.isContrNmParngIrt = false
          this.$emit('isContrNmParngIrt', !this.isContrNmParngIrt)
        }
      },
      parngIrt() {
        if(this.contrNm.length > 0 && this.parngIrt.length > 0) {
          this.isContrNmParngIrt = true
          this.$emit('isContrNmParngIrt', !this.isContrNmParngIrt) // MSPCT140P의 다음 버튼 활성화 (계약자, 예정이율 입력 시)
        } else {
          this.isContrNmParngIrt = false
          this.$emit('isContrNmParngIrt', !this.isContrNmParngIrt)
        }
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_custContRltnCd
      * 설명       : 고객계약관계코드 추출
      ******************************************************************************/
      fn_custContRltnCd () {
        // insrdObjScCd - '01':주피, '02':종피
        if (this.insrdObjScCd === '01' && this.isdNM === this.getCustInfo) { 
          this.custContRltnCd = '12'
        } else if (this.insrdObjScCd === '02' && this.isdNM === this.getCustInfo) {
          this.custContRltnCd = '13'
        } else if (this.insrdObjScCd === '01' && this.isdNM !== this.getCustInfo) {
          this.custContRltnCd = '21'
        } else if (this.insrdObjScCd === '02' && this.isdNM !== this.getCustInfo) {
          this.custContRltnCd = '23'
        }
      }, 

      /******************************************************************************
      * Function명 : fn_padCylCd
      * 설명       : 선택 납입주기
      ******************************************************************************/
      fn_padCylCd(selectData){
        let selData = this.items1.filter(item => {
          return item.key === selectData.key
        })[0]
        
        this.selectPrm = {key: selData.key, label: selData.label}
        this.padCylCd = this.selectPrm.key
      },

      /******************************************************************************
      * Function명 : closeBS
      * 설명       : 바텀시트 닫기
      ******************************************************************************/
      closeBS(){
        this.$refs.nsbottomsheet.close();
        // 납입완료 년월 초기화
        if(this.entryType==''){
          this.padCmpltYm = this.MSPCT141MParam.padCmpltYm.split('-')
        } else { // this.entryType=='upd'
          this.padCmpltYm = [this.getSelOtcomInsr.otcomAcontVO.padCmpltYm.substr(0,4),this.getSelOtcomInsr.otcomAcontVO.padCmpltYm.substr(4,6)]
        }
        // bottom-sheet 초기화
        this.bs1 = false
        this.bs2 = false
        this.bs3 = false
        this.bs4 = false
        this.bs5 = false
        this.bs6 = false
      },

      /******************************************************************************
      * Function명  : fn_validationCheck
      * 설명        : 유효성 체크
      ******************************************************************************/
      fn_validationCheck() {
        let today = moment(new Date().toISOString()).format('YYYYMM') // 현재년월
        let date1 = this.contYm[0] + this.contYm[1] // 계약년월
        let date2 = this.expYm[0] + this.expYm[1] // 만기년월
        let date3 = this.padCmpltYm[0] + this.padCmpltYm[1] // 납입완료
        

        if(today > date2) {
          // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC006']) // 만기년월은 현재년월보다 늦을 수 없습니다.
          this.bs2 = true
          this.$refs.nsbottomsheet.open();
          this.validationCheck = false
        }
        // else if(date1 > date2) {
        //   // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC007']) // 만기년월일은 계약년월일보다 늦을 수 없습니다.
        //   this.bs3 = true
        //   this.$refs.nsbottomsheet.open();
        //   this.validationCheck = false
        // }
        else if(date1 > date3) {
          // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC008']) // 납입완료 년월일은 계약년월일보다 늦을 수 없습니다.
          this.bs4 = true
          this.$refs.nsbottomsheet.open();
          this.validationCheck = false
        }
        else if(date3 > date2) {
          // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC009']) // 납입완료 년월일은 만기년월일보다 클 수 없습니다.
          this.bs5 = true
          this.$refs.nsbottomsheet.open();
          this.validationCheck = false
        }
        else if(date1 > today) {
          // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC010']) // 계약일자는 현재년월보다 클 수 없습니다.
          this.bs6 = true
          this.$refs.nsbottomsheet.open();
          this.validationCheck = false
        }
        else {
          this.validationCheck = true
        }
      },

      /******************************************************************************
      * Function명 : fn_beforeMoveMSPCT143D
      * 설명       : 입력한 기본정보 Vuex에 담아놓음
      ******************************************************************************/
      fn_beforeMoveMSPCT143D () {
        let gtplOtcomPrdtInptScCd = this.pdBtnIdx // 등록상품('1')
        let comyProfrOprcd = ''
        let outComyPrdtTrtyVO01 = ''
        // let InsuredGubun = '1' // 1: 주피 / 2: 종피
        this.InfoMSPCT141D = this.vind141D
        console.log('this.InfoMSPCT141D >>>', this.InfoMSPCT141D)

        // if (gtplOtcomPrdtInptScCd === '1') {
        //   if (this.scrapAplcYn === 'Y') { // 스크래핑 적용여부
        //     this.ItemGubun = '3' // 스크래핑일 경우
        //   } else {
        //     this.ItemGubun = '1'
        //   }
        // } else {
        //   this.ItemGubun = '2'
        // }

        // 등록상품 수정 (수정하기)
        if (this.entryType === 'upd') { 
          // comyProfrOprcd = this.getItems[0].comyProFrOprcd
          comyProfrOprcd = this.getSelOtcomInsr.outComyPrdtTrtyVO01[0].comyProfrOprcd
          // outComyPrdtTrtyVO01 = this.outComyPrdtTrtyVO01
          outComyPrdtTrtyVO01 = this.getSelOtcomInsr.outComyPrdtTrtyVO01
        }
        //등록상품 신규 등록 (or 미등록수정)
        else {
          comyProfrOprcd = this.InfoMSPCT141D.comyProfrOprcd // 선택 상품 코드(업체제공외부상품코드)
          outComyPrdtTrtyVO01 = ''
        }

        if (this.insrdObjScCd === '1' || this.insrdObjScCd === '01') {
          this.insrdObjScCd = '01' // 주피
        } else {
          this.insrdObjScCd = '02' // 종피
        }

        this.fn_custContRltnCd() // 고객계약관계코드 

        if(this.entryType==''){ // 신규등록
          let setVO = {
            chnlCustId: this.InfoMSPCT141D.CustInfo141.srcCustId, // 채널고객ID
            srcCustId: this.entryType === 'upd' ? this.srcCustId : this.InfoMSPCT141D.CustInfo141.srcCustId, // 등록시에는 채널고객ID와 동일, 수정 시 보험대상자를 변경하는 경우에 변경 전 보험대상자ID
            insrdObjScCd: this.insrdObjScCd, // 피보험자대상구분코드 01-주피, 02-종피
            otcomAcontVO: { // 타사기계약VO
              chnlCustId: this.InfoMSPCT141D.CustInfo141.chnlCustId, // 채널고객ID
              contrNm: this.contrNm, // 계약자명
              gtplInsrScCd: this.InfoMSPCT141D.CustInfo141.gtplInsrScCd, // 보장플랜보험구분코드 '':일반, '03':유니버설
              custContRltnCd: this.custContRltnCd, // 고객계약관계코드 '12-계약자+주피, 13-계약자+종피, 21-주피보험자, 23-종피보험자
              insrCoNm: this.InfoMSPCT141D.CustInfo141.insrCoNm, // 보험회사명
              insrdObjScCd: this.insrdObjScCd, // 피보험자대상구분코드 01-주피, 02-종피
              mnisdNm: this.insrdObjScCd === '01' ? this.isdNM : '', // 주피보험자명
              aisdNm: this.insrdObjScCd === '02' ? this.isdNM : '', // 종피보험자명
              otcomPrdtNm: this.InfoMSPCT141D.otcomPrdtNm, // 타사상품명
              comyProfrOprcd: comyProfrOprcd, // 업체제공외부상품코드
              contYm: `${this.contYm[0]}-${this.contYm[1]}`, // 계약년월
              expYm: `${this.expYm[0]}-${this.expYm[1]}`, // 만기년월
              padCylCd: this.selectPrm.key, // 납입주기코드
              padCmpltYm: `${this.padCmpltYm[0]}-${this.padCmpltYm[1]}`, // 납입완료년월
              prm: this.insurance, // 보험료
              gtplOtcomPrdtInptScCd: this.InfoMSPCT141D.CustInfo141.gtplOtcomPrdtInptScCd, // 보장플랜타사상품입력구분코드 1 : 등록 타사 상품 2 : 미등록 타사상품 3:스크래핑
              othInsrCoCd: this.InfoMSPCT141D.CustInfo141.othInsrCoCd, // 타보험회사코드
              parngIrt: this.parngIrt, // 예정이율
              //cnsltNo: this.selGtplHoshInfoInputData.cnsltNo, // 컨설턴트번호
              // ■■■ 아래는 스크래핑 타사를 위한 값임 ■■■ //
              scrapAplcYn: '', // 스크래핑 적용여부
              insrSecusNo: '', // 증권번호(스크래핑)
              prpslStatCd: '' // 제안상태코드(스크래핑)

            },
            outComyPrdtTrtyVO01: outComyPrdtTrtyVO01,
            otcomContSno: this.sno
          }
          this.CustInfo = this.getStore('ctStore').dispatch('OTCOMPCONTREG', {'data': setVO}) // vuex 타사 팝업관련 값 저장 방법
          console.log('등록상품셋브이옹오오오111(신규등록)', setVO)
        } else { // 수정(this.entryType=='upd')
          let setVO = {
            chnlCustId: this.contData.chnlCustId, // 채널고객ID
            srcCustId: this.contData.chnlCustId, // 등록시에는 채널고객ID와 동일, 수정 시 보험대상자를 변경하는 경우에 변경 전 보험대상자ID
            insrdObjScCd: this.insrdObjScCd, // 피보험자대상구분코드 01-주피, 02-종피
            otcomAcontVO: { // 타사기계약VO
              chnlCustId: this.contData.chnlCustId, // 채널고객ID
              contrNm: this.contrNm, // 계약자명
              gtplInsrScCd: this.getSelOtcomInsr.otcomAcontVO.gtplInsrScCd, // 보장플랜보험구분코드 '':일반, '03':유니버설
              custContRltnCd: this.custContRltnCd, // 고객계약관계코드 '12-계약자+주피, 13-계약자+종피, 21-주피보험자, 23-종피보험자
              insrCoNm: this.getSelOtcomInsr.otcomAcontVO.insrCoNm, // 보험회사명
              insrdObjScCd: this.insrdObjScCd, // 피보험자대상구분코드 01-주피, 02-종피
              mnisdNm: this.insrdObjScCd === '01' ? this.isdNM : '', // 주피보험자명
              aisdNm: this.insrdObjScCd === '02' ? this.isdNM : '', // 종피보험자명
              otcomPrdtNm: this.getSelOtcomInsr.otcomAcontVO.otcomPrdtNm, // 타사상품명
              comyProfrOprcd: comyProfrOprcd, // 업체제공외부상품코드
              contYm: `${this.contYm[0]}-${this.contYm[1]}`, // 계약년월
              expYm: `${this.expYm[0]}-${this.expYm[1]}`, // 만기년월
              padCylCd: this.selectPrm.key, // 납입주기코드
              padCmpltYm: `${this.padCmpltYm[0]}-${this.padCmpltYm[1]}`, // 납입완료년월
              prm: this.insurance, // 보험료
              gtplOtcomPrdtInptScCd: this.getSelOtcomInsr.otcomAcontVO.gtplOtcomPrdtInptScCd, // 보장플랜타사상품입력구분코드 1 : 등록 타사 상품 2 : 미등록 타사상품 3:스크래핑
              othInsrCoCd: this.getSelOtcomInsr.otcomAcontVO.othInsrCoCd, // 타보험회사코드
              parngIrt: this.parngIrt, // 예정이율
              //cnsltNo: this.selGtplHoshInfoInputData.cnsltNo, // 컨설턴트번호
              // ■■■ 아래는 스크래핑 타사를 위한 값임 ■■■ //
              scrapAplcYn: '', // 스크래핑 적용여부
              insrSecusNo: '', // 증권번호(스크래핑)
              prpslStatCd: '' // 제안상태코드(스크래핑)

            },
            outComyPrdtTrtyVO01: outComyPrdtTrtyVO01,
            otcomContSno: this.getSelOtcomInsr.otcomAcontVO.otcomContSno
          }
          this.CustInfo = this.getStore('ctStore').dispatch('OTCOMPCONTREG', {'data': setVO}) // vuex 타사 팝업관련 값 저장 방법
          console.log('등록상품셋브이옹오오오222(수정)', setVO)
        }
        
        // console.log('setVO>>>', setVO)
      },

      // 수정 및 삭제 위한 함수 영역
      /******************************************************************************
      * Function명 : fn_getOtInfo
      * 설명       : 등록된 등록타사 정보 가져오기(화면출력)
      ******************************************************************************/
      fn_getOtInfo(Data, Info) {
        this.entryType = 'upd'
        this.getSelOtcomInsr = Data.body
        this.contData = Info

        let PCC = this.getSelOtcomInsr.otcomAcontVO.padCylCd // 납입주기
        if(PCC == '01') {
          this.selectPrm = {key:'01' , label:'월납'}
        } else if(PCC == '02'){
          this.selectPrm = {key: '02', label: '2월납'}
        } else if(PCC == '03'){
          this.selectPrm = {key:'03' , label:'3월납'}
        } else if(PCC == '06'){
          this.selectPrm = {key:'06' , label:'6월납'}
        } else if(PCC == '12'){
          this.selectPrm = {key:'12' , label:'연납'}
        } else if(PCC == '99'){
          this.selectPrm = {key:'99' , label:'일시납'}
        }

        this.isdNM = this.getSelOtcomInsr.insrdObjScCd == '01' ? this.getSelOtcomInsr.otcomAcontVO.mnisdNm : this.getSelOtcomInsr.otcomAcontVO.aisdNm // 대상자
        this.insrdObjScCd = this.getSelOtcomInsr.insrdObjScCd // 피보험자대상구분코드 '01':주피, '02':종피
        this.contrNm = this.getSelOtcomInsr.otcomAcontVO.contrNm // 계약자
        this.contYm = [this.getSelOtcomInsr.otcomAcontVO.contYm.substr(0,4),this.getSelOtcomInsr.otcomAcontVO.contYm.substr(4,6)] // 계약년월 ("200011 -> ['2000','11']")
        this.expYm = [this.getSelOtcomInsr.otcomAcontVO.expYm.substr(0,4),this.getSelOtcomInsr.otcomAcontVO.expYm.substr(4,6)] // 만기년월
        this.dateLife = this.getSelOtcomInsr.otcomAcontVO.expYm == "999912" ? true : false // 종신
        // this.selectPrm.key = this.getSelOtcomInsr.otcomAcontVO.padCylCd // 납입주기 {key: '01', label: '월납'}
        this.padCylCd = this.getSelOtcomInsr.otcomAcontVO.padCylCd // 납입주기코드
        this.padCmpltYm = [this.getSelOtcomInsr.otcomAcontVO.padCmpltYm.substr(0,4),this.getSelOtcomInsr.otcomAcontVO.padCmpltYm.substr(4,6)] // 납인완료년월
        this.insurance = this.getSelOtcomInsr.otcomAcontVO.prm.amount // 보험료
        this.parngIrt = this.getSelOtcomInsr.otcomAcontVO.parngIrt // 예정이율
        
      },

    },
  };
</script>
<style scoped>
</style> /*
 * 업무구분: 타사증권 입력
 * 화 면 명: MSPCT142D
 * 화면설명: 타사증권 입력 2.기본정보 단계 (등록상품)
 * 접근권한:
 * 작 성 일: 2023.03
 * 작 성 자: 정지윤
 */
 <template>
  <!-- Content영역 -->
    <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30 mb50">
      <b class="fs22rem">계약 기본정보</b> 
      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">대상자</span>
        </div>               
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-text-field  class="form-input-name full" underline readonly v-model="isdNM" placeholder="보험대상자 이름 입력" />
        </ur-box-container>
      </ur-box-container>  

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">피보험자 구분</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
          <mo-segment-wrapper solid primary v-model="insrdObjScCd" class="chip-type-wrap">
            <mo-segment-button value="01">주피</mo-segment-button>
            <mo-segment-button value="02">종피</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container> 
      </ur-box-container> 

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7 must">계약자</span>
        </div>               
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-text-field  class="form-input-name full" underline placeholder="계약자 이름 입력" v-model="contrNm" :rules="validateRuleRequire1" />
        </ur-box-container>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">계약년월</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-month-picker class="full" v-model="contYm" :max-date="maxContYm"/>
        </ur-box-container>
      </ur-box-container> 

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">만기년월</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-month-picker class="full"  v-model="expYm" :min-date="minExpYm" :disabled="disabled"/>
        </ur-box-container>
        <div class="ns-check flex mt10">
          <mo-checkbox v-model="dateLife" small>종신</mo-checkbox>
        </div>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">납입주기</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <msp-bottom-select class="ns-dropdown-sheet" v-model="selectPrm.key" :items="items1" :itemValue="'key'" :itemText="'label'" @input="fn_padCylCd(selectPrm)" underline bottom-title="납입주기 선택" closeBtn scrolling placeholder="납입주기 선택" />
        </ur-box-container>
      </ur-box-container>  

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">납입완료</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-month-picker class="full" v-model="padCmpltYm" />
        </ur-box-container>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">보험료</span>
        </div>
        <div class="relative">
          <mo-decimal-field numeric maxlength="13" v-model="insurance" class="form-input-name input-clear-pr15" underline clearable placeholder="보험료 입력" />
          <span class="input-txt">원</span>
        </div>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7 must">예정이율</span>
        </div>
        <div class="relative">
          <mo-text-field mask="number" maxlength="3" class="form-input-name input-clear-pr15" underline clearable placeholder="예정이율 입력" v-model="parngIrt" :rules="validateRuleRequire2" /> <!-- mask = "decimal" -->
          <span class="input-txt">%</span>
        </div>
      </ur-box-container>
      <!-- Popup 납입완료가 만기년월보다 클 경우 Alert -->
      <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet msp-bottomSt-alert">
        <!-- content 영역 -->
        <div class="customer-info-txt txt-center">
          <p class="ment" v-if="bs1">납입완료 년월은<br/>만기년월 보다 늦을수 없습니다.</p>
          <p class="ment" v-if="bs2">만기년월은<br/>현재년월보다 늦을 수 없습니다.</p>
          <p class="ment" v-if="bs3">만기년월일은<br/>계약년월일보다 늦을 수 없습니다.</p>
          <p class="ment" v-if="bs4">납입완료 년월은<br/>계약년월일보다 늦을 수 없습니다.</p>
          <p class="ment" v-if="bs5">납입완료 년월은<br/>만기년월일보다 클 수 없습니다.</p>
          <p class="ment" v-if="bs6">계약일자는<br/>현재년월보다 클 수 없습니다.</p>
        </div>

        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" name="확인" @click="closeBS()">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
    </ur-box-container>  
  <!-- Content 영역 end -->

  
</template>
<script>

import moment from 'moment'

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT142D",
    screenId: "MSPCT142D",
    components: { },
    props: {
       vind141D: {
         type: Object,
         default: {}
       }
     },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      
    },
    mounted () {
      if(this.entryType==''){
        this.insurance = '0'
      }
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        InfoMSPCT141D: {}, // MSPCT141D 저장 정보
        // lv_HeaderviewScrollCompID: '',\
        disabled: false, 
        entryType: '',
        getSelOtcomInsr: {},
        validationCheck: true,

        CustInfo: {},
        contrNm: '', // 계약자
        isdNM: '', // 대상자명(보험자명)
        mnisdNm: '', // 주피보험자명
        aisdNm: '', // 종피보험자명
        custContRltnCd: '', // 고객계약관계코드
        insrdObjScCd: '01', // 피보험자대상구분코드 '01':주피, '02':종피
        selectPrm: {key: '01', label: '월납'}, // 납입주기
        padCylCd:'', // 납입주기코드
        dateLife: false, // 종신
        contYm: ['',''], // 계약년월
        expYm: ['',''], // 만기년월
        padCmpltYm: ['',''], // 납인완료년월
        insurance: '', // 보험료
        parngIrt : '', // 예정이율
        isContrNmParngIrt: true, // 계약자, 예정이율 입력확인
        maxContYm : moment(new Date().toISOString()).format('YYYY-MM-DD'), // 계약년월 최대일자
        // minExpYm : `${moment(new Date().toISOString()).format('YYYY')}-01-01`, // 만기년월 최소일자
        minExpYm : moment(new Date().toISOString()).format('YYYY-MM-DD'), // 만기년월 최소일자

        validateRuleRequire1: [
          v => !!v || '계약자를 입력해 주세요'
        ],
        validateRuleRequire2: [
          v => !!v || '예정이율을 입력해 주세요'
        ],

        // bottom-sheet
        bs1 : false,
        bs2 : false,
        bs3 : false,
        bs4 : false,
        bs5 : false,
        bs6 : false,
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      items1() {
        let rtn = [];
        rtn.push({key: '01', label: '월납'});
        rtn.push({key: '02', label: '2월납'});
        rtn.push({key: '03', label: '3월납'});
        rtn.push({key: '06', label: '6월납'});
        rtn.push({key: '12', label: '연납'});
        rtn.push({key: '99', label: '일시납'});
        return rtn;
      },

      MSPCT141MParam() {
        // 타사 등록상품 팝업관련 값
        let returnValue = this.getStore('ctStore').getters.getOtCompCont.data !== undefined ? this.getStore('ctStore').getters.getOtCompCont.data.otcomAcontVO : {}
        console.log('MSPCT141MParam', returnValue)
        return returnValue
      },
      
      getCustInfo () {
      return this.getStore('ctStore').getters.getCustBaseInfo.data.custNm
      }
    },


    watch: {
      dateLife () { // 종신 체크
        if (this.dateLife) {
          this.expYm = ['9999','12']
          // 캘린더 아이콘 비활성화
          this.disabled = true
        } 
        else {
          // this.expYm = this.MSPCT141MParam.expYm.split('-')
          this.expYm = moment(new Date().toISOString()).format('YYYY-MM').split('-')
          this.disabled = false
        }
      },
      contYm() {
        // 담기는 값 형식 맞추기. Month가 1~9 일 때 앞에 0 붙이기
        if (Number(this.contYm[1]) < 10){
          this.contYm[1] = `0${Number(this.contYm[1])}`
        }
      },
      expYm() {
        // 담기는 값 형식 맞추기. Month가 1~9 일 때 앞에 0 붙이기
        if (Number(this.expYm[1]) < 10){
          this.expYm[1] = `0${Number(this.expYm[1])}`
        }
      },
      padCmpltYm() {
        // 담기는 값 형식 맞추기. Month가 1~9 일 때 앞에 0 붙이기
        if (Number(this.padCmpltYm[1]) < 10){
          this.padCmpltYm[1] = `0${Number(this.padCmpltYm[1])}`
        }
        // 납입완료가 만기년월보다 클 경우 Alert
        if(Number(this.padCmpltYm[0]) > Number(this.expYm[0])) {
          this.bs1 = true
          this.$refs.nsbottomsheet.open();
        } else if(Number(this.padCmpltYm[0]) == Number(this.expYm[0]) && Number(this.padCmpltYm[1]) > Number(this.expYm[1])) {
          this.bs1 = true
          this.$refs.nsbottomsheet.open();
        } else{
          this.$refs.nsbottomsheet.close();
        }
      },

      insurance() {
        if(this.insurance == '') {
          this.insurance = '0'
        }
      },

      MSPCT141MParam() {
        if (Object.keys(this.MSPCT141MParam).length > 0) {
          this.contrNm = this.MSPCT141MParam.contrNm
          this.contYm = this.MSPCT141MParam.contYm.split('-')
          this.expYm = this.MSPCT141MParam.expYm.split('-')
          this.padCmpltYm = this.MSPCT141MParam.padCmpltYm.split('-')
          this.parngIrt = this.MSPCT141MParam.parngIrt
          this.mnisdNm = this.MSPCT141MParam.mnisdNm
          this.aisdNm = this.MSPCT141MParam.aisdNm
          this.padCylCd = this.MSPCT141MParam.padCylCd
          this.isdNM = this.mnisdNm!=='' ? this.mnisdNm : this.aisdNm
        }
      },
      contrNm() {
        if(this.contrNm.length > 0 && this.parngIrt.length > 0) {
          this.isContrNmParngIrt = true
          this.$emit('isContrNmParngIrt', !this.isContrNmParngIrt) // MSPCT140P의 다음 버튼 활성화 (계약자, 예정이율 입력 시)
        } else {
          this.isContrNmParngIrt = false
          this.$emit('isContrNmParngIrt', !this.isContrNmParngIrt)
        }
      },
      parngIrt() {
        if(this.contrNm.length > 0 && this.parngIrt.length > 0) {
          this.isContrNmParngIrt = true
          this.$emit('isContrNmParngIrt', !this.isContrNmParngIrt) // MSPCT140P의 다음 버튼 활성화 (계약자, 예정이율 입력 시)
        } else {
          this.isContrNmParngIrt = false
          this.$emit('isContrNmParngIrt', !this.isContrNmParngIrt)
        }
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_custContRltnCd
      * 설명       : 고객계약관계코드 추출
      ******************************************************************************/
      fn_custContRltnCd () {
        // insrdObjScCd - '01':주피, '02':종피
        if (this.insrdObjScCd === '01' && this.isdNM === this.getCustInfo) { 
          this.custContRltnCd = '12'
        } else if (this.insrdObjScCd === '02' && this.isdNM === this.getCustInfo) {
          this.custContRltnCd = '13'
        } else if (this.insrdObjScCd === '01' && this.isdNM !== this.getCustInfo) {
          this.custContRltnCd = '21'
        } else if (this.insrdObjScCd === '02' && this.isdNM !== this.getCustInfo) {
          this.custContRltnCd = '23'
        }
      }, 

      /******************************************************************************
      * Function명 : fn_padCylCd
      * 설명       : 선택 납입주기
      ******************************************************************************/
      fn_padCylCd(selectData){
        let selData = this.items1.filter(item => {
          return item.key === selectData.key
        })[0]
        
        this.selectPrm = {key: selData.key, label: selData.label}
        this.padCylCd = this.selectPrm.key
      },

      /******************************************************************************
      * Function명 : closeBS
      * 설명       : 바텀시트 닫기
      ******************************************************************************/
      closeBS(){
        this.$refs.nsbottomsheet.close();
        // 납입완료 년월 초기화
        if(this.entryType==''){
          this.padCmpltYm = this.MSPCT141MParam.padCmpltYm.split('-')
        } else { // this.entryType=='upd'
          this.padCmpltYm = [this.getSelOtcomInsr.otcomAcontVO.padCmpltYm.substr(0,4),this.getSelOtcomInsr.otcomAcontVO.padCmpltYm.substr(4,6)]
        }
        // bottom-sheet 초기화
        this.bs1 = false
        this.bs2 = false
        this.bs3 = false
        this.bs4 = false
        this.bs5 = false
        this.bs6 = false
      },

      /******************************************************************************
      * Function명  : fn_validationCheck
      * 설명        : 유효성 체크
      ******************************************************************************/
      fn_validationCheck() {
        let today = moment(new Date().toISOString()).format('YYYYMM') // 현재년월
        let date1 = this.contYm[0] + this.contYm[1] // 계약년월
        let date2 = this.expYm[0] + this.expYm[1] // 만기년월
        let date3 = this.padCmpltYm[0] + this.padCmpltYm[1] // 납입완료
        

        if(today > date2) {
          // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC006']) // 만기년월은 현재년월보다 늦을 수 없습니다.
          this.bs2 = true
          this.$refs.nsbottomsheet.open();
          this.validationCheck = false
        }
        // else if(date1 > date2) {
        //   // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC007']) // 만기년월일은 계약년월일보다 늦을 수 없습니다.
        //   this.bs3 = true
        //   this.$refs.nsbottomsheet.open();
        //   this.validationCheck = false
        // }
        else if(date1 > date3) {
          // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC008']) // 납입완료 년월일은 계약년월일보다 늦을 수 없습니다.
          this.bs4 = true
          this.$refs.nsbottomsheet.open();
          this.validationCheck = false
        }
        else if(date3 > date2) {
          // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC009']) // 납입완료 년월일은 만기년월일보다 클 수 없습니다.
          this.bs5 = true
          this.$refs.nsbottomsheet.open();
          this.validationCheck = false
        }
        else if(date1 > today) {
          // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC010']) // 계약일자는 현재년월보다 클 수 없습니다.
          this.bs6 = true
          this.$refs.nsbottomsheet.open();
          this.validationCheck = false
        }
        else {
          this.validationCheck = true
        }
      },

      /******************************************************************************
      * Function명 : fn_beforeMoveMSPCT143D
      * 설명       : 입력한 기본정보 Vuex에 담아놓음
      ******************************************************************************/
      fn_beforeMoveMSPCT143D () {
        let gtplOtcomPrdtInptScCd = this.pdBtnIdx // 등록상품('1')
        let comyProfrOprcd = ''
        let outComyPrdtTrtyVO01 = ''
        // let InsuredGubun = '1' // 1: 주피 / 2: 종피
        this.InfoMSPCT141D = this.vind141D
        console.log('this.InfoMSPCT141D >>>', this.InfoMSPCT141D)

        // if (gtplOtcomPrdtInptScCd === '1') {
        //   if (this.scrapAplcYn === 'Y') { // 스크래핑 적용여부
        //     this.ItemGubun = '3' // 스크래핑일 경우
        //   } else {
        //     this.ItemGubun = '1'
        //   }
        // } else {
        //   this.ItemGubun = '2'
        // }

        // 등록상품 수정 (수정하기)
        if (this.entryType === 'upd') { 
          // comyProfrOprcd = this.getItems[0].comyProFrOprcd
          comyProfrOprcd = this.getSelOtcomInsr.outComyPrdtTrtyVO01[0].comyProfrOprcd
          // outComyPrdtTrtyVO01 = this.outComyPrdtTrtyVO01
          outComyPrdtTrtyVO01 = this.getSelOtcomInsr.outComyPrdtTrtyVO01
        }
        //등록상품 신규 등록 (or 미등록수정)
        else {
          comyProfrOprcd = this.InfoMSPCT141D.comyProfrOprcd // 선택 상품 코드(업체제공외부상품코드)
          outComyPrdtTrtyVO01 = ''
        }

        if (this.insrdObjScCd === '1' || this.insrdObjScCd === '01') {
          this.insrdObjScCd = '01' // 주피
        } else {
          this.insrdObjScCd = '02' // 종피
        }

        this.fn_custContRltnCd() // 고객계약관계코드 

        if(this.entryType==''){ // 신규등록
          let setVO = {
            chnlCustId: this.InfoMSPCT141D.CustInfo141.srcCustId, // 채널고객ID
            srcCustId: this.entryType === 'upd' ? this.srcCustId : this.InfoMSPCT141D.CustInfo141.srcCustId, // 등록시에는 채널고객ID와 동일, 수정 시 보험대상자를 변경하는 경우에 변경 전 보험대상자ID
            insrdObjScCd: this.insrdObjScCd, // 피보험자대상구분코드 01-주피, 02-종피
            otcomAcontVO: { // 타사기계약VO
              chnlCustId: this.InfoMSPCT141D.CustInfo141.chnlCustId, // 채널고객ID
              contrNm: this.contrNm, // 계약자명
              gtplInsrScCd: this.InfoMSPCT141D.CustInfo141.gtplInsrScCd, // 보장플랜보험구분코드 '':일반, '03':유니버설
              custContRltnCd: this.custContRltnCd, // 고객계약관계코드 '12-계약자+주피, 13-계약자+종피, 21-주피보험자, 23-종피보험자
              insrCoNm: this.InfoMSPCT141D.CustInfo141.insrCoNm, // 보험회사명
              insrdObjScCd: this.insrdObjScCd, // 피보험자대상구분코드 01-주피, 02-종피
              mnisdNm: this.insrdObjScCd === '01' ? this.isdNM : '', // 주피보험자명
              aisdNm: this.insrdObjScCd === '02' ? this.isdNM : '', // 종피보험자명
              otcomPrdtNm: this.InfoMSPCT141D.otcomPrdtNm, // 타사상품명
              comyProfrOprcd: comyProfrOprcd, // 업체제공외부상품코드
              contYm: `${this.contYm[0]}-${this.contYm[1]}`, // 계약년월
              expYm: `${this.expYm[0]}-${this.expYm[1]}`, // 만기년월
              padCylCd: this.selectPrm.key, // 납입주기코드
              padCmpltYm: `${this.padCmpltYm[0]}-${this.padCmpltYm[1]}`, // 납입완료년월
              prm: this.insurance, // 보험료
              gtplOtcomPrdtInptScCd: this.InfoMSPCT141D.CustInfo141.gtplOtcomPrdtInptScCd, // 보장플랜타사상품입력구분코드 1 : 등록 타사 상품 2 : 미등록 타사상품 3:스크래핑
              othInsrCoCd: this.InfoMSPCT141D.CustInfo141.othInsrCoCd, // 타보험회사코드
              parngIrt: this.parngIrt, // 예정이율
              //cnsltNo: this.selGtplHoshInfoInputData.cnsltNo, // 컨설턴트번호
              // ■■■ 아래는 스크래핑 타사를 위한 값임 ■■■ //
              scrapAplcYn: '', // 스크래핑 적용여부
              insrSecusNo: '', // 증권번호(스크래핑)
              prpslStatCd: '' // 제안상태코드(스크래핑)

            },
            outComyPrdtTrtyVO01: outComyPrdtTrtyVO01,
            otcomContSno: this.sno
          }
          this.CustInfo = this.getStore('ctStore').dispatch('OTCOMPCONTREG', {'data': setVO}) // vuex 타사 팝업관련 값 저장 방법
          console.log('등록상품셋브이옹오오오111(신규등록)', setVO)
        } else { // 수정(this.entryType=='upd')
          let setVO = {
            chnlCustId: this.contData.chnlCustId, // 채널고객ID
            srcCustId: this.contData.chnlCustId, // 등록시에는 채널고객ID와 동일, 수정 시 보험대상자를 변경하는 경우에 변경 전 보험대상자ID
            insrdObjScCd: this.insrdObjScCd, // 피보험자대상구분코드 01-주피, 02-종피
            otcomAcontVO: { // 타사기계약VO
              chnlCustId: this.contData.chnlCustId, // 채널고객ID
              contrNm: this.contrNm, // 계약자명
              gtplInsrScCd: this.getSelOtcomInsr.otcomAcontVO.gtplInsrScCd, // 보장플랜보험구분코드 '':일반, '03':유니버설
              custContRltnCd: this.custContRltnCd, // 고객계약관계코드 '12-계약자+주피, 13-계약자+종피, 21-주피보험자, 23-종피보험자
              insrCoNm: this.getSelOtcomInsr.otcomAcontVO.insrCoNm, // 보험회사명
              insrdObjScCd: this.insrdObjScCd, // 피보험자대상구분코드 01-주피, 02-종피
              mnisdNm: this.insrdObjScCd === '01' ? this.isdNM : '', // 주피보험자명
              aisdNm: this.insrdObjScCd === '02' ? this.isdNM : '', // 종피보험자명
              otcomPrdtNm: this.getSelOtcomInsr.otcomAcontVO.otcomPrdtNm, // 타사상품명
              comyProfrOprcd: comyProfrOprcd, // 업체제공외부상품코드
              contYm: `${this.contYm[0]}-${this.contYm[1]}`, // 계약년월
              expYm: `${this.expYm[0]}-${this.expYm[1]}`, // 만기년월
              padCylCd: this.selectPrm.key, // 납입주기코드
              padCmpltYm: `${this.padCmpltYm[0]}-${this.padCmpltYm[1]}`, // 납입완료년월
              prm: this.insurance, // 보험료
              gtplOtcomPrdtInptScCd: this.getSelOtcomInsr.otcomAcontVO.gtplOtcomPrdtInptScCd, // 보장플랜타사상품입력구분코드 1 : 등록 타사 상품 2 : 미등록 타사상품 3:스크래핑
              othInsrCoCd: this.getSelOtcomInsr.otcomAcontVO.othInsrCoCd, // 타보험회사코드
              parngIrt: this.parngIrt, // 예정이율
              //cnsltNo: this.selGtplHoshInfoInputData.cnsltNo, // 컨설턴트번호
              // ■■■ 아래는 스크래핑 타사를 위한 값임 ■■■ //
              scrapAplcYn: '', // 스크래핑 적용여부
              insrSecusNo: '', // 증권번호(스크래핑)
              prpslStatCd: '' // 제안상태코드(스크래핑)

            },
            outComyPrdtTrtyVO01: outComyPrdtTrtyVO01,
            otcomContSno: this.getSelOtcomInsr.otcomAcontVO.otcomContSno
          }
          this.CustInfo = this.getStore('ctStore').dispatch('OTCOMPCONTREG', {'data': setVO}) // vuex 타사 팝업관련 값 저장 방법
          console.log('등록상품셋브이옹오오오222(수정)', setVO)
        }
        
        // console.log('setVO>>>', setVO)
      },

      // 수정 및 삭제 위한 함수 영역
      /******************************************************************************
      * Function명 : fn_getOtInfo
      * 설명       : 등록된 등록타사 정보 가져오기(화면출력)
      ******************************************************************************/
      fn_getOtInfo(Data, Info) {
        this.entryType = 'upd'
        this.getSelOtcomInsr = Data.body
        this.contData = Info

        let PCC = this.getSelOtcomInsr.otcomAcontVO.padCylCd // 납입주기
        if(PCC == '01') {
          this.selectPrm = {key:'01' , label:'월납'}
        } else if(PCC == '02'){
          this.selectPrm = {key: '02', label: '2월납'}
        } else if(PCC == '03'){
          this.selectPrm = {key:'03' , label:'3월납'}
        } else if(PCC == '06'){
          this.selectPrm = {key:'06' , label:'6월납'}
        } else if(PCC == '12'){
          this.selectPrm = {key:'12' , label:'연납'}
        } else if(PCC == '99'){
          this.selectPrm = {key:'99' , label:'일시납'}
        }

        this.isdNM = this.getSelOtcomInsr.insrdObjScCd == '01' ? this.getSelOtcomInsr.otcomAcontVO.mnisdNm : this.getSelOtcomInsr.otcomAcontVO.aisdNm // 대상자
        this.insrdObjScCd = this.getSelOtcomInsr.insrdObjScCd // 피보험자대상구분코드 '01':주피, '02':종피
        this.contrNm = this.getSelOtcomInsr.otcomAcontVO.contrNm // 계약자
        this.contYm = [this.getSelOtcomInsr.otcomAcontVO.contYm.substr(0,4),this.getSelOtcomInsr.otcomAcontVO.contYm.substr(4,6)] // 계약년월 ("200011 -> ['2000','11']")
        this.expYm = [this.getSelOtcomInsr.otcomAcontVO.expYm.substr(0,4),this.getSelOtcomInsr.otcomAcontVO.expYm.substr(4,6)] // 만기년월
        this.dateLife = this.getSelOtcomInsr.otcomAcontVO.expYm == "999912" ? true : false // 종신
        // this.selectPrm.key = this.getSelOtcomInsr.otcomAcontVO.padCylCd // 납입주기 {key: '01', label: '월납'}
        this.padCylCd = this.getSelOtcomInsr.otcomAcontVO.padCylCd // 납입주기코드
        this.padCmpltYm = [this.getSelOtcomInsr.otcomAcontVO.padCmpltYm.substr(0,4),this.getSelOtcomInsr.otcomAcontVO.padCmpltYm.substr(4,6)] // 납인완료년월
        this.insurance = this.getSelOtcomInsr.otcomAcontVO.prm.amount // 보험료
        this.parngIrt = this.getSelOtcomInsr.otcomAcontVO.parngIrt // 예정이율
        
      },

    },
  };
</script>
<style scoped>
</style>