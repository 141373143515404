 /*
 * 업무구분: 타사증권 입력
 * 화 면 명: MSPCT145D
 * 화면설명: 타사증권 입력 2.기본정보 단계 (미등록상품)
 * 접근권한:
 * 작 성 일: 2023.03
 * 작 성 자: 정지윤
 */
 <template>
  <ur-page-container class="msp" :show-title="false" title="타사증권 입력" :topButton="true" :action-type="actionSlot">
    <!-- Content영역 -->
    <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30 mb50">
      <b class="fs22rem">계약 기본정보</b> 
      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">대상자</span>
        </div>               
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-text-field  class="form-input-name full" underline readonly v-model="select2.label" placeholder="보험대상자 이름 입력" />
        </ur-box-container>
        <div class="mt6">
          <span>{{select2.mnCustRltnCdNm}} / 보험연령 {{select2.insrAge}}세 / {{select2.sxdsCd === '1' ? '남성' : '여성'}}</span>                  
        </div>
      </ur-box-container>  

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">피보험자 구분</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
          <mo-segment-wrapper solid primary v-model="firstItem1" class="chip-type-wrap">
            <mo-segment-button value="01">주피</mo-segment-button>
            <mo-segment-button value="02">종피</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container> 
      </ur-box-container> 

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7 must">계약번호</span>
        </div>               
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-text-field class="form-input-name full" :maxlength="50" v-model="contractorNumber" :rules="validateRuleRequire1" mask="NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN" underline clearable placeholder="입력"/>
        </ur-box-container>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7 must">계약자</span>
        </div>               
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-text-field  class="form-input-name full" underline clearable placeholder="입력" v-model="contractor" :rules="validateRuleRequire2" />
        </ur-box-container>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">계약년월</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-month-picker class="full" v-model="dateValue1" :max-date="maxdate1"/>
        </ur-box-container>
      </ur-box-container> 

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">만기년월</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-month-picker class="full"  v-model="dateValue2" :min-date="maxdate2" :disabled="dateFlag"/>
        </ur-box-container>
        <div class="ns-check flex mt10">
          <mo-checkbox v-model="date2Life" small>종신</mo-checkbox>
        </div>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">정기사망특약 만기년월</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-month-picker class="full" v-model="dateValue3" :min-date="maxdate3" :disabled="date3Flag"/>
        </ur-box-container>
        <div class="ns-check flex mt10">
          <mo-checkbox v-model="date3Life" small>종신</mo-checkbox>
        </div>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">특약만기년월</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-month-picker class="full" v-model="dateValue4" :min-date="maxdate4" :disabled="date4Flag" />
        </ur-box-container>
        <div class="ns-check flex mt10">
          <mo-checkbox v-model="date4Life" small>종신</mo-checkbox>
        </div>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">납입주기</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <msp-bottom-select class="ns-dropdown-sheet" v-model="selectPrm.key" :items="items1" :itemValue="'key'" :itemText="'label'" @input="fn_padCylCd(selectPrm)" underline bottom-title="납입주기 선택" closeBtn scrolling placeholder="납입주기 선택" />
        </ur-box-container>
      </ur-box-container>  

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">납입완료</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-month-picker class="full" v-model="dateValue5"/>
        </ur-box-container>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt20">
        <div class="info-title-wrap mb0">
            <span class="fs14rem crTy-bk7">보험료</span>
        </div>
        <div class="relative">
          <mo-decimal-field numeric class="form-input-name input-clear-pr15" v-model="insurance" :maxlength="13" underline clearable placeholder="보험료 입력" />
          <span class="input-txt">원</span>
        </div>
      </ur-box-container>

      <div class="text-gray-box mt20">
        <ul class="terms-list-area crTy-bk7 fs14rem">
          <li>정기보험 상품 입력 시 특약만기가 틀릴 경우 특약만기년도를 선택하여 장해/진단/수술/입원/치료 특약만기년도를 별도 체크해 주세요.</li>
        </ul>
      </div>
    </ur-box-container>  
    <!-- Content 영역 end --> 

    <!-- Popup 납입완료가 만기년월보다 클 경우 Alert -->
    <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
      <div class="customer-info-txt txt-center">
        <p class="ment" v-if="bs1">납입완료 년월은<br/>만기년월 보다 늦을 수 없습니다.</p>
        <p class="ment" v-if="bs2">만기년월은<br/>현재년월보다 늦을 수 없습니다.</p>
        <p class="ment" v-if="bs3">납입완료일은<br/>만기년월 보다 늦을 수 없습니다.</p>
        <p class="ment" v-if="bs4">납입완료일은<br/>계약년월 보다 빠를 수 없습니다.</p>
        <p class="ment" v-if="bs5">특약만기년월은<br/>만기년월 보다 클 수 없습니다.</p>
        <p class="ment" v-if="bs6">정기사망특약 만기년월은<br/>만기년월 보다 클 수 없습니다.</p>
        <p class="ment" v-if="bs7">계약일자는<br/>현재년월보다 클 수 없습니다.</p>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" name="확인" @click="closeBS()">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

  </ur-page-container>
</template>
<script>

import moment from 'moment'

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT145D",
    screenId: "MSPCT145D",
    components: { },
    props: {
       vind144D: {
         type: Object,
         default: {}
       }
     },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
            // 상단 검색, 카테고리 버튼 생성
      this.actionSlot = {
        actionComponent: {
          template:'<span><mo-button shape="border" class="ns-btn-round btn-title-style-1">보장설계</mo-button> <mo-icon icon-size="30px" type="picto" >more</mo-icon></span>',
          methods: {

          }
        }
      }
      
      
    },
    mounted () {
      this.insurance = '0' // 보험료 기본값
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        entryType: '',
        validationCheck: true,
        ConpanyAndProduct: {}, // 앞 화면 가입회사명, 상품명, 유니버설 입력 정보
        contractorCompany: '', // 가입회사
        productName: '', // 상품명
        gtplInsrScCd: '',  // 보장플랜보험구분코드 '':일반, '03':유니버설
        selGtplHoshInfoInputData: {
          mnCustId: this.getStore('ctStore').getters.getCustBaseInfo.data.chnlCustId,
          cnsltNo: this.getStore('ctStore').getters.getCustBaseInfo.data.cnsltNo
        },
        firstItem1: '01', // 주종피 ('01'-주피, '02'-종피)
        date2Life: false, // 만기년월 종신
        date3Life: false, // 정기사망특약 만기년월 종신
        date4Life: false, // 특약만기년월 종신
        flag: false,
        dateFlag: false,
        date3Flag: false,
        date4Flag: false,
        dateValue1: ['2000',moment(new Date().toISOString()).format('MM')], // 계약년월
        dateValue2: [moment(new Date().toISOString()).format('YYYY'), moment(new Date().toISOString()).format('MM')], // 만기년월
        dateValue3: [moment(new Date().toISOString()).format('YYYY'), moment(new Date().toISOString()).format('MM')], // 정기사망특약 만기년월
        dateValue4: [moment(new Date().toISOString()).format('YYYY'), moment(new Date().toISOString()).format('MM')], // 특약만기년월
        dateValue5: this.fn_paidEndYearMonth(), // 납입완료
        maxdate1: moment(new Date().toISOString()).format('YYYY-MM-DD'), // 계약년월 최대일자
        // maxdate2: `${moment(new Date().toISOString()).format('YYYY')}-01-01`, // 만기년월 최소일자
        maxdate2: moment(new Date().toISOString()).format('YYYY-MM-DD'), // 만기년월 최소일자
        maxdate3: `${moment(new Date().toISOString()).format('YYYY')}-01-01`, // 정기사망특약 만기년월 최소일자
        maxdate4: `${moment(new Date().toISOString()).format('YYYY')}-01-01`, // 특약만기년월 최소일자
        // maxdate5: '',
        selectPrm: {key: '01', label: '월납'}, // 납입주기
        select2: {key: '0', label: '', srcCustId: '', mnCustRltnCd: '', mnCustRltnCdNm: '', insrAge: '', sxdsCd: '', sxdsCdNm: ''}, // 보험 대상자
        getCustNm: [], // 보험 대상자 정보
        contractorNumber: '', // 계약번호
        validateRuleRequire1: [
          v => !!v || '계약번호를 입력해 주세요'
        ],
        contractor : '', // 계약자
        validateRuleRequire2: [
          v => !!v || '계약자를 입력해 주세요'
        ],
        insurance: '', // 보험료
        kcisSecusNo: '', // 신용정보원증권번호(보장분석시스템 고도화 PJT)
        isContNumContractor: false,
        otcomContSno: '',

        // bottom-sheet
        bs1: false,
        bs2: false,
        bs3: false,
        bs4: false,
        bs5: false,
        bs6: false,
        bs7: false,
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      items1() {
        let rtn = [];
        rtn.push({key: '01', label: '월납'});
        rtn.push({key: '02', label: '2월납'});
        rtn.push({key: '03', label: '3월납'});
        rtn.push({key: '06', label: '6월납'});
        rtn.push({key: '12', label: '연납'});
        rtn.push({key: '99', label: '일시납'});
        return rtn;
      },

    },

    watch: {
      contractorNumber() {
        if(this.contractorNumber !== '' && this.contractor !== '') {
          this.$emit('isContNumContractor', false) // MSPCT140P의 다음 버튼 활성화 (계약번호, 계약자 입력 시)
        } else {
          this.$emit('isContNumContractor', true)
        }
      },
      contractor() {
        if(this.contractorNumber !== '' && this.contractor !== '') {
          this.$emit('isContNumContractor', false) // MSPCT140P의 다음 버튼 활성화 (계약번호, 계약자 입력 시)
        } else {
          this.$emit('isContNumContractor', true)
        }
      },

      insurance(){
        if(this.insurance == '') {
          this.insurance = '0'
        }
      },

      vind144D() {
        this.ConpanyAndProduct = this.vind144D
        console.log('입력정보@@@ ! ', this.ConpanyAndProduct)
        this.contractorCompany = this.ConpanyAndProduct.contractorCompany
        this.productName = this.ConpanyAndProduct.productName
        this.gtplInsrScCd = this.ConpanyAndProduct.gtplInsrScCd
        if(this.ConpanyAndProduct.entryType == 'unReg' || this.ConpanyAndProduct.entryType == 'kcis'){
          this.firstItem1 = this.ConpanyAndProduct.insrdObjScCd // 피보험자 구분
          this.contractorNumber = this.ConpanyAndProduct.insrSecusNo // 계약번호
          this.contractor = this.ConpanyAndProduct.contrNm // 계약자
          this.dateValue1 = [this.ConpanyAndProduct.contYm.substr(0,4), this.ConpanyAndProduct.contYm.substr(4,6)] // 계약년월 ('200001' -> ['2000','01])
          this.dateValue2 = [this.ConpanyAndProduct.expYm.substr(0,4), this.ConpanyAndProduct.expYm.substr(4,6)] // 만기년월
          this.dateValue3 = [this.ConpanyAndProduct.fprdDthTrtyExpYm.substr(0,4), this.ConpanyAndProduct.fprdDthTrtyExpYm.substr(4,6)] // 정기사망특약만기년월
          this.dateValue4 = [this.ConpanyAndProduct.trtyExpYm.substr(0,4), this.ConpanyAndProduct.trtyExpYm.substr(4,6)] // 특약만기년월
          this.selectPrm.key = this.ConpanyAndProduct.padCylCd // 납입주기코드
          this.dateValue5 = [this.ConpanyAndProduct.padCmpltYm.substr(0,4), this.ConpanyAndProduct.padCmpltYm.substr(4,6)] // 납입완료
          this.insurance = this.ConpanyAndProduct.insurance // 보험료
          this.otcomContSno = this.ConpanyAndProduct.contNo
          this.kcisSecusNo = this.ConpanyAndProduct.kcisSecusNo
        }


        this.fn_selGtplHoshInfo() // 세대원정보 조회
        let otcom = this.getStore('ctStore').getters.getunOtcom.data
        if (otcom === undefined) {
          this.getStore('ctStore').dispatch('UNOTCOMREG', {'data': ''})
        }
      },

      dateValue1() {
        this.fn_selected()
      },
      dateValue2() {
        this.fn_selectedDate2()
        // 만기년월보다 큰 날짜 선택 불가능
        // this.maxdate1 = `${this.dateValue2[0]}-${this.dateValue2[1]}-01` // 계약년월
        // this.maxdate3 = `${this.dateValue2[0]}-${this.dateValue2[1]}-01` // 정기사망특약 만기년월
        // this.maxdate4 = `${this.dateValue2[0]}-${this.dateValue2[1]}-01` // 특약만기년월
        // this.maxdate5 = `${this.dateValue2[0]}-${this.dateValue2[1]}-01` // 납입완료
      },
      // 담기는 값 형식 맞추기. Month가 1~9 일 때 앞에 0 붙이기
      dateValue3() {
        if (Number(this.dateValue3[1]) < 10){
          this.dateValue3[1] = `0${Number(this.dateValue3[1])}`
        }
        if(this.dateValue3[0]+this.dateValue3[1] > this.dateValue2[0]+this.dateValue2[1]) {
          // 정기사망특약 만기년월은 만기년월 보다 클 수 없습니다.
          this.bs6 = true
          this.$refs.nsbottomsheet.open();
          this.dateValue3 = [moment(new Date().toISOString()).format('YYYY'), moment(new Date().toISOString()).format('MM')]
        }
      },
      dateValue4() {
        if (Number(this.dateValue4[1]) < 10){
          this.dateValue4[1] = `0${Number(this.dateValue4[1])}`
        }
        if(this.dateValue4[0]+this.dateValue4[1] > this.dateValue2[0]+this.dateValue2[1]) {
          // 특약만기년월은 만기년월 보다 클 수 없습니다.
          this.bs5 = true
          this.$refs.nsbottomsheet.open();
          this.dateValue4 = [moment(new Date().toISOString()).format('YYYY'), moment(new Date().toISOString()).format('MM')]
        }
      },
      dateValue5() {
        if (Number(this.dateValue5[1]) < 10){
          this.dateValue5[1] = `0${Number(this.dateValue5[1])}`
        }
        // 납입완료가 만기년월보다 클 경우 Alert
        if(Number(this.dateValue5[0]) > Number(this.dateValue2[0])) {
          this.bs1 = true
          this.$refs.nsbottomsheet.open();
        } else if(Number(this.dateValue5[0]) == Number(this.dateValue2[0]) && Number(this.dateValue5[1]) > Number(this.dateValue2[1])) {
          this.bs1 = true
          this.$refs.nsbottomsheet.open();
        } else{
          this.$refs.nsbottomsheet.close();
        }
      },
      
      date2Life() { // 만기년월 종신 체크
        this.ctStore = this.getStore('ctStore').getters.getunOtcom.data
        if (this.ctStore.insrSecusNo === undefined || this.ctStore.insrSecusNo === '' ) {
          if (this.date2Life === true) {
            this.dateFlag = true
            this.date3Flag = true
            this.date4Flag = true
            this.date3Life = true
            this.date4Life = true
            this.dateValue2 = ['9999','12']
            this.dateValue3 = ['9999','12']
            this.dateValue4 = ['9999','12']
          } else {
            this.dateFlag = false
            this.date3Flag = false
            this.date4Flag = false
            this.date3Life = false
            this.date4Life = false
            this.dateValue2 = moment(new Date().toISOString()).format('YYYY-MM').split('-')
            this.dateValue3 = moment(new Date().toISOString()).format('YYYY-MM').split('-')
            this.dateValue4 = moment(new Date().toISOString()).format('YYYY-MM').split('-')
          }
        } else {
          if (this.date2Life === true) {
            this.dateFlag = true
            this.dateValue2 = ['9999','12']
            if (this.dateValue3 === ['9999','12']) {
              this.date3Life = true
            }
            if (this.dateValue4 === ['9999','12']) {
              this.date4Life = true
            }
          } else {
            this.dateFlag = false
            this.date3Flag = false
            this.date4Flag = false
            this.date3Life = false
            this.date4Life = false
            this.dateValue3 = moment(new Date().toISOString()).format('YYYY-MM').split('-')
            this.dateValue2 = moment(new Date().toISOString()).format('YYYY-MM').split('-')
            this.dateValue4 = moment(new Date().toISOString()).format('YYYY-MM').split('-')
          }
        }
      },
      date3Life () { // 정기사망 특약 종신 체크
        if (this.date3Life === true) {
          this.date3Flag = true
          this.dateValue3 = ['9999','12']
          if (this.dateValue2[0]+this.dateValue2[1] < this.dateValue3[0]+this.dateValue3[1]) {
            // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC019']) // 정기사망특약 만기년월은 만기년월보다 클 수 없습니다.
            this.bs6 = true
            this.$refs.nsbottomsheet.open();
            this.dateValue3 = moment(new Date().toISOString()).format('YYYY-MM').split('-')
            this.date3Flag = false
            this.date3Life = false
          }
        } else {
          this.date3Flag = false
          this.dateValue3 = moment(new Date().toISOString()).format('YYYY-MM').split('-')
        }
      },
      date4Life () { // 특약만기년월 종신 체크
        if (this.date4Life === true) {
          this.date4Flag = true
          this.dateValue4 = ['9999','12']
          if (this.dateValue2[0]+this.dateValue2[1] < this.dateValue4[0]+this.dateValue4[1]) {
            // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC018']) // 특약만기년월은 만기년월보다 클 수 없습니다.
            this.bs5 = true
            this.$refs.nsbottomsheet.open();
            this.dateValue4 = moment(new Date().toISOString()).format('YYYY-MM').split('-')
            this.date4Flag = false
            this.date4Life = false
          }
        } else {
          this.date4Flag = false
          this.dateValue4 = moment(new Date().toISOString()).format('YYYY-MM').split('-')
        }
      },
      
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_replaceNm
      * 설명       : " 제거
      * 출력물 문자열 전송시 "를 포함하고 있을 경우 에러가 발생하여 " 제거
      ******************************************************************************/
      fn_replaceNm () {
        // this.contractorCompany = this.contractorCompany.replace(/\"/g, '')
        // this.productName = this.productName.replace(/\"/g, '')
        this.contractor = this.contractor.replace(/\"/g, '')
      },

      /******************************************************************************
      * Function명 : fn_selected
      * 설명       : 계약년월의 월을 변경하면 만기년월, 정기사망특약만기년월, 특약만기년월의 월도 동일하게 변경
      ******************************************************************************/
      fn_selected () {
        // 담기는 값 형식 맞추기. Month가 1~9 일 때 앞에 0 붙이기
        if (Number(this.dateValue1[1]) < 10){
          this.dateValue1[1] = `0${Number(this.dateValue1[1])}`
        }

        let paidEndMonthChoice = this.dateValue1[1]
        // 납입완료 월 변경(계약년월 월 - 1월)
        if ( paidEndMonthChoice === '1' || paidEndMonthChoice === '01') {
          paidEndMonthChoice = '12'
        } else {
          paidEndMonthChoice = String(Number(paidEndMonthChoice) - 1)
          if (Number(paidEndMonthChoice) < 10) {
            paidEndMonthChoice = '0' + paidEndMonthChoice
          } else {
            paidEndMonthChoice = String(paidEndMonthChoice)
          }
        }
        // 만기, 정기사망특약만기, 특약만기 월 변경
        // let mon = lv_DateString.substr(5, 2)
        this.dateValue2 = [this.dateValue2[0], this.dateValue1[1]]
        this.dateValue3 = [this.dateValue3[0], this.dateValue1[1]]
        this.dateValue4 = [this.dateValue4[0], this.dateValue1[1]]
        this.dateValue5 = [this.dateValue5[0], paidEndMonthChoice]
      },

      fn_selectedDate2 () {
        // 담기는 값 형식 맞추기. Month가 1~9 일 때 앞에 0 붙이기
        if (Number(this.dateValue2[1]) < 10){
          this.dateValue2[1] = `0${Number(this.dateValue2[1])}`
        }
          this.dateValue3 = [this.dateValue3[0], this.dateValue1[1]]
          this.dateValue4 = [this.dateValue4[0], this.dateValue1[1]]
      },

      /******************************************************************************
      * Function명  : fn_paidEndYearMonth
      * 설명        : 납입완료 월 계산
      * Params      :
      ******************************************************************************/
      fn_paidEndYearMonth () {
        let getYYYY = moment(new Date().toISOString()).format('YYYY')
        let paidEndMonthChoice = moment(new Date().toISOString()).format('MM')
        let now = Number(moment(new Date().toISOString()).format('MM'))
        if (now === 1) {
          paidEndMonthChoice = '12'
        } else {
          paidEndMonthChoice = String(Number(paidEndMonthChoice) - 1)
          if (Number(paidEndMonthChoice) <= 9) {
            paidEndMonthChoice = '0' + paidEndMonthChoice
          } else {
            paidEndMonthChoice = String(paidEndMonthChoice)
          }
        }
        return [getYYYY, paidEndMonthChoice]
      },

      /******************************************************************************
      * Function명  : fn_selGtplHoshInfo
      * 설명        : 세대원정보 조회
      ******************************************************************************/
      fn_selGtplHoshInfo () {
        let lv_Vm = this
        let ipData = lv_Vm.selGtplHoshInfoInputData

        this.$bizUtil.ctUtils.fn_SelHems(lv_Vm, ipData).then(function (response) {
          let rtnArray = response

          if (rtnArray !== null || rtnArray !== '') {
            lv_Vm.fn_gtplCllgCustVO(rtnArray)

            // 신용정보원 추가 - 보장분석시스템 고도화 PJT
            // if (lv_Vm.entryType !== 'unReg' && lv_Vm.entryType !== 'kcis') {
              lv_Vm.select2 = {
                key: lv_Vm.getCustNm[0].key, // index
                label: lv_Vm.getCustNm[0].label, // 보험대상자
                srcCustId: lv_Vm.getCustNm[0].srcCustId, // 채널ID
                mnCustRltnCd: lv_Vm.getCustNm[0].mnCustRltnCd, // 주고객관계코드
                mnCustRltnCdNm: lv_Vm.getCustNm[0].mnCustRltnCdNm, // 주고객관계코드명
                insrAge: lv_Vm.getCustNm[0].insrAge, // 보험연령
                sxdsCd: lv_Vm.getCustNm[0].sxdsCd,
                sxdsCdNm: lv_Vm.getCustNm[0].sxdsCdNm// 성별코드명
              }
            // }
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },

      /******************************************************************************
      * Function명 : fn_gtplCllgCustVO
      * 설명       : 세대원 정보(고객명, 주고객관계코드 추출)
      ******************************************************************************/
      fn_gtplCllgCustVO (getCust) {
        // 비동의 유무 체크
        let data = getCust
        let sVO = {}
        let sList = []
        for (let i in data) {
          if (data[i].gtplCllgCustInfoPrcusCnsntVO.gtplInfoPrcusCnsntYmd !== ' ') {
            sVO = {}
            sVO.key = i
            sVO.label = data[i].custNm
            sVO.srcCustId = data[i].chnlCustId
            sVO.mnCustRltnCd = data[i].mnCustRltnCd
            sVO.mnCustRltnCdNm = data[i].mnCustRltnCdNm
            sVO.insrAge = data[i].insrAge
            sVO.sxdsCd = data[i].sxdsCd
            sVO.sxdsCdNm = data[i].sxdsCdNm
            sList.push(sVO)
          }
        }
        // console.log(sList)
        this.getCustNm = sList
      },

      /******************************************************************************
      * Function명 : fn_padCylCd
      * 설명       : 선택 납입주기
      ******************************************************************************/
      fn_padCylCd(selectData){
        let selData = this.items1.filter(item => {
          return item.key === selectData.key
        })[0]

        this.selectPrm = {key: selData.key, label: selData.label}
      },

      /******************************************************************************
      * Function명 : closeBS
      * 설명       : 바텀시트 닫기
      ******************************************************************************/
      closeBS(){
        this.$refs.nsbottomsheet.close();
        // 납입완료 년월 초기화
        // if(this.entryType == ''){
        //   this.dateValue5 = this.fn_paidEndYearMonth()
        // } else { // this.entryType == 'upd'
        //   this.dateValue5 = [this.ConpanyAndProduct.padCmpltYm.substr(0,4), this.ConpanyAndProduct.padCmpltYm.substr(4,6)]
        // }
        // bottom-sheet 초기화
        this.bs1 = false
        this.bs2 = false
        this.bs3 = false
        this.bs4 = false
        this.bs5 = false
        this.bs6 = false
        this.bs7 = false
      },

      /******************************************************************************
      * Function명  : fn_validationCheck
      * 설명        : 유효성 체크
      *             : 만기년월보다 클 경우
      ******************************************************************************/
      fn_validationCheck() {
        let today = moment(new Date().toISOString()).format('YYYYMM') // 현재년월
        let date1 = this.dateValue1[0] + this.dateValue1[1] // 계약년월
        let date2 = this.dateValue2[0] + this.dateValue2[1] // 만기년월
        let date3 = this.dateValue3[0] + this.dateValue3[1] // 정기사망특약 만기년월
        let date4 = this.dateValue4[0] + this.dateValue4[1] // 특약만기년월
        let date5 = this.dateValue5[0] + this.dateValue5[1] // 납입완료

        // if(date1 > date2) {
        //   this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC007']) // 만기년월일은 계약년월일보다 늦을 수 없습니다.
        //   this.validationCheck = false
        // }
        if(today > date2) {
          // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC006']) // 만기년월은 현재년월보다 늦을 수 없습니다.
          this.bs2 = true
          this.$refs.nsbottomsheet.open();
          this.validationCheck = false
        }
        else if(date5 > date2) {
          // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC016']) // 납입완료일은 만기년월 보다 늦을 수 없습니다.
          this.bs3 = true
          this.$refs.nsbottomsheet.open();
          this.validationCheck = false
        }
        else if(date1 > date5) {
          // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC017']) // 납입완료일은 계약년월 보다 빠를 수 없습니다.
          this.bs4 = true
          this.$refs.nsbottomsheet.open();
          this.validationCheck = false
        }
        else if(date4 > date2) {
          // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC018']) // 특약만기년월은 만기년월 보다 클 수 없습니다.
          this.bs5 = true
          this.$refs.nsbottomsheet.open();
          this.validationCheck = false
        }
        else if(date3 > date2) {
          // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC019']) // 정기사망특약 만기년월은 만기년월 보다 클 수 없습니다.
          this.bs6 = true
          this.$refs.nsbottomsheet.open();
          this.validationCheck = false
        }
        else if(date1 > today) {
          // this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC010']) // 계약일자는 현재년월보다 클 수 없습니다.
          this.bs7 = true
          this.$refs.nsbottomsheet.open();
          this.validationCheck = false
        }
        else {
          this.validationCheck = true
        }
      },

      /******************************************************************************
      * Function명 : fn_setMSPCT145D
      * 설명       : 입력한 보험기본정보 Vuex에 담아놓음
      ******************************************************************************/
      fn_setMSPCT145D () {
        let getunOtcom = this.getStore('ctStore').getters.getunOtcom.data
        console.log('getunOtcom>>>>>', getunOtcom)

        // 출력물 문자열 전송시 "를 포함하고 있을 경우 에러가 발생하여 " 제거
        if(this.contractorCompany && this.productName && this.contractor) {
          this.contractorCompany = this.contractorCompany.replace(/\"/g, '')
          this.productName = this.productName.replace(/\"/g, '')
          this.contractor = this.contractor.replace(/\"/g, '')
        }

        if (this.otcomContSno === '') {
          let setVO = {
            cnsltNo: this.selGtplHoshInfoInputData.cnsltNo, // 컨설턴트번호
            chnlMnCustId: this.selGtplHoshInfoInputData.mnCustId, // 채널주고객ID
            chnlCustId: this.select2.srcCustId, // 채널고객ID
            srcCustId: this.select2.srcCustId, // 선택한 세대원의 채널 ID
            mnCustRltnCd: this.select2.mnCustRltnCd, // 주고객관계코드
            mnCustRltnCdNm: this.select2.mnCustRltnCdNm, // 가족관계 구분명
            insrAge: this.select2.insrAge, // 연령
            insrSxdsCd: this.select2.sxdsCd, // 성별코드
            insrSxdsCdNm: this.select2.sxdsCdNm, // 성별코드명
            trtyExpChkYn: 'Y', // 특약만기체크여부 (AS-IS 동일?)
            crncyCd: 'KRW', // 화페코드 (AS-IS 동일)
            contrNm: this.contractor, // 계약자명
            insrCoNm: this.contractorCompany, // 보험회사명
            otcomPrdtNm: this.productName, // 타사상품명
            gtplInsrScCd: this.gtplInsrScCd, // 유니버설상품
            insrdNm: this.select2.label, // 보험대상자
            insrdObjScCd: this.firstItem1, // 피보험자대상구분코드(01.주피, 02종피)
            insrSecusNo: this.contractorNumber, // 보험증권번호
            contYm: this.dateValue1[0]+this.dateValue1[1], // 계약년월 (['2000','01'] -> '200001')
            expYm: this.dateValue2[0]+this.dateValue2[1], // 만기년월
            fprdDthTrtyExpYm: this.dateValue3[0]+this.dateValue3[1], // 정기사망특약만기년월
            trtyExpYm: this.dateValue4[0]+this.dateValue4[1], // 특약만기년월
            padNts: '0', // 납입횟수
            padCylCd: this.selectPrm.key, // 납입주기코드
            padCmpltYm: this.dateValue5[0]+this.dateValue5[1], // 납입완료년월
            prm: {amount: this.insurance}, // 보험료,
            kcisSecusNo: this.kcisSecusNo, // 신용정보원증권번호(보장분석시스템 고도화 PJT)
          }
          this.getStore('ctStore').dispatch('UNOTCOMREG', {'data': setVO})
          console.log('셋브이오오ㅇㅇㅇㅇㅇ1', setVO)
        } 
        else {
          let setVO = {
            cnsltNo: this.selGtplHoshInfoInputData.cnsltNo, // 컨설턴트번호
            chnlMnCustId: this.selGtplHoshInfoInputData.mnCustId, // 채널주고객ID
            chnlCustId: this.select2.srcCustId, // 채널고객ID
            srcCustId: this.select2.srcCustId, // 선택한 세대원의 채널 ID
            mnCustRltnCd: this.select2.mnCustRltnCd, // 주고객관계코드
            mnCustRltnCdNm: this.select2.mnCustRltnCdNm, // 가족관계 구분명
            insrAge: this.select2.insrAge, // 연령
            insrSxdsCd: this.select2.sxdsCd, // 성별코드
            insrSxdsCdNm: this.select2.sxdsCdNm, // 성별코드명
            trtyExpChkYn: 'Y', // 특약만기체크여부 (AS-IS 동일?)
            crncyCd: 'KRW', // 화페코드 (AS-IS 동일)
            contrNm: this.contractor, // 계약자명
            insrCoNm: this.contractorCompany, // 보험회사명
            otcomPrdtNm: this.productName, // 타사상품명
            gtplInsrScCd: this.gtplInsrScCd, // 유니버설상품
            insrdNm: this.select2.label, // 보험대상자
            insrdObjScCd: this.firstItem1, // 피보험자대상구분코드(01.주피, 02종피)
            insrSecusNo: this.contractorNumber, // 보험증권번호
            contYm: this.dateValue1[0]+this.dateValue1[1], // 계약년월 (['2000','01'] -> '200001')
            expYm: this.dateValue2[0]+this.dateValue2[1], // 만기년월
            fprdDthTrtyExpYm: this.dateValue3[0]+this.dateValue3[1], // 정기사망특약만기년월
            trtyExpYm: this.dateValue4[0]+this.dateValue4[1], // 특약만기년월
            padNts: '0', // 납입횟수
            padCylCd: this.selectPrm.key, // 납입주기코드
            padCmpltYm: this.dateValue5[0]+this.dateValue5[1], // 납입완료년월
            prm: {amount: this.insurance}, // 보험료
            kcisSecusNo: this.kcisSecusNo, // 신용정보원증권번호(보장분석시스템 고도화 PJT)
            otcomContSno: this.otcomContSno, // 계약번호
            cncrDthCovrAmt: getunOtcom.cncrDthCovrAmt ? String(getunOtcom.cncrDthCovrAmt.longAmount / 10000) : '', // 암사망
            genDthCovrAmt: getunOtcom.genDthCovrAmt ? String(getunOtcom.genDthCovrAmt.longAmount / 10000) : '', // 일반사망
            genDstrDthCovrAmt: getunOtcom.genDstrDthCovrAmt ? String(getunOtcom.genDstrDthCovrAmt.longAmount / 10000) : '', // 일반재해
            fprdDthTrtyCovrAmt: getunOtcom.fprdDthTrtyCovrAmt ? String(getunOtcom.fprdDthTrtyCovrAmt.longAmount / 10000) : '', // 정기사망특약
            diseDthCovrAmt: getunOtcom.diseDthCovrAmt ? String(getunOtcom.diseDthCovrAmt.longAmount / 10000) : '', // 질병사망
            diseHspzRldmCovrAmt: getunOtcom.diseHspzRldmCovrAmt ? String(getunOtcom.diseHspzRldmCovrAmt.longAmount / 10000) : '', // 질병입원의료비
            diseMdhspRldmCovrAmt: getunOtcom.diseMdhspRldmCovrAmt ? String(getunOtcom.diseMdhspRldmCovrAmt.longAmount / 10000) : '', // 질병통원의료비
            dstrHspzRldmCovrAmt: getunOtcom.dstrHspzRldmCovrAmt ? String(getunOtcom.dstrHspzRldmCovrAmt.longAmount / 10000) : '', // 상해입원의료비
            dstrMdhspRldmCovrAmt: getunOtcom.dstrMdhspRldmCovrAmt ? String(getunOtcom.dstrMdhspRldmCovrAmt.longAmount / 10000) : '', // 상해통원의료비
            mtherRldmCovrAmt: getunOtcom.mtherRldmCovrAmt ? String(getunOtcom.mtherRldmCovrAmt.longAmount / 10000) : '', // 도수치료
            injcRldmCovrAmt: getunOtcom.injcRldmCovrAmt ? String(getunOtcom.injcRldmCovrAmt.longAmount / 10000) : '', // 주사료
            mriRldmCovrAmt: getunOtcom.mriRldmCovrAmt ? String(getunOtcom.mriRldmCovrAmt.longAmount / 10000) : '', // MRI/MRA
            cncrMdhspRmdyCovrAmt: getunOtcom.cncrMdhspRmdyCovrAmt ? String(getunOtcom.cncrMdhspRmdyCovrAmt.longAmount / 1000) : '', // 암
            cncrRarMdhspCovrAmt: getunOtcom.cncrRarMdhspCovrAmt ? String(getunOtcom.cncrRarMdhspCovrAmt.longAmount / 10000) : '', // 항암치료 > 항암방사선치료
            lgatAntirDignCovrAmt: getunOtcom.lgatAntirDignCovrAmt ? String(getunOtcom.lgatAntirDignCovrAmt.longAmount / 10000) : '', // 고액항암 > 고액항암(표적)
            immueAntirDignCovrAmt: getunOtcom.immueAntirDignCovrAmt ? String(getunOtcom.immueAntirDignCovrAmt.longAmount / 10000) : '', // 면역항암
            dstrFrctDignCovrAmt: getunOtcom.dstrFrctDignCovrAmt ? String(getunOtcom.dstrFrctDignCovrAmt.longAmount / 10000) : '', // 재해골절진단
            pstsRmdyCovrAmt: getunOtcom.pstsRmdyCovrAmt ? String(getunOtcom.pstsRmdyCovrAmt.longAmount / 10000) : '', // 치과_보철치료
            psvtRmdyCovrAmt: getunOtcom.psvtRmdyCovrAmt ? String(getunOtcom.psvtRmdyCovrAmt.longAmount / 10000) : '', // 치과_보존치료
            // dstrDsabRatOhrCovrAmt: getunOtcom.dstrDsabRatOhrCovrAmt ? String(getunOtcom.dstrDsabRatOhrCovrAmt.longAmount / 10000) : '', // 장해율 100%
            // dstrDsabRatSvtyCovrAmt: getunOtcom.dstrDsabRatSvtyCovrAmt ? String(getunOtcom.dstrDsabRatSvtyCovrAmt.longAmount / 10000) : '', // 장해율 70%
            // dstrDsabRatThCovrAmt: getunOtcom.dstrDsabRatThCovrAmt ? String(getunOtcom.dstrDsabRatThCovrAmt.longAmount / 10000) : '', // 장해율 3%
            // sroSurgCovrAmt: getunOtcom.sroSurgCovrAmt ? String(getunOtcom.sroSurgCovrAmt.longAmount / 10000) : '', // 중대한수술
            nwSurgCovrAmt: getunOtcom.nwSurgCovrAmt ? String(getunOtcom.nwSurgCovrAmt.longAmount / 10000) : '', // 신수술
            feclsCovrAmt: getunOtcom.feclsCovrAmt ? String(getunOtcom.feclsCovrAmt.longAmount / 10000) : '', // 1~5종 (최대값) 종수술 > 질병종수술
            cncrSurgCovrAmt: getunOtcom.cncrSurgCovrAmt ? String(getunOtcom.cncrSurgCovrAmt.longAmount / 10000) : '', // 암(암수술보장금액)
            spfDiseSurgCovrAmt: getunOtcom.spfDiseSurgCovrAmt ? String(getunOtcom.spfDiseSurgCovrAmt.longAmount / 10000) : '', // 특정질병 > 특정질병수술(N대)
            lgatCncrDignCovrAmt: getunOtcom.lgatCncrDignCovrAmt ? String(getunOtcom.lgatCncrDignCovrAmt.longAmount / 10000) : '', // 고액암
            genCncrDignCovrAmt: getunOtcom.genCncrDignCovrAmt ? String(getunOtcom.genCncrDignCovrAmt.longAmount / 10000) : '', // 일반암
            crbhmDignCovrAmt: getunOtcom.crbhmDignCovrAmt ? String(getunOtcom.crbhmDignCovrAmt.longAmount / 10000) : '', // 뇌출혈
            cbifDignCovrAmt: getunOtcom.cbifDignCovrAmt ? String(getunOtcom.cbifDignCovrAmt.longAmount / 10000) : '', // 뇌경색
            cevarDsCovrAmt: getunOtcom.cevarDsCovrAmt ? String(getunOtcom.cevarDsCovrAmt.longAmount / 10000) : '', // 뇌혈관질환 추가 - 보장분석시스템 고도화PJT
            // tiscaDignCovrAmt: getunOtcom.tiscaDignCovrAmt ? String(getunOtcom.tiscaDignCovrAmt.longAmount / 10000) : '', // 일과성 뇌허혈발작 추가 - 보장분석시스템 고도화PJT
            actmiDignCovrAmt: getunOtcom.actmiDignCovrAmt ? String(getunOtcom.actmiDignCovrAmt.longAmount / 10000) : '', // 급성심근경색
            // spfIshHetDsDignAmt: getunOtcom.spfIshHetDsDignAmt ? String(getunOtcom.spfIshHetDsDignAmt.longAmount / 10000) : '', // 특정허열심장질환
            ishHetDsDignAmt: getunOtcom.ishHetDsDignAmt ? String(getunOtcom.ishHetDsDignAmt.longAmount / 10000) : '', // 허열심장질환
            // afaflDignCovrAmt: getunOtcom.afaflDignCovrAmt ? String(getunOtcom.afaflDignCovrAmt.longAmount / 10000) : '', // 심방세동/조동 추가 - 보장분석시스템 고도화PJT
            ltcDignCovrAmt: getunOtcom.ltcDignCovrAmt ? String(getunOtcom.ltcDignCovrAmt.longAmount / 10000) : '', // LTC(중증) > 장기요양(중증)
            ltcMsptDignCovrAmt: getunOtcom.ltcMsptDignCovrAmt ? String(getunOtcom.ltcMsptDignCovrAmt.longAmount / 10000) : '', // LTC(경증) > 장기요양(경증)
            ssptDmntDignCovrAmt: getunOtcom.ssptDmntDignCovrAmt ? String(getunOtcom.ssptDmntDignCovrAmt.longAmount / 10000) : '', // 중등도치매
            msptDmntDignCovrAmt: getunOtcom.msptDmntDignCovrAmt ? String(getunOtcom.msptDmntDignCovrAmt.longAmount / 10000) : '', // 경증치매
            // diabtDignCovrAmt: getunOtcom.diabtDignCovrAmt ? String(getunOtcom.diabtDignCovrAmt.longAmount / 10000) : '', // 당뇨
            cncrHspzCovrAmt: getunOtcom.cncrHspzCovrAmt ? String(getunOtcom.cncrHspzCovrAmt.longAmount / 1000) : '', // 암 입원
            dstrHspzCovrAmt: getunOtcom.dstrHspzCovrAmt ? String(getunOtcom.dstrHspzCovrAmt.longAmount / 1000) : '', // 재해 입원
            genDiseHspzCovrAmt: getunOtcom.genDiseHspzCovrAmt ? String(getunOtcom.genDiseHspzCovrAmt.longAmount / 1000) : '', // 기타 질병
            ondayGenHspzCovrAmt: getunOtcom.ondayGenHspzCovrAmt ? String(getunOtcom.ondayGenHspzCovrAmt.longAmount / 1000) : '', // 1일 입원
            hspzCarerUseDignAmt: getunOtcom.hspzCarerUseDignAmt ? String(getunOtcom.hspzCarerUseDignAmt.longAmount / 1000) : '', // 입원간병인사용 > 질병입원간병인사용
            oldThclsCovrAmt: getunOtcom.oldThclsCovrAmt ? String(getunOtcom.oldThclsCovrAmt.longAmount / 10000) : '', // 1~3종 (최대값)
            oldTwclsCovrAmt: getunOtcom.oldTwclsCovrAmt ? String(getunOtcom.oldTwclsCovrAmt.longAmount / 10000) : '', // 1~3종 (최대값)
            spfCncrSurgCovrAmt: getunOtcom.spfCncrSurgCovrAmt ? String(getunOtcom.spfCncrSurgCovrAmt.longAmount / 10000) : '', // 특정암(특정암수술보장금액)
            // ASR240301221_보장설계 신규급부 개발_사랑On 2024.06.04
            diseDsabRatEyCovrAmt: getunOtcom.diseDsabRatEyCovrAmt ? String(getunOtcom.diseDsabRatEyCovrAmt.longAmount / 10000) : '', // 질병후유장해 80%
            dstrDsabRatEyCovrAmt: getunOtcom.dstrDsabRatEyCovrAmt ? String(getunOtcom.dstrDsabRatEyCovrAmt.longAmount / 10000) : '', // 재해후유장해 80%
            aryhiDignCovrAmt: getunOtcom.aryhiDignCovrAmt ? String(getunOtcom.aryhiDignCovrAmt.longAmount / 10000) : '', // 부정맥
            cncrMdcMdhspCovrAmt: getunOtcom.cncrMdcMdhspCovrAmt ? String(getunOtcom.cncrMdcMdhspCovrAmt.longAmount / 10000) : '', // 항암약물치료
            uppHsplCncrMdhspCovrAmt: getunOtcom.uppHsplCncrMdhspCovrAmt ? String(getunOtcom.uppHsplCncrMdhspCovrAmt.longAmount / 1000) : '', // 상급병원암통원
            cllgCncrMdhspCovrAmt: getunOtcom.cllgCncrMdhspCovrAmt ? String(getunOtcom.cllgCncrMdhspCovrAmt.longAmount / 1000) : '', // 종합병원암통원
            cncrMdhspRmdyCovrAmt: getunOtcom.cncrMdhspRmdyCovrAmt ? String(getunOtcom.cncrMdhspRmdyCovrAmt.longAmount / 1000) : '', // 암통원
            micoiDignCovrAmt: getunOtcom.micoiDignCovrAmt ? String(getunOtcom.micoiDignCovrAmt.longAmount / 10000) : '', // 경도인지장애
            dstrSgcfCovrAmt: getunOtcom.dstrSgcfCovrAmt ? String(getunOtcom.dstrSgcfCovrAmt.longAmount / 10000) : '', // 재해종수술
            diseSurgCovrAmt: getunOtcom.diseSurgCovrAmt ? String(getunOtcom.diseSurgCovrAmt.longAmount / 10000) : '', // 재해수술
            dstrSurgCovrAmt: getunOtcom.dstrSurgCovrAmt ? String(getunOtcom.dstrSurgCovrAmt.longAmount / 10000) : '', // 재해수술
            uppWardHspzCovrAmt: getunOtcom.uppWardHspzCovrAmt ? String(getunOtcom.uppWardHspzCovrAmt.longAmount / 1000) : '', // 상급병실입원
            dstrHspzCarerUseCovrAmt: getunOtcom.dstrHspzCarerUseCovrAmt ? String(getunOtcom.dstrHspzCarerUseCovrAmt.longAmount / 1000) : '' // 재해입원간병인사용
          }
          this.getStore('ctStore').dispatch('UNOTCOMREG', {'data': setVO})
          console.log('셋브이오오ㅇㅇㅇㅇㅇ2', setVO)
        }
      },

    },
  };
</script>
<style scoped>
</style>