<template>
 <ur-page-container title="계약별 보장내역" :show-title="true" type="subpage" class="msp" @on-header-left-click="fn_close"><!-- <popup> -->
    <ur-box-container direction="column" alignV="start" class="">
      <ur-box-container alignV="start" componentid="" direction="column" class="pal2430">
        <div class="fexTy3-1" @click="fn_openPrdList">
            <div class="">
                <span class="fs19rem fwb" :class="{'crTy-purple1' : selectItem.productNamePrefix === '(신)', 'crTy-bk3' : selectItem.productNamePrefix === '(타)'}">{{(selectItem.productNamePrefix)}}</span>
                <span class="fs19rem fwb">{{(selectItem.productName)}}</span>
            </div>
            <mo-icon icon="msp-arrow-down" class="min24 fs16 mt8 ml10">msp-arrow-down</mo-icon>
        </div>
        <div class="fexTy3 full mt20">
            <span class="fs14rem">{{selectItem.company}}</span>
            <div>
                <span class="fs14rem crTy-bk7">월보험료</span>
                <span class="fs16rem fwb crTy-blue3 ml10">{{selectItem.payment}}원</span>
            </div>
        </div>
      </ur-box-container>
      <ur-box-container componentid="" direction="column" class="bgcolor-1 h10px">
      </ur-box-container>

      <ur-box-container componentid="" direction="column" class="pb82"> <!-- 퍼블 : 하단 버튼 영역에 List가 가려질때 대비 -->
        <div class="table-area pal3024" v-if="outComyPrdtTrtyVO.length > 0"> <!-- 퍼블 : 특약정보(data에서 List 구성시) -->
          <div class="fexTy3">
              <span class="fs18rem">특약정보</span>
              <span class="crTy-bk7">(단위:만원)</span>
          </div>
          <table class="table col-type mt8">
              <colgroup>
              <col width="" />
              <col width="80px" />  <!-- 퍼블 : 특약정보의 경우 th영역의 text가 길어 금액부분을 고정 -->
              </colgroup>
              <tbody>
                  <tr v-for="(item, idx) in outComyPrdtTrtyVO" :key="idx">
                      <th class="bdRN txt-left pl10">{{item.otcomTrtyInsrNm + '-' + item.otcomTrtyShpCdNm + '-' + item.otcomTrtyEntCustScCdNm}}</th>
                      <td class="txt-right pr10">
                          <span>{{$commonUtil.numberWithCommas(item.uniacCustEntAmt.amount)}}</span>
                      </td>
                  </tr>              
              </tbody>
          </table>
        </div>

        <template v-else>
          <template v-for="(item, idx) in regOtcomInsrVO">
            <div class="table-area pal3024" :key="idx"> 
              <div class="fexTy3">
                  <span class="fs18rem">{{item.category}}</span>
                  <span class="crTy-bk7">(단위:만원)</span>
              </div>
              <table class="table col-type mt8">
                <colgroup>
                <col width="50%" />
                <col width="" />
                </colgroup>
                <tbody>
                  <template v-for="(data, idx) in item.data">
                    <tr :key="'data' + idx">
                      <th class="bdRN txt-left pl10">
                        <span>{{data.label}}</span>
                        <span class="fs12rem" v-if="data.dcmp === 1">(천원)</span>
                      </th>
                      <td class="txt-right pr10">
                          <span>{{data.value}}</span>
                      </td>
                    </tr> 
                  </template>            
                </tbody>
              </table>
            </div>
          </template>
        </template>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_updateBtn()">타사증권 입력</mo-button>
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_close">확인</mo-button>
        </div>
      </ur-box-container>


      <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet closebtn">
        <template v-slot:title>보험선택</template>
          <div class="ns-btn-close" @click="fn_closePrdList" name="닫기"></div>

        <!-- content 영역 -->
          <div class="bdnone"> 
            <ur-box-container alignV="start" componentid="" direction="column" class="mb30">
                <mo-list :list-data="prdList">
                    <template #list-item="{item}">
                        <mo-list-item class="mo-list--check" :class="{'on' : (selectItem.chnlCustId == item.chnlCustId && selectItem.contNo == item.contNo)}">
                            <div class="list-item__contents">
                                <div class="list-item__contents__info"> 
                                    <div class="list_content_date pl24 pt10 pb10 fexTy3">
                                        <div @click="fn_selectItem(item)">
                                            <span class="fs17rem " :class="{'crTy-purple1' : item.gtplOtcomPrdtInptScCd === '4', 'crTy-bk3' : item.gtplOtcomPrdtInptScCd === '1' || item.gtplOtcomPrdtInptScCd === '2'}">
                                                {{item.gtplOtcomPrdtInptScCd === '1' || item.gtplOtcomPrdtInptScCd === '2' ? '(타)' : item.gtplOtcomPrdtInptScCd === '4' ? '(신)' : ''}}
                                            </span>
                                            <span class="fs17rem">{{item.prdtNm}}</span>
                                            <!-- <div class="mt4 wauto fex-rw">
                                                <span class="fs17rem fwb">{{item.data3}}</span> 
                                            </div> -->
                                        </div>
                                        <mo-icon icon="msp-check-bold" class="ml4 min20"/>
                                    </div>
                                </div>
                            </div>
                        </mo-list-item>
                    </template>
                </mo-list>
            </ur-box-container>
          </div>        
      </mo-bottom-sheet>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import CtConstants from '@/config/constants/ctConstants'
  import MSPCT140P from '@/ui/ct/MSPCT140P' // 타사증권 수정 및 삭제

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT115P",
    screenId: "MSPCT115P",
    components: {},
    props: {
      pData: {
        type: Array,
        default: []
      },
      pItem: {
        type: Object,
        default: {}
      }
        
    },
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created () {
      // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_close)
      // ctConstants 급부 항목 설정 (출처: CtConstants.ctTableLoadMap)
      let tmpctTableLoadMap = this.$bizUtil.cloneDeep(CtConstants.ctTableLoadMap)
      let tmpregOtcomInsrVO = tmpctTableLoadMap.reduce((acc,cur)=> { return acc.concat(cur.columns) }, []) // CtConstants.regOtcomInsrVO
      for (let index = 0; index < tmpregOtcomInsrVO.length; index++) {
        let tmpData = tmpregOtcomInsrVO[index].data
        for (let idx = 0; idx < tmpData.length; idx++) {
          if (tmpData[idx].hasOwnProperty('type')) {
            let tmp = {}
            tmp['label'] = tmpData[idx].type
            tmp['attr'] = tmpData[idx].attr
            tmp['value'] = '0'            
            tmp['dcmp'] = (tmpData[idx].dcmp !== null && tmpData[idx].dcmp !== undefined && tmpData[idx].dcmp !== '') ? tmpData[idx].dcmp : 0
            delete tmpData[idx]
            tmpData[idx] = tmp
          }
        }
        tmpregOtcomInsrVO[index].category = tmpregOtcomInsrVO[index].title
      }
      this.regOtcomInsrVO = tmpregOtcomInsrVO
    },
    beforeDestroy () {
      // backKey event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_close)
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)

      console.log('this.$props >>> ', this.$props)
      this.prdList = this.$props.pData
      this.selectItem = this.$props.pItem
      this.fn_selectItem(this.selectItem)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        prdList: [],
        selectItem: {},
        outComyPrdtTrtyVO: [],
        regOtcomInsrVO: [], // CtConstants.regOtcomInsrVO,
        entryType: '', // 진입구분값 reg:신규등록 upd:수정 unReg:미등록 수정 scrp:스크래핑 kcis:신용정보원(수정)
        getSelOtcomInsr: {}, // 등록된 타사보험 조회값
        contNoChnlCustId: {},
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_selectItem(item) {
        this.outComyPrdtTrtyVO = []
        
        if (item.gtplOtcomPrdtInptScCd === '1') { // 등록타사
          this.entryType = 'upd'
          this.fn_selRegOtcomInsr(item)
        } else if (item.gtplOtcomPrdtInptScCd === '3') {  // 스크래핑제외
        } else if (item.gtplOtcomPrdtInptScCd === '4') {  // 신정원
          this.entryType = 'kcis'
          this.fn_selKcisOtcomInsr(item)
        } else { // 미등록타사
          this.entryType = 'unReg'
          this.fn_selUnRegOtcomInsr(item)
        }
        this.fn_closePrdList()
      },
      /******************************************************************************
      * Function명  : fn_selRegOtcomInsr
      * 설명        : 등록된 타사보험 계약사항 조회
      * Params      :
      ******************************************************************************/
      fn_selRegOtcomInsr (item) {
        let lv_Vm = this
        let chnlMnCustId = this.getStore('ctStore').getters.getCovanData.data.gtplCllgHoshVO.chnlMnCustId
        let pParams = {
          otcomContSno: item.contNo,
          chnlCustId: item.chnlCustId,
          chnlMnCustId: chnlMnCustId
        }

        this.$bizUtil.ctUtils.fn_selRegOtcomInsr(lv_Vm, pParams, true).then(function (response) {
          console.log('등록된 타사보험 계약사항 조회 response >>> ', response)
          lv_Vm.getSelOtcomInsr = response
          lv_Vm.contNoChnlCustId = pParams
          if (response.msgComm.msgCd !== 'EFWS003') {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            lv_Vm.fn_close() // 에러시 팝업 닫힘
          }else{
            let otcomAcontVO = response.body.otcomAcontVO
            lv_Vm.selectItem = {
              chnlCustId: response.body.chnlCustId,
              contNo: response.body.otcomContSno,
              productNamePrefix: '(타)',
              productName: otcomAcontVO.otcomPrdtNm,
              company: otcomAcontVO.insrCoNm,
              payment: lv_Vm.$commonUtil.numberWithCommas(otcomAcontVO.prm.amount)
            }

            lv_Vm.fn_SelListOtCompPrdtTrty(response.body)
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
      * Function명 : fn_SelListOtCompPrdtTrty
      * 설명       : 타사 상품 내용(특약정보)를 가져오는 인터페이스(IF-CT-0018) 호출
      ******************************************************************************/
      fn_SelListOtCompPrdtTrty (data) {
        let lv_Vm = this
        let pParams = {
          'othInsrCoCd': data.otcomAcontVO.othInsrCoCd, // 타보험회사코드
          'comyProfrOprcd': data.outComyPrdtTrtyVO01[0].comyProfrOprcd // 업체제공외부상품코드
        }
        const trnstId = 'txTSSCT18S1'
        const auth = 'S'

        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          let outComyPrdtTrtyVO = response.body.outComyPrdtTrtyVO
          console.log('■■■ 해당상품에 속한 모든 특약의 모수 ■■■ :: ', outComyPrdtTrtyVO)
          console.log('■■■ 저장시 선택한 특약들 ■■■ :: ', data.outComyPrdtTrtyVO01)

          outComyPrdtTrtyVO.forEach((trtyData) => {
            data.outComyPrdtTrtyVO01.some((selTrtyVO) => {
              if(selTrtyVO.comyProfrOtrcd === trtyData.comyProfrOtrcd && selTrtyVO.otcomTrtyEntCustScCd === trtyData.otcomTrtyEntCustScCd){
                lv_Vm.outComyPrdtTrtyVO.push(selTrtyVO)
                return true
              }
            })
          })
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
      * Function명 : fn_selUnRegOtcomInsr
      * 설명       : 미등록타사보험조회
      ******************************************************************************/
      fn_selUnRegOtcomInsr (item) {
        let lv_Vm = this
        let pParams = {
          otcomContSno: item.contNo,
          chnlCustId: item.chnlCustId
        }
        this.$bizUtil.ctUtils.fn_selUnRegOtcomInsr(lv_Vm, pParams, true).then(function (response) {
          console.log('미등록타사보험조회 response >>> ', response)
          lv_Vm.getSelOtcomInsr = response
          lv_Vm.contNoChnlCustId = pParams
          if (response.msgComm.msgCd !== 'EFWS003') {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            lv_Vm.showPopup = false // 에러시 팝업 닫힘
          }else{
            lv_Vm.fn_setScrnData(response.body, '(타)')
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
       * Function명 : fn_selKcisOtcomInsr
       * 설명       : 신용정보원 계약상세 조회한다.
       * [보장분석고도화PJT] 신용정보원 계약상세 조회
      ******************************************************************************/
      fn_selKcisOtcomInsr (item) {
        let lv_Vm = this
        let pParams = {
          otcomContSno: item.contNo,
          chnlCustId: item.chnlCustId
        }
        this.$bizUtil.ctUtils.fn_selKcisOtcomInsr(lv_Vm, pParams, true).then(function (response) {
          console.log('신용정보원 계약상세 조회 response >>> ', response)
          lv_Vm.getSelOtcomInsr = response
          lv_Vm.contNoChnlCustId = pParams
          if (response.msgComm.msgCd !== 'EFWS003') {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            lv_Vm.showPopup = false // 에러시 팝업 닫힘
          }else{
            lv_Vm.fn_setScrnData(response.body, '(신)')
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      fn_setScrnData(UnRegOtcomlnsrVO, pre){
        this.selectItem = {
          chnlCustId: UnRegOtcomlnsrVO.chnlCustId,
          contNo: UnRegOtcomlnsrVO.insrSecusNo,
          productNamePrefix: pre,
          productName: UnRegOtcomlnsrVO.otcomPrdtNm,
          company: UnRegOtcomlnsrVO.insrCoNm,
          payment: this.$commonUtil.numberWithCommas(UnRegOtcomlnsrVO.prm.amount)
        }

        let itemList = this.regOtcomInsrVO
        for (let index = 0; index < itemList.length; index++) {
          let items = itemList[index].data
          for (let index2 = 0; index2 < items.length; index2++) {
            let item = items[index2]
            if(UnRegOtcomlnsrVO[item.attr]){
              item.value = this.$commonUtil.numberWithCommas(UnRegOtcomlnsrVO[item.attr].longAmount / 10000)
            }
          }
        }
      },
      fn_close() {
        this.$emit('onPopupClose')
      },
      /******************************************************************************
       * Function명 : fn_updateBtn
       * 설명       : 타사증권 수정 및 삭제 버튼
      ******************************************************************************/
      fn_updateBtn() {
        this.lv_AlertPop = this.$bottomModal.open(MSPCT140P, {
          properties: {
            // pSelRldmEntData: this.selRldmEntData,
            pEntryType: this.entryType, // 진입구분값 reg:신규등록 upd:수정 unReg:미등록 수정 scrp:스크래핑 kcis:신용정보원(수정)
            pSelOtcomInsr: this.entryType==''? {} : this.getSelOtcomInsr, // 선택한 타사 보험 조회 값(등록, 미등록, 신정원)
            pContNoChnlCustId: this.contNoChnlCustId
          },
          listeners: {
            onPopupSave: () => {
              console.log('적용되면 갱신시켜줘야함!!!')
              //this.fn_SelRcalCovrAnly() // 재조회
              this.$emit('onPopupSaveClose', true) // 재조회 // MSPCT112D
              this.$bottomModal.close(this.lv_AlertPop)
            },
            onPopupClose: () => {
              console.log('그냥종료!!!')                    
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        },
        {
          properties: {
            noHeader: true
          }
        })
      },

      fn_openPrdList() { this.$refs.nsbottomsheet.open() },
      fn_closePrdList() { this.$refs.nsbottomsheet.close() }
    }
  };
</script>
<style scoped>
</style>