<template>
  <ur-page-container class="msp" :show-title="false" title="상세 보장설계" :topButton="false" ref="page">
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap ptmode">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_ClickBtnPrevious()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '상세 보장설계' : '상세 보장설계'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>
          <!-- stickey 영역 -->     

          <!-- stickey 영역 -->
          <template #sticky >
            <mo-tab-box solid primary ref="tabBox" v-model="tab_index" class="ns-move-tab-box mt54">
              <mo-tab-label idx="1" @click="fn_tabIdx('1')">계약별</mo-tab-label>
              <mo-tab-label idx="2" @click="fn_tabIdx('2')">기간별</mo-tab-label>
            </mo-tab-box>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" direction="column">
              <ur-box-container direction="column" alignV="start" v-if="tab_index === '1'" class="pt10">
                <div class="sticky-area">
                  <div class="table-area sticky-t07" ref="tableAreaPop" @scroll="checkScrollPop">
                  <!-- <div class="table-area sticky-t07_1" ref="tableAreaPop" @scroll="checkScrollPop"> -->
                    <table class="table row-type ws_N">
                      <thead>
                        <tr>
                          <th rowspan="2" colspan="2" class="d0-fix">보장급부</th>
                          <!-- <th rowspan="2" class="d0-fix">보장급부</th> -->
                          <th colspan="5" class="aR fs12">(단위:만원)</th>
                          <!-- 동적 타이틀 -->
                          <td rowspan="2" v-for="(item, idx) in headerData" :key="idx">
                            <div class="ellipsis-w145 ws_YY fs15">{{item.name}}</div>
                            <div class="flex-sc mt5">
                              <mo-badge class="badge-sample-badge sm" :class="item.isRecommendProduct ? 'lightred2' : !item.isOtherProduct ? 'lightblue' : item.gtplOtcomPrdtInptScCd === '4' ? 'purple' : 'lightgray2'">
                                {{item.isRecommendProduct ? '추천' : !item.isOtherProduct ? '당사' : item.gtplOtcomPrdtInptScCd === '4' ? '신정원' : '타사'}}
                              </mo-badge>
                              <p class="price">월 <strong>{{item.monthPrice}}</strong></p>
                            </div>
                          </td>
                          <!-- // 동적 타이틀 -->
                        </tr>
                        <tr>
                          <!-- 정적 타이틀 -->
                          <th>권장금액</th>
                          <th>총보장자산</th>
                          <th class="bg_dark">수정</th>
                          <th class="bg_dark">추가</th>
                          <th class="bdR">설계후</th>
                          <!-- // 정적 타이틀 -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, idx) in gridData" :key="idx">
                          <th v-if="item.title" :rowspan="item.rowspan" class="vaT ws_YY wb_K d1-fix" :class="item.color">{{item.title}}</th>
                          <th class="aL ws_YY d2-fix">{{item.type}}</th>
                          <!-- <th class="aL ws_YY d2-fix">
                            <div class="flex">
                              <div v-if="item.title" :class="item.color" class="flex-d1 sticky-txt">{{item.title}}</div>
                              <div class="flex-d2 sticky-txt">{{item.type}}</div>
                            </div>
                          </th> -->
                          <!-- 정적 타이틀 데이터 -->
                          <td class="bg_gray aR"> {{item.requireAmount}} </td>
                          <td class="bg_gray aR"> {{item.custAmount}} </td>
                          <td class="bg_dark aR">
                            <div :class="item.decrease"> <span>{{item.modifyAmount}}</span> </div>
                          </td>
                          <td class="bg_dark aR">
                            <div :class="item.increase"> <span>{{item.addAmount}}</span> </div>
                          </td>
                          <td class="bg_gray aR"> {{item.amount}} </td>
                          <!-- 동적 타이틀 데이터 -->
                          <td class="aR" v-for="(item2, idx2) in headerData" :key="idx2">
                            {{item['amt'+idx2]}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button class="icon-right" @click="scrollToRightPop" ref="iconRight" v-show="showButton"><i></i></button>
                  <button class="icon-down" @click="scrollToDownPop" ref="iconDown" v-show="showButton"><i></i></button>
                </div>
              </ur-box-container>
              <ur-box-container direction="column" alignV="start" v-if="tab_index === '2'" class="pt10">
                <div class="sticky-area">
                  <div class="table-area sticky-t07" ref="tableAreaPop" @scroll="checkScrollPop">
                  <!-- <div class="table-area sticky-t07_1" ref="tableAreaPop" @scroll="checkScrollPop"> -->
                    <table class="table row-type ws_N">
                      <thead>
                        <tr>
                          <th colspan="2" class="d0-fix aR">
                            <p class="fs12">(단위: 만원)</p><p>보장급부</p>
                          </th>
                          <!-- <th class="d0-fix aR">
                            <p class="fs12">(단위: 만원)</p><p>보장급부</p>
                          </th> -->
                          <td></td>
                          <td v-for="(item, idx) in headerData2" :key="idx">
                            <strong class="fs18">{{item.name}}</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, idx) in gridData2" :key="idx">
                          <th v-if="item.title" :rowspan="item.rowspan" class="vaT ws_YY wb_K d1-fix" :class="item.color">{{item.title}}</th>
                          <th class="aL ws_YY d2-fix">{{item.type}}</th>
                          <!-- <th class="aL ws_YY d2-fix">
                            <div class="flex">
                              <div v-if="item.title" :class="item.color" class="flex-d1 sticky-txt">{{item.title}}</div>
                              <div class="flex-d2 sticky-txt">{{item.type}}</div>
                            </div>
                          </th> -->
                          <td class="aR wrap-bf">
                            <div class="before">설계 전</div>
                            <div class="after">후</div> 
                          </td>
                          <td class="aR wrap-bf" v-for="(item2, idx2) in showCovrIdx" :key="idx2">
                            <div class="before cE">{{item['bfAmt'+idx2]}}</div>
                            <div class="after">{{item['afAmt'+idx2]}}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button class="icon-right" @click="scrollToRightPop" ref="iconRight" v-show="showButton"><i></i></button>
                  <button class="icon-down" @click="scrollToDownPop" ref="iconDown" v-show="showButton"><i></i></button>
                </div>
              </ur-box-container>
            </ur-box-container>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import CtConstants from '@/config/constants/ctConstants'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT124P",
    screenId: "MSPCT124P",
    components: { },
    props: {
      pSlctTabIdx: {
        type: String,
        default: '1'
      },

      pProductList: {
        type: Array,
        default: []
      }
    },
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
    }, // mounted end
    mounted () {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog(this.$options.screenId)

      this.fn_setGridData()

    }, // mounted end
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        showButton: true,
        tab_index: '1',
        gridData: [],
        headerData: [],
        gridData2: [],
        headerData2: [],
        scrolling: null
      }
    }, // data end
    watch:{
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      tableHeaderMock: function () {
        return this.$bizUtil.ctUtils.fn_GetTableTitles()
      },
      lv_BaseData: function () {
        // 고객 기본정보
        let returnValue = this.getStore('ctStore').getters.getCustBaseInfo
        return returnValue
      },
      showCovrIdx() {
        return CtConstants.showCovrIdx
      }
    }, // computed end
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_setGridData() {
        let lv_Vm = this
        lv_Vm.gridData = []
        lv_Vm.headerData = []
console.log('tableHeaderMock>> ', this.tableHeaderMock)
        for(let i=0; i<lv_Vm.tableHeaderMock.length; i++){
          let data = lv_Vm.tableHeaderMock[i].columns[0].data
          let title = lv_Vm.tableHeaderMock[i].title

          for(let j=0; j<data.length; j++){
            let row = {}
            
            row.title = ''
            if(j == 0) {
              row.title = title
              row.rowspan = data.length

              if('사망·후유장해' === title || '사망·재해장해' === title){
                row.color = 'cB'
              }else if('진단' === title){
                row.color = 'cG'
              }else if('암치료' === title || '간병·수술' === title || '입원·생활보장' === title
                      || '수술' === title || '입원' === title || '통원치료' === title){
                row.color = 'cP'
              }
            }
            row.type = data[j].type
            row.amount = lv_Vm.$bizUtil.numberWithCommasByNumber(data[j].amount)
            row.attr = data[j].attr
            row.requireAmount = lv_Vm.$bizUtil.numberWithCommasByNumber(data[j].requireAmount)
            row.custAmount = lv_Vm.$bizUtil.numberWithCommasByNumber(data[j].custAmount)
            row.modifyAmount = lv_Vm.$bizUtil.numberWithCommasByNumber(data[j].modifyAmount)
            if(data[j].modifyAmount < 0){
              row.decrease = 'decrease'
            }else{
              row.decrease = ''
            }
            row.addAmount = lv_Vm.$bizUtil.numberWithCommasByNumber(data[j].addAmount)
            if(data[j].addAmount > 0){
              row.increase = 'increase'
            }else{
              row.increase = ''
            }

            lv_Vm.gridData.push(row)
          }
        }

        for(let i=0; i<lv_Vm.$props.pProductList.length; i++){
          let details = lv_Vm.$props.pProductList[i].details

          for(let j=0; j<lv_Vm.gridData.length; j++){
            lv_Vm.gridData[j]['amt'+i] = lv_Vm.fn_getAmt(details, lv_Vm.gridData[j].attr)
          }
        }

        // let idxList = []
        // for(let i=0; i<lv_Vm.gridData.length; i++){
        //   if('사망·재해장해' === lv_Vm.gridData[i].title){
        //     idxList.push(i)
        //   }
        //   else if('진단' === lv_Vm.gridData[i].title){
        //     idxList.push(i)
        //   }
        //   else if('수술' === lv_Vm.gridData[i].title){
        //     idxList.push(i)
        //   }
        // }

        // for(let i=idxList.length-1; i>=0; i--){
        //   lv_Vm.gridData.splice(idxList[i], 0, lv_Vm.titleHeaderGroup[i]) 
        // }
        
        // console.log('lv_Vm.gridData 최종 >> ', JSON.stringify(lv_Vm.gridData))

        for(let i=0; i<lv_Vm.$props.pProductList.length; i++){
          let row = {}

          row.name = lv_Vm.$props.pProductList[i].name
          row.isOtherProduct = lv_Vm.$props.pProductList[i].isOtherProduct
          row.gtplOtcomPrdtInptScCd = lv_Vm.$props.pProductList[i].gtplOtcomPrdtInptScCd
          row.isRecommendProduct = lv_Vm.$props.pProductList[i].isRecommendProduct
          row.hasProduct = lv_Vm.$props.pProductList[i].hasProduct
          row.monthPrice = lv_Vm.$bizUtil.numberWithCommasByNumber(lv_Vm.$props.pProductList[i].monthPrice) + '원'

          lv_Vm.headerData.push(row)
        }


        lv_Vm.gridData2 = []
        lv_Vm.headerData2 = []

        let totalData_all = {}
        let ctTableEngNmLoadMap = lv_Vm.$bizUtil.ctUtils.fn_GetTableTitlesEngNm()

        // 설계전
        let rmovDupData_before = lv_Vm.$bizUtil.ctUtils.fn_getAcontRmovDup(lv_Vm, 'before', lv_Vm.getStore('ctStore').getters.getCovanData.data)
        let totalData_before = lv_Vm.$bizUtil.ctUtils.fn_getSumByYear(rmovDupData_before.type, lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.age, rmovDupData_before.tcompAcontVOFt, rmovDupData_before.otcompAcontVOFt, rmovDupData_before.entplPrpslPrdtVO, rmovDupData_before.gtplInqrStndYrVO)
        // 설계후
        let rmovDupData_after = lv_Vm.$bizUtil.ctUtils.fn_getAcontRmovDup(lv_Vm, 'after', lv_Vm.getStore('ctStore').getters.getCovanData.data)
        let totalData_after = lv_Vm.$bizUtil.ctUtils.fn_getSumByYear(rmovDupData_after.type, lv_Vm.getStore('ctStore').getters.getCustBaseInfo.data.age, rmovDupData_after.tcompAcontVOFt, rmovDupData_after.otcompAcontVOFt, rmovDupData_after.entplPrpslPrdtVO, rmovDupData_after.gtplInqrStndYrVO)

        // 전/후 데이터 한 객체에 담기
        let initCovanAmt = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] // 연령별 금액 (10구간) 데이터 가 없는경우 초기값
        for (let i in ctTableEngNmLoadMap) {
          let group = ctTableEngNmLoadMap[i]
          for (let j in group) {
            // 설계전 정보가 無, 설계후 정보가 有 인경우 년도 / 나이구간 정보를 설계후 로 대체
            totalData_all.yr = totalData_before.yr.length === 0 ? totalData_after.yr : totalData_before.yr
            totalData_all.age = totalData_before.age.length === 0 ? totalData_after.age : totalData_before.age
            // 설계전 데이터가 없는경우 보장금액 0 표시
            totalData_all[group[j]] = (totalData_before[group[j]] === undefined ? initCovanAmt : totalData_before[group[j]])
            totalData_all['AFTER_' + group[j]] = (totalData_after[group[j]] === undefined ? initCovanAmt : totalData_after[group[j]])
          }
        }

        // console.log('totalData_all :: ', JSON.stringify(totalData_all))
        let tmpGridData = []
        for(let i=0; i<lv_Vm.tableHeaderMock.length; i++){
          let data = lv_Vm.tableHeaderMock[i].columns[0].data
          let title = lv_Vm.tableHeaderMock[i].title

          for(let j=0; j<data.length; j++){
            let row = {}
            
            row.title = ''
            if(j == 0) {
              row.title = title
              row.rowspan = data.length

              if('사망·후유장해' === title || '사망·재해장해' === title){
                row.color = 'cB'
              }else if('진단' === title){
                row.color = 'cG'
              }else if('암치료' === title || '간병·수술' === title || '입원·생활보장' === title
                      || '수술' === title || '입원' === title || '통원치료' === title){
                row.color = 'cP'
              }
            }
            row.type = data[j].type
            row.attr = data[j].attr

            tmpGridData.push(row)
          }
        }

        for(let i=0; i<tmpGridData.length; i++){
          let attr = tmpGridData[i].attr
          // console.log('attr >> ', attr)
          let amtList = totalData_all[attr]
          let afterAmtList = totalData_all['AFTER_'+attr]
          let row = {}

          // console.log(amtList, afterAmtList)

          row = this.fn_getBfAf(amtList, tmpGridData[i], 'bf')
          row = this.fn_getBfAf(afterAmtList, row, 'af')

          lv_Vm.gridData2.push(row)

        }

        // console.log('lv_Vm.gridData2 >> ', JSON.stringify(lv_Vm.gridData2))

        lv_Vm.headerData2 = []
        for(let i=0; i<this.showCovrIdx.length; i++){
          let row = {}
          row.name = totalData_all.age[this.showCovrIdx[i]]

          if(row.name === 113){
            row.name = '종신'
          }else{
            row.name += '세'
          }

          lv_Vm.headerData2.push(row)
        }

        this.tab_index = this.pSlctTabIdx === '3' ? '1' :this.pSlctTabIdx
        this.$refs.tabBox.setIdx(this.tab_index)
      },

      fn_getAmt(details, attr) {
        let rtn = ""
        let bool = false
        let lv_Vm = this

        for(let j=0; j<details.length; j++) {
          if(bool) break
          let attrList = details[j]

          for(let k=0; k<attrList.length; k++) {
            if(attr === attrList[k].attr){
              // console.log(attr , attrList[k].attr, attrList[k].value)
              rtn = lv_Vm.$bizUtil.numberWithCommasByNumber(attrList[k].value)
              bool = true
              break
            }
          }

        }

        return rtn
      },
      fn_getBfAf(amtList, gridRow, bfaf) {
        let lv_Vm = this
// console.log(amtList)
        for(let i=0; i<this.showCovrIdx.length; i++){
          // console.log('this.showCovrIdx[i] >> ', this.showCovrIdx[i])
          gridRow[bfaf+'Amt'+i] = lv_Vm.$bizUtil.numberWithCommasByNumber((amtList[this.showCovrIdx[i]]/10000), gridRow)
        }

        return gridRow
      },
      
      fn_ClickBtnPrevious() {
        this.$emit('onPopupClose')
      },

      checkScrollPop() {
        let lv_Vm = this

        if(lv_Vm.showButton){
          const left = lv_Vm.$refs.tableAreaPop.scrollLeft
          const top = lv_Vm.$refs.tableAreaPop.scrollTop
          if(left > 0 || top > 0) { 
            lv_Vm.showButton = false
          }
        }
      },
      scrollToRightPop() {
        const tableArea = this.$refs.tableAreaPop;
        const scrollAmount = 200;
        const scrollDuration = 1000;
        const startingPosition = tableArea.scrollLeft;
        const targetPosition = startingPosition + scrollAmount;
        this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'horizontal');
       },
      scrollToDownPop() { 
        const tableArea = this.$refs.tableAreaPop;
        const scrollAmount = 200;
        const scrollDuration = 1000;
        const startingPosition = tableArea.scrollTop;
        const targetPosition = startingPosition + scrollAmount;
        this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'vertical');
      },

      animateScroll(element, start, target, duration, direction) {
        const startTime = performance.now();
        const animate = (currentTime) => {
          const elapsedTime = currentTime - startTime;
          const progress = Math.min(elapsedTime / duration, 1);
          const easedProgress = this.easeInOutCubic(progress);

          if (direction === 'vertical') {
            element.scrollTop = start + (target - start) * easedProgress;
          } else if (direction === 'horizontal') {
            element.scrollLeft = start + (target - start) * easedProgress;
          }

          if (elapsedTime < duration) {
            requestAnimationFrame(animate);
            
          }
        };
        animate(startTime);
      },

      easeInOutCubic(t) {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t -2) + 1;
      },

      fn_tabIdx(val) {
        if (val === '1') {
          this.tab_index = '1'
        } else {
          this.tab_index = '2'
        }
      }


    } // methods end
  }
</script>
<style scoped>
</style>