<template>
  <ur-page-container ref="page" class="msp" :show-title="false" title="권장금액 선택" :topButton="true" :action-type="actionSlot">
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_close">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '권장금액 선택' : '권장금액 선택'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px"></div> 
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <ur-box-container alignV="start" componentid="" direction="column" class="pal2430">
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment msp-segment-ty1">
                <mo-segment-wrapper solid primary ref="radio" v-model="radioStrValue">
                  <!-- <mo-segment-button value="01">표준형</mo-segment-button> -->
                  <mo-segment-button value="01">권장형</mo-segment-button>
                  <mo-segment-button value="03">컨설턴트 선택형</mo-segment-button>
                </mo-segment-wrapper>
              </ur-box-container>
            </ur-box-container>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- 표준형 -->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area" v-if="radioStrValue == '01'">
              <template v-for="(item, idx) in tableHeaderMock">
                <ur-box-container direction="column" align-v="start" class="ns-add-area pt0 pb35" :key="idx">
                  <div class="fexTy3-2 full mb10">
                    <span class="fwm fs18rem">{{item.columns[0].title}}</span>
                    <span class="fs14rem crTy-bk7">(단위:만원)</span>
                  </div>              
                  <div class="table-area">
                    <table class="table col-type palr10">
                      <colgroup>
                        <col width="50%" />
                        <col width="" />
                      </colgroup>
                      <tbody>
                        <template v-for="(data, idx) in item.columns[0].data">
                          <tr :key="idx">
                            <th class="bdRN txt-left">{{data.type}}</th>
                            <td class="txt-right">{{$commonUtil.numberWithCommas(data.requireAmount)}}</td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </ur-box-container>
              </template>
            </ur-box-container>
            <!-- 컨설턴트 선택형 -->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area" v-if="radioStrValue == '03'">
              <template v-for="(item, idx) in tableHeaderMockByCust">
                <ur-box-container direction="column" align-v="start" class="ns-add-area pt30 pb35" :key="idx">
                  <div class="fexTy3-2 full mb10">
                    <span class="fwm fs18rem">{{item.columns[0].title}}</span>
                    <span class="fs14rem crTy-bk7">(단위:만원)</span>
                  </div>              
                  <div class="table-area">
                    <table class="table col-type pa-type10">
                      <colgroup>
                        <col width="50%" />
                        <col width="" />
                      </colgroup>
                      <tbody>
                        <template v-for="(data, idx2) in item.columns[0].data">
                          <tr :key="''+ idx + idx2">
                            <th class="bdRN">{{data.type}}</th>
                            <td class="txt-right">
                              <mo-decimal-field class="form-input-round txt-right" numeric :ref="data.attr" v-model="data.requireAmount" placeholder="입력" max-numlength="6"/>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </ur-box-container> 
              </template>
            </ur-box-container>              

            <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_close">취소</mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_confirmEnter">확인</mo-button>
              </div>
            </ur-box-container>
          </template>

        </mo-collapsing-header-view>
      </div> 
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT150P",
    screenId: "MSPCT150P",
    components: { },
    props: {
      pAttr: ''
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
            // 상단 검색, 카테고리 버튼 생성
      this.actionSlot = {
        actionComponent: {
          template:'<span><mo-button shape="border" class="ns-btn-round btn-title-style-1">보장설계</mo-button> <mo-icon icon-size="30px" type="picto" >more</mo-icon></span>',
          methods: {

          }
        }
      }
    },
    mounted () {
      this.$bizUtil.insSrnLog(this.$options.screenId)

      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

      this.fn_selMSPCT150P()

      if(!this.$bizUtil.isEmpty(this.$props.pAttr)){
        this.radioStrValue = '03'
        let lv_vm = this
        setTimeout( function () {
          lv_vm.$refs[lv_vm.$props.pAttr][0].focus()
        }, 500)
      }

      // this.radioStrValue = '01'
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        stndKeyList: [],
        lv_HeaderviewScrollCompID: '',
        radioStrValue: '',

        confirmMsg: '',
        cancelMsg: '',
        
        // validateRuleRequired1: [
        //   (v) => (v === '' || v === undefined) || '필수입력항목입니다.' + v
        // ],
        // testValue: 0,

        // rulegenDthCovrAmt: [
        //   // v => !!v || '필수입력항목입니다.' + '>> '+ v + ' <<'
        //   v => '필수입력항목입니다.' + '>> '+ v + ' <<' + !v + !!v
        // ],
        // rulediseDthCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulecncrDthCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulegenDstrDthCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // ruledstrDsabRatOhrCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // ruledstrDsabRatSvtyCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // ruledstrDsabRatThCovrAmt: [v => !!v || '필수입력항목입니다.' + v],

        // rulegenCncrDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulelgatCncrDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulecrbhmDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulecbifDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulecevarDsCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // ruletiscaDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // ruleactmiDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulespfIshHetDsDignAmt: [v => !!v || '필수입력항목입니다.' + v],
        // ruleishHetDsDignAmt: [v => !!v || '필수입력항목입니다.' + v],
        // ruleafaflDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // ruleltcDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // ruleltcMsptDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulessptDmntDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulemsptDmntDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulediabtDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],

        // rulesroSurgCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulenwSurgCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulefeclsCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulecncrSurgCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulespfDiseSurgCovrAmt: [v => !!v || '필수입력항목입니다.' + v],

        // rulecncrHspzCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulegenDiseHspzCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // ruledstrHspzCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // ruleondayGenHspzCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulehspzCarerUseDignAmt: [v => !!v || '필수입력항목입니다.' + v],

        // rulecncrMdhspRmdyCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulecncrRarMdhspCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulelgatAntirDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // ruleimmueAntirDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // ruledstrFrctDignCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulepstsRmdyCovrAmt: [v => !!v || '필수입력항목입니다.' + v],
        // rulepsvtRmdyCovrAmt: [v => !!v || '필수입력항목입니다.' + v]
        
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      tableHeaderMock: function () {
        return this.$bizUtil.cloneDeep(this.$bizUtil.ctUtils.fn_GetTableTitles())
      },
      tableHeaderMockByCust: function () {
        return this.$bizUtil.cloneDeep(this.$bizUtil.ctUtils.fn_GetTableTitles())
      }
    },
    watch: {
      // radioStrValue(v) {
      //   console.log(v)
      //   if(v === '01'){
      //     this.cancelMsg = '취소하시겠습니까?'
      //   }else if(v === '03'){
      //     this.cancelMsg = '편집을 취소하시겠습니까?'
      //   }
      // }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_close() {
        let lv_vm = this
        if(this.radioStrValue === '01'){
          this.cancelMsg = '취소하시겠습니까?'
        }else if(this.radioStrValue === '03'){
          this.cancelMsg = '편집을 취소하시겠습니까?'
        }

        this.$bizUtil.ctUtils.fn_confirm(lv_vm, {
          title: '',
          content: lv_vm.cancelMsg,
          title_pos_btn: '예',
          title_neg_btn: '아니오',
          fn_pos: function(){
            lv_vm.$emit('onPopupClose')
          }
        })
      },
      fn_confirmEnter () {
        let lv_Vm = this
        let mockData = []
        let params = {}
        let stnrdMdlClbyCustVO = []
        let lv_CstInfo = this.getStore('ctStore').getters.getCustBaseInfo.data
        params['cnsltNo'] = lv_CstInfo.cnsltNo
        params['chnlCustId'] = lv_CstInfo.chnlCustId

        console.log('this.radioStrValue >> ', this.radioStrValue)
        if (this.radioStrValue == '01') { // 표준형 선택 시
          mockData = this.tableHeaderMock
        } else {
          mockData = this.tableHeaderMockByCust
        }

        let paramData = {}
        let vuexGtplCovrVO = {} // VUEX 에 저장하기 위한 표준모델 데이터 (각 보장급부에 longAmount, intAmount, doubleAmount, amount 금액 항목 필요)
        let amount = {} // 각 VUEX 보장급부 표준금액에 저장되는 금액 항목 배열
        for (let i in mockData) {
          let objCol = mockData[i].columns
          let j = 0
          for (j in objCol) {
            let objData = objCol[j].data
            let k = 0
            for (k in objData) {
              let amountChk = objData[k].requireAmount * 10000
              if (objData[k].requireAmount === '') {
                this.confirmMsg = '"' + objData[k].type + '" 금액을 입력하여 주세요~'
                this.$addSnackbar({text: this.confirmMsg, error: true})
                this.$refs[objData[k].attr][0].focus()
                return false
              } else {
                // 서버 저장용 표준모델 데이터
                paramData[objData[k].attr] = amountChk

                // VUEX 에 저장하기 위한 표준모델 데이터
                amount = {}
                amount.longAmount = amountChk
                amount.intAmount = amountChk
                amount.doubleAmount = amountChk
                amount.amount = String(amountChk)
                vuexGtplCovrVO[objData[k].attr] = amount
              }
            }
          }
        }
        stnrdMdlClbyCustVO = {
          'chnlCustId': lv_CstInfo.chnlCustId,
          'gtplCovrMdlScCd': this.radioStrValue,
          'gtplCovrVO': paramData
        }
        params['stnrdMdlClbyCustVO'] = stnrdMdlClbyCustVO

        // GA 매니저 대행 컨설턴트 변경시
        if(this.$cmUtil.fn_GachangeTF()){
          params.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
        }
        //매니저가 컨설턴트를 변경했을경우
        this.stndKeyList = []
        if(this.$cmUtil.fn_GachangeTF()){
          this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
        }

        console.log('params = ' ,params)
        let trnstId = 'txTSSCT08I1'
        let auth = 'S'
        window.vue.getStore('progress').dispatch('UPDATE', true)
        this.post(this, params, trnstId, auth)
          .then(function (response) {
            window.vue.getStore('progress').dispatch('UPDATE', false)
            if (lv_Vm.$commonUtil.isSuccess(response)) {
              // 보장플랜보장 모델 구분 코드 , 보장플랜보장 급부 정보 저장
              lv_Vm.getStore('ctStore').dispatch('CUSTCOVRST', {'gtplCovrMdlScCd': stnrdMdlClbyCustVO.gtplCovrMdlScCd, 'data': vuexGtplCovrVO})
              lv_Vm.$addSnackbar({text: '반영되었습니다.'})

              lv_Vm.$emit('onPopupSave')

              // // lv_Vm.getStore('ctStore').dispatch('CUSTREGMDF_F', true)
              // // 보장분석 재계산 IF 를 수행한후 해당 부모화면을 다시 재로드한다.
              // Promise.all([lv_Vm.$parent.$refs.TSSCT005MTab.fn_SelRcalCovrAnly()])
              //   .then(function (response) {
              //     let currContentPrg = lv_Vm.$parent.currentContent.component
              //     if ( currContentPrg === 'TSSCT006M') { // 호출한 부모화면의 컨텐츠가 TSSCT006M ('보장분석>> 보장분석-상품별' 화면이면)
              //       lv_Vm.$parent.$refs.currComp.fn_initTSSCT006MLoadData() // TSSCT006M 의 fn_initTSSCT006MLoadData(초기 로딩 함수) 실행
              //     } else if ( currContentPrg === 'TSSCT008M') { // 호출한 부모화면의 컨텐츠가 TSSCT008M ('보장분석>> 상품컨설팅-상품별' 화면이면)
              //       lv_Vm.$parent.$refs.currComp.fn_initTSSCT008MLoadData() // TSSCT008M 의 fn_initTSSCT006MLoadData(초기 로딩 함수) 실행
              //     } else if ( currContentPrg === 'TSSCT009M') { // 호출한 부모화면의 컨텐츠가 TSSCT009M ('보장분석>> 설계후 보장분석' 화면이면) // 표준모델 재갱신
              //       lv_Vm.$parent.$refs.currComp.fn_loadTSCT009MDataSet() // TSSCT008M 의 fn_initTSSCT006MLoadData(초기 로딩 함수) 실행
              //     }
              //   })
              //   .catch(function (error) {
              //     window.vue.error(error)
              //   })
            } else {
              lv_Vm.confirmMsg = '등록에 실패하였습니다. 다시 시도하여 주세요.'
              lv_Vm.$addSnackbar({text: this.confirmMsg, error: true})
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * Function명 : fn_selTSSCT009P
      * 설명       : 표준모델 조회
      ******************************************************************************/
      fn_selMSPCT150P () {
        let covrStnrdMdlList = this.getStore('ctStore').getters.getCovanData.data.gtplCllgHoshVO.covrStnrdMdlVO // 보장표준모델
        let stnrdMdlClbyCust = this.getStore('ctStore').getters.getCustCovrStnrd // 현재 선택된 세대원의 표준모델

        let stnrdMdl = {data: {}}
        
        console.log('stnrdMdlClbyCust - ' + stnrdMdlClbyCust + ', stnrdMdlClbyCust.gtplCovrMdlScCd - ' + stnrdMdlClbyCust.gtplCovrMdlScCd)
        if (!this.$bizUtil.isEmpty(stnrdMdlClbyCust) && !this.$bizUtil.isEmpty(stnrdMdlClbyCust.gtplCovrMdlScCd)) {
          this.radioStrValue = stnrdMdlClbyCust.gtplCovrMdlScCd
          console.log('this.radioStrValue >> ', this.radioStrValue)
          console.log('stnrdMdlClbyCust.cncrDthCovrAmt - ' + stnrdMdlClbyCust.data['cncrDthCovrAmt'].amount)
          this.$bizUtil.ctUtils.fn_showStndModel(this.tableHeaderMockByCust, stnrdMdlClbyCust)
        } else {
          this.radioStrValue = '01'
          // 표준형 셋팅
          for (let i in covrStnrdMdlList) {
            if (covrStnrdMdlList[i].gtplCovrMdlScCd === '01') {
              console.log('보장표준모델VO에서 정보를 가져옴')
              stnrdMdl.data = covrStnrdMdlList[i].gtplCovrVO
              this.$bizUtil.ctUtils.fn_showStndModel(this.tableHeaderMockByCust, stnrdMdl)
            }
          }
        }

        // 표준형 모델 금액 셋팅함
        for (let i in covrStnrdMdlList) {
          if (covrStnrdMdlList[i].gtplCovrMdlScCd === '01') {
            stnrdMdl.data = covrStnrdMdlList[i].gtplCovrVO
            this.$bizUtil.ctUtils.fn_showStndModel(this.tableHeaderMock, stnrdMdl)
          }
        }


        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ', this.tableHeaderMockByCust, this.tableHeaderMock)
      }
    }
  }
</script>
<style scoped>
</style>