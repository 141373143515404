 /*
 * 업무구분: 타사증권 입력
 * 화 면 명: MSPCT146D
 * 화면설명: 타사증권 입력 3.특약정보 단계 (미등록상품)
 * 접근권한:
 * 작 성 일: 2023.03
 * 작 성 자: 정지윤
 */
 <template>
  <ur-page-container class="msp" :show-title="false" title="타사증권 입력" :topButton="true" :action-type="actionSlot">
<!-- Content영역 -->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30">
              <b class="fs22rem">보장내역</b>
            </ur-box-container>  
            <ur-box-container direction="column" align-v="start" class="ns-add-area pt30 pb35">
              <div class="mb10">
                <span class="fwm fs18rem">사망·후유장해</span>
              </div>              
              <div class="table-area">
                <table class="table col-type pa-type10">
                  <colgroup>
                    <col width="50%" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th class="bdRN">일반사망</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="genDthCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">질병사망</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="diseDthCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">암사망</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="cncrDthCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">일반재해사망</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="genDstrDthCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">정기사망특약</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="fprdDthTrtyCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr>
                      <th class="bdRN">장해율 100%</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="dstrDsabRatOhrCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">장해율 70%</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="dstrDsabRatSvtyCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">장해율 3%</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="dstrDsabRatThCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr> -->
                    <tr>
                      <th class="bdRN">질병후유장해 80%</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="diseDsabRatEyCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">재해후유장해 80%</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="dstrDsabRatEyCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ur-box-container> 
            <ur-box-container direction="column" align-v="start" class="ns-add-area pb35">
              <div class="mb10">
                <span class="fwm fs18rem">진단</span>
              </div>              
              <div class="table-area">
                <table class="table col-type pa-type10">
                  <colgroup>
                    <col width="50%" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th class="bdRN">일반암</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="genCncrDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">고액암</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="lgatCncrDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">뇌출혈</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="crbhmDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">뇌경색</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="cbifDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">뇌혈관질환</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="cevarDsCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr>
                      <th class="bdRN">일과성 뇌허혈발작</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="tiscaDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr> -->
                    <tr>
                      <th class="bdRN">급성심근경색</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="actmiDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr>
                      <th class="bdRN">특정허혈심장질환</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="spfIshHetDsDignAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr> -->
                    <tr>
                      <th class="bdRN">허혈심장질환</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="ishHetDsDignAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr>
                      <th class="bdRN">심방세동/조동</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="afaflDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr> -->
                    <!-- <tr>
                      <th class="bdRN">LTC(중증)</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="ltcDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">LTC(경증)</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="ltcMsptDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">중증도치매</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="ssptDmntDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">경증치매</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="msptDmntDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">당뇨</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="diabtDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr> -->
                    <tr>
                      <th class="bdRN">부정맥</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="aryhiDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ur-box-container> 
            <ur-box-container direction="column" align-v="start" class="ns-add-area pb35">
              <div class="mb10">
                <span class="fwm fs18rem">암치료</span>
              </div>              
              <div class="table-area">
                <table class="table col-type pa-type10">
                  <colgroup>
                    <col width="50%" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th class="bdRN">면역항암</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="immueAntirDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">고액항암(표적)</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="lgatAntirDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">항암방사선치료</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="cncrRarMdhspCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">항암약물치료</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="cncrMdcMdhspCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">상급병원암통원</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="uppHsplCncrMdhspCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">천원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">종합병원암통원</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="cllgCncrMdhspCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">천원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">암통원</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="cncrMdhspRmdyCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">천원</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ur-box-container>
            <ur-box-container direction="column" align-v="start" class="ns-add-area pb35">
              <div class="mb10">
                <span class="fwm fs18rem">간병·수술</span>
              </div>              
              <div class="table-area">
                <table class="table col-type pa-type10">
                  <colgroup>
                    <col width="50%" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th class="bdRN">장기요양(중증)</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="ltcDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">장기요양(경증)</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="ltcMsptDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">중증도치매</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="ssptDmntDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">경증치매</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="msptDmntDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">경도인지장애</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="micoiDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr>
                      <th class="bdRN">중대한 수술</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="sroSurgCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr> -->
                    <tr>
                      <th class="bdRN">신수술</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="nwSurgCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">질병종수술</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="feclsCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">재해종수술</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="dstrSgcfCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">질병수술</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="diseSurgCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">재해수술</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="dstrSurgCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">암수술</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="cncrSurgCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">특정질병수술(N대)</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="spfDiseSurgCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ur-box-container>
            <ur-box-container direction="column" align-v="start" class="ns-add-area pb35">
              <div class="mb10">
                <span class="fwm fs18rem">입원·생활보장</span>
              </div>              
              <div class="table-area">
                <table class="table col-type pa-type10">
                  <colgroup>
                    <col width="50%" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th class="bdRN">암입원</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="cncrHspzCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">천원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">질병입원</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="genDiseHspzCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">천원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">재해입원</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="dstrHspzCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">천원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">상급병실입원</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="uppWardHspzCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">천원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">1일입원</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="ondayGenHspzCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">천원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">질병입원간병인사용</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="hspzCarerUseDignAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">천원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">재해입원간병인사용</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="dstrHspzCarerUseCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">천원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">재해골절진단</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="dstrFrctDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">치과 보철치료</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="pstsRmdyCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">치과 보존치료</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="psvtRmdyCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ur-box-container>
            <!-- <ur-box-container direction="column" align-v="start" class="ns-add-area pb35">
              <div class="mb10">
                <span class="fwm fs18rem">통원치료</span>
              </div>              
              <div class="table-area">
                <table class="table col-type pa-type10">
                  <colgroup>
                    <col width="50%" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th class="bdRN">암통원</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="cncrMdhspRmdyCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">천원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">항암치료</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="cncrRarMdhspCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">고액항암</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="lgatAntirDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">면역항암</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="immueAntirDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">재해골절진단</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="dstrFrctDignCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">치과 보철치료</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="pstsRmdyCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="bdRN">치과 보존치료</th>
                      <td>
                        <div class="fexTy5">
                          <mo-decimal-field numeric :maxlength="9" class="form-input-round w78 txt-right" v-model="psvtRmdyCovrAmt" placeholder="입력" />
                          <span class="ml4 fs14rem">만원</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ur-box-container>  -->
            <ur-box-container direction="column" align-v="start" class="con-area pb50">            
              <div class="text-gray-box">
                <ul class="terms-list-area crTy-bk7 fs14rem">
                  <li>타사 실손의료보험 보장내역의 입원,통원,처방조제비란에는 각각 항목별 보장한도 금액을 입력하시기 바랍니다.</li>
                </ul>
              </div> 
            </ur-box-container>  
            <!-- Content 영역 end -->

    <!-- Popup 납입완료가 만기년월보다 클 경우 Alert -->
    <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
      <div class="customer-info-txt txt-center">
        <p class="ment">납입완료 년월은<br/>만기년월 보다 늦을수 없습니다.</p>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" name="확인">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT146D",
    screenId: "MSPCT146D",
    components: { },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
            // 상단 검색, 카테고리 버튼 생성
      this.actionSlot = {
        actionComponent: {
          template:'<span><mo-button shape="border" class="ns-btn-round btn-title-style-1">보장설계</mo-button> <mo-icon icon-size="30px" type="picto" >more</mo-icon></span>',
          methods: {

          }
        }
      }
    },
    mounted () {
      
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 사망·재해장해
        genDthCovrAmt: 0, // 일반사망
        diseDthCovrAmt: 0, // 질병사망
        cncrDthCovrAmt: 0, // 암사망
        genDstrDthCovrAmt: 0, // 일반재해사망
        fprdDthTrtyCovrAmt: 0, // 정기사망특약
        // dstrDsabRatOhrCovrAmt: 0, // 장해율 100%
        // dstrDsabRatSvtyCovrAmt: 0, // 장해율 70%
        // dstrDsabRatThCovrAmt: 0, // 장해율 3%
        // 진단
        genCncrDignCovrAmt: 0, // 일반암
        lgatCncrDignCovrAmt: 0, // 고액암
        crbhmDignCovrAmt: 0, // 뇌출혈
        cbifDignCovrAmt: 0, // 뇌경색
        cevarDsCovrAmt: 0, // 뇌혈관질환 추가 - 보장분석시스템 고도화PJT
        // tiscaDignCovrAmt: 0, // 일과성 뇌허혈발작 추가 - 보장분석시스템 고도화PJT
        actmiDignCovrAmt: 0, // 급성심근경색
        // spfIshHetDsDignAmt: 0, // 특정허열성심장질환
        ishHetDsDignAmt: 0, // 허열성심장질환
        // afaflDignCovrAmt: 0, // 심방세동/조동 추가 - 보장분석시스템 고도화PJT
        ltcDignCovrAmt: 0, // LTC(중증) > 장기요양(중증)
        ltcMsptDignCovrAmt: 0, // LTC(경증) > 장기요양(경증)
        ssptDmntDignCovrAmt: 0, // 중등도치매
        msptDmntDignCovrAmt: 0, // 경증치매
        // diabtDignCovrAmt: 0, // 당뇨
        // 수술
        // sroSurgCovrAmt: 0, // 중대한수술
        nwSurgCovrAmt: 0, // 신수술
        feclsCovrAmt: 0, // 종수술 > 질병종수술 1~5종 (최대값)
        cncrSurgCovrAmt: 0, // 암수술(암수술보장금액)
        spfDiseSurgCovrAmt: 0, // 특정질병수술 > 특정질병수술(N대)
        // 입원
        cncrHspzCovrAmt: 0, // 암입원
        genDiseHspzCovrAmt: 0, // 질병입원
        dstrHspzCovrAmt: 0, // 재해입원
        ondayGenHspzCovrAmt: 0, // 1일입원
        hspzCarerUseDignAmt: 0, // 입원간병인사용 > 질병입원간병인사용
        // 통원치료
        cncrMdhspRmdyCovrAmt: 0, // 암통원
        cncrRarMdhspCovrAmt: 0, // 항암치료 > 항암방사선치료
        lgatAntirDignCovrAmt: 0, // 고액항암 > 고액항암(표적)
        immueAntirDignCovrAmt: 0, // 면역항암
        dstrFrctDignCovrAmt: 0, // 재해골절진단
        pstsRmdyCovrAmt: 0, // 치과_보철치료
        psvtRmdyCovrAmt: 0, // 치과_보존치료
        // ASR240301221_보장설계 신규급부 개발_사랑On 2024.06.04
        diseDsabRatEyCovrAmt: 0, // 질병후유장해 80%
        dstrDsabRatEyCovrAmt: 0, // 재해후유장해 80%
        aryhiDignCovrAmt: 0, // 부정맥
        cncrMdcMdhspCovrAmt: 0, // 항암약물치료
        uppHsplCncrMdhspCovrAmt: 0, // 상급병원암통원
        cllgCncrMdhspCovrAmt: 0, // 종합병원암통원
        cncrMdhspRmdyCovrAmt: 0, // 암통원
        micoiDignCovrAmt: 0, // 경도인지장애
        dstrSgcfCovrAmt: 0, // 재해종수술
        diseSurgCovrAmt: 0, // 질병수술
        dstrSurgCovrAmt: 0, // 재해수술
        uppWardHspzCovrAmt: 0, // 상급병실입원
        dstrHspzCarerUseCovrAmt: 0, // 재해입원간병인사용
        // 의료 실손
        diseHspzRldmCovrAmt: '0', // 질병입원의료비
        diseMdhspRldmCovrAmt: '0', // 질병통원의료비
        dstrHspzRldmCovrAmt: '0', // 상해입원의료비
        dstrMdhspRldmCovrAmt: '0', // 상해통원의료비
        mtherRldmCovrAmt: '0', // 도수치료
        injcRldmCovrAmt: '0', // 주사료
        mriRldmCovrAmt: '0', // MRI/MRA

        getSelOtcomInsr: {} ,
        entryType:''
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      getunOtcom() {
        return this.getStore('ctStore').getters.getunOtcom.data !== undefined ? this.getStore('ctStore').getters.getunOtcom.data : {}
      }
    },
    watch: {
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_BtnI1OnClick
      * 설명       : 미등록된 타사보험 저장(IF-CT-0012) 인터페이스 호출
      ******************************************************************************/
      fn_BtnI1OnClick () {
        if (Object.keys(this.getunOtcom).length > 0) {
          let lv_Vm = this
          let pParams = {
            // 사망
            genDthCovrAmt: lv_Vm.genDthCovrAmt * 10000, // 일반사망
            diseDthCovrAmt: lv_Vm.diseDthCovrAmt * 10000, // 질병사망
            cncrDthCovrAmt: lv_Vm.cncrDthCovrAmt * 10000, // 암사망
            genDstrDthCovrAmt: lv_Vm.genDstrDthCovrAmt * 10000, // 일반재해
            fprdDthTrtyCovrAmt: lv_Vm.fprdDthTrtyCovrAmt * 10000, // 정기사망특약
            // 수술
            // sroSurgCovrAmt: lv_Vm.sroSurgCovrAmt * 10000, // 중대한수술
            nwSurgCovrAmt: lv_Vm.nwSurgCovrAmt * 10000, // 신수술
            feclsCovrAmt: lv_Vm.feclsCovrAmt * 10000, // 1~5종 (최대값) > 질병종수술
            cncrSurgCovrAmt: lv_Vm.cncrSurgCovrAmt * 10000, // 암(암수술보장금액)
            spfDiseSurgCovrAmt: lv_Vm.spfDiseSurgCovrAmt * 10000, // 특정질병 > 특정질병수술(N대)
            // 의료 실손
            diseHspzRldmCovrAmt: '0', // 질병입원의료비
            diseMdhspRldmCovrAmt: '0', // 질병통원의료비
            dstrHspzRldmCovrAmt: '0', // 상해입원의료비
            dstrMdhspRldmCovrAmt: '0', // 상해통원의료비
            mtherRldmCovrAmt: '0', // 도수치료
            injcRldmCovrAmt: '0', // 주사료
            mriRldmCovrAmt: '0', // MRI/MRA
            // 장해
            // dstrDsabRatOhrCovrAmt: lv_Vm.dstrDsabRatOhrCovrAmt * 10000, // 장해율 100%
            // dstrDsabRatSvtyCovrAmt: lv_Vm.dstrDsabRatSvtyCovrAmt * 10000, // 장해율 70%
            // dstrDsabRatThCovrAmt: lv_Vm.dstrDsabRatThCovrAmt * 10000, // 장해율 3%
            // 입원
            cncrHspzCovrAmt: lv_Vm.cncrHspzCovrAmt * 1000, // 암입원
            genDiseHspzCovrAmt: lv_Vm.genDiseHspzCovrAmt * 1000, // 기타 질병
            dstrHspzCovrAmt: lv_Vm.dstrHspzCovrAmt * 1000, // 재해입원
            ondayGenHspzCovrAmt: lv_Vm.ondayGenHspzCovrAmt * 1000, // 1일입원
            hspzCarerUseDignAmt: lv_Vm.hspzCarerUseDignAmt * 1000, // 입원간병인사용 > 질병입원간병인사용
            // 통원치료
            cncrMdhspRmdyCovrAmt: lv_Vm.cncrMdhspRmdyCovrAmt * 1000, // 암
            cncrRarMdhspCovrAmt: lv_Vm.cncrRarMdhspCovrAmt * 10000, // 항암치료 > 항암방사선치료
            lgatAntirDignCovrAmt: lv_Vm.lgatAntirDignCovrAmt * 10000, // 고액항암 > 고액항암(표적)
            immueAntirDignCovrAmt: lv_Vm.immueAntirDignCovrAmt * 10000, // 면역항암
            dstrFrctDignCovrAmt: lv_Vm.dstrFrctDignCovrAmt * 10000, // 재해골절진단
            pstsRmdyCovrAmt: lv_Vm.pstsRmdyCovrAmt * 10000, // 치과_보철치료
            psvtRmdyCovrAmt: lv_Vm.psvtRmdyCovrAmt * 10000, // 치과_보존치료
            // 진단
            genCncrDignCovrAmt: lv_Vm.genCncrDignCovrAmt * 10000, // 일반암
            lgatCncrDignCovrAmt: lv_Vm.lgatCncrDignCovrAmt * 10000, // 고액암
            crbhmDignCovrAmt: lv_Vm.crbhmDignCovrAmt * 10000, // 뇌출혈
            cbifDignCovrAmt: lv_Vm.cbifDignCovrAmt * 10000, // 뇌경색
            cevarDsCovrAmt: lv_Vm.cevarDsCovrAmt * 10000, // 뇌혈관질환 추가 - 보장분석시스템 고도화PJT
            // tiscaDignCovrAmt: lv_Vm.tiscaDignCovrAmt * 10000, // 일과성 뇌허혈발작 추가 - 보장분석시스템 고도화PJT
            actmiDignCovrAmt: lv_Vm.actmiDignCovrAmt * 10000, // 급성심근경색
            // spfIshHetDsDignAmt: lv_Vm.spfIshHetDsDignAmt * 10000, // 특정허열성심장질환
            ishHetDsDignAmt: lv_Vm.ishHetDsDignAmt * 10000, // 허열성심장질환
            // afaflDignCovrAmt: lv_Vm.afaflDignCovrAmt * 10000, // 심방세동/조동 추가 - 보장분석시스템 고도화PJT
            ltcDignCovrAmt: lv_Vm.ltcDignCovrAmt * 10000, // LTC(중증) > 장기요양(중증)
            ltcMsptDignCovrAmt: lv_Vm.ltcMsptDignCovrAmt * 10000, // LTC(경증) > 장기요양(경증)
            ssptDmntDignCovrAmt: lv_Vm.ssptDmntDignCovrAmt * 10000, // 중등도치매
            msptDmntDignCovrAmt: lv_Vm.msptDmntDignCovrAmt * 10000, // 경증치매
            // diabtDignCovrAmt: lv_Vm.diabtDignCovrAmt * 10000, // 당뇨
            // ASR240301221_보장설계 신규급부 개발_사랑On 2024.06.04
            diseDsabRatEyCovrAmt: lv_Vm.diseDsabRatEyCovrAmt * 10000, // 질병후유장해 80%
            dstrDsabRatEyCovrAmt: lv_Vm.dstrDsabRatEyCovrAmt * 10000, // 재해후유장해 80%
            aryhiDignCovrAmt: lv_Vm.aryhiDignCovrAmt * 10000, // 부정맥
            cncrMdcMdhspCovrAmt: lv_Vm.cncrMdcMdhspCovrAmt * 10000, // 항암약물치료
            uppHsplCncrMdhspCovrAmt: lv_Vm.uppHsplCncrMdhspCovrAmt * 1000, // 상급병원암통원
            cllgCncrMdhspCovrAmt: lv_Vm.cllgCncrMdhspCovrAmt * 1000, // 종합병원암통원
            cncrMdhspRmdyCovrAmt: lv_Vm.cncrMdhspRmdyCovrAmt * 1000, // 암통원
            micoiDignCovrAmt: lv_Vm.micoiDignCovrAmt * 10000, // 경도인지장애
            dstrSgcfCovrAmt: lv_Vm.dstrSgcfCovrAmt * 10000, // 재해종수술
            diseSurgCovrAmt: lv_Vm.diseSurgCovrAmt * 10000, // 질병수술
            dstrSurgCovrAmt: lv_Vm.dstrSurgCovrAmt * 10000, // 재해수술
            uppWardHspzCovrAmt: lv_Vm.uppWardHspzCovrAmt * 1000, // 상급병실입원
            dstrHspzCarerUseCovrAmt: lv_Vm.dstrHspzCarerUseCovrAmt * 1000, // 재해입원간병인사용
            // 기타 디폴트로 들어가는 보장내역
            oldThclsCovrAmt: '0', // 1~3종 (최대값)
            oldTwclsCovrAmt: '0', // 1~3종 (최대값)
            spfCncrSurgCovrAmt: '0', // 특정암(특정암수술보장금액)
            ciDignCovrAmt: '0', // CI진단보장금액 (AS-IS 에서 0으로 할당)
            disePrescRldmCovrAmt: '0', // 질병처방실손보장금액
            dstrPrescRldmCovrAmt: '0', // 재해처방실손보장금액
            esrfDignCovrAmt: '0', // 말기신부전증진단보장금액
            ldsDignCovrAmt: '0', // 폐질환진단보장금액
            lvdsDignCovrAmt: '0', // 간질환진단보장금액
            comprSroDiseDignAmt: '0', // 비교중대질병진단
            // 보험기본정보
            chnlCustId: lv_Vm.getunOtcom.chnlCustId, // 채널고객ID
            chnlMnCustId: lv_Vm.getStore('ctStore').getters.getCovanData.data.gtplCllgHoshVO.chnlMnCustId, // 채널주고객ID
            srcCustId: lv_Vm.getunOtcom.srcCustId, //
            cnsltNo: lv_Vm.getunOtcom.cnsltNo, // 컨설턴트번호
            contrNm: lv_Vm.getunOtcom.contrNm, // 계약자명
            mnCustRltnCd: lv_Vm.getunOtcom.mnCustRltnCd, // 주고객관계코드
            mnCustRltnCdNm: lv_Vm.getunOtcom.mnCustRltnCdNm, // 가족관계 구분명
            insrAge: lv_Vm.getunOtcom.insrAge, // 연령
            insrSxdsCd: lv_Vm.getunOtcom.insrSxdsCd, // 성별코드
            insrSxdsCdNm: lv_Vm.getunOtcom.insrSxdsCdNm, // 성별코드명
            insrCoNm: lv_Vm.getunOtcom.insrCoNm, // 보험회사명
            otcomPrdtNm: lv_Vm.getunOtcom.otcomPrdtNm, // 타사 상품명
            insrdNm: lv_Vm.getunOtcom.insrdNm, // 보험대상자
            gtplInsrScCd: lv_Vm.getunOtcom.gtplInsrScCd, // 보장플랜보험구분코드(03. 유니버설)
            insrdObjScCd: lv_Vm.getunOtcom.insrdObjScCd, // 피보험자대상구분코드(01.주피, 02종피)
            insrSecusNo: lv_Vm.getunOtcom.insrSecusNo, // 보험증권번호
            contYm: lv_Vm.getunOtcom.contYm.substr(0, 7).replace('-', ''), // 계약년월
            expYm: lv_Vm.getunOtcom.expYm.substr(0, 7).replace('-', ''), // 만기년월
            fprdDthTrtyExpYm: lv_Vm.getunOtcom.fprdDthTrtyExpYm.substr(0, 7).replace('-', ''), // 정기사망특약만기년월
            trtyExpYm: lv_Vm.getunOtcom.trtyExpYm.substr(0, 7).replace('-', ''), // 특약만기년월
            padCmpltYm: lv_Vm.getunOtcom.padCmpltYm.substr(0, 7).replace('-', ''), // 납입완료년월
            padCylCd: lv_Vm.getunOtcom.padCylCd, // 납입주기 코드
            padNts: lv_Vm.getunOtcom.padNts, // 납입주기 횟수
            prm: lv_Vm.getunOtcom.prm, // 보험료
            trtyExpChkYn: lv_Vm.getunOtcom.trtyExpChkYn, // 특약만기체크여부
            crncyCd: lv_Vm.getunOtcom.crncyCd, // 화폐코드
            otcomContSno: lv_Vm.getunOtcom.otcomContSno ? lv_Vm.getunOtcom.otcomContSno : '', // 타사계약일련번호
            kcisSecusNo: lv_Vm.getunOtcom.kcisSecusNo ? lv_Vm.getunOtcom.kcisSecusNo : '' // 신용정보원증권번호(보장분석시스템 고도화 PJT)
          }
          // let diseHspzRldmCovrAmt = Number(pParams.diseHspzRldmCovrAmt)
          // let diseMdhspRldmCovrAmt = Number(pParams.diseMdhspRldmCovrAmt)
          // let dstrHspzRldmCovrAmt = Number(pParams.dstrHspzRldmCovrAmt)
          // let dstrMdhspRldmCovrAmt = Number(pParams.dstrMdhspRldmCovrAmt)
          // let mtherRldmCovrAmt = Number(pParams.mtherRldmCovrAmt)
          // let injcRldmCovrAmt = Number(pParams.injcRldmCovrAmt)
          // let mriRldmCovrAmt = Number(pParams.mriRldmCovrAmt)

          // if (Number(pParams.contYm) >= 201704) {
          //   if ((mtherRldmCovrAmt + injcRldmCovrAmt + mriRldmCovrAmt) > 0) {
          //     if ((diseHspzRldmCovrAmt + diseMdhspRldmCovrAmt + dstrHspzRldmCovrAmt + dstrMdhspRldmCovrAmt) === 0) {
          //       this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC014'])
          //       return false
          //     }
          //   }
          // } else if (Number(pParams.contYm) < 201704) {
          //   if ((mtherRldmCovrAmt + injcRldmCovrAmt + mriRldmCovrAmt) > 0) {
          //     lv_Vm.regOtcomInsrVO[0][1].data[4].value = '0' // 도수치료
          //     lv_Vm.regOtcomInsrVO[0][1].data[5].value = '0' // 주사료
          //     lv_Vm.regOtcomInsrVO[0][1].data[6].value = '0'
          //     this.getStore('confirm').dispatch('ADD', this.$t('ct')['ECTC015'])
          //     return false
          //   }
          // }
          let trnstId = 'txTSSCT12I1'
          let auth = 'I'
          window.vue.getStore('progress').dispatch('UPDATE', true)
          console.log('pParams>>>>>>>>>>>>>>', pParams)
          
          // 신용정보원 보험 저장(등록/수정) - 보장분석시스템 고도화 PJT
          // if (this.$parent.$refs.TSSCT005P.entryType === 'kcis') {
          if (this.entryType === 'kcis') {
            trnstId = 'txTSSCT12I2'
          }

          this.post(lv_Vm, pParams, trnstId, auth, {}, true)
            .then(function (response) {
              window.vue.getStore('progress').dispatch('UPDATE', false)
              if (lv_Vm.$commonUtil.isSuccess(response)) {
                // let currContentPrg = lv_Vm.$parent.currentContent.component
                let lv_PromiseArray = []
                // lv_Vm.getStore('ctStore').dispatch('CUSTREGMDF_F', true)
                // lv_PromiseArray.push(lv_Vm.$parent.$refs.TSSCT005MTab.fn_initLoadOtCompData())
                // if ( currContentPrg === 'TSSCT003M') { // 호출한 부모화면의 컨텐츠가 TSSCT003M ('보장분석>> 계약현황분석 기계약현황' 화면이면)
                //   lv_PromiseArray.push(lv_Vm.$parent.$refs.currComp.fn_initTSSCT003MLoadData()) // TSSCT003M 의 fn_initTSSCT003MLoadData(초기 로딩 함수) 실행
                // } else if ( currContentPrg === 'TSSCT006M') { // 호출한 부모화면의 컨텐츠가 TSSCT003M ('보장분석>> 보장분석 >> 상품별' 화면이면)
                //   lv_PromiseArray.push(lv_Vm.$parent.$refs.currComp.fn_initTSSCT006MLoadData()) // TSSCT003M 의 fn_initTSSCT006MLoadData(초기 로딩 함수) 실행
                // } else if ( currContentPrg === 'TSSCT008M') { // 호출한 부모화면의 컨텐츠가 TSSCT003M ('보장분석>> 상품컨설팅 >> 상품별' 화면이면)
                //   lv_PromiseArray.push(lv_Vm.$parent.$refs.currComp.fn_initTSSCT008MLoadData()) // TSSCT003M 의 fn_initTSSCT008MLoadData(초기 로딩 함수) 실행
                // }
                // // else {
                // //   lv_PromiseArray.push(lv_Vm.$parent.$refs.TSSCT005MTab.fn_initLoadOtCompData()) // 그외 TSSCT005M 의 fn_initLoadOtCompData(타사증권 입력후 갱신 함수) 실행
                // // }
                Promise.all(lv_PromiseArray)
                  .then(function (response) {
                    lv_Vm.getStore('ctStore').dispatch('CUSTREGMDF_F', true) // 상품구성변경여부
                    // lv_Vm.getStore('confirm').dispatch('ADD', '등록되었습니다.')
                  }).catch(function (error) {
                    window.vue.error(error)
                  })
              } else {
                lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
              }
            })
            .catch(function (error) {
              window.vue.error(error)
              console.log('error@@ >>> ', error)
            }) 
          //this.fn_SelRegOtcomList()
          console.log('pParams >>> ', pParams)
        }
      },

      /******************************************************************************
      * Function명 : fn_SelRegOtcomList
      * 설명       : 미등록된 타사보험 보장내역 조회
      ******************************************************************************/
      fn_SelRegOtcomList () {
        // let lv_tempArray = []
        for (let i in this.regOtcomInsrVO) {
          for (let j in this.regOtcomInsrVO[i]) {
            let regOtcomVO = this.regOtcomInsrVO[i][j].data
            for (let k in regOtcomVO) {
              let lv_ChkItem = regOtcomVO[k]

              // // 신용정보원 추가 - 보장분석시스템 고도화 PJT
              // if (this.$parent.$refs.TSSCT005P.entryType === 'unReg' || this.$parent.$refs.TSSCT005P.entryType === 'kcis') {
              //   lv_ChkItem.value = this.getunOtcom[lv_ChkItem.attr]
              // } else {
              //   lv_ChkItem.value = '0'
              // }
            }
          }
        }
      },

      /******************************************************************************
      * Function명 : fn_updateData
      * 설명       : 미등록타사, 신용정보원 수정 시 
      ******************************************************************************/
      fn_updateData(vindData, type) {
        this.getSelOtcomInsr = vindData.body
        this.entryType = type
        // 사망·재해장해
        this.genDthCovrAmt = this.getSelOtcomInsr.genDthCovrAmt.intAmount / 10000, // 일반사망
        this.diseDthCovrAmt = this.getSelOtcomInsr.diseDthCovrAmt.intAmount / 10000, // 질병사망
        this.cncrDthCovrAmt = this.getSelOtcomInsr.cncrDthCovrAmt.intAmount / 10000, // 암사망
        this.genDstrDthCovrAmt = this.getSelOtcomInsr.genDstrDthCovrAmt.intAmount / 10000, // 일반재해사망
        this.fprdDthTrtyCovrAmt = this.getSelOtcomInsr.fprdDthTrtyCovrAmt.intAmount / 10000, // 정기사망특약
        // this.dstrDsabRatOhrCovrAmt = this.getSelOtcomInsr.dstrDsabRatOhrCovrAmt.intAmount / 10000, // 장해율 100%
        // this.dstrDsabRatSvtyCovrAmt = this.getSelOtcomInsr.dstrDsabRatSvtyCovrAmt.intAmount / 10000, // 장해율 70%
        // this.dstrDsabRatThCovrAmt = this.getSelOtcomInsr.dstrDsabRatThCovrAmt.intAmount / 10000, // 장해율 3%
        // 진단
        this.genCncrDignCovrAmt = this.getSelOtcomInsr.genCncrDignCovrAmt.intAmount / 10000, // 일반암
        this.lgatCncrDignCovrAmt = this.getSelOtcomInsr.lgatCncrDignCovrAmt.intAmount / 10000, // 고액암 > 고액항암(표적)
        this.crbhmDignCovrAmt = this.getSelOtcomInsr.crbhmDignCovrAmt.intAmount / 10000, // 뇌출혈
        this.cbifDignCovrAmt = this.getSelOtcomInsr.cbifDignCovrAmt.intAmount / 10000, // 뇌경색
        this.cevarDsCovrAmt = this.getSelOtcomInsr.cevarDsCovrAmt.intAmount / 10000, // 뇌혈관질환 추가 - 보장분석시스템 고도화PJT
        // this.tiscaDignCovrAmt = this.getSelOtcomInsr.tiscaDignCovrAmt.intAmount / 10000, // 일과성 뇌허혈발작 추가 - 보장분석시스템 고도화PJT
        this.actmiDignCovrAmt = this.getSelOtcomInsr.actmiDignCovrAmt.intAmount / 10000, // 급성심근경색
        // this.spfIshHetDsDignAmt = this.getSelOtcomInsr.spfIshHetDsDignAmt.intAmount / 10000, // 특정허열성심장질환
        this.ishHetDsDignAmt = this.getSelOtcomInsr.ishHetDsDignAmt.intAmount / 10000, // 허열성심장질환
        // this.afaflDignCovrAmt = this.getSelOtcomInsr.afaflDignCovrAmt.intAmount / 10000, // 심방세동/조동 추가 - 보장분석시스템 고도화PJT
        this.ltcDignCovrAmt = this.getSelOtcomInsr.ltcDignCovrAmt.intAmount / 10000, // LTC(중증) > 장기요양(중증)
        this.ltcMsptDignCovrAmt = this.getSelOtcomInsr.ltcMsptDignCovrAmt.intAmount / 10000, // LTC(경증) > 장기요양(경증)
        this.ssptDmntDignCovrAmt = this.getSelOtcomInsr.ssptDmntDignCovrAmt.intAmount / 10000, // 중등도치매
        this.msptDmntDignCovrAmt = this.getSelOtcomInsr.msptDmntDignCovrAmt.intAmount / 10000, // 경증치매
        // this.diabtDignCovrAmt = this.getSelOtcomInsr.diabtDignCovrAmt.intAmount / 10000, // 당뇨
        // 수술
        // this.sroSurgCovrAmt = this.getSelOtcomInsr.sroSurgCovrAmt.intAmount / 10000, // 중대한수술
        this.nwSurgCovrAmt = this.getSelOtcomInsr.nwSurgCovrAmt.intAmount / 10000, // 신수술
        this.feclsCovrAmt = this.getSelOtcomInsr.feclsCovrAmt.intAmount / 10000, // 종수술 > 질병종수술 1~5종 (최대값)
        this.cncrSurgCovrAmt = this.getSelOtcomInsr.cncrSurgCovrAmt.intAmount / 10000, // 암수술(암수술보장금액)
        this.spfDiseSurgCovrAmt = this.getSelOtcomInsr.spfDiseSurgCovrAmt.intAmount / 10000, // 특정질병수술 > 특정질병수술(N대)
        // 입원
        this.cncrHspzCovrAmt = this.getSelOtcomInsr.cncrHspzCovrAmt.intAmount / 1000, // 암입원
        this.genDiseHspzCovrAmt = this.getSelOtcomInsr.genDiseHspzCovrAmt.intAmount / 1000, // 질병입원
        this.dstrHspzCovrAmt = this.getSelOtcomInsr.dstrHspzCovrAmt.intAmount / 1000, // 재해입원
        this.ondayGenHspzCovrAmt = this.getSelOtcomInsr.ondayGenHspzCovrAmt.intAmount / 1000, // 1일입원
        this.hspzCarerUseDignAmt = this.getSelOtcomInsr.hspzCarerUseDignAmt.intAmount / 1000, // 입원간병인사용 > 질병입원간병인사용
        // 통원치료
        this.cncrMdhspRmdyCovrAmt = this.getSelOtcomInsr.cncrMdhspRmdyCovrAmt.intAmount / 1000, // 암통원
        this.cncrRarMdhspCovrAmt = this.getSelOtcomInsr.cncrRarMdhspCovrAmt.intAmount / 10000, // 항암치료 > 항암방사선치료
        this.lgatAntirDignCovrAmt = this.getSelOtcomInsr.lgatAntirDignCovrAmt.intAmount / 10000, // 고액항암
        this.immueAntirDignCovrAmt = this.getSelOtcomInsr.immueAntirDignCovrAmt.intAmount / 10000, // 면역항암
        this.dstrFrctDignCovrAmt = this.getSelOtcomInsr.dstrFrctDignCovrAmt.intAmount / 10000, // 재해골절진단
        this.pstsRmdyCovrAmt = this.getSelOtcomInsr.pstsRmdyCovrAmt.intAmount / 10000, // 치과_보철치료
        this.psvtRmdyCovrAmt = this.getSelOtcomInsr.psvtRmdyCovrAmt.intAmount / 10000, // 치과_보존치료
        // ASR240301221_보장설계 신규급부 개발_사랑On 2024.06.04
        this.diseDsabRatEyCovrAmt = this.getSelOtcomInsr.diseDsabRatEyCovrAmt.intAmount / 10000, // 질병후유장해 80%
        this.dstrDsabRatEyCovrAmt = this.getSelOtcomInsr.dstrDsabRatEyCovrAmt.intAmount / 10000, // 재해후유장해 80%
        this.aryhiDignCovrAmt = this.getSelOtcomInsr.aryhiDignCovrAmt.intAmount / 10000, // 부정맥
        this.cncrMdcMdhspCovrAmt = this.getSelOtcomInsr.cncrMdcMdhspCovrAmt.intAmount / 10000, // 항암약물치료
        this.uppHsplCncrMdhspCovrAmt = this.getSelOtcomInsr.uppHsplCncrMdhspCovrAmt.intAmount / 1000, // 상급병원암통원
        this.cllgCncrMdhspCovrAmt = this.getSelOtcomInsr.cllgCncrMdhspCovrAmt.intAmount / 1000, // 종합병원암통원
        this.cncrMdhspRmdyCovrAmt = this.getSelOtcomInsr.cncrMdhspRmdyCovrAmt.intAmount / 1000, // 암통원
        this.micoiDignCovrAmt = this.getSelOtcomInsr.micoiDignCovrAmt.intAmount / 10000, // 경도인지장애
        this.dstrSgcfCovrAmt = this.getSelOtcomInsr.dstrSgcfCovrAmt.intAmount / 10000, // 재해종수술
        this.diseSurgCovrAmt = this.getSelOtcomInsr.diseSurgCovrAmt.intAmount / 10000, // 질병수술
        this.dstrSurgCovrAmt = this.getSelOtcomInsr.dstrSurgCovrAmt.intAmount / 10000, // 재해수술
        this.uppWardHspzCovrAmt = this.getSelOtcomInsr.uppWardHspzCovrAmt.intAmount / 1000, // 상급병실입원
        this.dstrHspzCarerUseCovrAmt = this.getSelOtcomInsr.dstrHspzCarerUseCovrAmt.intAmount / 1000 // 재해입원간병인사용
      },
    
    },
  };
</script>
<style scoped>
</style>